import React from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { WorkspaceMemberDto } from '@api/Api';

interface IDeleteMemeberModal {
  members: WorkspaceMemberDto[];
  visible: boolean;
  handleShowDeleteMemberModal: (a: boolean) => void;
  handleDeleteMembers: (ids: string[]) => void | Promise<void>;
}

function DeleteMemberModal({
  members,
  visible,
  handleShowDeleteMemberModal,
  handleDeleteMembers
}: IDeleteMemeberModal) {
  const onFinish = async () => {
    await handleDeleteMembers(members.map((member) => member.id));
    handleShowDeleteMemberModal(false);
  };
  const title = members.length > 1 ? 'Delete members' : 'Delete member';
  const description = `Do you want to delete ${
    members.length > 1 ? `these ${members.length} members` : 'this member'
  }? They will not be notified that they have been removed from the account.`;
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      onCancel={() => handleShowDeleteMemberModal(false)}
      zIndex={1005}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 12 }}
        >
          {title}
        </h2>

        <p className="modal_container__subtitle" style={{ marginBottom: 32 }}>
          {description}
        </p>
        <div className="modal_container__actions">
          <Button
            type="text"
            size="large"
            style={{
              marginRight: 12,
              border: 'none',
              height: 48,
              padding: '12px 19px'
            }}
            onClick={() => handleShowDeleteMemberModal(false)}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={onFinish}
            style={{ width: 216, height: 48, padding: '8px 15px' }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteMemberModal;
