import { CampaignItemDto } from '@api/Api';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { TasksTable } from '@components/Tables';
import TaskListSkeleton from '@components/Tasks/TaskListSkeleton';

import { useTypedSelector } from '@hooks';
import {
  campaignStateSelector,
  tasksBoardSelector
} from '@redux/selectors/tasks';
import React, { memo, useEffect, useMemo, useRef } from 'react';

export default memo(function TasksBoardTable(props: {
  campaign: CampaignItemDto;
  boardId: string;
  boardName: string;
  onLoadMore: () => void;
  draggable: boolean;
  draggingTaskId: string | null;
  overTaskId: string | null;
  overBoardId: string | null;
  dropTaskAfter: boolean;
  onDragStart: (boardId: string, taskId: string) => void;
  onDragEnter: (boardId: string, taskId: string | null) => void;
  onDragEnd: () => void;
}) {
  const {
    campaign,
    boardId,
    boardName,
    onLoadMore,
    draggable,
    draggingTaskId,
    overTaskId,
    overBoardId,
    dropTaskAfter,
    onDragStart,
    onDragEnter,
    onDragEnd
  } = props;
  const campaignId = campaign.id;
  const tasksCount = useTypedSelector(
    (state) => tasksBoardSelector(state, { campaignId, boardId }).count
  );
  const hasMore = useTypedSelector(
    (state) => tasksBoardSelector(state, { campaignId, boardId }).hasMore
  );
  const loading = useTypedSelector(
    (state) =>
      tasksBoardSelector(state, { campaignId, boardId }).fetch.newRequest
        ?.fetchType ?? false
  );
  const taskIds = useTypedSelector(
    (state) => tasksBoardSelector(state, { campaignId, boardId }).taskIds
  );

  const board = useMemo(
    () => ({ id: boardId, name: boardName }),
    [boardId, boardName]
  );
  const customFields = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).customFields.list
  );
  const newTasks = useTypedSelector((state) => state.tasks.newTasks).filter(
    (x) =>
      x.location.campaigns.some(
        (x) => x.id === campaignId && x.boardId === boardId
      )
  );
  const topNewTasksCount = newTasks.filter(
    (x) => x.position === 'start-of-list'
  ).length;
  const bottomNewTasksCount = newTasks.filter(
    (x) => x.position === 'end-of-list'
  ).length;
  const bottomSkeletonsCount =
    tasksCount + bottomNewTasksCount - taskIds.length;
  const allSkeletonsCount = topNewTasksCount + bottomSkeletonsCount;

  const ref = useRef<HTMLDivElement>(null);
  const prevTopNewTasksCount = useRef(topNewTasksCount);
  const prevBottomNewTasksCount = useRef(bottomNewTasksCount);
  useEffect(() => {
    if (
      topNewTasksCount !== prevTopNewTasksCount.current ||
      bottomNewTasksCount !== prevBottomNewTasksCount.current
    ) {
      if (
        topNewTasksCount > prevTopNewTasksCount.current ||
        bottomNewTasksCount > prevBottomNewTasksCount.current
      ) {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block:
            topNewTasksCount > prevTopNewTasksCount.current ? 'start' : 'end'
        });
      }
      prevTopNewTasksCount.current = topNewTasksCount;
      prevBottomNewTasksCount.current = bottomNewTasksCount;
    }
  }, [topNewTasksCount, bottomNewTasksCount]);
  return (
    <div ref={ref}>
      <InfiniteScroll
        loading={loading}
        next={onLoadMore}
        hasMore={hasMore}
        loader={
          <TaskListSkeleton
            count={
              bottomSkeletonsCount + (taskIds.length ? 0 : topNewTasksCount)
            }
          />
        }
        alwaysShowLoader
      >
        <TasksTable
          organizationId={campaign.organizationId}
          workspaceId={campaign.workspaceId}
          isExistCampaign
          taskIds={taskIds}
          hidePlaceholder={allSkeletonsCount > 0}
          board={board}
          campaign={campaign}
          customFields={customFields}
          topSkeletonsCount={topNewTasksCount}
          draggable={draggable && boardId !== 'archived'}
          draggingTaskId={draggingTaskId}
          overBoardId={draggingTaskId ? overBoardId : null}
          overTaskId={overBoardId === boardId ? overTaskId : null}
          dropTaskAfter={dropTaskAfter}
          onDragStart={onDragStart}
          onDragEnter={onDragEnter}
          onDragEnd={onDragEnd}
        />
      </InfiniteScroll>
    </div>
  );
});
