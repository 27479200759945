import InvoiceHistorySection from '@pages/AdminSettings/components/BillingTab/components/InvoiceHistorySection';
import PaymentMethodSection from '@pages/AdminSettings/components/BillingTab/components/PaymentMethodSection';
import PlanInformationSection from '@pages/AdminSettings/components/BillingTab/components/PlanInformationSection';
import { Divider } from 'antd';
import React, { memo } from 'react';

export default memo(function BillingTab() {
  return (
    <div className="billing_container">
      <PlanInformationSection />
      <Divider className="billing_container__divider" />
      <PaymentMethodSection />
      <Divider className="billing_container__divider" />
      <InvoiceHistorySection />
    </div>
  );
});
