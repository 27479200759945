import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TasksList from '@pages/Tasks/TasksList';
import { useOrganization } from '@components/OrganizationBoundary';

function Tasks() {
  const { currentOrganization } = useOrganization();
  const upgradeUniversalTasksView =
    !currentOrganization.entity?.features.universalTaskView;
  if (upgradeUniversalTasksView) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    );
  }
  return (
    <div className="tasks-container">
      <Routes>
        <Route path="all">
          <Route index element={<TasksList page="all" />} />
          <Route path=":workspaceId" element={<TasksList page="all" />} />
        </Route>
        <Route path="my">
          <Route index element={<TasksList page="my" />} />
          <Route path=":workspaceId" element={<TasksList page="my" />} />
        </Route>
        <Route path="assigned">
          <Route index element={<TasksList page="assigned" />} />
          <Route path=":workspaceId" element={<TasksList page="assigned" />} />
        </Route>
        <Route path="deleted">
          <Route index element={<TasksList page="deleted" />} />
          <Route path=":workspaceId" element={<TasksList page="deleted" />} />
        </Route>
        <Route index element={<Navigate to="all" replace />} />
      </Routes>
    </div>
  );
}

export default Tasks;
