import ImageComponent from '@components/ImageComponent';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import { Popover } from 'antd';
import React, { memo } from 'react';

type WorkspacesCellProps = {
  memberId: string;
};

const ORGANIZATION_ROLE_COPY: { [id: string]: string } = {
  'workspace://team-lead': 'Workspace lead',
  'workspace://member': 'Workspace member',
  'workspace://contributor': 'Workspace contributor'
};

export default memo(function WorkspacesCell({ memberId }: WorkspacesCellProps) {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;
  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );
  if (!member) return null;

  return (
    <Popover
      className="workspaces_popover workspaces_cell"
      overlayClassName="workspaces_popover_overlay"
      placement="right"
      title={`Workspace${member.workspaces.length > 1 ? 's' : ''} (${
        member.workspaces.length
      })`}
      trigger={['hover']}
      content={
        <div className="ws_list_container">
          {member.workspaces.map((el) => {
            return (
              <div key={el.id} className="ws_item">
                <ImageComponent
                  alt={`${el.name} avatar`}
                  src={el.picture.url}
                  width={40}
                  height={40}
                  types={['png', 'webp']}
                  id={el.id}
                  name={el.name}
                />
                <div className="info">
                  <div className="ws_name">{el.name}</div>
                  <div className="ws_role">
                    {el.role ? ORGANIZATION_ROLE_COPY[el.role] : 'Admin'}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      }
    >
      {member.workspaces.length}
    </Popover>
  );
});
