import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import {
  multipartUploadParts,
  multipartUploadPartsUploadParams,
  multipartUploadAbort,
  multipartUploadComplete
} from '@api/MultipartUpload';
import { nanoid } from 'nanoid';
import { apiClient } from '@api/client';

export function createTaskAttachmentsUppy(userId: string) {
  let sessionId = sessionStorage.getItem('task-attachments-uppy-session-id');
  if (!sessionId) {
    sessionId = nanoid();
    sessionStorage.setItem('task-attachments-uppy-session-id', sessionId);
  }
  const uppy = new Uppy({
    id: `task-attachments-uppy_${userId}_${sessionId}`,
    restrictions: {
      requiredMetaFields: ['readyForUpload']
    } as any
  });
  uppy.use(AwsS3Multipart, {
    getChunkSize() {
      const MB = 1024 * 1024;
      return 5 * MB;
    },
    async createMultipartUpload(file) {
      const taskId = file.meta.taskId as string;
      const {
        data: { attachmentId, uploadId, key }
      } = await apiClient.attachment.attachmentControllerInitAttachmentUpload({
        targetId: taskId,
        type: 'task-attachment',
        fileName: file.name,
        fileSize: file.size
      });
      file.meta.attachmentId = attachmentId;
      uppy.setFileMeta(file.id, { attachmentId });
      return { uploadId, key };
    },
    async listParts(_, { key, uploadId }) {
      const {
        data: { parts }
      } = await multipartUploadParts({ key, uploadId });

      return parts;
    },
    async prepareUploadParts(_, { key, uploadId, parts }) {
      try {
        const {
          data: { presignedUrls, headers }
        } = await multipartUploadPartsUploadParams({
          key,
          uploadId,
          partNumber: parts.map((x) => x.number)
        });
        return { presignedUrls, headers };
      } catch (err) {
        (err as any).source = { status: 500 };
        throw err;
      }
    },
    async abortMultipartUpload(_, { key, uploadId }) {
      await multipartUploadAbort({ key, uploadId });
    },
    async completeMultipartUpload(_, { key, uploadId, parts }) {
      await multipartUploadComplete({ key, uploadId, parts: parts as any });
      return {};
    }
  });
  return {
    uppy,
    userId
  };
}
