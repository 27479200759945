import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'antd';
import { useAuth } from '@hooks/useAuth';
import { ReactComponent as LockWithStarsSvg } from '@assets/icons/lock-with-stars.svg';
import { ReactComponent as LockOpenWithStarsSvg } from '@assets/icons/lock-open-with-stars.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import ToggleStepVerification from '@components/TwoStepVerification/ToggleStepVerification';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import Icon from '@ant-design/icons';

function Authentication(): React.ReactElement {
  const { user } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const isVerificationEnabled = user.twoStepVerificationEnabled;
  const upgradeTwoFactorAuth =
    !isVerificationEnabled &&
    !currentOrganization.entity?.features.enableTwoFactorAuth;
  return (
    <div className="profile_authentication_container">
      <Row gutter={24} align="middle" justify="space-between">
        <Col>
          <Row gutter={24} align="middle">
            <Col>
              {isVerificationEnabled ? (
                <LockOpenWithStarsSvg />
              ) : (
                <LockWithStarsSvg />
              )}
            </Col>
            <Col>
              <div className="profile_settings_subtitle">
                Two-factor authentication
              </div>
              {isVerificationEnabled
                ? 'Currently enabled for this account'
                : 'Add an additional layer of security and protection to your account.'}
            </Col>
          </Row>
        </Col>
        <Col>
          <UpgradePlanTooltip
            text=" to a Team plan to access this feature."
            isCanVisible={upgradeTwoFactorAuth}
          >
            <Button
              type="primary"
              size="middle"
              className="profile_authentication_btn"
              onClick={() => setIsModalVisible(true)}
              disabled={upgradeTwoFactorAuth}
              icon={
                upgradeTwoFactorAuth ? (
                  <Icon component={UpgradePlanSvg} />
                ) : undefined
              }
            >
              {isVerificationEnabled ? 'Disable' : 'Enable'}
            </Button>
          </UpgradePlanTooltip>
        </Col>
      </Row>
      <Modal
        visible={isModalVisible}
        footer={null}
        centered={true}
        width="100vw"
        closable={false}
        destroyOnClose={true}
        onCancel={() => setIsModalVisible(false)}
        className="profile_authentication_modal"
      >
        <ToggleStepVerification onBack={() => setIsModalVisible(false)} />
      </Modal>
    </div>
  );
}

export default Authentication;
