import { AssetItemDto } from '@api/Api';
import AssetItem from '@components/Approval/ApprovalEmptyStateWrapper/components/AssetItem';
import React, { memo } from 'react';

import './AssetList.less';

type AssetsListProps = {
  list: AssetItemDto[];
  getApprovalsData: () => void;
  onCloseModal: () => void;
};
export default memo(function AssetsList({
  list,
  getApprovalsData,
  onCloseModal
}: AssetsListProps) {
  return (
    <div className="asset-list">
      {list.map((it) => (
        <AssetItem
          key={it.id}
          asset={it}
          onCloseModal={onCloseModal}
          getApprovalsData={getApprovalsData}
        />
      ))}
    </div>
  );
});
