import React from 'react';
import { Button } from 'antd';

import { ReactComponent as MobileWarnSvg } from '@assets/icons/mobile-warn.svg';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';

import './BlockMobile.less';

function BlockMobile() {
  const openDesktopVersion = () => {
    const viewport = document.querySelector('meta[name=viewport]');

    if (viewport) viewport.setAttribute('content', 'width=1024px');
  };

  return (
    <div className="block-mobile_container">
      <div className="block-mobile_logo">
        <LogoSvg />
      </div>
      <div className="block-mobile_icon">
        <MobileWarnSvg />
      </div>
      <div className="block-mobile_title">
        It looks like you are using a mobile device
      </div>
      <div className="description">
        StreamWork isn’t currently available on mobile browsers. To get the most
        out of StreamWork, switch to desktop.
      </div>

      <Button
        style={{ marginTop: 16 }}
        type="ghost"
        size="large"
        onClick={openDesktopVersion}
      >
        Enter desktop site
      </Button>
    </div>
  );
}

export default BlockMobile;
