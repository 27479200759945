import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import MembersTab from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTab';
import TabBarExtraContent from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/TabBarExtraContent';
import WorkspacesTab from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/WorkspacesTab';
import {
  fetchOrganizationMembersCount,
  fetchOrganizationMembersList,
  fetchOrganizationWorkspacesList
} from '@redux/actions/organizations';
import {
  loadMoreMembers,
  setOrganizationActiveTab,
  setOrganizationMembersListNeedRefresh,
  setOrganizationMembersListParams,
  setOrganizationWorkspacesListNeedRefresh,
  setOrganizationWorkspacesListParams
} from '@redux/reducers/organizations';
import {
  organizationMembersStateSelector,
  organizationWorkspaceFilteredCountStateSelector,
  organizationWorkspaceStateSelector
} from '@redux/selectors/organizations';
import { Tabs } from 'antd';
import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default memo(function OrganizationTabs() {
  const { tab } = useParams();
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(true);
  const { activeTab } = currentOrganization;
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const workspacesCount = useTypedSelector((state) =>
    organizationWorkspaceFilteredCountStateSelector(state, { organizationId })
  );

  const membersCount = useTypedSelector(
    (state) => organizationMembersStateSelector(state, { organizationId }).count
  );

  const membersSearchQuery = useTypedSelector(
    (state) =>
      organizationMembersStateSelector(state, { organizationId }).searchQuery
  );

  const workspacesSearchQuery = useTypedSelector(
    (state) =>
      organizationWorkspaceStateSelector(state, { organizationId }).searchQuery
  );

  useEffect(() => {
    if (tab !== 'organization') return;
    if (activeTab === 'manage_workspaces')
      dispatch(setOrganizationWorkspacesListNeedRefresh({ organizationId }));

    if (activeTab === 'manage_members')
      dispatch(setOrganizationMembersListNeedRefresh({ organizationId }));
  }, [dispatch, activeTab, tab, organizationId]);

  useFetch({
    key: `organization-workspaces-list-${organizationId}`,
    selector: (state) =>
      organizationWorkspaceStateSelector(state, { organizationId }).fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchOrganizationWorkspacesList({
          organizationId,
          fetchType
        })
      );
    }
  });

  useFetch({
    key: `organization-members-count-${organizationId}`,
    selector: (state) =>
      organizationMembersStateSelector(state, { organizationId }).countFetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchOrganizationMembersCount({
          organizationId,
          fetchType
        })
      );
    }
  });

  const membersLoading = useFetch({
    key: `organization-members-list-${organizationId}`,
    selector: (state) =>
      organizationMembersStateSelector(state, { organizationId }).fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchOrganizationMembersList({
          organizationId,
          fetchType
        })
      );
    }
  });

  const isManageWorkspaceTabActive = useMemo(
    () => currentOrganization.activeTab === 'manage_workspaces',
    [currentOrganization.activeTab]
  );

  const onLoadMembersMore = useCallback(() => {
    dispatch(loadMoreMembers({ organizationId }));
  }, [organizationId, dispatch]);

  const onOrderMembersChange = useCallback(
    (orderBy) => {
      dispatch(
        setOrganizationMembersListParams({
          organizationId,
          orderBy: [orderBy]
        })
      );
    },
    [dispatch, organizationId]
  );

  const onMembersSearchQueryChange = useCallback(
    (searchQuery) => {
      dispatch(
        setOrganizationMembersListParams({
          organizationId,
          searchQuery
        })
      );
    },
    [dispatch, organizationId]
  );

  const onWorkspacesSearchQueryChange = useCallback(
    (searchQuery) => {
      dispatch(
        setOrganizationWorkspacesListParams({
          organizationId,
          searchQuery
        })
      );
    },
    [dispatch, organizationId]
  );

  const onChangeTab = useCallback(
    (activeTab) => {
      dispatch(setOrganizationActiveTab({ activeTab, organizationId }));
    },
    [dispatch, organizationId]
  );

  const tabItems = useMemo(
    () => [
      {
        label: (
          <div className="tab-title">
            Manage workspaces <span>{workspacesCount}</span>
          </div>
        ),
        key: 'manage_workspaces',
        children: <WorkspacesTab />
      },
      {
        label: (
          <div className="tab-title">
            Manage members <span>{membersCount}</span>
          </div>
        ),
        key: 'manage_members',
        children: (
          <MembersTab
            membersLoading={membersLoading}
            onOrderMembersChange={onOrderMembersChange}
            onLoadMoreMembers={onLoadMembersMore}
          />
        )
      }
    ],
    [
      membersLoading,
      workspacesCount,
      membersCount,
      onLoadMembersMore,
      onOrderMembersChange
    ]
  );

  return (
    <div className="organization_container__body">
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        items={tabItems}
        tabBarExtraContent={
          <TabBarExtraContent
            isManageWorkspaceTabActive={isManageWorkspaceTabActive}
            value={
              isManageWorkspaceTabActive
                ? workspacesSearchQuery
                : membersSearchQuery
            }
            onChange={
              isManageWorkspaceTabActive
                ? onWorkspacesSearchQueryChange
                : onMembersSearchQueryChange
            }
          />
        }
      />
    </div>
  );
});
