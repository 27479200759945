import { useOrganization } from '@components/OrganizationBoundary';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { Button, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import React, { memo, useMemo } from 'react';
import {
  toggleOrganizationRenameModal,
  toggleWorkspaceOrganizationDeletionModal,
  toggleWorkspaceOrganizationLeaveModal
} from '@redux/actions/modalAction';
import { nonNull } from '@helpers/non-null';

export default memo(function OrganizationInfo() {
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(true);

  const menuItems: ItemType[] = useMemo(() => {
    if (!currentOrganization.entity) return [];
    const entity = nonNull(currentOrganization.entity);
    const items: ItemType[] = [];
    if (!entity.owner.me)
      items.push({
        key: 'leave_organization',
        label: 'Leave organization',
        onClick: () => {
          dispatch(
            toggleWorkspaceOrganizationLeaveModal({
              entity,
              visible: true,
              entityName: 'organization'
            })
          );
        }
      });

    if (entity.permissions.changeDetails)
      items.push({
        key: 'rename_organization',
        label: 'Rename organization',
        onClick: () => {
          dispatch(
            toggleOrganizationRenameModal({
              organization: entity,
              visible: true
            })
          );
        }
      });

    if (entity.permissions.delete)
      items.push({
        key: 'delete_organization',
        label: 'Delete organization',
        onClick: () => {
          dispatch(
            toggleWorkspaceOrganizationDeletionModal({
              entityName: 'organization',
              visible: true,
              entity
            })
          );
        }
      });

    return items;
  }, [dispatch, currentOrganization.entity]);

  return (
    <div className="organization_container__manage">
      <div className="title">
        {currentOrganization.entity?.name || ''}
        <Dropdown
          trigger={['click']}
          overlayClassName="organization_container__manage_overlay"
          placement="bottomRight"
          overlay={<Menu items={menuItems} />}
        >
          <Button
            type="text"
            size="small"
            style={{ padding: 0, marginLeft: 12 }}
          >
            <ActionSvg style={{ marginRight: 0 }} />
          </Button>
        </Dropdown>
      </div>
      <div className="description">
        Manage all workspaces and members in your organization here.
      </div>
    </div>
  );
});
