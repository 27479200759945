import React, { useEffect } from 'react';
import { Col, Divider, Row, Select, message } from 'antd';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useAuth } from '@hooks/useAuth';
import { updatePersonalInfo } from '@api/Profile';
import { UpdatePersonalInfoDto } from '@api/Api';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as CheckSvg } from '@assets/icons/check.svg';
import PerWorkspaceSettings from '@pages/ProfileSettings/Notifications/PerWorkspaceSettings';
import SlackButton from '@pages/ProfileSettings/Notifications/SlackButton';
import { timeZones } from './timezones';
import './Notifications.less';

const { Option } = Select;

function Notifications() {
  const [currentWorkspace] = useCurrentWorkspace(false);
  const { user, updateUserProfile } = useAuth(true, 'internal');

  const updateUserData = async (data: UpdatePersonalInfoDto) => {
    try {
      await updatePersonalInfo(data);
      updateUserProfile(data);
    } catch (err: any) {
      message.error(err.response?.data?.message?.[0] || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (!user.timeZone) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (userTimeZone) {
        const getTimeZone = timeZones.find(
          (item) => item.value === userTimeZone
        );
        if (getTimeZone?.value) {
          updateUserData({ timeZone: getTimeZone.value });
        }
      }
    }
  }, [user]);

  return (
    <div className="notifications_container">
      <div className="notifications_block">
        <div className="profile_settings_title">
          Email notification preferences
        </div>
        <div className="profile_settings_text b-gray-color">
          Choose how often you would like to receive email notifications about
          your account activity in StreamWork.
        </div>
      </div>
      <Row gutter={24} justify="space-between" align="middle" wrap={false}>
        <Col>
          <div className="profile_settings_subtitle">Frequency</div>
          <div className="profile_settings_text">
            Choose how frequently you would like to receive email notifications
          </div>
        </Col>
        <Col id="notifications-time">
          <Select
            className="profile_settings_select b-custom-icon"
            dropdownClassName="profile_settings_select-dropdown b-time"
            size="large"
            suffixIcon={<ArrowDownSvg />}
            menuItemSelectedIcon={<CheckSvg />}
            value={user?.notificationFrequency}
            placeholder="Time"
            onChange={(value) =>
              updateUserData({ notificationFrequency: value })
            }
            getPopupContainer={() =>
              document.querySelector('#notifications-time') as HTMLElement
            }
          >
            <Option value={1440}>Daily</Option>
            <Option value={60}>Hourly</Option>
            <Option value={30}>30 minutes</Option>
            <Option value={15}>15 minutes</Option>
          </Select>
        </Col>
      </Row>
      <Divider className="profile_settings_divider" />
      <Row gutter={24} align="middle" justify="space-between" wrap={false}>
        <Col>
          <div className="profile_settings_subtitle">Timezone</div>
          <div className="profile_settings_text">Choose your timezone</div>
        </Col>
        <Col id="notifications-timezone">
          <Select
            className="profile_settings_select b-custom-icon"
            dropdownClassName="profile_settings_select-dropdown b-timezone"
            size="large"
            suffixIcon={<ArrowDownSvg />}
            menuItemSelectedIcon={<CheckSvg />}
            placeholder="Choose your timezone"
            value={user.timeZone}
            onChange={(value) => updateUserData({ timeZone: value })}
            getPopupContainer={() =>
              document.querySelector('#notifications-timezone') as HTMLElement
            }
          >
            {timeZones.map(
              (zone: { name: string; value: string }, index: number) => (
                <Option key={index} value={zone.value}>
                  {zone.name}
                </Option>
              )
            )}
          </Select>
        </Col>
      </Row>
      <Divider className="profile_settings_divider" />
      <div className="notifications_block">
        <div className="profile_settings_title">Chat app connection</div>
        <div className="profile_settings_text b-gray-color">
          Connect your preferred chat application. Get notified about important
          updates and messages directly in your preferred chat app.
        </div>
      </div>
      <Row gutter={24} align="middle" wrap={false}>
        <Col>
          <div className="profile_settings_subtitle">
            <SlackButton />
          </div>
        </Col>
      </Row>
      {!!currentWorkspace && (
        <>
          <Divider className="profile_settings_divider" />
          <div className="notifications_block">
            <div className="profile_settings_title">
              Per workspace preferences
            </div>
            <div className="profile_settings_text b-gray-color">
              Your notification preferences in <b>{currentWorkspace.name}</b>{' '}
              workspace
            </div>
          </div>
          <PerWorkspaceSettings />
        </>
      )}
    </div>
  );
}

export default Notifications;
