import React, { forwardRef, useMemo } from 'react';
import { Dropdown, Menu, Skeleton, Button, Tooltip } from 'antd';
import { FoldersTable } from '@components/Tables';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FolderSvg } from '@assets/icons/folder.svg';
import { ReactComponent as PrivateFolderSvg } from '@assets/icons/folder-private.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { assetGetAssetsDownloadList } from '@api/Asset';
import { useDispatch } from 'react-redux';
import { AssetItemDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import {
  reorganizeFolderList,
  restoreDeletedFolderAction,
  setSelectedFolder
} from '@redux/actions/assetsAction';
import {
  toggleChangeFolderAccessModal,
  toggleEntityDeletionModal,
  toggleManageFolderAccessModal,
  toggleRenameEntityModal
} from '@redux/actions/modalAction';
import { AssetsActionTypes } from '@redux/types/assetsType';
import { downloadAll } from '@helpers/download';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { DraggableItem } from '@components/DraggableList';
import useTypedDispatch from '@hooks/useTypedDispatch';

function FoldersList({
  countToFetch,
  isCardViewMode,
  canReorganize,
  canDropInside,
  listLoading,
  onAssetDrop
}: {
  countToFetch: number;
  isCardViewMode: boolean;
  canReorganize?: boolean;
  canDropInside?: boolean;
  listLoading: boolean;
  onAssetDrop?: (assetId: string, folderId: string) => void;
}) {
  const dispatch = useTypedDispatch();
  const foldersData = useTypedSelector(({ assets }) => assets.folders);
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  return (
    <div>
      {isCardViewMode ? (
        <div
          className="assets-container"
          style={{
            paddingRight: 34,
            paddingLeft: 34,
            marginRight: -34,
            marginLeft: -34
          }}
        >
          <DraggableList
            className="assets-container-list"
            gap={16}
            disableReorder={!canReorganize}
            onReorder={(e) => {
              const newList = [...(foldersData.edges ?? [])];
              const movedItem = newList.splice(e.prevIndex, 1)[0];
              newList.splice(e.newIndex, 0, movedItem);
              const beforeFolderId = newList[e.newIndex - 1]?.node.id;
              dispatch(
                reorganizeFolderList({ id: e.item.id, beforeFolderId }, newList)
              );
            }}
            onDropInside={(e) => {
              if (onAssetDrop) onAssetDrop(e.item.id, e.insideItem.id);
            }}
          >
            {!listLoading && foldersData.edges
              ? foldersData.edges
                  .concat(new Array(countToFetch).fill(''))
                  .map(({ node }) => {
                    if (!node)
                      return (
                        <div className="assets_folder_container">
                          <Skeleton.Input
                            style={{ width: '100%', height: 56 }}
                          />
                        </div>
                      );

                    return (
                      <DraggableItem
                        key={node.id}
                        id={node.id}
                        type="folder"
                        droppable={canDropInside ? 'asset' : false}
                      >
                        <FolderEntity node={node} />
                      </DraggableItem>
                    );
                  })
              : new Array(
                  (campaign?.foldersCount || 0) > 25
                    ? 25
                    : campaign?.foldersCount
                )
                  .fill('')
                  .map((it, idx) => {
                    return (
                      <div key={idx} className="assets_folder_container">
                        <Skeleton.Input style={{ width: '100%', height: 56 }} />
                      </div>
                    );
                  })}
          </DraggableList>
        </div>
      ) : (
        <div className="assets-container-list">
          <FoldersTable
            foldersData={foldersData}
            onDrop={onAssetDrop}
            countToFetch={countToFetch}
            countToLoad={new Array(campaign?.foldersCount).fill('') || []}
            listLoading={listLoading}
          />
        </div>
      )}
    </div>
  );
}

const FolderEntity = forwardRef(
  (
    {
      node
    }: {
      node: AssetItemDto;
    },
    ref: any
  ) => {
    const dispatch = useDispatch();
    const { currentOrganization } = useOrganization();
    const navigate = useNavigate();
    const assetsCountCopy = useMemo(() => {
      const count = node.folder?.totalAssetsCount || 0;
      let copy = 'asset';
      if (count === 0 || count > 1) copy += 's';
      return `${count} ${copy}`;
    }, [node.folder?.totalAssetsCount]);
    const upgradePrivateFolders =
      !currentOrganization.entity?.features.privateFolders;
    return (
      <div ref={ref} className="assets_folder_container">
        <div
          className="assets_folder"
          onClick={() => {
            dispatch(setSelectedFolder(node));
            dispatch({
              type: AssetsActionTypes.SET_ASSETS_LIST,
              payload: { edges: null, count: null }
            });
            navigate(`/campaigns/single/${node.campaignId}/${node?.id}`);
          }}
        >
          <div className="assets_folder__body">
            {node?.isPrivate ? (
              <PrivateFolderSvg style={{ width: 24, height: 24 }} />
            ) : (
              <FolderSvg />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Tooltip
                placement="topLeft"
                overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                title={node?.folder?.name}
              >
                <span>{node?.folder?.name}</span>
              </Tooltip>

              <span className="assets-count">{assetsCountCopy}</span>
            </div>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={
                <Menu>
                  {!node.deletedAt ? (
                    <>
                      <Menu.Item
                        disabled={node.folder?.assetsCount === 0}
                        onClick={async () => {
                          if (!node.folder) return;
                          const {
                            data: { list }
                          } = await assetGetAssetsDownloadList({
                            campaignId: node.campaignId,
                            workspaceId: node.workspaceId,
                            parentId: node.id
                          });
                          downloadAll(
                            list.map((x) => x.sourceUrl),
                            node.folder.name
                          );
                        }}
                      >
                        Download files
                      </Menu.Item>
                      {node?.folder?.permissions?.setVisibility && (
                        <Menu.Item
                          disabled={!node.isPrivate && upgradePrivateFolders}
                          onClick={({ domEvent }) => {
                            domEvent.stopPropagation();
                            if (!node.isPrivate && upgradePrivateFolders)
                              return;
                            if (!node) return;
                            dispatch(
                              toggleChangeFolderAccessModal({
                                folder: node,
                                visible: true,
                                callback: async (folder: AssetItemDto) => {
                                  if (!folder.isPrivate) return;
                                  dispatch(
                                    toggleManageFolderAccessModal({
                                      folder,
                                      visible: true
                                    })
                                  );
                                }
                              })
                            );
                          }}
                        >
                          <UpgradePlanTooltip
                            text=" to a Team plan to access this feature."
                            isCanVisible={
                              !node.isPrivate && upgradePrivateFolders
                            }
                            placement="right"
                            innerStyle={{
                              transform: 'translate(-30px, 0)'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center'
                              }}
                            >
                              {node?.isPrivate ? 'Make public' : 'Make private'}

                              {!node.isPrivate && upgradePrivateFolders && (
                                <UpgradePlanSvg className="upgrade-icon" />
                              )}
                            </div>
                          </UpgradePlanTooltip>
                        </Menu.Item>
                      )}
                      {node?.folder?.permissions?.rename && (
                        <Menu.Item
                          onClick={() => {
                            dispatch(
                              toggleRenameEntityModal({
                                visible: true,
                                entity: node,
                                page: 'folder'
                              })
                            );
                          }}
                        >
                          Rename
                        </Menu.Item>
                      )}
                      {(node?.folder?.permissions?.inviteContributors ||
                        node?.folder?.permissions?.inviteFromList) && (
                        <Menu.Item
                          onClick={() => {
                            if (!node) return;
                            dispatch(
                              toggleManageFolderAccessModal({
                                folder: node,
                                visible: true
                              })
                            );
                          }}
                        >
                          Manage Users
                        </Menu.Item>
                      )}
                      {node?.folder?.permissions?.delete && (
                        <Menu.Item
                          onClick={() => {
                            dispatch(
                              toggleEntityDeletionModal({
                                entity: node,
                                page: 'folder',
                                permanent: false,
                                visible: true
                              })
                            );
                          }}
                        >
                          Delete
                        </Menu.Item>
                      )}
                    </>
                  ) : (
                    <>
                      {' '}
                      <Menu.Item
                        key="delete_permanent"
                        disabled={!node.folder?.permissions.delete}
                        onClick={() => {
                          dispatch(
                            toggleEntityDeletionModal({
                              entity: node,
                              page: 'folder',
                              permanent: true,
                              visible: true
                            })
                          );
                        }}
                      >
                        Delete forever
                      </Menu.Item>
                      <Menu.Item
                        key="restore"
                        disabled={!node.folder?.permissions.delete}
                        onClick={() => {
                          dispatch(
                            restoreDeletedFolderAction({ folderId: node.id })
                          );
                        }}
                      >
                        Restore
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              }
              overlayClassName="asset_links_overlay"
              placement="bottomLeft"
              trigger={['click']}
            >
              <Button type="text" size="small" style={{ padding: 0 }}>
                <ActionSvg style={{ marginRight: 0 }} />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
);

export default FoldersList;
