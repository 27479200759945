import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { useFetch } from '@hooks/useFetch';
import useTypedDispatch from '@hooks/useTypedDispatch';
import InvoiceItemSkeleton from '@pages/AdminSettings/components/BillingTab/components/components/InvoiceItemSkeleton/InvoiceItemSkeleton';
import InvoicesTable from '@pages/AdminSettings/components/BillingTab/components/components/InvoicesTable/InvoicesTable';
import { fetchOrganizationInvoiceHistory } from '@redux/actions/organizations';
import { loadMoreInvoices } from '@redux/reducers/organizations';
import {
  organizationInvoicesStateSelector,
  organizationItemStateSelector
} from '@redux/selectors/organizations';
import React, { memo, useCallback } from 'react';

export default memo(function InvoiceHistorySection() {
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);

  const count = useTypedSelector(
    (state) =>
      organizationInvoicesStateSelector(state, {
        organizationId: organization.id
      }).count
  );

  const list = useTypedSelector(
    (state) =>
      organizationInvoicesStateSelector(state, {
        organizationId: organization.id
      }).list
  );
  const hasMore = useTypedSelector(
    (state) =>
      organizationInvoicesStateSelector(state, {
        organizationId: organization.id
      }).hasMore
  );

  const loading = useFetch({
    key: `organizations-invoices-${organization.id}`,
    selector: (state) =>
      organizationItemStateSelector(state, { organizationId: organization.id })
        .invoices.fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationInvoiceHistory({
          fetchType,
          organizationId: organization.id
        })
      )
  });
  const onLoadMore = useCallback(() => {
    dispatch(loadMoreInvoices({ organizationId: organization.id }));
  }, [dispatch, organization.id]);
  return (
    <div className="billing_container__invoice_history">
      <div className="title">Invoice history</div>
      <InfiniteScroll
        loading={loading}
        next={onLoadMore}
        hasMore={hasMore}
        loader={<InvoiceItemSkeleton count={count - list.length} />}
        alwaysShowLoader
      >
        <InvoicesTable list={list} />
      </InfiniteScroll>
    </div>
  );
});
