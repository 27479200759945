import React, { memo, useState } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import { Button, Input, Popover } from 'antd';
import { useUpdateTask } from '@hooks/tasks';
import { TaskFieldItemDto } from '@api/Api';

interface CustomTextColumnProps {
  taskId: string;
  field: TaskFieldItemDto;
}

export default memo(function CustomTextColumn(props: CustomTextColumnProps) {
  const { taskId, field } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const value =
    task.customFields.find((x) => x.id === field.id)?.value?.toString() ?? '';
  const updateTask = useUpdateTask();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>(value);
  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) setText(value);
  };
  return (
    <Popover
      placement="left"
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}
      overlayClassName="popover-container"
      overlayStyle={{ width: 400, padding: 24 }}
      content={
        <div className="task-table-custom-menu-container">
          <Input.TextArea
            placeholder="Add text..."
            value={text}
            onChange={(event) => setText(event.target.value)}
            autoSize
          />
          <div className="custom-menu-button-container">
            <span onClick={() => onOpenChange(false)}>Cancel</span>
            <Button
              type="primary"
              onClick={() => {
                updateTask({
                  id: taskId,
                  customFields: {
                    add: text
                      ? [
                          {
                            id: field.id,
                            type: field.type,
                            name: field.name,
                            value: text
                          }
                        ]
                      : [],
                    remove: !text ? [{ id: field.id }] : []
                  }
                });
                onOpenChange(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={classNames(
          'task-table-item-container',
          task.board.id === 'archived' && 'deleted-task-container'
        )}
        style={{ width: 142, height: 57, overflow: 'hidden' }}
      >
        {value && <span className="task-table-custom-text-item">{value}</span>}
      </div>
    </Popover>
  );
});
