import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Tabs } from 'antd';

import './AdminSettings.less';
import { useOrganization } from '@components/OrganizationBoundary';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import BillingTab from '@pages/AdminSettings/components/BillingTab/BillingTab';
import OrganizationTab from '@pages/AdminSettings/components/OrganizationTab/OrganizationTab';

const tabs = {
  billing: 'billing',
  organization: 'organization'
} as const;

function AdminSettings() {
  const params = useParams();
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization(true);
  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const onTabChange = (key: string) => {
    navigate(key);
  };
  const defaultTab = tabs.billing;
  const tabParam = params.tab ?? defaultTab;
  const tab = Object.values(tabs).includes(tabParam as any)
    ? tabParam
    : defaultTab;

  if (!currentWorkspace && defaultWorkspace) {
    setCurrentWorkspace(defaultWorkspace.id);
  }
  const tabItems = useMemo(() => {
    const items = [];
    if (currentOrganization.entity?.permissions.billing)
      items.push({
        label: 'Billing',
        key: 'billing',
        children: <BillingTab />
      });
    items.push({
      label: 'Organization',
      key: 'organization',
      children: <OrganizationTab />
    });

    return items;
  }, [currentOrganization.entity?.permissions.billing]);
  return (
    <div className="admin_settings_container">
      <Tabs defaultActiveKey={tab} onChange={onTabChange} items={tabItems} />
    </div>
  );
}

export default AdminSettings;
