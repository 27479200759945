import { AssetForApprovalDto } from '@api/Api';
import Status from '@components/Approval/Status';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';
import DueDate from '@components/Tasks/DueDate';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { isDateSameOrBefore } from '@helpers/compareDueDate';
import { useCurrentTime } from '@hooks/useCurrentTime';
import { Tooltip } from 'antd';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

type ApprovalItemProps = {
  asset: AssetForApprovalDto;
};
export default memo(function ApprovalItem({ asset }: ApprovalItemProps) {
  const now = useCurrentTime();
  const firstVersion = asset.asset?.versions?.[0];
  const aw = firstVersion.approvalWorkflow;
  const imgUrl = assetPreviewThumbnails({
    url: firstVersion.metadata.preview?.url || '',
    status: firstVersion.metadata.preview?.status || '',
    type: firstVersion.metadata.type || ''
  });

  const currentAwStageId = aw?.currentStageId;
  const currentStageDeadline = aw?.stages.find(
    (it) => it.id === currentAwStageId
  )?.deadline;

  const isPastDueDate = useMemo(() => {
    return isDateSameOrBefore({
      dateToCompare: currentStageDeadline,
      baseDate: now
    });
  }, [now, currentStageDeadline]);

  const stageReviewers = useMemo(() => {
    const currentStage = aw?.stages.find((it) => it.id === currentAwStageId);
    if (!currentStage) return [];
    return currentStage.members;
  }, [aw?.stages, currentAwStageId]);
  return (
    <div className="approval_item">
      <div className="approval_item__preview">
        <a href={aw?.url} target="_blank" rel="noreferrer">
          {typeof imgUrl === 'string' ? (
            <img
              src={imgUrl}
              alt={firstVersion?.name}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            imgUrl
          )}
        </a>
      </div>
      <div className="approval_item__body">
        <div className="approval_item__body_title">
          <a href={aw?.url} target="_blank" className="copy" rel="noreferrer">
            {firstVersion?.name}
          </a>
          <AvatarsGroup
            membersType="aw_stage_members"
            maxCount={1}
            users={stageReviewers}
            avatarSize={24}
          />
        </div>
        <div className="approval_item__bottom">
          {aw && <Status approvalWorkflow={aw} />}
          {currentStageDeadline && (
            <DueDate urgent={isPastDueDate} date={currentStageDeadline} />
          )}
          <div className="breadcrumbs">
            {asset?.campaignName && asset.campaignId && (
              <Tooltip
                overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                title={asset.campaignName}
                placement={asset.campaignName.length > 30 ? 'left' : 'top'}
              >
                <Link
                  to={`/campaigns/single/${asset.campaignId}?tab=approvals&approvalId=${asset.id}`}
                  className="approval_item__link"
                >
                  {asset.campaignName}
                </Link>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
