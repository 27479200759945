import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import moment from 'moment';
import React, { memo } from 'react';

type AddedCellProps = {
  memberId: string;
};
export default memo(function AddedCell({ memberId }: AddedCellProps) {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );

  if (!member) return null;

  return (
    <div className="added_cell">
      {moment(member.createdAt).format('MMM DD, h:mm a')}
    </div>
  );
});
