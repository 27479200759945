import React, { useState } from 'react';
import { Form, Input, Row, Col, Popover, message } from 'antd';
import ProfileForm from '@components/ProfileForm';
import { passwordValidator } from '@helpers/auth';

import { ReactComponent as EyeOpenSvg } from '@assets/icons/eye-opened.svg';
import { ReactComponent as EyeCloseSvg } from '@assets/icons/eye-closed.svg';
import { profileChangePassword } from '@api/Profile';

interface IUserPasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function UserPassword(): React.ReactElement {
  const [newPassword, setNewPassword] = useState<string>();
  const list = passwordValidator(newPassword || '');
  const content = list.map((el, index) => (
    <li
      key={`password-text-item_${index}`}
      className={el.valid ? 'complete' : ''}
    >
      {el.copy}
    </li>
  ));

  const onSubmit = async ({ newPassword, oldPassword }: IUserPasswordForm) => {
    try {
      await profileChangePassword({
        newPassword,
        oldPassword
      });
    } catch (error: any) {
      message.error('Password does not match');
      throw new Error(error);
    }
  };

  const passwordIconRender = (visible: boolean) =>
    visible ? <EyeOpenSvg /> : <EyeCloseSvg />;

  return (
    <ProfileForm onSubmit={onSubmit}>
      <>
        <div className="profile_settings_subtitle">Change password</div>
        <Row gutter={32} className="profile_settings_row b-half">
          <Col span={24}>
            <Form.Item
              label="Password"
              name="oldPassword"
              rules={[{ required: true, message: 'Password required.' }]}
            >
              <Input.Password
                placeholder="Enter password"
                size="large"
                iconRender={passwordIconRender}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32} className="profile_settings_row b-half">
          <Col span={24}>
            <Popover
              placement={window.innerWidth > 769 ? 'right' : 'top'}
              content={<ul>{content}</ul>}
              trigger="focus"
              overlayClassName="custom_popover"
            >
              <Form.Item
                label="New password"
                name="newPassword"
                rules={[
                  { required: true, message: 'New password required.' },
                  () => ({
                    validator(_, value) {
                      const isValid = passwordValidator(value || '').filter(
                        (item) => !item.valid
                      ).length;
                      setNewPassword(value);
                      if (!value || !isValid) {
                        return Promise.resolve();
                      }
                      return Promise.reject('New password required.');
                    }
                  })
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter new password"
                  iconRender={passwordIconRender}
                />
              </Form.Item>
            </Popover>
          </Col>
        </Row>
        <Row gutter={32} className="profile_settings_row b-half">
          <Col span={24}>
            <Form.Item
              label="Repeat password"
              name="confirmPassword"
              rules={[
                { required: true, message: 'Repeat password required.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Passwords do not match');
                  }
                })
              ]}
            >
              <Input.Password
                placeholder="Repeat password"
                size="large"
                iconRender={passwordIconRender}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    </ProfileForm>
  );
}

export default UserPassword;
