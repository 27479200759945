import React, {
  memo,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import WelcomeVideo from '@assets/videos/intro.mp4';
import Poster from '@assets/videos/poster.png';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { Button } from 'antd';
import { ReactComponent as Logo } from '@assets/icons/logo.svg';
import { ReactComponent as EllipseRight } from '@assets/icons/ellipse-right.svg';
import { ReactComponent as EllipseLeft } from '@assets/icons/ellipse-left.svg';

import './WelcomeOverlay.less';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { nonNull } from '@helpers/non-null';

const videoJsOptions: VideoJsPlayerOptions = {
  autoplay: false,
  fluid: false,
  responsive: true
};

type WelcomeOverlayProps = {
  setShowWelcomeOverlay: (v: boolean) => void;
};

export default memo(function WelcomeOverlay({
  setShowWelcomeOverlay
}: WelcomeOverlayProps) {
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const playerRef = React.useRef<VideoJsPlayer>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const { onStartTour } = useContext(OnboardingProcessContext);

  useEffect(() => {
    const videoElement = nonNull(videoRef.current);
    const player = videojs(videoElement, videoJsOptions, () => {
      setReady(true);
    });
    (playerRef.current as any) = player;
    return () => {
      player.dispose();
    };
  }, []);
  useEffect(() => {
    if (!playerRef.current || !ready) return;
    playerRef.current.poster(Poster);
    playerRef.current.src({
      type: 'video/mp4',
      src: `${WelcomeVideo}`
    });
  }, [ready]);
  useEffect(() => {
    if (!playerRef.current) return;
    const player = playerRef.current;
    const onEnded = () => {
      setPlayVideo(false);
    };
    player.on('ended', onEnded);

    return () => {
      player.off('ended', onEnded);
    };
  }, []);

  const onVideoPlayPause = useCallback((v) => {
    setPlayVideo(v);
    if (!videoRef.current) return;
    if (v) videoRef.current.play();
    else videoRef.current.pause();
  }, []);

  const onStart = useCallback(() => {
    setShowWelcomeOverlay(false);
    onStartTour({ type: 'first-enter-steps' });
  }, [onStartTour, setShowWelcomeOverlay]);

  return (
    <div className="welcome-overlay">
      <div className="welcome-overlay-wrapper">
        <div className="welcome-overlay-wrapper__ellipse-left">
          <EllipseLeft />
        </div>
        <div className="welcome-overlay-wrapper__ellipse-right">
          <EllipseRight />
        </div>

        <div className="welcome-overlay-wrapper__logo">
          <Logo />
        </div>
        <div className="welcome-overlay-wrapper-body">
          <div className="welcome-overlay-wrapper-body__copies">
            <h3>Welcome to StreamWork</h3>
            <p>
              Watch our founder walk through a quick overview of StreamWork.
            </p>
          </div>
          <div
            className="welcome-overlay-wrapper-body__video"
            onClick={() => onVideoPlayPause(!playVideo)}
          >
            {!playVideo && (
              <div className="welcome-overlay-wrapper-body__video-overlay">
                <div className="background" />
                <div className="welcome-overlay-wrapper-body__video-overlay-start">
                  <div className="welcome-overlay-wrapper-body__video-overlay-pulse" />
                </div>
              </div>
            )}
            <div data-vjs-player>
              <video ref={videoRef} className="video-js" playsInline />
            </div>
          </div>
          <div className="welcome-overlay-wrapper-body__divider">or</div>
          <div className="welcome-overlay-wrapper-body__button">
            <Button type="primary" size="large" onClick={onStart}>
              Get started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
