import React, { useState } from 'react';
import { Form, Input, Row, Col, message } from 'antd';

// import PhoneInput from 'react-phone-input-2';

import ProfileForm from '@components/ProfileForm';
import { UpdatePersonalInfoDto } from '@api/Api';
import { nameValidator } from '@helpers/validators';
import { useAuth } from '@hooks/useAuth';
import { updatePersonalInfo } from '@api/Profile';

function isValidName(value: string, message: string) {
  const isValid = nameValidator(value || '');
  if (!value || isValid) {
    return Promise.resolve();
  }
  return Promise.reject(message);
}

function UserInfo(): React.ReactElement {
  const { user, updateUserProfile } = useAuth(true, 'internal');
  const [fieldPhone, setFieldPhone] = useState<string>('');
  const { firstName, lastName, phone, companyName } = user;

  const initialValues = {
    firstName,
    lastName,
    companyName,
    phone
  };

  const onSubmitForm = async (
    data: Partial<UpdatePersonalInfoDto>
  ): Promise<void> => {
    if (!data) return;
    try {
      await updatePersonalInfo(data);
      updateUserProfile({
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        companyName: data.companyName ?? null,
        phone: data.phone ?? null
      });
    } catch (err: any) {
      message.error(err.response?.data?.message?.[0] || 'Something went wrong');
      throw new Error(err);
    }
  };

  // const onChangePhone = (value: string) => {
  //   setFieldPhone(`+${value}`);
  // };

  return (
    <ProfileForm initialValues={initialValues} onSubmit={onSubmitForm}>
      <>
        <Row gutter={32} className="profile_settings_row">
          <Col sm={12} xs={24}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                { required: true, message: 'First name is required.' },
                () => ({
                  validator(_, value) {
                    return isValidName(value, 'Please enter valid First name.');
                  }
                })
              ]}
            >
              <Input placeholder="First name" size="large" />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                { required: true, message: 'Last name is required.' },
                () => ({
                  validator(_, value) {
                    return isValidName(value, 'Please enter valid Last name.');
                  }
                })
              ]}
            >
              <Input placeholder="Last name" size="large" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={32} className="profile_settings_row">
          <Col sm={12} xs={24}>
            <PhoneInput
              specialLabel="Phone number"
              country="us"
              value={fieldPhone}
              containerClass="custom_phone"
              inputClass="custom_phone_input"
              dropdownClass="custom_phone_select"
              countryCodeEditable={false}
              enableSearch={true}
              disableSearchIcon={true}
              onChange={onChangePhone}
            />
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label="Company"
              name="companyName"
              rules={[
                () => ({
                  validator(_, value) {
                    return isValidName(value, 'Please enter valid Company.');
                  }
                })
              ]}
            >
              <Input placeholder="Company" size="large" />
            </Form.Item>
          </Col>
        </Row> */}
      </>
    </ProfileForm>
  );
}

UserInfo.defaultProps = {
  phone: '',
  companyName: ''
};

export default UserInfo;
