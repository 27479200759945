import { AWStageDto } from '@api/Api';
import moment from 'moment';

export default function isSomeAvStageOverdue(stages: AWStageDto[]) {
  const _moment = moment().startOf('minute');
  return stages.some((it) => {
    const deadlineMoment = moment(it.deadline).startOf('minute');
    return _moment.isSameOrAfter(deadlineMoment);
  });
}
