import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Col,
  Divider,
  Row,
  Form,
  Switch,
  Button,
  Table,
  Checkbox,
  message,
  Skeleton
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTypedSelector } from '@hooks';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  loadMoreCampaigns,
  setCampaignList
} from '@redux/actions/campaignAction';
import {
  getNotificationPreferences,
  updateNotificationPreferences
} from '@api/Profile';
import { DisabledPreferencesDto, PreferencesItemDto } from '@api/Api';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { ReactComponent as SelectSvg } from '@assets/icons/select-menu-item.svg';
import { useAuth } from '@hooks/useAuth';

function getValue(obg: object): string[] {
  if (!obg || typeof obg !== 'object') {
    return [];
  }
  return Object.entries(obg)
    .filter(([_, value]) => value === false)
    .map(([key]) => key);
}

let successTime: ReturnType<typeof setTimeout>;

type TItemData = {
  key: PreferencesItemDto['email'][number];
  name: string;
};

const data: TItemData[] = [
  {
    name: 'Someone invites me to a campaign',
    key: 'you-added-to-campaign'
  },
  {
    name: 'Someone invites me to a private folder',
    key: 'you-added-to-folder'
  },
  {
    name: 'Someone assigns me to a task',
    key: 'you-assigned-to-task'
  },
  {
    name: 'Someone mentions me in a task',
    key: 'mention-in-task'
  },
  {
    name: 'Someone comments or replies to a task I created',
    key: 'new-comment-in-owned-task'
  },
  {
    name: 'Someone comments or replies to a task I am assigned to',
    key: 'new-comment-in-assigned-task'
  },
  {
    name: 'Task status changes',
    key: 'task-status-changed'
  },
  {
    name: 'Task due date changes',
    key: 'task-due-date-changed'
  },
  {
    name: 'A new media has been uploaded',
    key: 'new-asset'
  },
  {
    name: 'A new version of an media has been uploaded',
    key: 'new-asset-version'
  },
  {
    name: 'Someone new has joined a campaign I have access to',
    key: 'joined-to-campaign'
  },
  {
    name: 'Someone new has joined a folder I have access to',
    key: 'joined-to-folder'
  },
  {
    name: 'Someone comments on an media I have access to',
    key: 'new-comment-in-asset'
  },
  {
    name: 'Someone replies to my comment on an media',
    key: 'reply-to-comment-in-asset'
  },
  {
    name: 'Someone mentions me in a comment on an media',
    key: 'mention-in-comment-in-asset'
  },
  {
    name: 'Someone invites me to review an media',
    key: 'aw-invitation'
  },
  {
    name: 'Someone granted me permission to manage approval routing',
    key: 'aw-created'
  },
  {
    name: 'A new stage started on approval routing I can manage',
    key: 'aw-new-stage-started'
  },
  {
    name: 'Approval routing due date has changed',
    key: 'aw-stage-due-date-changed'
  },
  {
    name: 'A reviewer has submitted a decision on an media',
    key: 'aw-decision-submitted'
  },
  {
    name: 'Final approval decision determined for an media',
    key: 'aw-final-decision-submitted'
  }
];

export default function PerWorkspaceSettings() {
  const [currentWorkspace] = useCurrentWorkspace();
  const campaignsData = useTypedSelector(({ campaign }) => campaign);
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [notificationDisabledData, setNotificationDisabledData] =
    useState<PreferencesItemDto>({
      email: [],
      inApp: [],
      chat: [],
      campaigns: []
    });
  const [form] = Form.useForm();
  const emailValues = Form.useWatch('email', form);
  const inAppValues = Form.useWatch('inApp', form);
  const chatValues = Form.useWatch('chat', form);
  const isAllDisabled = useMemo(() => {
    return (
      emailValues &&
      Object.values(emailValues).every((v) => v === false) &&
      inAppValues &&
      Object.values(inAppValues).every((v) => v === false) &&
      chatValues &&
      Object.values(chatValues).every((v) => v === false)
    );
  }, [emailValues, inAppValues, chatValues]);
  const { user } = useAuth(true, 'internal');
  const anyChatConnected = user.slackConnected;

  const loadMoreCampaignData = async () => {
    await loadMoreCampaigns({
      workspaceId: currentWorkspace.id,
      after: campaignsData.endCursor
    })(dispatch);
  };

  const getCampaignList = async () => {
    if (currentWorkspace.id) {
      await setCampaignList({
        workspaceId: currentWorkspace.id
      })(dispatch);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { disabled }
      }: { data: { disabled: PreferencesItemDto } } =
        await getNotificationPreferences({
          workspaceId: currentWorkspace.id
        });
      await getCampaignList();
      setNotificationDisabledData(disabled);
    })();
  }, [currentWorkspace.id]);

  useEffect(() => {
    const { email, inApp, chat, campaigns } = notificationDisabledData;
    const defaultValues: { name: string | string[]; value: boolean }[] = [];
    data.forEach((element: TItemData) => {
      defaultValues.push({
        name: ['email', element.key],
        value: !email.some((item: string) => item === element.key)
      });
      defaultValues.push({
        name: ['inApp', element.key],
        value: !inApp.some((item: string) => item === element.key)
      });
      defaultValues.push({
        name: ['chat', element.key],
        value: !chat.some((item: string) => item === element.key)
      });
    });
    campaigns.forEach((item: string) => {
      defaultValues.push({
        name: ['campaigns', item],
        value: false
      });
    });
    form.setFields(defaultValues);
  }, [notificationDisabledData]);

  const onSubmit = async (data: any) => {
    try {
      setIsSuccess(false);
      setIsSubmit(true);
      clearInterval(successTime);
      const email = getValue(data.email);
      const inApp = getValue(data.inApp);
      const chat = getValue(data.chat);
      const campaigns = getValue(data.campaigns);
      await updateNotificationPreferences({
        workspaceId: currentWorkspace.id,
        disabled: {
          email,
          inApp,
          chat,
          campaigns
        } as DisabledPreferencesDto
      });
      setIsSuccess(true);
      successTime = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (error) {
      console.warn(error, '-> updateNotificationPreferences');
      message.error('Something went wrong');
    } finally {
      setIsSubmit(false);
    }
  };

  const setAll = (enabled: boolean) => {
    const defaultValues: any = [];
    data.forEach((element: TItemData) => {
      const { key } = element;
      defaultValues.push({
        name: ['email', key],
        value: enabled
      });
      defaultValues.push({
        name: ['inApp', key],
        value: enabled
      });
      defaultValues.push({
        name: ['chat', key],
        value: enabled
      });
    });
    form.setFields(defaultValues);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Notifications',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        className: 'b-email-notification',
        render: (text: string, node: TItemData) => {
          return (
            <Form.Item name={['email', node.key]} valuePropName="checked">
              <Checkbox className="b-checkbox-blue" />
            </Form.Item>
          );
        }
      },
      {
        title: 'Web (in-app)',
        dataIndex: 'inApp',
        className: 'b-web-app-notification',
        render: (text: string, node: TItemData) => {
          return (
            <Form.Item name={['inApp', node.key]} valuePropName="checked">
              <Checkbox className="b-checkbox-blue" />
            </Form.Item>
          );
        }
      },
      {
        title: 'Chat app',
        dataIndex: 'chat',
        className: 'b-web-app-notification',
        render: (text: string, node: TItemData) => {
          return (
            <Form.Item name={['chat', node.key]} valuePropName="checked">
              <Checkbox
                className="b-checkbox-blue"
                disabled={!anyChatConnected}
              />
            </Form.Item>
          );
        }
      }
    ],
    [anyChatConnected]
  );

  return (
    <Form
      form={form}
      className="notifications_settings_form"
      onFinish={onSubmit}
    >
      <div className="notifications_settings">
        {Array.isArray(campaignsData.edges) &&
        campaignsData.edges.length !== 0 ? (
          <>
            <div className="profile_settings_subtitle">
              Campaign subscriptions
            </div>
            <div className="profile_settings_text b-gray-color">
              Choose which campaigns you would like to receive notifications
              from.
            </div>
            <div
              className="notifications_campaigns"
              id="notifications_campaigns"
            >
              <InfiniteScroll
                dataLength={campaignsData.edges?.length || 0}
                next={loadMoreCampaignData}
                hasMore={campaignsData.hasNext || false}
                loader={
                  <Row
                    className="notifications_campaigns-block"
                    align="middle"
                    wrap={false}
                    gutter={16}
                  >
                    <Col className="notifications_campaigns-img">
                      <Skeleton.Input
                        style={{
                          width: '56px',
                          minWidth: '1px'
                        }}
                      />
                    </Col>
                    <Col className="notifications_campaigns-name">
                      <Skeleton.Input />
                    </Col>
                    <Col className="notifications_campaigns-switch">
                      <Skeleton.Input />
                    </Col>
                  </Row>
                }
                scrollableTarget="notifications_campaigns"
                style={{
                  overflow: 'hidden'
                }}
              >
                {campaignsData.edges.map(({ node }) => {
                  const asset = node?.topAssets?.[0];
                  const image = assetPreviewThumbnails({
                    url: asset?.previewUrl || '',
                    status: asset?.status || '',
                    type: asset?.type || ''
                  });
                  return (
                    <Row
                      className="notifications_campaigns-block"
                      align="middle"
                      wrap={false}
                      gutter={16}
                      key={node.id}
                    >
                      <Col className="notifications_campaigns-img">
                        {typeof image === 'string' ? (
                          <img src={image} alt="" />
                        ) : (
                          image
                        )}
                      </Col>
                      <Col className="notifications_campaigns-name">
                        {node.name}
                      </Col>
                      <Col className="notifications_campaigns-switch">
                        <Form.Item
                          className="profile_settings_switch"
                          name={['campaigns', node.id]}
                          valuePropName="checked"
                        >
                          <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            defaultChecked={true}
                            className="profile_settings_switch-control"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </InfiniteScroll>
            </div>
            <Divider className="profile_settings_divider" />
          </>
        ) : null}
        <Row
          className="notifications_block"
          wrap={false}
          align="bottom"
          justify="space-between"
          gutter={16}
        >
          <Col>
            <div className="profile_settings_subtitle">Notify me when:</div>
            <div className="profile_settings_text b-gray-color">
              Choose which notifications you would like to receive for the
              campaigns you are subscribed to in StreamWork.
            </div>
          </Col>
          <Col>
            <Button
              type="text"
              htmlType="button"
              onClick={() => setAll(isAllDisabled)}
              key={isAllDisabled}
            >
              {isAllDisabled ? 'Enable all' : 'Disable all'}
            </Button>
          </Col>
        </Row>
        <div className="table_container">
          <Table columns={columns} pagination={false} dataSource={data} />
        </div>
      </div>
      <Button
        className="notifications_settings_form-btn"
        htmlType="submit"
        type="primary"
        loading={isSubmit}
        icon={isSuccess && <SelectSvg className="b-icon-select" />}
      >
        {isSuccess ? 'Saved' : 'Save'}
      </Button>
    </Form>
  );
}
