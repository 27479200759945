import React from 'react';
import { Button } from 'antd';

import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

type DropdownMenuButtonProps = {
  onOpenChange: (prev: any) => void;
};
function DropdownMenuButton({ onOpenChange }: DropdownMenuButtonProps) {
  const onClick = (e: any) => {
    e.stopPropagation();
    onOpenChange((prev: boolean) => !prev);
  };
  return (
    <div className="dropdown-menu-button-container">
      <Button type="text" onClick={onClick}>
        <ActionSvg style={{ marginLeft: 0, marginRight: 0 }} />
      </Button>
    </div>
  );
}

export default DropdownMenuButton;
