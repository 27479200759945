import { WorkspaceItemDto } from '@api/Api';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

interface IWorkspaceContext {
  readonly currentWorkspace?: WorkspaceItemDto;
  readonly defaultWorkspace?: WorkspaceItemDto;
  readonly setWorkspaceId: (id: string | undefined) => void;
}
type IWorkspaceReadonlyContext = Omit<IWorkspaceContext, 'setWorkspaceId'>;

const WorkspaceContext = createContext<IWorkspaceContext>({
  setWorkspaceId: () => {
    /* do nothing */
  }
});
WorkspaceContext.displayName = 'Workspace';

interface WorkspaceProviderProps {
  readonly workspaces: readonly WorkspaceItemDto[];
  readonly children: ReactNode;
}

export function WorkspaceProvider({
  workspaces,
  children
}: WorkspaceProviderProps) {
  const { currentOrganization } = useOrganization(false);
  workspaces = currentOrganization
    ? workspaces.filter(
        (x) => x.organizationId === currentOrganization.entity?.id
      )
    : [];
  const [workspaceId, setWorkspaceId] = useState<string>();
  const currentWorkspace = useMemo(
    () =>
      workspaceId ? workspaces.find((x) => x.id === workspaceId) : undefined,
    [workspaceId, workspaces]
  );
  const defaultWorkspace = workspaces[0];
  const contextValue = useMemo(
    () => ({ currentWorkspace, defaultWorkspace, setWorkspaceId }),
    [currentWorkspace, defaultWorkspace, setWorkspaceId]
  );
  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspace<TRequired extends boolean = true>(
  required = true as TRequired
): TRequired extends true
  ? Required<IWorkspaceReadonlyContext>
  : IWorkspaceReadonlyContext {
  const context = useContext(WorkspaceContext);
  if (required === true) {
    return {
      currentWorkspace: nonNull(context.currentWorkspace, 'currentWorkspace'),
      defaultWorkspace: nonNull(context.defaultWorkspace, 'defaultWorkspace')
    };
  }
  return context as any;
}

function WorkspaceRedirect() {
  const { currentWorkspace, defaultWorkspace } = useWorkspace(false);
  if (currentWorkspace) return <Outlet />;
  if (defaultWorkspace)
    return <Navigate to={`/ws/${defaultWorkspace.id}`} replace />;
  return <Navigate to="/ws/create" replace />;
}

export function WorkspaceBoundary() {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { setWorkspaceId } = useContext(WorkspaceContext);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setWorkspaceId(workspaceId);
    setReady(true);
    return () => setWorkspaceId(undefined);
  }, [workspaceId, setWorkspaceId]);
  return ready ? <WorkspaceRedirect /> : null;
}
