import React, { memo, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import '@components/Modals/ProfileModal/ProfileModalContainer.less';

const { Title, Text } = Typography;

interface DeleteCustomFieldModalProps {
  onCancel(): void;
  onConfirm(): void;
  open: boolean;
}

export default memo(function DeleteCustomFieldModal({
  onCancel,
  onConfirm,
  open
}: DeleteCustomFieldModalProps) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (open) form.resetFields();
  }, [open, form]);
  return (
    <Modal
      footer={null}
      centered={true}
      width={528}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      wrapClassName="delete-modal"
    >
      <div className="profile_modal_container">
        <Form form={form} onFinish={async () => onConfirm()} layout="vertical">
          <Title level={4} className="profile_modal_title">
            Delete task column
          </Title>
          <Text className="profile_modal_text">
            Are you sure you want to delete this column? All corresponding data
            in tasks will be deleted. This can not be undone.
          </Text>
          <Form.Item
            label="Please type “Delete” to confirm"
            name="text"
            rules={[
              {
                required: true,
                message: 'Please, type “Delete” to confirm".'
              },
              () => ({
                validator(_, value) {
                  if (!value || value === 'Delete') {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please, type “Delete” to confirm');
                }
              })
            ]}
          >
            <Input placeholder="Delete" size="large" />
          </Form.Item>
          <Row gutter={24} justify="end" className="profile_modal_bottom">
            <Col>
              <Button
                type="text"
                htmlType="button"
                size="middle"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                className="b-btn-primary"
              >
                Delete task column
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
});
