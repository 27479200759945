import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import MembersTable from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/MembersTable';
import MemberItemSkeleton from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MemberItemSkeleton/MemberItemSkeleton';

import {
  organizationMembersIdsListStateSelector,
  organizationMembersStateSelector
} from '@redux/selectors/organizations';
import { ListFetchType } from '@redux/types/organizations';
import React, { memo } from 'react';

type MembersTabProps = {
  membersLoading: ListFetchType | false;
  onOrderMembersChange: (orderBy: string) => void;
  onLoadMoreMembers: () => void;
};

export default memo(function MembersTab({
  membersLoading,
  onOrderMembersChange,
  onLoadMoreMembers
}: MembersTabProps) {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const list = useTypedSelector((state) =>
    organizationMembersIdsListStateSelector(state, { organizationId })
  );

  const hasMore = useTypedSelector(
    (state) =>
      organizationMembersStateSelector(state, { organizationId }).hasMore
  );
  const count = useTypedSelector(
    (state) => organizationMembersStateSelector(state, { organizationId }).count
  );

  return (
    <InfiniteScroll
      loading={membersLoading}
      next={onLoadMoreMembers}
      loader={<MemberItemSkeleton count={count - list.length} />}
      alwaysShowLoader
      hasMore={hasMore}
    >
      <MembersTable
        list={list}
        count={count}
        onOrderChange={onOrderMembersChange}
      />
    </InfiniteScroll>
  );
});
