import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Dropdown, Input, Menu, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useLayout } from '@layouts/PageLayout';

import { ReactComponent as SortArrowSvg } from '@assets/icons/sort-arrow.svg';
import { ReactComponent as SortTilesSvg } from '@assets/icons/sort-tiles.svg';
import { ReactComponent as SortListSvg } from '@assets/icons/sort-list.svg';
import './PageSortPanel.less';

interface PageSortPanelProps {
  entity: string;
  onAddClick?: React.MouseEventHandler<HTMLElement>;
  onSearch?: (params: any) => any;
  onOrderBy?: (params: any) => any;
  classNameSearchContainer?: string;
  classNameActionButton?: string;
  sortMenuModal?: React.ReactElement;
  isVisibleSortStyle?: boolean;
  searchInputComponent?: React.ReactElement;
  classNamePanel?: string;
  actionButtonName?: string;
  isActionButtonDisabled?: boolean;
  actionButtonDisabledReason?: string;
}

function PageSortPanel({
  entity,
  onAddClick,
  onSearch,
  onOrderBy,
  classNameSearchContainer,
  classNameActionButton,
  sortMenuModal,
  isVisibleSortStyle,
  searchInputComponent,
  classNamePanel,
  actionButtonName,
  isActionButtonDisabled,
  actionButtonDisabledReason
}: PageSortPanelProps) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedSorter, setSelectedSorter] = useState<string>('');

  const { currentView, setCurrentView } = useLayout();
  const SortMenu = (
    <Menu
      selectedKeys={[selectedSorter]}
      onClick={({ key }) => {
        setSelectedSorter(key);
        if (onOrderBy) {
          onOrderBy(key);
        }
      }}
    >
      <Menu.Item key="createdAt:DESC">Date created</Menu.Item>
      <Menu.Item key="lastEventTime:DESC">Date modified</Menu.Item>
    </Menu>
  );

  const SearchInput = (
    <Input
      size="large"
      placeholder={`Search ${entity}`}
      prefix={<SearchOutlined />}
      className="search_input"
      onChange={(event) => {
        const searchQuery = event.target.value;
        setSearchQuery(event.target.value);
        if (!onSearch) return;
        if (searchQuery) {
          onSearch(searchQuery);
        } else {
          onSearch(null);
        }
      }}
      value={searchQuery}
    />
  );

  return (
    <div className={classNames('page-sort-panel-container', classNamePanel)}>
      <div className={classNames('control-panel', classNameSearchContainer)}>
        {searchInputComponent || SearchInput}
        <div className="sort-container">
          <Dropdown
            overlay={sortMenuModal || SortMenu}
            placement="bottomLeft"
            overlayClassName="help_dropdown"
            trigger={['click']}
          >
            {/* <div className="sort-text-container">
              <SortArrowSvg />
              <span>Sort</span>
            </div> */}
            <Button
              style={{
                marginRight: 20,
                height: 'auto',
                padding: '3px 8px 5px 8px'
              }}
              type="text"
              onClick={() => {
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
              }}
              icon={<SortArrowSvg />}
            >
              Sort
            </Button>
          </Dropdown>
          {isVisibleSortStyle && (
            <>
              <Button
                type="text"
                style={{ padding: 4, height: 'auto' }}
                className="select-assets-view-btn"
                onClick={() => {
                  // onTilesClick();
                  setCurrentView('tile');
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                }}
              >
                <SortTilesSvg
                  style={{ marginRight: 0 }}
                  className={
                    currentView === 'tile' ? 'select-assets-view-icon' : null
                  }
                />
              </Button>
              <div className="line" />
              <Button
                type="text"
                style={{ padding: 4, height: 'auto' }}
                className="select-assets-view-btn"
                onClick={() => {
                  setCurrentView('list');
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                }}
              >
                <SortListSvg
                  style={{ marginRight: 0 }}
                  className={
                    currentView === 'list' ? 'select-assets-view-icon' : null
                  }
                />
              </Button>
            </>
          )}
          {onAddClick && (
            <Tooltip
              overlayClassName="permission-hint long-text-hint"
              overlayInnerStyle={{ maxWidth: 285 }}
              title={actionButtonDisabledReason}
              trigger="hover"
              placement="bottom"
            >
              <Button
                type="primary"
                onClick={onAddClick}
                disabled={isActionButtonDisabled}
                className={classNameActionButton}
              >
                {actionButtonName || `New ${entity}`}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

PageSortPanel.defaultProps = {
  onAddClick: undefined,
  onSearch: undefined,
  onOrderBy: undefined,
  classNameSearchContainer: undefined,
  classNameActionButton: undefined,
  sortMenuModal: undefined,
  isVisibleSortStyle: true,
  searchInputComponent: undefined,
  classNamePanel: undefined,
  actionButtonName: undefined,
  isActionButtonDisabled: false
};

export default PageSortPanel;
