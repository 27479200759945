import { CreditCardOutlined } from '@ant-design/icons';
import { Button, Menu, Popover, Spin } from 'antd';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as VisaSvg } from '@assets/icons/PaymentCards/visa.svg';
import { ReactComponent as MastercardSvg } from '@assets/icons/PaymentCards/mastercard.svg';
import { ReactComponent as AmexSvg } from '@assets/icons/PaymentCards/amex.svg';
import { ReactComponent as DiscoverSvg } from '@assets/icons/PaymentCards/discover.svg';
import { ReactComponent as JcbSvg } from '@assets/icons/PaymentCards/jcb.svg';
import { ReactComponent as UnionpaySvg } from '@assets/icons/PaymentCards/unionpay.svg';
import React, { memo, useCallback, useMemo } from 'react';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useFetch } from '@hooks/useFetch';
import {
  organizationItemStateSelector,
  organizationPaymentMethodStateSelector
} from '@redux/selectors/organizations';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchOrganizationPaymentMethod } from '@redux/actions/organizations';
import { subscriptionReplaceCard } from '@api/Subscription';
import { useDeletePaymentMethod } from '@hooks/organization';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTypedSelector } from '@hooks';

export default memo(function PaymentMethodSection() {
  const dispatch = useTypedDispatch();
  const deletePaymentMethod = useDeletePaymentMethod();
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;
  const paymentMethod = useTypedSelector((state) =>
    organizationPaymentMethodStateSelector(state, organizationId)
  );

  const editCreditCard = useCallback(async () => {
    const {
      data: { url }
    } = await subscriptionReplaceCard({
      organizationId: organization.id,
      state: JSON.stringify({ redirectUrl: '/admin-settings' })
    });
    if (url) window.open(url, '_self');
  }, [organization.id]);

  const deleteCreditCard = useCallback(async () => {
    deletePaymentMethod({ organizationId: organization.id });
  }, [deletePaymentMethod, organization.id]);

  const menuItems: ItemType[] = useMemo(
    () => [
      {
        key: 'edit',
        label: 'Edit',
        onClick: editCreditCard
      },
      {
        key: 'delete',
        label: 'Delete',
        onClick: deleteCreditCard
      }
    ],
    [deleteCreditCard, editCreditCard]
  );

  const loading = useFetch({
    key: `organizations-payment-method-${organization.id}`,
    selector: (state) =>
      organizationItemStateSelector(state, { organizationId: organization.id })
        .paymentMethod.fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationPaymentMethod({
          fetchType,
          organizationId: organization.id
        })
      )
  });

  return (
    <div className="billing_container__payment_method">
      <div className="title">Payment method</div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div>
          {!paymentMethod.it ? (
            <div className="no_card">
              <div className="copy">No card on file</div>
              <div className="action">
                <Button type="ghost" onClick={editCreditCard}>
                  Update
                </Button>
              </div>
            </div>
          ) : (
            <div className="card_block">
              <div className="card_block__info">
                <div className="type">
                  <PaymentCardIcon type={paymentMethod.it.type} />
                </div>
                <div className="number">
                  {' '}
                  <span>••••</span>
                  <span>••••</span>
                  <span>••••</span> {paymentMethod.it.last4Digits}
                </div>
              </div>
              <div className="card_block__action">
                <Popover
                  placement="right"
                  overlayClassName="card-popover-container"
                  trigger={['click']}
                  content={<Menu selectedKeys={[]} items={menuItems} />}
                >
                  <Button type="text" size="small" style={{ padding: 0 }}>
                    <ActionSvg style={{ marginRight: 0 }} />
                  </Button>
                </Popover>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

type PaymentCardIconProps = {
  type: string;
};

const PaymentCardIcon = memo(({ type }: PaymentCardIconProps) => {
  if (type === 'visa') return <VisaSvg />;
  if (type === 'mastercard') return <MastercardSvg />;
  if (type === 'amex') return <AmexSvg />;
  if (type === 'discover') return <DiscoverSvg />;
  if (type === 'jcb') return <JcbSvg />;
  if (type === 'unionpay') return <UnionpaySvg />;

  return <CreditCardOutlined />;
});
