import { Divider, Modal, Tooltip } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import React, { useEffect } from 'react';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { toggleManageAssetVersionsModal } from '@redux/actions/modalAction';
import Loader from '@components/Loader';
import { removeAssetVersionFromStackAction } from '@redux/actions/assetsAction';
import moment from 'moment';
import { updateCampaignCounters } from '@redux/actions/campaignAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';

function ManageAssetVersionsModal() {
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.manageAssetVersionsModal
  );
  const assets = useTypedSelector(({ assets }) => assets.files.edges);

  useEffect(() => {
    if (!modalState) return;
    const asset = assets?.find((el) => el.node.id === modalState?.assetId)?.node
      .asset;
    if (!asset) return;
    dispatch(
      toggleManageAssetVersionsModal({
        ...modalState,
        permissions: asset.permissions,
        versions: asset.versions,
        versionsCount: asset.versionsCount
      })
    );
  }, [assets, dispatch]);

  return (
    <Modal
      visible={modalState?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      onCancel={() => {
        dispatch(toggleManageAssetVersionsModal(null));
      }}
      width={688}
    >
      <div className="modal_container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, marginBottom: 12 }}
          >
            Manage version stack
          </h2>
          <p
            className="modal_container__subtitle"
            style={{ color: '#161819', marginBottom: 32 }}
          >
            {modalState?.versionsCount} version
            {modalState?.versionsCount && modalState?.versionsCount > 1
              ? 's'
              : ''}
          </p>
        </div>
        <Divider style={{ margin: '0 0 32px' }} />
        <div className="modal_container__assets_list">
          {modalState?.versions.map((el, idx) => {
            const canUnstackVersion =
              modalState.versionsCount > 1 && idx === 0 && modalState.assetId;

            const previrew = assetPreviewThumbnails({
              url: el.metadata.preview?.url || '',
              status: el.metadata.preview?.status,
              type: el.metadata.type
            });
            return (
              <div className="item" key={el.id}>
                <div className="asset">
                  <div className="preview">
                    {previrew === 'pending' && (
                      <Loader
                        classNameWrapper="assets-loader-wrapper"
                        spinSize={40}
                        isSpinning={true}
                      />
                    )}
                    {previrew !== 'pending' && typeof previrew === 'string' ? (
                      <img
                        src={`${previrew}`}
                        alt=""
                        className="assets-loader-wrapper"
                      />
                    ) : (
                      previrew
                    )}
                  </div>

                  <div className="asset_info">
                    <div className="title">
                      <span className="copy">
                        <Tooltip
                          placement="top"
                          overlayClassName="toggle_favorite_overlay"
                          title={`${el.name}.${el.extension}`}
                        >
                          <span>
                            {el.name}.{el.extension}
                          </span>
                        </Tooltip>
                      </span>
                      <span className="version">
                        V{modalState.versionsCount - idx}
                      </span>
                    </div>
                    <div className="edited">
                      Edited {moment(el.createdAt).fromNow()}
                    </div>
                  </div>
                </div>
                {canUnstackVersion && modalState?.permissions?.delete && (
                  <Tooltip
                    placement="top"
                    overlayClassName="toggle_favorite_overlay"
                    title="Remove from version stack"
                  >
                    <span
                      className="action"
                      onClick={() => {
                        dispatch(
                          removeAssetVersionFromStackAction({
                            assetId: modalState.assetId,
                            assetVersionId: el.id
                          })
                        );
                        dispatch(updateCampaignCounters('addFile'));
                        dispatch(
                          updateAssetsWorkspaceCounters('revertFromStack')
                        );
                      }}
                    >
                      <CrossSvg />
                    </span>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
export default ManageAssetVersionsModal;
