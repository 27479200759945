import React, { useCallback } from 'react';
import { Modal, Button } from 'antd';

import { ReactComponent as AddDetailsSvg } from '@assets/icons/add-campaign-details.svg';
import { ReactComponent as UploadFilesSvg } from '@assets/icons/upload-campaign-files.svg';
import { ReactComponent as AddTaskSvg } from '@assets/icons/add-campaign-task.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import {
  toggleCampaignCreatedModal,
  toggleCampaignInfoModal,
  toggleUploadAssetModal
} from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { openNewTaskModal } from '@redux/reducers/tasks';
import { useNavigate } from 'react-router-dom';

function CampaignCreatedModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignCreatedModal = useTypedSelector(
    ({ modal }) => modal.campaignCreatedModal
  );
  const selectedCampaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );

  const onCloseModal = useCallback(() => {
    dispatch(toggleCampaignCreatedModal(null));
    navigate(`/campaigns/single/${selectedCampaign?.id}`);
  }, [navigate, dispatch, selectedCampaign?.id]);

  return (
    <Modal
      visible={campaignCreatedModal?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={964}
      onCancel={onCloseModal}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{
            fontSize: 22,
            marginBottom: '0.5em',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          Ready to get started?
        </h2>
        <p
          className="modal_container__subtitle"
          style={{ textAlign: 'center' }}
        >
          Choose what you want to do first.
        </p>
        <div className="modal_container__campaign">
          <div className="actions_block">
            <div className="action">
              <div className="action__icon">
                <AddDetailsSvg />
              </div>
              <div className="action__info">
                <div className="title">Add details</div>
                <div className="description">Add details to campaign</div>
                <Button
                  onClick={() => {
                    dispatch(toggleCampaignCreatedModal(null));
                    dispatch(
                      toggleCampaignInfoModal({
                        campaign: selectedCampaign,
                        activeTab: 'details',
                        visible: true
                      })
                    );
                    navigate(`/campaigns/single/${selectedCampaign?.id}`);
                  }}
                  type="primary"
                >
                  Add details
                </Button>
              </div>
            </div>
            <div className="action">
              <div className="action__icon">
                <UploadFilesSvg />
              </div>
              <div className="action__info">
                <div className="title">Upload files</div>
                <div className="description">Upload new files to campaign</div>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(toggleCampaignCreatedModal(null));
                    dispatch(
                      toggleUploadAssetModal({
                        visible: true,
                        postAction: 'redirect-to-campaign',
                        workspaceId: selectedCampaign?.workspaceId || ''
                      })
                    );
                    navigate(`/campaigns/single/${selectedCampaign?.id}`);
                  }}
                >
                  Upload files
                </Button>
              </div>
            </div>
            <div className="action">
              <div className="action__icon">
                <AddTaskSvg />
              </div>
              <div className="action__info">
                <div className="title">Add new task</div>
                <div className="description">Add tasks to campaign</div>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    dispatch(toggleCampaignCreatedModal(null));
                    if (selectedCampaign) {
                      dispatch(
                        openNewTaskModal({
                          organizationId: selectedCampaign.organizationId,
                          workspaceId: selectedCampaign.workspaceId,
                          campaign: selectedCampaign
                        })
                      );
                    }
                    navigate(
                      `/campaigns/single/${selectedCampaign?.id}?tab=tasks`
                    );
                  }}
                >
                  Add task
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Button
          type="ghost"
          size="large"
          style={{
            display: 'flex',
            margin: '0 auto',
            fontSize: 14,
            fontWeight: 700,
            border: 'none',
            height: 'auto',
            padding: '4px 0px'
          }}
          onClick={onCloseModal}
        >
          Skip
        </Button>
      </div>
    </Modal>
  );
}

export default CampaignCreatedModal;
