import React, { useState, memo, useEffect } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ErrorSvg } from '@assets/icons/free_trial_has_ended.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { useOrganization } from '@components/OrganizationBoundary';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleBillingModal } from '@redux/actions/modalAction';

export default memo(function TrialEndsInAlert({
  fullScreen = false
}: {
  fullScreen?: boolean;
}) {
  const dispatch = useDispatch();

  const { currentOrganization } = useOrganization();
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [diff, setDiff] = useState<number>(0);

  useEffect(() => {
    const org = currentOrganization.entity;
    if (!org) return;
    if (!org.trialEndDate) return;
    if (org.planType === 'free') return;
    if (org.planStatus !== 'trialing') return;

    const trialEndDate = moment(org.trialEndDate);
    const today = moment(new Date().toISOString());

    const diff = trialEndDate.diff(today, 'days');
    if (diff > 7) return;
    setDiff(diff);
    setIsAlertVisible(true);
  }, [currentOrganization]);

  if (!isAlertVisible) return null;
  return (
    <div
      className={classNames(
        'alert-container',
        fullScreen && 'alert-container-full-screen'
      )}
    >
      <div className="info">
        <div className="icon">
          <ErrorSvg />
        </div>

        <div className="copies">
          <span className="title">
            Your trial ends in {diff} day{diff > 1 ? 's' : ''}.
          </span>
        </div>
      </div>
      <div className="actions">
        <Button
          type="primary"
          onClick={() => {
            setIsAlertVisible(false);
            dispatch(toggleBillingModal({ visible: true }));
          }}
        >
          Upgrade
        </Button>
        <CrossSvg
          style={{ cursor: 'pointer' }}
          onClick={() => setIsAlertVisible(false)}
        />
      </div>
    </div>
  );
});
