import React, { useState, useEffect, memo } from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as DeleteCustomColumnSvg } from '@assets/icons/delete-task-table-custom-column.svg';
import { TaskFieldItemDto } from '@api/Api';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { renameTaskCustomField } from '@redux/actions/tasks';

interface CustomColumnTitleProps {
  field: TaskFieldItemDto;
  canDelete: boolean;
  canRename: boolean;
  onDelete: (field: TaskFieldItemDto) => void;
}

export default memo(function CustomColumnTitle({
  field,
  canRename,
  canDelete,
  onDelete
}: CustomColumnTitleProps) {
  const dispatch = useTypedDispatch();
  const [columnName, setColumnName] = useState(field.name);

  useEffect(() => {
    setColumnName(field.name);
  }, [field.name]);

  const handleRenameColumn = () => {
    if (!canRename) return;
    if (columnName) {
      dispatch(
        renameTaskCustomField({
          campaignId: field.campaignId,
          taskFieldId: field.id,
          name: columnName
        })
      );
    } else {
      setColumnName(field.name);
    }
  };

  return (
    <div className="task-table-custom-column-title" style={{ maxWidth: 200 }}>
      <input
        readOnly={!canRename}
        value={columnName}
        onChange={(e) => setColumnName(e.target.value)}
        onBlur={() => handleRenameColumn()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            (e.target as HTMLInputElement).blur();
            handleRenameColumn();
          }
        }}
        maxLength={100}
      />
      {!!canDelete && (
        <Tooltip
          overlayClassName="task-group-settings-hint"
          title="Remove column"
          trigger="hover"
        >
          <DeleteCustomColumnSvg onClick={() => onDelete(field)} />
        </Tooltip>
      )}
    </div>
  );
});
