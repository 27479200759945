import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as CombineIcon } from '@assets/icons/combine-assets-icon.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { toggleCopyAssetVersionModal } from '@redux/actions/modalAction';
import { apiClient } from '@api/client';
import { useWebSocketDebouncedSubscription } from '@hooks/useWebSocketClient';
import '@components/Assets/CombineAssetsModal/CombineAssetsModal.less';

function CopyAssetVersionModal() {
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.copyAssetVersionModal
  );

  const [loading, setLoading] = useState(false);
  const [newVersionId, setNewVersionId] = useState<string | null>(null);
  const websocketNewVersions = useRef(new Set<string>());

  useEffect(() => {
    const newVersions = websocketNewVersions.current;
    return () => {
      setLoading(false);
      setNewVersionId(null);
      newVersions.clear();
    };
  }, [modalState?.assetId, modalState?.versionId]);

  const handleCopyAssetVersion = async () => {
    if (!modalState) return;
    setLoading(true);
    const {
      data: { newVersionId }
    } = await apiClient.asset.assetControllerCopyAssetVersion({
      assetId: modalState.assetId,
      versionId: modalState.versionId
    });
    if (websocketNewVersions.current.has(newVersionId)) {
      dispatch(toggleCopyAssetVersionModal(null));
    } else {
      setNewVersionId(newVersionId);
    }
  };

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      messages.forEach(({ payload }) => {
        if (payload.reason !== 'new-version') return;
        if (payload.assetId !== modalState?.assetId) return;
        if (newVersionId === payload.assetVersionId) {
          setLoading(false);
          setNewVersionId(null);
          websocketNewVersions.current.clear();
          dispatch(toggleCopyAssetVersionModal(null));
        } else {
          websocketNewVersions.current.add(payload.assetVersionId);
        }
      });
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== modalState?.assetId) return false;
      if (msg.payload.reason !== 'new-version') return false;
      return true;
    },
    'asset-updated'
  );

  return (
    <Modal
      className="combine-assets-modal"
      open={modalState?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      onCancel={() => dispatch(toggleCopyAssetVersionModal(null))}
      width={464}
    >
      <CrossSvg
        className="close-task-modal"
        onClick={() => dispatch(toggleCopyAssetVersionModal(null))}
      />
      <div className="combine-assets-title">
        <CombineIcon />
        <span>Copy version</span>
      </div>
      {modalState?.isAssetWithAW && (
        <div className="combine-description-text">
          <span className="combine-description-asset">
            {modalState?.assetName}
          </span>{' '}
          has an approval in progress. Do you want to add this new version to
          that approval routing?
        </div>
      )}
      {!modalState?.isAssetWithAW && (
        <div className="combine-description-text">
          Do you want to copy this version as the latest version of{' '}
          <span className="combine-description-asset">
            {modalState?.assetName}
          </span>
          ?
        </div>
      )}
      <div className="combine-button-container">
        <Button
          type="text"
          onClick={() => dispatch(toggleCopyAssetVersionModal(null))}
        >
          No
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => handleCopyAssetVersion()}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export default CopyAssetVersionModal;
