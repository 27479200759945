import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { Modal, Tabs, message, Spin, Button, Row, Col, Tooltip } from 'antd';
import {
  createAssetReview,
  shareAssetReviewLink,
  updateAssetReview
} from '@api/AssetReview';
import {
  UpdateAssetReviewDto,
  CreateAssetReviewDto,
  CreateAssetReviewSuccessDto,
  AssetItemDto
} from '@api/Api';
import { useTypedSelector } from '@hooks';
import {
  toggleApprovalModal,
  toggleShareAssetModal
} from '@redux/actions/modalAction';

import Sharing from '@components/Modals/ShareAssetModal/Sharing';
import Settings from '@components/Modals/ShareAssetModal/Settings';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as CheckTaskSvg } from '@assets/icons/check-task.svg';
import { ReactComponent as MagicianSvg } from '@assets/icons/magician-small.svg';

import './ShareAssetModal.less';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import ApprovalWorkflow from '@components/Modals/ShareAssetModal/ApprovalWorkflow';
import { useOrganization } from '@components/OrganizationBoundary';
import { updateAssetSelectedVersion } from '@redux/actions/mediaViewerAction';

const { TabPane } = Tabs;
const allowCommentsKey = 'ALLOW_COMMENTS_KEY';
function ShareAssetModal() {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');

  const { currentOrganization } = useOrganization(false);
  const shareAssetModal = useTypedSelector(
    ({ modal }) => modal.shareAssetModal
  );
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [assetReviewId, setAssetReviewId] = useState<string | null>();
  const [accessLink, setAccessLink] = useState<string | null>(null);
  const [linkName, setLinkName] = useState<string>();
  const [isLinkDisabled, setIsLinkDisabled] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('sharing');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [hasActiveApproval, setHasActiveApproval] = useState<boolean>(false);
  const [assetWithActiveAw, setAssetWithActiveAw] = useState<
    { asset: AssetItemDto; versionId?: string | undefined }[]
  >([]);
  const [settingPermissions, setSettingPermissions] = useState<{
    allowComments: boolean;
    allowDownloads: boolean;
    allowAllVersions: boolean;
  }>({
    allowComments: localStorage.getItem(allowCommentsKey) === 'true',
    allowDownloads: true,
    allowAllVersions: false
  });

  const allowCommentsRef = useRef(settingPermissions.allowComments);
  allowCommentsRef.current = settingPermissions.allowComments;
  const previewLink: string | null = useMemo(
    () =>
      `/external-reviewer?reviewId=${assetReviewId}&password=${
        hasPassword ? 'true' : 'false'
      }&active=true&preview=true`,
    [assetReviewId, hasPassword]
  );
  const isLastVersionShared = useMemo(() => {
    const asset = shareAssetModal?.assets[0].asset;
    const v = asset?.asset?.versions.find(
      (it) => it.id === shareAssetModal?.assets[0].versionId
    );
    const index = asset?.asset?.versions.findIndex((it) => it.id === v?.id);
    return index === 0;
  }, [shareAssetModal?.assets]);
  const bottomText: string = useMemo(() => {
    if (settingPermissions.allowComments && settingPermissions.allowDownloads) {
      return 'This link lets anyone download and comment on the media.';
    }
    if (settingPermissions.allowComments) {
      return 'This link lets anyone comment on the media but not download.';
    }
    if (settingPermissions.allowDownloads) {
      return 'This link lets anyone download the media but not comment.';
    }
    return 'This link lets anyone view the media but not download or comment.';
  }, [settingPermissions]);

  const getAssetData = async () => {
    if (!shareAssetModal || shareAssetModal.assets.length <= 0) return;
    try {
      const { assets } = shareAssetModal;

      const assetWithAW = assets.filter((it) => {
        const v = it.versionId
          ? it.asset.asset?.versions.find((x) => x.id === it.versionId)
          : it.asset.asset?.versions[0];
        if (!v) return false;
        return (
          v?.approvalWorkflow &&
          !v?.approvalWorkflow.isFinished &&
          !v?.approvalWorkflow.isPaused
        );
      });
      if (assetWithAW.length) {
        setHasActiveApproval(true);
        setAssetWithActiveAw(assetWithAW);
        setIsLoading(false);
        return;
      }
      setHasActiveApproval(false);
      setAssetWithActiveAw([]);
      const _assets = assets.map((it) => ({
        id: it.asset.id,
        versionId: it.versionId
      }));
      await getAssetReview(_assets);
    } catch (error: any) {
      onCloseModal();
      message.error({
        content: error?.response?.data?.message || 'Something went wrong',
        className: 'message-dark-modal'
      });
    }
  };

  useEffect(() => {
    if (shareAssetModal?.visible) {
      setIsLoading(shareAssetModal.visible);
    }
    return () => {
      setActiveTab('sharing');
    };
  }, [shareAssetModal]);

  useEffect(() => {
    if (!shareAssetModal?.visible) return;

    setSettingPermissions({
      allowComments: localStorage.getItem(allowCommentsKey) === 'true',
      allowDownloads: true,
      allowAllVersions: false
    });
  }, [shareAssetModal?.visible]);

  useEffect(() => {
    if (shareAssetModal?.visible) {
      (async () => {
        await getAssetData();
      })();
    }
  }, [shareAssetModal]);

  const handleOpenApprovalModal = () => {
    const asset = shareAssetModal?.assets[0].asset;
    const v = shareAssetModal?.assets[0].asset.asset?.versions.find(
      (it) => it.id === shareAssetModal.assets[0].versionId
    );

    const _assetVersion = selectedVersion || v;
    if (_assetVersion && asset) {
      onCloseModal();
      dispatch(
        toggleApprovalModal({
          visible: true,
          assetVersion: _assetVersion,
          isLastVersion:
            _assetVersion.versionNumber === asset.asset?.versionsCount,
          assetId: asset.id,
          campaignId: asset.campaignId,
          allowCreate: !!asset.asset?.permissions.createApprovalWorkflows,
          callback: (asset) => {
            const variant = asset.asset?.versions.find(
              (el) => el.id === _assetVersion.id
            );
            if (!variant) return;
            dispatch(
              updateAssetSelectedVersion({
                newSelectedVersion: variant
              })
            );
          }
        })
      );
    }
  };

  const getAssetReview = async (
    assets: { id: string; versionId?: string }[]
  ) => {
    if (!shareAssetModal) return;
    if (shareAssetModal.action === 'create') {
      try {
        const formData: CreateAssetReviewDto = {
          assets,
          active: true,
          password: null,
          ...settingPermissions
        };

        const { data }: { data: CreateAssetReviewSuccessDto } =
          await createAssetReview(formData);

        setAssetReviewId(data.review.id);
        setAccessLink(data.review.url);
        setIsLinkDisabled(!data.review.active);
      } catch (error: any) {
        onCloseModal();
        message.error({
          content: error?.response?.data?.message || 'Something went wrong',
          className: 'message-dark-modal'
        });
      }
    } else {
      setSettingPermissions({
        allowComments: shareAssetModal.review?.allowComments || false,
        allowDownloads: shareAssetModal.review?.allowDownloads || false,
        allowAllVersions: shareAssetModal.review?.allowAllVersions || false
      });
      setAssetReviewId(shareAssetModal.review?.id);
      setAccessLink(shareAssetModal.review?.url || '');
      setIsLinkDisabled(!shareAssetModal.review?.active);
    }
    setIsLoading(false);
  };

  const onSubmitInviteUser = async (data: {
    users: string[];
    groups: string[];
    message?: string;
  }): Promise<boolean | void> => {
    if (assetReviewId) {
      try {
        await shareAssetReviewLink({
          assetReviewId,
          userEmails: data.users,
          groupIds: data.groups,
          ...(data.message && { customMessage: data.message })
        });
        setIsSuccess(true);
        await MixpanelService.track(
          mixpanel,
          user.id,
          MixpanelEventType.MANAGE_ASSETS,
          {
            addToFavorite: false,
            deleteAsset: false,
            newAsset: false,
            externalLinkShare: true,
            approvalRouting: false,
            newCommentAsset: false,
            annotationLeft: false,
            taskFromComment: false
          }
        );
        return true;
      } catch (error: any) {
        message.error({
          content: error?.response?.data?.message || 'Something went wrong',
          className: 'message-dark-modal'
        });
        return false;
      }
    }
  };

  const onUpdateLinkName = useCallback(
    async (name: string) => {
      if (!assetReviewId) return;
      setLinkName(name);
      await updateAssetReview({ reviewId: assetReviewId, name });
    },
    [assetReviewId]
  );

  const onUpdateAssetReview = async (data: UpdateAssetReviewDto) => {
    if (assetReviewId) {
      try {
        const { allowComments, allowDownloads, allowAllVersions, password } =
          data;
        const formData: UpdateAssetReviewDto = {
          reviewId: assetReviewId,
          allowComments,
          allowDownloads,
          allowAllVersions,
          password: password || null
        };

        await updateAssetReview(formData);
        if (shareAssetModal?.updFunc)
          shareAssetModal?.updFunc({
            id: shareAssetModal.review?.id || '',
            allowComments: allowComments || false,
            allowDownloads: allowDownloads || false,
            allowAllVersions: allowAllVersions || false,
            password: password || null
          });
        setSettingPermissions({
          allowComments: allowComments || false,
          allowDownloads: allowDownloads || false,
          allowAllVersions: allowAllVersions || false
        });
        setHasPassword(!!password);
      } catch (error: any) {
        if (error?.response?.data?.errorCode === 'feature_not_allowed') {
          message.error({
            content: 'This feature is included in the higher plan',
            className: 'message-dark-modal'
          });
        } else {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
        throw error;
      }
    }
  };

  const onChangeTab = (key: string) => {
    if (!hasActiveApproval) {
      setActiveTab(key);
    }
  };

  const onCloseModal = () => {
    setIsSuccess(false);
    dispatch(toggleShareAssetModal(null));
    localStorage.setItem(
      allowCommentsKey,
      allowCommentsRef.current ? 'true' : 'false'
    );
  };

  return (
    <Modal
      open={shareAssetModal?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={492}
      zIndex={1006}
      className="share_asset_modal_container"
      onCancel={onCloseModal}
    >
      {isLoading ? (
        <Spin className="player_loader share_asset_modal_loader" />
      ) : isSuccess ? (
        <div className="modal_container">
          <h2 className="modal_container__title">Link successfully shared</h2>
          <div className="share_asset_modal_success">
            <div className="share_asset_modal_success-inner">
              <CheckTaskSvg className="share_asset_modal_success-icon" />
            </div>
          </div>
        </div>
      ) : (
        <div className="modal_container">
          <h2 className="modal_container__title">
            {(shareAssetModal?.assets.length || 0) > 1
              ? `Share ${shareAssetModal?.assets.length} media`
              : 'Share media'}
          </h2>
          <div className="share_asset_modal_link">
            {shareAssetModal &&
              shareAssetModal.assets.slice(0, 2).map((it, index) => {
                let v = it.asset.asset?.versions.find(
                  (version) => version.id === it.versionId
                );
                if (!v) v = it.asset.asset?.versions[0];
                const isLastItem =
                  shareAssetModal.assets.slice(0, 2).length - 1 === index;
                return (
                  <span key={it.asset.id}>
                    {v?.name}.{v?.extension}
                    {!isLastItem && ','}
                  </span>
                );
              })}
            {shareAssetModal && shareAssetModal.assets.slice(2).length > 0 && (
              <Tooltip
                title={
                  <>
                    {shareAssetModal.assets.slice(2).map((it) => {
                      let v = it.asset.asset?.versions.find(
                        (version) => version.id === it.versionId
                      );
                      if (!v) v = it.asset.asset?.versions[0];
                      if (!v) return null;
                      return (
                        <div key={v.id}>
                          {v.name}.{v.extension}
                        </div>
                      );
                    })}
                  </>
                }
                placement="bottom"
                overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
              >
                <span className="more">
                  {shareAssetModal.assets.slice(2).length}+
                </span>
              </Tooltip>
            )}
          </div>

          <Tabs
            activeKey={activeTab}
            onTabClick={onChangeTab}
            className="modal_container__invitation_type"
          >
            <TabPane tab="Sharing" key="sharing">
              {hasActiveApproval ? (
                <ApprovalWorkflow
                  assets={shareAssetModal?.assets}
                  assetWithActiveAw={assetWithActiveAw}
                />
              ) : (
                <>
                  <Sharing
                    link={accessLink}
                    linkName={linkName}
                    previewLink={previewLink}
                    isDisabled={isLinkDisabled}
                    campaignId={
                      shareAssetModal?.assets[0].asset.campaignId || ''
                    }
                    onSubmit={onSubmitInviteUser}
                    onUpdateLinkName={onUpdateLinkName}
                  />
                  {shareAssetModal?.action === 'edit' ? (
                    <div className="share_asset_modal_info">
                      {currentOrganization?.entity?.features.approvalRouting &&
                        isLastVersionShared &&
                        shareAssetModal?.assets.length === 1 && (
                          <>
                            <p className="modal_container__copy">
                              Route this media for approval
                            </p>
                            <div className="share_asset_modal_info-text">
                              Did you know you can collect approvals on this
                              media? We started an approval routing for you
                              based on your previous shares.
                            </div>
                            <Button
                              type="primary"
                              htmlType="button"
                              onClick={handleOpenApprovalModal}
                            >
                              Complete approval routing
                            </Button>
                          </>
                        )}
                    </div>
                  ) : (
                    <div className="share_asset_modal_bottom-info">
                      <Row gutter={16}>
                        <Col className="share_asset_modal_bottom-info-icon">
                          <MagicianSvg />
                        </Col>
                        <Col className="share_asset_modal_bottom-info-text">
                          {bottomText} Manage permissions{' '}
                          <a
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              onChangeTab('settings');
                            }}
                          >
                            here.
                          </a>
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tab="Settings" key="settings">
              <Settings
                onSubmit={onUpdateAssetReview}
                settingPermissions={settingPermissions}
                onCloseModal={onCloseModal}
                handleOpenApprovalModal={handleOpenApprovalModal}
                assetsCount={shareAssetModal?.assets.length || 0}
                isLastVersionShared={isLastVersionShared}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Modal>
  );
}

export default ShareAssetModal;
