import { OrganizationWorkspaceItemDto } from '@api/Api';
import ImageComponent from '@components/ImageComponent';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { Button, Tooltip } from 'antd';
import React, { memo, useMemo } from 'react';
import {
  toggleBillingModal,
  toggleWorkspaceInvitationModal
} from '@redux/actions/modalAction';
import WorkspaceDropDownMenu from '@components/EntitiesDropdownMenu/WorkspaceDropDownMenu';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';

type WorkspaceItemProps = {
  workspace: OrganizationWorkspaceItemDto;
};

export default memo(function WorkspaceItem({ workspace }: WorkspaceItemProps) {
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(true);

  const organization = nonNull(currentOrganization.entity);
  const { seatsCount } = currentOrganization;

  const maxSeatsReached =
    seatsCount.count >=
    (organization.limits.orgMembers ?? Number.POSITIVE_INFINITY);
  const users = useMemo(() => workspace.topMembers, [workspace.topMembers]);

  return (
    <div className="workspace_card_container">
      <div className="card">
        <div className="row">
          <div className="workspace_info">
            <div className="workspace_info__image">
              <ImageComponent
                alt={`${workspace.name} avatar`}
                src={workspace.picture.url}
                width={40}
                height={40}
                types={['png', 'webp']}
                id={workspace.id}
                name={workspace.name}
              />
            </div>
            <div className="workspace_info__copy">
              <div className="name">{workspace.name}</div>
              <div className="users_count">
                {workspace.totalMembersCount}{' '}
                {workspace.totalMembersCount > 1 ? 'users' : 'user'}{' '}
              </div>
            </div>
          </div>
          <div className="workspace_action">
            <WorkspaceDropDownMenu workspace={workspace} />
          </div>
        </div>
        <div className="row">
          <div className="members">
            <AvatarsGroup
              maxCount={5}
              avatarSize={40}
              users={users}
              membersType="member"
            />
          </div>
          <Tooltip
            overlayInnerStyle={{ width: 318 }}
            overlayClassName="toggle_favorite_overlay"
            title={
              maxSeatsReached && (
                <>
                  You have reached the user limit in your account. <br />
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      if (!currentOrganization.entity?.permissions.billing)
                        return;
                      dispatch(toggleBillingModal({ visible: true }));
                    }}
                  >
                    Upgrade
                  </span>{' '}
                  to invite more people.
                </>
              )
            }
            placement="bottom"
          >
            <Button
              onClick={() => {
                dispatch(
                  toggleWorkspaceInvitationModal({
                    visible: true,
                    workspace
                  }) as any
                );
              }}
              type="link"
              disabled={maxSeatsReached}
            >
              <PlusSvg /> Invite member
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
});
