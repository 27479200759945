import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useMemo,
  ReactNode,
  useRef
} from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { Layout } from 'antd';
import AppHeader from '@components/AppHeader';
import WorkspacesPanel from '@components/WorkspacesPanel';
import MenuPanel from '@components/MenuPanel';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentWorkspace } from '@hooks/workspace';
import { ReactComponent as UploadFilesSvg } from '@assets/icons/upload-campaign-files.svg';
import { ReactComponent as LogoWhite } from '@assets/icons/logo-white.svg';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  ExtraStorageSizeAlert,
  ExtraUsersAlert,
  FreeTrialHasEnded,
  PaymentNotAuthorizedAlert
} from '@components/Alerts';
import TrialEndsInAlert from '@components/Alerts/TrialEndsInAlert';
import BlockMobile from '@components/BlockMobile/BlockMobile';
import { getFiles } from '@helpers/getFiles';
import { useAssetsUppy } from '@context/AssetsUppyContext';
import { useResponsive } from '@hooks/useResponsive';
import { ActionToast } from '@components/Toasts';
import WelcomeOverlay from '@pages/Start/components/WelcomeOverlay/WelcomeOverlay';
import { useAuth } from '@hooks/useAuth';

const { Header, Content, Sider } = Layout;

const workspacesListSiderWidth = 65;
const workspaceSiderWidth = 240;
type LayoutViewType = string;

const PAGE_LAYOUT_KEY = 'page-layout';

interface IPageLayoutContext {
  readonly currentView: LayoutViewType;
  readonly setCurrentView: (view: LayoutViewType) => void;
}

const PageLayoutContext = createContext<IPageLayoutContext>({
  currentView: 'tile',
  setCurrentView: (view: LayoutViewType) => {
    /* Empty */
  }
});
PageLayoutContext.displayName = 'PageLayout';

export function PageLayoutProvider({ children }: { children: ReactNode }) {
  const layout = localStorage.getItem(PAGE_LAYOUT_KEY) || 'tile';

  const [currentView, setCurrentView] = useState<LayoutViewType>(layout);

  useEffect(() => {
    localStorage.setItem(PAGE_LAYOUT_KEY, currentView);
  }, [currentView]);

  const contextValue = useMemo(
    () => ({
      currentView,
      setCurrentView
    }),
    [currentView]
  );

  return (
    <PageLayoutContext.Provider value={contextValue}>
      {children}
    </PageLayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(PageLayoutContext);
  return {
    currentView: context.currentView,
    setCurrentView: context.setCurrentView
  };
}

function PageLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uppy = useAssetsUppy();
  const { isXsWidth } = useResponsive();
  const [currentWorkspace] = useCurrentWorkspace(false);

  const [showWorkspaceSider, setShowWorkspaceSider] = useState<boolean>(true);
  const [contentMargin, setContentMargin] = useState<number>(
    workspaceSiderWidth + workspacesListSiderWidth
  );

  const { user } = useAuth(true, 'internal');
  const [showWelcomeOverlay, setShowWelcomeOverlay] = useState<boolean>(false);
  const [showInvitation, setShowInvitation] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const isNewUserRef = useRef(user.isNewUser);
  isNewUserRef.current = user.isNewUser;

  useEffect(() => {
    const welcome =
      searchParamsRef.current.get('welcome') ?? isNewUserRef.current;
    const invitation = searchParamsRef.current.get('invitation');
    setShowWelcomeOverlay(!!welcome);
    setShowInvitation(!!invitation);
    searchParamsRef.current.delete('invitation');
    searchParamsRef.current.delete('welcome');
    setSearchParamsRef.current(searchParamsRef.current);
  }, []);

  useEffect(() => {
    if (!showInvitation) return;
    toast(
      <ActionToast
        title="Invitations sent!"
        onUndo={undefined}
        description={undefined}
        closeToast={undefined}
      />,
      {
        hideProgressBar: true,
        style: { width: '345px', height: 80 },
        bodyClassName: 'toast_container--invitation'
      }
    );
  }, [showInvitation]);

  useEffect(() => {
    const newShowWorkspaceSider = !!currentWorkspace;
    const newContentMargin =
      workspacesListSiderWidth +
      (newShowWorkspaceSider ? workspaceSiderWidth : 0);
    setShowWorkspaceSider(newShowWorkspaceSider);
    setContentMargin(newContentMargin);
  }, [currentWorkspace]);

  const dragEnterCounter = useRef(0);
  const [showDropzone, setShowDropzone] = useState(false);

  if (isXsWidth) {
    return <BlockMobile />;
  }

  if (showWelcomeOverlay)
    return <WelcomeOverlay setShowWelcomeOverlay={setShowWelcomeOverlay} />;

  return (
    <PageLayoutProvider>
      <Layout
        id="app_layout"
        style={{ minHeight: '100vh', background: '#fff' }}
        onDragOver={(e) => {
          if (!e.dataTransfer.types.includes('Files')) return;
          e.preventDefault();
          e.stopPropagation();
          e.dataTransfer.dropEffect = 'copy';
        }}
        onDragEnter={(e) => {
          if (!e.dataTransfer.types.includes('Files')) return;
          e.preventDefault();
          e.stopPropagation();
          dragEnterCounter.current += 1;
          if (!showDropzone) setShowDropzone(true);
        }}
        onDragLeave={(e) => {
          if (!e.dataTransfer.types.includes('Files')) return;
          e.stopPropagation();
          dragEnterCounter.current -= 1;
          if (dragEnterCounter.current <= 0) {
            dragEnterCounter.current = 0;
            if (showDropzone) setShowDropzone(false);
          }
        }}
        onDropCapture={async (e) => {
          if (!e.dataTransfer.types.includes('Files')) return;
          e.preventDefault();
          e.stopPropagation();
          dragEnterCounter.current = 0;
          setShowDropzone(false);
          const files = await getFiles(e.dataTransfer.items);
          if (!files.length) return;
          uppy.addFiles(
            files.map((file) => ({
              name: file.name,
              type: file.type,
              data: file,
              meta: {
                postUpload: 'redirect-to-campaign'
              }
            }))
          );
          dispatch(
            toggleUploadAssetModal({
              visible: true,
              postAction: 'redirect-to-campaign',
              workspaceId: currentWorkspace?.id || ''
            })
          );
        }}
      >
        {showDropzone && (
          <div className="drag_state_overlay">
            <div className="cloud">
              <UploadFilesSvg />
            </div>
          </div>
        )}
        <Sider
          style={{
            height: '100vh',
            maxHeight: '100vh',
            position: 'fixed',
            left: 0
          }}
          className="workspaces_sider"
          width={workspacesListSiderWidth}
        >
          <WorkspacesPanel />
        </Sider>

        {showWorkspaceSider && (
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: workspacesListSiderWidth
            }}
            className="menu_sider"
            width={workspaceSiderWidth}
          >
            <div className="logo">
              <LogoWhite
                onClick={() => {
                  if (!currentWorkspace?.id) return;
                  navigate(`/campaigns/all/${currentWorkspace.id}`);
                }}
              />
            </div>
            <MenuPanel />
          </Sider>
        )}
        <OverlayScrollbarsComponent
          options={{
            resize: 'none',
            autoUpdateInterval: 100
          }}
          style={{
            marginLeft: contentMargin,
            maxWidth: `calc(100% - ${contentMargin}px)`,
            flexGrow: 1
          }}
        >
          <Layout
            className="site-layout"
            style={{ background: '#fff', height: '100%' }}
          >
            <Header
              className="site-layout-background"
              style={{ width: `calc(100% - ${contentMargin}px)`, padding: 0 }}
            >
              <AppHeader />
            </Header>
            <Content style={{ marginTop: 72 }}>
              <div
                className="site-layout-background"
                style={{
                  padding: '30px 72px',
                  minHeight: 360,
                  maxHeight: 'calc(100vh - 72px)',
                  overflow: 'auto'
                }}
              >
                <TrialEndsInAlert fullScreen />
                <FreeTrialHasEnded fullScreen />
                <PaymentNotAuthorizedAlert fullScreen />
                <ExtraUsersAlert fullScreen />
                <ExtraStorageSizeAlert fullScreen />
                <Outlet />
              </div>
            </Content>
          </Layout>
        </OverlayScrollbarsComponent>

        <ToastContainer
          transition={Slide}
          closeOnClick={false}
          closeButton={false}
          position="top-center"
          theme="dark"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          progressClassName="fancy-progress-bar"
        />
      </Layout>
    </PageLayoutProvider>
  );
}

export default PageLayout;
