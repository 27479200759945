import React, { useEffect, useState } from 'react';

import { AssetLinksTable } from '@components/Tables';
import {
  assetReviewDeleteAssetReview,
  listAssetReviews,
  // assetReviewListAssets,
  updateAssetReview
} from '@api/AssetReview';
import { useTypedSelector } from '@hooks';
import { AssetReviewItemDto, PageDto } from '@api/Api';
import InfiniteScroll from 'react-infinite-scroll-component';
import LottieComponent from '@components/Lottie';

function CampaignSharedLinksTab() {
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const [linksData, setLinksData] = useState<
    (PageDto & { edges: { node: AssetReviewItemDto; cursor: string }[] }) | null
  >();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLinksData(null);
    if (!campaignInfoModal?.visible) return;
    (async () => {
      setLoading(true);
      const { data } = await listAssetReviews({
        campaignId: campaignInfoModal?.campaign?.id || ''
      });
      setLinksData(data);
      setLoading(false);
    })();
  }, [campaignInfoModal]);
  const loadMore = async () => {
    if (!linksData) return;
    setLoading(true);
    const { data } = await listAssetReviews({
      campaignId: campaignInfoModal?.campaign?.id || '',
      after: linksData.endCursor || undefined
    });
    const newLinksData = {
      ...linksData,
      ...data,
      edges: [...linksData.edges, ...data.edges]
    };
    setLoading(false);
    setLinksData(newLinksData);
  };

  const onActiveChange = async (v: boolean, id: string) => {
    if (!linksData) return;
    await updateAssetReview({ reviewId: id, active: v });
    const newLinksData = {
      ...linksData,
      edges: linksData.edges.map((el) => {
        if (el.node.id === id)
          return {
            ...el,
            node: {
              ...el.node,
              active: v
            }
          };
        return el;
      })
    };
    setLinksData(newLinksData);
  };

  const onRemoveLink = async (id: string) => {
    if (!linksData) return;
    await assetReviewDeleteAssetReview({ reviewId: id });
    const newLinksData = {
      ...linksData,
      edges: linksData.edges.filter((el) => el.node.id !== id)
    };
    setLinksData(newLinksData);
  };

  const onUpdateSettings = ({
    id,
    allowComments,
    allowDownloads,
    allowAllVersions,
    password
  }: {
    id: string;
    allowComments: boolean;
    allowDownloads: boolean;
    allowAllVersions: boolean;
    password: string | null;
  }) => {
    if (!linksData) return;
    const newLinksData = {
      ...linksData,
      edges: linksData.edges.map((el) => {
        if (el.node.id === id)
          return {
            ...el,
            node: {
              ...el.node,
              allowComments,
              allowDownloads,
              allowAllVersions,
              password
            }
          };
        return el;
      })
    };
    setLinksData(newLinksData);
  };

  return (
    <>
      <p className="tab_description">
        Manage links that were shared externally.
      </p>
      <div className="links_container">
        {loading || (linksData && linksData.edges.length > 0) ? (
          <InfiniteScroll
            dataLength={linksData?.edges?.length || 0}
            next={loadMore}
            hasMore={linksData?.hasNext || false}
            height="calc(100vh - 308px)"
            style={{
              overflowX: 'hidden',
              paddingRight: '25px',
              marginRight: '-20px'
            }}
            loader={null}
          >
            <AssetLinksTable
              linksData={linksData?.edges || []}
              loading={loading}
              onActiveChange={onActiveChange}
              onRemoveLink={onRemoveLink}
              onUpdateSettings={onUpdateSettings}
            />
          </InfiniteScroll>
        ) : (
          <div className="empty_links">
            <LottieComponent className="icon" view="addShareLink" />
            <div className="copy">Start sharing media with your team.</div>
          </div>
        )}
      </div>
    </>
  );
}

export default CampaignSharedLinksTab;
