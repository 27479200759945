import { nonNull } from '@helpers/non-null';
import Controls from '@pages/Start/components/Modals/WatchVideoModal/Controls';
import React, { useEffect, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import CreateCampaign from '@assets/videos/create-campaign.png';
import CreateTaskFromComment from '@assets/videos/create-task-from-comment.png';
import CreateTask from '@assets/videos/create-task.png';
import RouteForApproval from '@assets/videos/route-for-approval.png';
import UploadAsset from '@assets/videos/upload-asset.png';
import 'video.js/dist/video-js.css';
import { OnboardingStepsType } from '@context/OnboardingProcessProvider';

const videoJsOptions: VideoJsPlayerOptions = {
  autoplay: false,
  aspectRatio: '1092:720',
  fluid: false,
  responsive: true
};

type Props = {
  src: string;
  type: OnboardingStepsType | false;
};

const poster = {
  'first-enter-steps': undefined,
  'revisit-onboarding': undefined,
  'explore-demo-campaign': undefined,
  'create-campaign': CreateCampaign,
  'upload-asset': UploadAsset,
  'create-task': CreateTask,
  'create-task-from-comment': CreateTaskFromComment,
  'route-for-approval': RouteForApproval
};

export default function Video({ src, type }: Props) {
  const [ready, setReady] = useState<boolean>(false);
  const playerRef = React.useRef<VideoJsPlayer>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videoElement = nonNull(videoRef.current);
    const player = videojs(videoElement, videoJsOptions, () => {
      setReady(true);
    });
    (playerRef.current as any) = player;
    return () => {
      player.dispose();
    };
  }, []);
  useEffect(() => {
    if (!playerRef.current || !ready || !src) return;
    if (type) playerRef.current.poster(poster[type]);
    playerRef.current.src({
      type: 'video/mp4',
      src
    });
  }, [src, ready, type]);
  return (
    <div data-vjs-player className="modal_container__watch-video-player">
      <video ref={videoRef} className="video-js" playsInline />
      {ready && <Controls ref={playerRef} />}
    </div>
  );
}
