import React, { useState } from 'react';
import { message, Form, Button, Typography, Input, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as GmailSvg } from '@assets/icons/gmail.svg';

const { Title, Text } = Typography;

interface IConfirmEmailModalProps {
  onSubmit(data: { code: string }): void;
}

const inputArray: string[] = [
  'number-1',
  'number-2',
  'number-3',
  'number-4',
  'number-5',
  'number-6'
];

function ConfirmEmailModal({ onSubmit }: IConfirmEmailModalProps) {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmitForm = async (data: object) => {
    const code = Object.values(data).join('');
    setIsSubmit(true);
    try {
      await onSubmit({ code });
      form.resetFields();
    } catch (error) {
      console.warn(error, 'Confirm Email');
      message.error('Something wend wrong');
    } finally {
      setIsSubmit(false);
    }
  };

  const onChangeForm = (e: React.ChangeEvent<HTMLFormElement>) => {
    const input: HTMLFormElement = e.target;
    const {
      form: { elements }
    } = input;
    const arrayElements: HTMLFormElement[] = new Array(...elements);
    const indexCurrentInput: number = arrayElements.indexOf(input);
    const nextElement: HTMLFormElement = arrayElements[indexCurrentInput + 1];
    if (nextElement && input.value && !nextElement.value) {
      nextElement.focus();
      return;
    }
    form.submit();
  };

  const onPasteForm = (e: React.ClipboardEvent) => {
    const text: string = e.clipboardData.getData('text');
    if (text) {
      const arrayWords: string[] = text.replace(' ', '').slice(0, 6).split('');
      const formValue: { [key: string]: string } = {};
      for (let i = 0; i < arrayWords.length; i++) {
        const name = inputArray[i];
        const value = arrayWords[i];
        if (value) {
          formValue[name] = value;
        }
      }
      form.setFieldsValue(formValue);
      form.submit();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
      layout="vertical"
      onChange={onChangeForm}
      onPaste={onPasteForm}
    >
      <Title level={4} className="profile_modal_title">
        Confirm your email
      </Title>
      <Text className="profile_modal_text">
        We’ve sent you a code to your email. Please enter it below to confirm
        your password.
      </Text>
      <Button
        type="link"
        target="_blank"
        href="https://mail.google.com/"
        icon={<GmailSvg />}
        className="profile_modal_gmail-btn"
      >
        Go to Gmail
      </Button>
      <Row gutter={12} className="profile_modal_row">
        {inputArray.map((name, index) => (
          <Col span={4} key={`number_${index}`}>
            <Form.Item name={name} rules={[{ required: true, message: '' }]}>
              <Input
                className="profile_modal_input-code"
                size="large"
                maxLength={1}
              />
            </Form.Item>
          </Col>
        ))}
        {isSubmit && (
          <div className="profile_modal_spinner">
            <LoadingOutlined />
          </div>
        )}
      </Row>
      <div className="profile_modal_bottom-text">
        {/* eslint-disable-next-line prettier/prettier */}
        <a href="#!">Send code</a> again or chat with <a href="#!">our support team</a>
      </div>
    </Form>
  );
}

export default ConfirmEmailModal;
