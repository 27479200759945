import React, { forwardRef, SyntheticEvent, useMemo, useState } from 'react';
import UserAvatar from '@components/UserAvatar';
import { Avatar, Select } from 'antd';
import type { BaseSelectRef, CustomTagProps } from 'rc-select/lib/BaseSelect';
import { MemberDto, MemberGroupDto, PageDto } from '@api/Api';
import { useDebounced } from '@helpers/useDebounced';
import { nonNull } from '@helpers/non-null';
import { emailValidatorRegExp } from '@helpers/validators';
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query';
import type { BaseOptionType } from 'antd/lib/select';
import { hashedColor } from '@helpers/hashedColor';
import './InviteMembersSelect.less';

type TData<T> = PageDto & {
  edges: {
    node: T;
    cursor: string;
  }[];
};

type SelectedMember = {
  type: 'member';
  id: string;
  name: string;
  email: string;
};

type SelectedGroup = {
  type: 'group';
  id: string;
  name: string;
};

type SelectedNewMember = {
  type: 'new';
  email: string;
};

export type SelectedItem = SelectedMember | SelectedGroup | SelectedNewMember;

interface InviteMembersSelectProps<
  TMember extends MemberDto = MemberDto,
  TGroup extends MemberGroupDto = MemberGroupDto
> {
  value?: SelectedItem[];
  onChange?: (value: SelectedItem[]) => void;
  membersQueryKey?: QueryKey;
  membersQueryFn?: (
    context: QueryFunctionContext<QueryKey, string | undefined> & {
      searchQuery: string;
    }
  ) => Promise<TData<TMember>>;
  groupsQueryKey?: QueryKey;
  groupsQueryFn?: (
    context: QueryFunctionContext<QueryKey, string | undefined> & {
      searchQuery: string;
    }
  ) => Promise<TData<TGroup>>;
  queryEnabled?: boolean;
}

function Tag({ value, onClose }: CustomTagProps) {
  const item: SelectedItem = JSON.parse(value);
  const label =
    item.type === 'new'
      ? item.email
      : item.type === 'group'
      ? item.name
      : item.name || item.email;
  return (
    <span
      className="selected_item"
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <span className="selected_item__content">
        {item.type === 'new' && (
          <span className="new main-body-text main-body-text--tiny main-body-text--white main-body-text--bold">
            new
          </span>
        )}
        <span className="name main-body-text">{label}</span>
        <span className="close" onClick={onClose} />
      </span>
    </span>
  );
}

export default forwardRef(function InviteMembersSelect<
  TMember extends MemberDto = MemberDto
>(
  {
    value,
    onChange,
    membersQueryKey,
    membersQueryFn,
    groupsQueryKey,
    groupsQueryFn,
    queryEnabled
  }: InviteMembersSelectProps<TMember>,
  forwardedRef: React.Ref<BaseSelectRef>
) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounced(searchQuery, 250);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const memberGroupsQueryEnabled = !!(groupsQueryKey && groupsQueryFn);
  const memberGroupsQuery = useInfiniteQuery({
    queryKey: groupsQueryKey ? [...groupsQueryKey, debouncedSearchQuery] : [],
    keepPreviousData: true,
    enabled: memberGroupsQueryEnabled && queryEnabled,
    queryFn: async (ctx) => {
      const queryFn = nonNull(groupsQueryFn);
      return queryFn({
        ...ctx,
        pageParam: ctx.pageParam || undefined,
        searchQuery: debouncedSearchQuery
      });
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersQueryEnabled = !!(membersQueryKey && membersQueryFn);
  const membersQuery = useInfiniteQuery({
    queryKey: membersQueryKey ? [...membersQueryKey, debouncedSearchQuery] : [],
    keepPreviousData: true,
    enabled: membersQueryEnabled && queryEnabled,
    queryFn: async (ctx) => {
      const queryFn = nonNull(membersQueryFn);
      return queryFn({
        ...ctx,
        pageParam: ctx.pageParam || undefined,
        searchQuery: debouncedSearchQuery
      });
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersList = useMemo(
    () =>
      membersQuery.data?.pages.flatMap((x) => x.edges.map((x) => x.node)) ?? [],
    [membersQuery.data]
  );
  const memberGroupsList = useMemo(
    () =>
      memberGroupsQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => x.node)
      ) ?? [],
    [memberGroupsQuery.data]
  );

  const memberGroupsLoading =
    memberGroupsQueryEnabled &&
    (memberGroupsQuery.isLoading ||
      memberGroupsQuery.isFetchingNextPage ||
      memberGroupsQuery.isPreviousData);
  const membersLoading =
    membersQueryEnabled &&
    (membersQuery.isLoading ||
      membersQuery.isFetchingNextPage ||
      membersQuery.isPreviousData);

  const loading = memberGroupsLoading || membersLoading;
  const showMembers =
    !memberGroupsQueryEnabled ||
    memberGroupsQuery.hasNextPage === false ||
    memberGroupsQuery.isPreviousData;

  const handleChange = (values: string[]) => {
    const validValues = values.flatMap((str) => {
      const items: SelectedItem[] = [];
      try {
        items.push(JSON.parse(str));
      } catch {
        items.push(
          ...str
            .split(' ')
            .map((value) => value.trim().toLowerCase())
            .filter((value) => !!value && emailValidatorRegExp.test(value))
            .map((value) => ({ type: 'new' as const, email: value }))
        );
      }
      return items;
    });
    if (onChange) onChange(Array.from(new Set(validValues)));
    setDropdownVisible(false);
    setSearchQuery('');
  };
  const loadMoreData = (e: SyntheticEvent) => {
    const query = showMembers ? membersQuery : memberGroupsQuery;
    if (query.isFetchingNextPage || !query.hasNextPage) return;
    const target = e.target as HTMLDivElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      query.fetchNextPage();
    }
  };
  const open = dropdownVisible && membersList.length > 0;
  const groupOptions = useMemo<BaseOptionType[]>(() => {
    return memberGroupsList.map((item) => ({
      value: JSON.stringify({ type: 'group', id: item.id, name: item.name }),
      label: (
        <div className="list_item">
          <div className="list_item__avatar">
            <Avatar
              size={40}
              shape="square"
              style={{
                background: hashedColor(item.id, 'memberGroupAvatar')
              }}
            >
              {item.name[0].toUpperCase()}
            </Avatar>
          </div>
          <div className="list_item__user_info">
            <div className="list_item__user_info__name">{item.name}</div>
            <div className="list_item__user_info__email">
              {item.membersCount} members
            </div>
          </div>
        </div>
      )
    }));
  }, [memberGroupsList]);
  const memberOptions = useMemo(() => {
    return membersList.map((item) => {
      const email = nonNull(item.user?.email || item.invitationEmail);
      const name = item.user?.name || '';
      return {
        value: JSON.stringify({
          type: 'member',
          id: item.id,
          email,
          name
        }),
        label: (
          <div className="list_item">
            <div className="list_item__avatar">
              <UserAvatar
                isActive={!item.invitationEmail}
                size="large"
                src={item.user?.picture.url || ''}
                userEmail={email}
                userName={name}
              />
            </div>
            <div className="list_item__user_info">
              <div className="list_item__user_info__name">{name}</div>
              <div className="list_item__user_info__email">{email}</div>
            </div>
          </div>
        )
      };
    });
  }, [membersList]);

  const options = useMemo(() => {
    return showMembers ? [...groupOptions, ...memberOptions] : groupOptions;
  }, [groupOptions, memberOptions, showMembers]);

  const strValue = useMemo(() => {
    return value?.map((item) => JSON.stringify(item));
  }, [value]);

  return (
    <Select
      ref={forwardedRef}
      mode="tags"
      size="large"
      listHeight={280}
      listItemHeight={64}
      placeholder="Invite by email or from list"
      tokenSeparators={[',', '\r', '\n']}
      showArrow={false}
      filterOption={false}
      searchValue={searchQuery}
      onSearch={setSearchQuery}
      onChange={handleChange}
      className="invite-members-select"
      popupClassName="invite-members-select__list"
      onPopupScroll={loadMoreData}
      tagRender={Tag}
      open={open}
      onDropdownVisibleChange={setDropdownVisible}
      value={strValue}
      loading={loading}
      options={options}
    />
  );
});
