import { InvoiceItemDto } from '@api/Api';
import { subscriptionGetInvoiceDownloadUrl } from '@api/Subscription';
import { Button, Table } from 'antd';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { ReactComponent as DownloadInvoiceSvg } from '@assets/icons/download-invoice.svg';
import './InvoicesTable.less';
import InvoiceStatusTag from '@pages/AdminSettings/components/BillingTab/components/components/InvoicesTable/InvoiceStatusTag';
import classNames from 'classnames';

type InvoicesTableProps = {
  list: InvoiceItemDto[];
};

export default memo(function InvoicesTable({ list }: InvoicesTableProps) {
  const columns = useMemo(
    () => [
      {
        title: 'Invoice number',
        dataIndex: 'number',
        key: 'number',
        width: 320,
        render: (item: string) => {
          return <span className="time-state">{item}</span>;
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 228,
        render: (item: string) => {
          return (
            <span className="time-state">
              {moment(item).format('MMM DD, YYYY')}
            </span>
          );
        }
      },
      {
        title: 'Total amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 190,
        render: (item: number) => {
          return (
            <span className="time-state">
              {item < 0 ? '-' : ''}${Math.abs(item) / 100}
            </span>
          );
        }
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 160,
        render: (
          item: 'draft' | 'open' | 'paid' | 'void' | 'uncollectible'
        ) => {
          return <InvoiceStatusTag status={item} />;
        }
      },
      {
        title: '',
        key: 'action',
        render: (item: any, invoice: InvoiceItemDto) => (
          <Button
            type="text"
            size="small"
            style={{ float: 'right', height: 24, padding: 0 }}
            onClick={async () => {
              const {
                data: { url }
              } = await subscriptionGetInvoiceDownloadUrl({
                invoiceId: invoice.id
              });
              if (url) window.open(url);
            }}
          >
            <DownloadInvoiceSvg style={{ marginRight: 0 }} />
          </Button>
        )
      }
    ],
    []
  );
  return (
    <div
      className={classNames(
        'table_container',
        !list.length && 'table_container_invoice--hide-placeholder'
      )}
    >
      <Table pagination={false} columns={columns} dataSource={list} />
    </div>
  );
});
