import React, { useState, useEffect } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ErrorSvg } from '@assets/icons/contributors_limit_error_notification.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '@components/OrganizationBoundary';

PaymentNotAuthorizedAlert.defaultProps = {
  fullScreen: false
};

function PaymentNotAuthorizedAlert({ fullScreen }: { fullScreen?: boolean }) {
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization();
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!currentOrganization.entity?.permissions.billing) return;
    if (!currentOrganization.entity.lockReasons.includes('subscription-unpaid'))
      return;

    setIsAlertVisible(true);
  }, [currentOrganization]);

  if (!isAlertVisible) return null;
  return (
    <div
      className={classNames(
        'alert-container',
        fullScreen && 'alert-container-full-screen'
      )}
    >
      <div className="info">
        <div className="icon">
          <ErrorSvg />
        </div>

        <div className="copies">
          <span className="title">Payment not authorized</span>
          <span className="description">
            Please ensure you have a valid credit card on file.
          </span>
        </div>
      </div>
      <div className="actions">
        <Button
          type="primary"
          onClick={() => {
            setIsAlertVisible(false);
            navigate('/admin-settings/billing');
          }}
        >
          Update billing
        </Button>
        <CrossSvg
          style={{ cursor: 'pointer' }}
          onClick={() => setIsAlertVisible(false)}
        />
      </div>
    </div>
  );
}

export default PaymentNotAuthorizedAlert;
