import { OnboardingPercentage } from '@helpers/StartOnboardingSteps';
import { useAuth } from '@hooks/useAuth';
import { Progress } from 'antd';
import React, { memo, useMemo } from 'react';

import './OnboardingProgress.less';
import { useCurrentWorkspace } from '@hooks/workspace';

export default memo(function OnboardingProgress() {
  const [currentWorkspace] = useCurrentWorkspace();

  const { user } = useAuth(true, 'internal');

  const completed = useMemo(() => {
    const isContributor = currentWorkspace.role === 'workspace://contributor';
    const { completed, total } = OnboardingPercentage(
      isContributor,
      user.completedOnboardingSteps
    );
    return { completed, total };
  }, [user.completedOnboardingSteps, currentWorkspace.role]);

  return (
    <div className="onboarding-progress">
      <Progress
        percent={(100 / completed.total) * completed.completed}
        showInfo={false}
        strokeColor="#3553DB"
        trailColor="#F5F6FD"
      />
    </div>
  );
});
