import React, { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { message, Button, Form, Typography, Input, Row, Col } from 'antd';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useOrganization } from '@components/OrganizationBoundary';

const { Title, Text } = Typography;

interface IDeleteAccountModalProps {
  onClose(): void;
  onSubmit(data: any): void;
}

function DeleteAccountModal({ onClose, onSubmit }: IDeleteAccountModalProps) {
  const { logout } = useAuth();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { user } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const { currentOrganization } = useOrganization();

  const onSubmitForm = async (data: any) => {
    setIsSubmit(true);
    try {
      await onSubmit(data);
      message.success('Account successfully deleted');
      await MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.ACCOUNT,
        {
          planType: currentOrganization?.entity?.planType ?? 'free',
          accountDeletion: true
        }
      );
      logout({ returnTo: window.location.origin });
    } catch (error: any) {
      const errorText: string =
        error?.response?.data?.errorCode || 'Something wend wrong';
      if (errorText === 'user_is_organization_owner') {
        message.error('You are the owner, so you cannot delete your account');
      } else {
        message.error(errorText);
      }
      setIsSubmit(false);
    }
  };

  return (
    <Form form={form} onFinish={onSubmitForm} layout="vertical">
      <Title level={4} className="profile_modal_title">
        Delete account
      </Title>
      <Text className="profile_modal_text">
        Are you sure you want to delete your account? All data and media in your
        account will be deleted. This can not be undone.
      </Text>
      <Form.Item
        label="Please type “Delete” to confirm"
        name="text"
        rules={[
          { required: true, message: 'Please, type “Delete” to confirm".' },
          () => ({
            validator(_, value) {
              if (!value || value === 'Delete') {
                return Promise.resolve();
              }
              return Promise.reject('Please, type “Delete” to confirm');
            }
          })
        ]}
      >
        <Input placeholder="Delete" size="large" />
      </Form.Item>
      <Row gutter={24} justify="end" className="profile_modal_bottom">
        <Col>
          <Button
            type="text"
            htmlType="button"
            size="middle"
            disabled={isSubmit}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            loading={isSubmit}
            className="b-btn-primary"
          >
            Delete account
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default DeleteAccountModal;
