import React, { memo } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as TagsMenuSvg } from '@assets/icons/tags.svg';
import { ReactComponent as HyperlinkMenuSvg } from '@assets/icons/hyperlink.svg';
import { ReactComponent as CustomColumnSvg } from '@assets/icons/custom-column.svg';
import { TaskFieldItemDto } from '@api/Api';
import { ItemType, MenuItemGroupType } from 'antd/lib/menu/hooks/useItems';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { createTaskCustomField } from '@redux/actions/tasks';
import { nonNull } from '@helpers/non-null';

interface ActionsColumnTitleProps {
  campaignId: string | null;
  canCreateCustomFields: boolean;
  fields: TaskFieldItemDto[];
}

export default memo(function ActionsColumnTitle({
  campaignId,
  canCreateCustomFields,
  fields
}: ActionsColumnTitleProps) {
  const dispatch = useTypedDispatch();
  if (!campaignId || !canCreateCustomFields) return null;
  const items: ItemType[] = [];
  const addColumnGroup: MenuItemGroupType = {
    key: 'add-column',
    type: 'group',
    label: 'ADD COLUMN',
    children: []
  };
  if (canCreateCustomFields) {
    if (!fields.some((x) => x.type === 'tags')) {
      nonNull(addColumnGroup.children).push({
        key: 'tags',
        label: (
          <>
            <TagsMenuSvg />
            <span>Tags</span>
          </>
        ),
        onClick: () =>
          dispatch(
            createTaskCustomField({
              campaignId,
              type: 'tags',
              name: 'Tags'
            })
          )
      });
    }
    if (!fields.some((x) => x.type === 'url')) {
      nonNull(addColumnGroup.children).push({
        key: 'url',
        label: (
          <>
            <HyperlinkMenuSvg />
            <span>Hyperlink</span>
          </>
        ),
        onClick: () =>
          dispatch(
            createTaskCustomField({
              campaignId,
              type: 'url',
              name: 'Hyperlink'
            })
          )
      });
    }
    if (addColumnGroup.children?.length) items.push(addColumnGroup);
    if (!fields.some((x) => x.type === 'text')) {
      items.push({
        key: 'add-custom',
        type: 'group',
        label: 'ADD CUSTOM COLUMN',
        children: [
          {
            key: 'text',
            label: (
              <>
                <CustomColumnSvg />
                <span>Custom</span>
              </>
            ),
            onClick: () =>
              dispatch(
                createTaskCustomField({
                  campaignId,
                  type: 'text',
                  name: 'Custom'
                })
              )
          }
        ]
      });
    }
  }
  if (!items.length) return null;
  return (
    <Dropdown
      overlay={
        <Menu
          selectable={false}
          className="task-custom-columns-menu"
          items={items}
        />
      }
      placement="bottomLeft"
      trigger={['click']}
    >
      <Tooltip
        overlayClassName="long-text-hint"
        title="Add custom column"
        trigger="hover"
        placement="top"
      >
        <div className="action-icon">
          <PlusSvg />
        </div>
      </Tooltip>
    </Dropdown>
  );
});
