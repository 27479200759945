import { AssetItemDto } from '@api/Api';
import Loader from '@components/Loader';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

type AssetItemProps = {
  asset: AssetItemDto;
  onCloseModal: () => void;
  getApprovalsData: () => void;
};

export default memo(function AssetItem({
  asset,
  onCloseModal,
  getApprovalsData
}: AssetItemProps) {
  const dispatch = useDispatch();
  const assetFirstVersion = useMemo(
    () => asset.asset?.versions[0],
    [asset.asset?.versions]
  );
  const assetImage = useMemo(() => {
    if (!assetFirstVersion) return 'pending';
    return assetPreviewThumbnails({
      url: assetFirstVersion.metadata.preview?.url || '',
      status: assetFirstVersion.metadata.preview?.status,
      type: assetFirstVersion.metadata.type,
      format: 'img'
    });
  }, [assetFirstVersion]);

  const fileSize = useMemo(() => {
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let fs = 'n/a';
    if (!assetFirstVersion) return fs;
    if (assetFirstVersion.size > 0) {
      const i: number = parseInt(
        Math.floor(
          Math.log(assetFirstVersion.size) / Math.log(1024)
        ).toString(),
        10
      );
      if (i === 0) fs = `${assetFirstVersion.size} ${sizes[i]}`;
      fs = `${(assetFirstVersion.size / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    }
    return fs;
  }, [assetFirstVersion]);
  const onOpenApprovalModal = useCallback(() => {
    if (!assetFirstVersion) return;
    onCloseModal();
    dispatch(
      toggleApprovalModal({
        visible: true,
        assetVersion: assetFirstVersion,
        isLastVersion: true,
        assetId: asset.id,
        campaignId: asset.campaignId,
        allowCreate: !!asset.asset?.permissions.createApprovalWorkflows,
        callback: () => {
          getApprovalsData();
        }
      })
    );
  }, [
    assetFirstVersion,
    onCloseModal,
    dispatch,
    asset.id,
    asset.campaignId,
    asset.asset?.permissions.createApprovalWorkflows,
    getApprovalsData
  ]);

  return (
    <div className="asset-list--item" onClick={onOpenApprovalModal}>
      <div>
        <div className="preview">
          {assetImage === 'pending' && (
            <Loader spinSize={40} isSpinning={true} />
          )}
          {assetImage !== 'pending' && typeof assetImage === 'string' && (
            <img src={`${assetImage}`} alt="" />
          )}
        </div>
        <div className="name">
          <span>{assetFirstVersion?.name}</span>
          <div className="ext">{assetFirstVersion?.extension}</div>
        </div>
      </div>
      <div>
        <div className="size">{fileSize}</div>
      </div>
    </div>
  );
});
