import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import WorkspaceItem from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/WorkspaceItem';
import WorkspaceItemSkeleton from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/WorkspaceItemSkeleton';

import {
  organizationWorkspaceFilteredCountStateSelector,
  organizationWorkspaceFilteredListStateSelector,
  organizationWorkspaceStateSelector
} from '@redux/selectors/organizations';
import React, { memo } from 'react';

export default memo(function WorkspacesTab() {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const initiallyLoaded = useTypedSelector(
    (state) =>
      organizationWorkspaceStateSelector(state, { organizationId }).fetch
        .initiallyLoaded
  );

  const count = useTypedSelector((state) =>
    organizationWorkspaceFilteredCountStateSelector(state, { organizationId })
  );
  const list = useTypedSelector((state) =>
    organizationWorkspaceFilteredListStateSelector(state, { organizationId })
  );

  if (!initiallyLoaded)
    return (
      <div className="workspaces">
        {new Array(count).fill(undefined).map((_, idx) => (
          <WorkspaceItemSkeleton key={idx} />
        ))}
      </div>
    );

  return (
    <div className="workspaces">
      {list.map((it) => (
        <WorkspaceItem key={it.id} workspace={it} />
      ))}
    </div>
  );
});
