import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { useDeleteOrganizationMember } from '@hooks/organization';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import { Button } from 'antd';
import React, { memo, useCallback } from 'react';

type ActionCell = {
  memberId: string;
};
export default memo(function ActionCell({ memberId }: ActionCell) {
  const deleteOrganizationMember = useDeleteOrganizationMember();
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );

  const onDeleteMember = useCallback(() => {
    if (!member?.id) return;
    const memberId = [member.id];
    deleteOrganizationMember({ memberId, organizationId });
  }, [deleteOrganizationMember, member?.id, organizationId]);

  if (!member) return null;
  return (
    <div className="action-cell">
      <Button
        type="link"
        style={{ cursor: 'pointer', float: 'right' }}
        onClick={onDeleteMember}
      >
        Remove
      </Button>
    </div>
  );
});
