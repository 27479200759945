import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import FolderItem from '@pages/Campaigns/FolderItem';
import CampaignItem from '@pages/Campaigns/CampaignItem';
import { useCurrentWorkspace } from '@hooks/workspace';
import CampaignsList from './CampaignsList';

function Campaigns() {
  const [currentWorkspace] = useCurrentWorkspace(false);
  return (
    <div className="campaigns_container">
      <Routes>
        <Route path="all">
          <Route index element={<CampaignsList page="all" />} />
          <Route path=":workspaceId" element={<CampaignsList page="all" />} />
        </Route>
        {currentWorkspace?.permissions?.createCampaigns && (
          <Route path="my">
            <Route index element={<CampaignsList page="my" />} />
            <Route path=":workspaceId" element={<CampaignsList page="my" />} />
          </Route>
        )}
        <Route path="favorite">
          <Route index element={<CampaignsList page="favorite" />} />
          <Route
            path=":workspaceId"
            element={<CampaignsList page="favorite" />}
          />
        </Route>
        <Route path="deleted">
          <Route index element={<CampaignsList page="deleted" />} />
          <Route
            path=":workspaceId"
            element={<CampaignsList page="deleted" />}
          />
        </Route>
        <Route path="single/:campaignId" element={<CampaignItem />} />
        <Route path="single/:campaignId/:folderId" element={<FolderItem />} />

        <Route index element={<Navigate to="all" replace />} />
      </Routes>
    </div>
  );
}

export default Campaigns;
