import React, { useEffect, useMemo, useState } from 'react';
import { Button, message, Spin } from 'antd';
import { chooseEmailService } from '@helpers/auth';
import { ReactComponent as CircleArrowLeft } from '@assets/icons/arrow-left.svg';
import CodeLine from '@components/CodeLine';
import { useAuth } from '@hooks/useAuth';
import {
  disableTwoStepVerification,
  enableTwoStepVerification,
  resendTwoStepVerificationCode
} from '@api/Profile';
import LottieComponent from '@components/Lottie';

interface IToggleStepVerificationProps {
  onBack?(): void;
}

const CODE_LINE_LENGTH = 6;

function ToggleStepVerification({ onBack }: IToggleStepVerificationProps) {
  const { user, updateUserProfile } = useAuth(true, 'internal');
  const isVerificationEnabled = user.twoStepVerificationEnabled;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepConfirmed, setIsStepConfirmed] = useState(false);
  const [isSendCode, setIsSendCode] = useState<boolean>(false);
  const [error, setError] = useState<string | boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const resendVerificationCode = async () => {
    try {
      setIsSendCode(true);
      await resendTwoStepVerificationCode({
        forDisable: isVerificationEnabled
      });
      message.success('The code has been sent');
    } catch (error: any) {
      message.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setIsSendCode(false);
    }
  };

  useEffect(() => {
    (async () => {
      await resendTwoStepVerificationCode({
        forDisable: isVerificationEnabled
      });
      setIsLoading(false);
    })();
  }, []);

  const onStepVerification = async (code: string) => {
    try {
      if (isVerificationEnabled) {
        await disableTwoStepVerification({ code });
      } else {
        await enableTwoStepVerification({ code });
      }
      updateUserProfile({
        twoStepVerificationEnabled: !isVerificationEnabled
      });
      setIsStepConfirmed(true);
      if (onBack) {
        setTimeout(() => {
          onBack();
        }, 4000);
      }
    } catch (error: any) {
      console.warn(error, '-> onStepVerification');
      message.error('Wrong code');
    }
  };

  const handleChange = async (code: string) => {
    setError(false);
    if (code.length === CODE_LINE_LENGTH) {
      setDisabled(true);
      await onStepVerification(code);
      setDisabled(false);
    }
  };

  const { service, Logo, link } = useMemo(
    () => chooseEmailService(user.email),
    [user.email]
  );

  const userEmail = useMemo(
    () =>
      user.email.replace(
        /(\S{1})(\S+?)(?=[@])/g,
        (a, b, c) => b + c.replace(/./g, '*')
      ),
    [user.email]
  );

  if (isLoading) {
    return <Spin className="page_spinner" size="large" />;
  }

  if (isStepConfirmed) {
    return (
      <div className="onboarding_container">
        <div className="onboarding_container__secure">
          <div
            className="step_container"
            style={{ maxWidth: 420, margin: '0 auto' }}
          >
            <p className="step_container__title">Code accepted!</p>
            <p className="step_container__description">
              {isVerificationEnabled
                ? 'You have enabled two-factor authentication'
                : 'You have disabled two-factor authentication'}
            </p>
            <div
              className="step_container__body"
              style={{ textAlign: 'center' }}
            >
              <div className="success">
                <LottieComponent view="success" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding_container">
      <div className="onboarding_container__secure">
        <div
          className="step_container"
          style={{ maxWidth: 420, margin: '0 auto' }}
        >
          <p className="step_container__title">
            Setup two-factor authentication
          </p>
          <p className="step_container__description">
            Enter below the two-step verification code sent to <br />
            <span style={{ color: '#161819', fontWeight: 'bold' }}>
              {userEmail}
            </span>
          </p>
          <div className="step_container__body" style={{ textAlign: 'center' }}>
            <div>
              <Button
                type="text"
                size="large"
                className="provider"
                href={link}
                target="_blank"
              >
                <Logo />
                Go To {service}
              </Button>
              <CodeLine
                length={CODE_LINE_LENGTH}
                onChange={(code) => handleChange(code)}
                isValid={!error}
                disabled={disabled}
              />
              <p className="resend">
                <Button
                  type="link"
                  disabled={isSendCode}
                  onClick={resendVerificationCode}
                >
                  Send code
                </Button>
                <span>again or chat with</span>
                <Button type="link">our support team</Button>
              </p>
            </div>
          </div>
        </div>
        <div className="onboarding_container__controller">
          <Button
            className="onboarding_container__controller_button onboarding_container__controller_button--prev"
            type="text"
            onClick={() => onBack && onBack()}
          >
            <div className="icon">
              <CircleArrowLeft />
            </div>
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

ToggleStepVerification.defaultProps = {
  onBack: null
};

export default ToggleStepVerification;
