import React, { useRef } from 'react';
import { TasksOrderBy } from '@redux/types/tasks';
import useTypedSelector from '@hooks/useTypedSelector';
import { campaignStateSelector } from '@redux/selectors/tasks';
import { Button, Dropdown, Input, Menu } from 'antd';
import { ReactComponent as SortArrowSvg } from '@assets/icons/sort-arrow.svg';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { nonNull } from '@helpers/non-null';

const orderByOptions = [
  {
    key: 'sort-by',
    type: 'group' as const,
    label: 'SORT BY',
    children: [
      {
        key: 'order:ASC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Custom
          </>
        )
      },
      {
        key: 'createdAt:DESC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Date created
          </>
        )
      },
      {
        key: 'dueDate:ASC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Due date
          </>
        )
      },
      {
        key: 'status:ASC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Status
          </>
        )
      },
      {
        key: 'lastEventTime:DESC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Date modified
          </>
        )
      }
    ]
  }
];

interface TasksListControlsProps {
  campaignId: string;
  onSearchQueryChange: (value: string) => void;
  onOrderByChange: (value: TasksOrderBy) => void;
}

export default function TasksListControls(props: TasksListControlsProps) {
  const { campaignId, onSearchQueryChange, onOrderByChange } = props;
  const searchQuery = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).searchQuery
  );
  const orderBy = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).orderBy
  );
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref} className="actions-container">
      <Input
        style={{ width: 170, marginRight: 16, padding: '8px 12px' }}
        placeholder="Search tasks"
        prefix={<SearchSvg />}
        size="large"
        value={searchQuery ?? ''}
        onChange={(e) => {
          onSearchQueryChange(e.target.value);
        }}
      />
      <Dropdown
        getPopupContainer={() => nonNull(ref.current)}
        overlay={
          <Menu
            items={orderByOptions}
            selectedKeys={[orderBy]}
            onClick={({ key }) => {
              onOrderByChange(key as TasksOrderBy);
            }}
          />
        }
        placement="bottomLeft"
        overlayClassName="help_dropdown"
        trigger={['click']}
      >
        <Button
          style={{ marginRight: 20, height: 'auto' }}
          type="text"
          icon={<SortArrowSvg />}
        >
          Sort
        </Button>
      </Dropdown>
    </div>
  );
}
