import React from 'react';
import replaceUrlParam from '@helpers/replaceUrlParam';
import { getFirstAlphanumeric } from '@helpers/stringTransformers';
import { hashedColor } from '@helpers/hashedColor';

interface IImageProps {
  id: string;
  src?: string | null | undefined;
  name: string;
  width: number;
  height: number;
  alt: string;
  types: ('png' | 'jpg' | 'webp')[] | null;
}

function ImageComponent({
  id,
  src,
  width,
  name,
  height,
  alt,
  types
}: IImageProps) {
  const getImageSrcByParameters = ({
    width,
    height,
    type,
    src
  }: {
    width: number;
    height: number;
    type: 'jpg' | 'png' | 'webp';
    src: string;
  }): string => {
    if (isBlob(src)) return src;

    let newUrl = src;

    if (width) {
      newUrl = replaceUrlParam(newUrl, 'width', `${width}`);
    }
    if (height) {
      newUrl = replaceUrlParam(newUrl, 'height', `${height}`);
    }
    if (type) {
      newUrl = replaceUrlParam(newUrl, 'type', `${type}`);
    }

    return newUrl;
  };

  const isBlob = (src: string) => src.includes('blob:');

  if (!src)
    return (
      <span
        style={{
          backgroundColor: hashedColor(id, 'organizationAvatar')
        }}
      >
        {getFirstAlphanumeric(name || '') || name[0]}
      </span>
    );

  return (
    <figure className="image">
      <picture className="image__content">
        {!isBlob(src) &&
          types &&
          types
            .slice(0)
            .reverse()
            .map((type) => (
              <source
                key={`image-source-type-${type}`}
                srcSet={`${getImageSrcByParameters({
                  width,
                  height,
                  type,
                  src
                })} 1x, ${getImageSrcByParameters({
                  width: Math.ceil(width * 1.5),
                  height: Math.ceil(height * 1.5),
                  type,
                  src
                })} 1.5x, ${getImageSrcByParameters({
                  width: width * 2,
                  height: height * 2,
                  type,
                  src
                })} 2x`}
                type={`image/${type}`}
              />
            ))}

        <img
          srcSet={
            !isBlob(src) && types
              ? `${getImageSrcByParameters({
                  width,
                  height,
                  type: types[0] || 'image/jpg',
                  src
                })} 1x, ${getImageSrcByParameters({
                  width: Math.ceil(width * 1.5),
                  height: Math.ceil(height * 1.5),
                  type: types[0] || 'image/jpg',
                  src
                })} 1.5x, ${getImageSrcByParameters({
                  width: width * 2,
                  height: height * 2,
                  type: types[0] || 'image/jpg',
                  src
                })} 2x`
              : undefined
          }
          width={width}
          height={width}
          alt={alt || ''}
          src={
            !isBlob(src) && types
              ? getImageSrcByParameters({
                  width: width * 2,
                  height: height * 2,
                  type: types[0] || 'image/jpg',
                  src
                })
              : src
          }
        />
      </picture>
    </figure>
  );
}

export default ImageComponent;
