import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as CopySvg } from '@assets/icons/copy.svg';
import { ReactComponent as LockSvg } from '@assets/icons/lock.svg';
import { ReactComponent as UnlockSvg } from '@assets/icons/unlock.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

import { Table, Tooltip, Dropdown, Menu, Switch, message, Button } from 'antd';

import { ActionToast } from '@components/Toasts';

import './Table.less';
import { AssetItemDto, AssetReviewItemDto } from '@api/Api';
import removeHttp from '@helpers/removeHttps';
import moment from 'moment';
import {
  toggleCampaignInfoModal,
  toggleShareAssetModal
} from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { apiClient } from '@api/client';
import classNames from 'classnames';

function AssetLinksTable({
  linksData,
  loading,
  onActiveChange,
  onRemoveLink,
  onUpdateSettings
}: {
  linksData: { node: AssetReviewItemDto; cursor: string }[];
  loading: boolean;
  onActiveChange: (v: boolean, id: string) => void;
  onRemoveLink: (id: string) => void;
  onUpdateSettings: ({
    id,
    allowComments,
    allowDownloads,
    allowAllVersions,
    password
  }: {
    id: string;
    allowComments: boolean;
    allowDownloads: boolean;
    allowAllVersions: boolean;
    password: string | null;
  }) => void;
}) {
  const dispatch = useDispatch();
  const [copiedLink, setCopiedLink] = useState<string>();
  const [switchLoading, setSwitchLoading] = useState<string | boolean>(false);
  const removeLink = (id: string) => {
    let action = true;
    toast(
      <ActionToast
        title="Link removed"
        onUndo={() => {
          action = false;
        }}
        description={undefined}
        closeToast={undefined}
      />,
      {
        onClose: () => action && onRemoveLink(id)
      }
    );
  };

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      width: 250,
      render: (text: string, { node }: { node: AssetReviewItemDto }) => {
        const restAssets = node.assets.slice(1);
        const hasMoreAssets = restAssets.length > 0;

        return (
          <div className="asset_info">
            <Tooltip
              placement="bottomLeft"
              title={
                hasMoreAssets ? (
                  <div className="link-asset-list">
                    {restAssets.map((it) => {
                      return (
                        <div key={it.id} className="link-asset-list-item">
                          <div className="link-asset-list-item__preview">
                            <img src={it.previewUrl ?? ''} alt="asset" />
                          </div>
                          <div className="link-asset-list-item__name">
                            <p>
                              {it.name}.{it.extension}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : undefined
              }
              overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
            >
              <div className="asset_info__picture">
                <img src={node.assets[0].previewUrl || ''} alt="" />
                {hasMoreAssets && (
                  <div className="asset_info__picture-more">
                    +{restAssets.length}
                  </div>
                )}
              </div>
            </Tooltip>
            <Link
              to={`/media-viewer/${node.assets[0].id}/${
                node.assets[0].versionId || ''
              }`}
              onClick={() => dispatch(toggleCampaignInfoModal(null))}
              className="asset__full-name"
            >
              <Tooltip
                placement="topLeft"
                overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                title={node.assets[0].name}
              >
                <span className="asset__name">{node.assets[0].name}.</span>
              </Tooltip>
              <span className="asset__ext">{node.assets[0].extension}</span>
            </Link>
          </div>
        );
      }
    },
    {
      title: 'Link',
      dataIndex: 'link',
      width: 212,
      render: (text: string, { node }: { node: AssetReviewItemDto }) => {
        const hasActiveAW = node.assets.some((it) => it.hasActiveAW);

        return (
          <div className="link_name">
            <div
              className={classNames({
                link: true,
                'link--disabled': hasActiveAW,
                'link--copied': copiedLink === node.url
              })}
              onClick={(e) => {
                console.log(e);

                if (hasActiveAW) return;
                message.success({
                  content: 'Copied link to clipboard',
                  className: 'message-dark-modal',
                  icon: <SuccessSvg className="anticon" />
                });
                setCopiedLink(node.url);
                navigator.clipboard.writeText(node.url);
              }}
            >
              <span className="main-body-text">{removeHttp(node.url)}</span>
              <CopySvg />
            </div>
            {hasActiveAW ? (
              <div className="access">
                <LockSvg />
                <span>Unactive</span>
              </div>
            ) : (
              <div className="access">
                <Tooltip
                  placement="bottomLeft"
                  overlayClassName="toggle_favorite_overlay"
                  title={
                    node.password ? (
                      <>Password enabled</>
                    ) : (
                      <>Password disabled</>
                    )
                  }
                >
                  {node.password ? <LockSvg /> : <UnlockSvg />}
                </Tooltip>
                {(node.allowComments || node.allowDownloads) && (
                  <span>
                    Allow: {node.allowComments && 'comments'}
                    {node.allowDownloads && node.allowComments && ', '}
                    {node.allowDownloads && 'downloads'}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Date created',
      dataIndex: 'dateCreated',
      width: 166,
      render: (text: string, { node }: { node: AssetReviewItemDto }) => (
        <>{moment(node.createdAt).format('MMM DD, h:mm a')}</>
      )
    },
    {
      title: 'Viewed',
      dataIndex: 'viewed',
      width: 84,
      render: (text: string, { node }: { node: AssetReviewItemDto }) =>
        node.visitsCounter
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: 115,
      render: (text: string, { node }: { node: AssetReviewItemDto }) => {
        const hasActiveAW = node.assets.some((it) => it.hasActiveAW);
        let copy = node.active ? 'On' : 'Off';
        if (hasActiveAW) copy = 'Off';
        return (
          <Tooltip
            placement="topRight"
            overlayClassName="toggle_favorite_overlay"
            overlayStyle={{ width: '280px', maxWidth: 'unset' }}
            title={
              hasActiveAW && 'Link disabled while media in approval routing'
            }
          >
            <Switch
              loading={switchLoading === node.id}
              onChange={async (v) => {
                if (hasActiveAW) return;
                setSwitchLoading(node.id);
                await onActiveChange(v, node.id);
                setSwitchLoading(false);
              }}
              checked={hasActiveAW ? false : node.active}
              style={{ marginRight: 12 }}
            />{' '}
            {copy}
          </Tooltip>
        );
      }
    },
    {
      title: '',
      key: 'action',
      width: 30,
      render: (text: string, { node }: { node: AssetReviewItemDto }) => {
        const hasActiveAW = node.assets.some((it) => it.hasActiveAW);

        return (
          <Dropdown
            overlay={
              <Menu style={{ padding: '19px 12px' }}>
                <Menu.Item
                  onClick={async () => {
                    const assets: {
                      asset: AssetItemDto;
                      versionId?: string | undefined;
                    }[] = [];
                    await Promise.all(
                      node.assets.map(async (it) => {
                        const {
                          data: { asset }
                        } = await apiClient.asset.assetControllerGetAsset({
                          assetId: it.id
                        });
                        const _asset = {
                          asset,
                          versionId: node.assets.find(
                            (it) => it.id === asset.id
                          )?.versionId
                        };
                        assets.push(_asset);
                      })
                    );
                    dispatch(
                      toggleShareAssetModal({
                        visible: true,
                        assets,
                        action: 'edit',
                        review: node,
                        updFunc: onUpdateSettings
                      })
                    );
                  }}
                >
                  Link settings
                </Menu.Item>
                {!hasActiveAW && (
                  <Menu.Item
                    onClick={() => {
                      message.info('Copied to clipboard');
                      navigator.clipboard.writeText(node.url);
                    }}
                  >
                    Copy link
                  </Menu.Item>
                )}

                <Menu.Item onClick={() => removeLink(node.id)}>
                  Remove
                </Menu.Item>
              </Menu>
            }
            overlayClassName="asset_links_overlay"
            placement="bottomRight"
            trigger={['click']}
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              type="text"
              size="small"
              style={{ padding: 0, width: 24, height: 24 }}
            >
              <ActionSvg style={{ marginRight: 0 }} />
            </Button>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <div className="asset-links-table">
      <Table
        columns={columns}
        loading={loading}
        dataSource={linksData}
        rowKey={({ node }: { node: AssetReviewItemDto }) => node?.id}
      />
    </div>
  );
}

export default AssetLinksTable;
