import React, { memo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import { Popover } from 'antd';
import { priorityMenu } from '@components/Tasks/Priority/priorityConfig';
import Priority from '@components/Tasks/Priority';
import { ReactComponent as PrioritySvg } from '@assets/icons/priority-task-table-icon.svg';
import { ReactComponent as CloseSvg } from '@assets/icons/cross.svg';
import { useUpdateTask } from '@hooks/tasks';

interface PriorityColumnProps {
  taskId: string;
}

export default memo(function PriorityColumn(props: PriorityColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const updateTask = useUpdateTask();
  return (
    <Popover
      placement="left"
      trigger="click"
      content={priorityMenu(task.priority ?? null, (newPriority) =>
        updateTask({ id: task.id, priority: newPriority.priority })
      )}
      overlayClassName="popover-container"
    >
      <div
        className={classNames(
          'task-table-item-container',
          (!!task.deletedAt || task.board?.id === 'archived') &&
            'deleted-task-container'
        )}
      >
        {task.priority && (
          <div className="priority-content-container">
            <Priority priority={task.priority} />
            <CloseSvg
              onClick={(event: any) => {
                event.stopPropagation();
                updateTask({ id: task.id, priority: null });
              }}
            />
          </div>
        )}
        {!task.priority && <PrioritySvg />}
      </div>
    </Popover>
  );
});
