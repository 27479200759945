import React, { memo } from 'react';
import { Skeleton } from 'antd';

export default memo(function WorkspaceItemSkeleton() {
  return (
    <div className="workspace_card_container">
      <div className="card">
        <Skeleton />
      </div>
    </div>
  );
});
