import React, { useState } from 'react';
import DropdownMenuButton from '@components/EntitiesDropdownMenu/DropdownMenuButton';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { Dropdown, Menu, Tooltip } from 'antd';

import './EntitiesDropdownMenu.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useOrganization } from '@components/OrganizationBoundary';
import { useDispatch } from 'react-redux';
import {
  toggleBillingModal,
  toggleWorkspaceCreationModal,
  toggleWorkspaceInvitationModal,
  toggleWorkspaceMembersModal,
  toggleWorkspaceOrganizationDeletionModal,
  toggleWorkspaceOrganizationLeaveModal
} from '@redux/actions/modalAction';
import { WorkspaceItemDto } from '@api/Api';

WorkspaceMenu.defaultProps = {
  setIsDropDownOpen: undefined
};

type WorkspaceMenuProps = {
  workspace: WorkspaceItemDto;
  setIsDropDownOpen?: (v: boolean) => void;
};

export function WorkspaceMenu({
  workspace,
  setIsDropDownOpen
}: WorkspaceMenuProps) {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization();
  const isUsersCountLimitReached =
    currentOrganization.seatsCount.count >=
    (currentOrganization.entity?.limits.orgMembers ?? Number.POSITIVE_INFINITY);

  const items: ItemType[] = [
    {
      key: 'invite_to_workspace',
      label: (
        <Tooltip
          overlayInnerStyle={{ width: 318 }}
          overlayClassName="toggle_favorite_overlay"
          title={
            isUsersCountLimitReached && (
              <>
                You have reached the user limit in your account. <br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    if (!currentOrganization.entity?.permissions.billing)
                      return;
                    dispatch(toggleBillingModal({ visible: true }));
                  }}
                >
                  Upgrade
                </span>{' '}
                to invite more people.
              </>
            )
          }
          placement="right"
        >
          <span style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
            Invite to workspace {isUsersCountLimitReached && <UpgradePlanSvg />}
          </span>
        </Tooltip>
      ),
      disabled:
        !workspace.permissions.inviteContributor ||
        !workspace.permissions.inviteMember ||
        !workspace.permissions.inviteTeamLead,
      onClick: () => {
        if (isUsersCountLimitReached) {
          if (!currentOrganization.entity?.permissions.billing) return;
          dispatch(toggleBillingModal({ visible: true }));
          return;
        }
        dispatch(
          toggleWorkspaceInvitationModal({
            visible: true,
            workspace
          })
        );
      }
    },
    {
      key: 'manage_workspace',
      label: 'Manage workspace',
      disabled: !workspace.permissions.changeDetails,
      onClick: () => {
        dispatch(
          toggleWorkspaceCreationModal({
            visible: true,
            isEditing: true,
            workspace
          })
        );
      }
    },
    {
      key: 'members',
      label: 'Members & Groups',
      disabled: !workspace.permissions.listMembers,
      onClick: () => {
        dispatch(
          toggleWorkspaceMembersModal({
            visible: true,
            workspace
          })
        );
      }
    },
    {
      key: 'leave workspace',
      label: 'Leave workspace',
      disabled: currentOrganization.entity?.permissions.listWorkspaces,
      onClick: () => {
        dispatch(
          toggleWorkspaceOrganizationLeaveModal({
            visible: true,
            entity: workspace,
            entityName: 'workspace'
          })
        );
      }
    },
    {
      key: 'delete_workspace',
      label: 'Delete workspace',
      disabled: !workspace?.permissions.delete,
      onClick: () => {
        dispatch(
          toggleWorkspaceOrganizationDeletionModal({
            entityName: 'workspace',
            visible: true,
            entity: workspace
          })
        );
      }
    }
  ];
  return (
    <Menu
      className="entities-dropdown-menu-overlay"
      items={items}
      onClick={() => {
        if (setIsDropDownOpen) setIsDropDownOpen(false);
      }}
    />
  );
}

function WorkspaceDropDownMenu({ workspace }: { workspace: WorkspaceItemDto }) {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={(value) => setIsDropDownOpen(value)}
      open={isDropDownOpen}
      overlay={
        <WorkspaceMenu
          setIsDropDownOpen={setIsDropDownOpen}
          workspace={workspace}
        />
      }
      overlayClassName="entities-dropdown-menu-overlay"
    >
      <DropdownMenuButton onOpenChange={setIsDropDownOpen} />
    </Dropdown>
  );
}

export default WorkspaceDropDownMenu;
