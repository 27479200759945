import { Button } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as SlackSvg } from '@assets/icons/slack.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import Icon from '@ant-design/icons';
import { useAuth } from '@hooks/useAuth';
import { apiClient } from '@api/client';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

export default function SlackButton() {
  const { user, updateUserProfile } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization();
  const connected = user.slackConnected;
  const needUpgrade =
    !connected && !currentOrganization.entity?.features.slackIntegration;
  const [loading, setLoading] = useState(false);
  const connect = async () => {
    if (loading) return;
    setLoading(true);
    try {
      // Axios always follows redirect
      const {
        data: { url }
      } = await apiClient.slack.slackControllerInstallSlack(
        {
          state: JSON.stringify({ redirectUrl: window.location.href })
        },
        {
          withCredentials: true
        }
      );
      window.location.href = url;
    } finally {
      setLoading(false);
    }
  };
  const disconnect = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await apiClient.slack.slackControllerUninstallSlack();
      updateUserProfile({ slackConnected: false });
    } finally {
      setLoading(false);
    }
  };
  if (connected) {
    return (
      <Button
        type="dashed"
        danger
        size="large"
        loading={loading}
        icon={<Icon component={SlackSvg} />}
        onClick={disconnect}
      >
        Uninstall Slack
      </Button>
    );
  }
  return (
    <UpgradePlanTooltip
      text=" to a Team plan to access this feature."
      isCanVisible={needUpgrade}
    >
      <Button
        loading={loading}
        type="default"
        size="large"
        icon={<Icon component={needUpgrade ? UpgradePlanSvg : SlackSvg} />}
        onClick={connect}
        disabled={needUpgrade}
      >
        Connect Slack
      </Button>
    </UpgradePlanTooltip>
  );
}
