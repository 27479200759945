import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { Button, Avatar, Dropdown } from 'antd';
import React, { memo, useMemo } from 'react';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';
import { WorkspaceItemDto, WorkspaceMemberGroupListItemDto } from '@api/Api';
import { hashedColor } from '@helpers/hashedColor';
import './WorkspaceMemberGroupItem.less';
import {
  toggleWorkspaceMemberGroupInviteModal,
  toggleWorkspaceMemberGroupModal
} from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

type WorkspaceMemberGroupItemProps = {
  group: WorkspaceMemberGroupListItemDto;
  workspace: WorkspaceItemDto;
  onDelete: () => void;
};

export default memo(function WorkspaceMemberGroupItem({
  group,
  workspace,
  onDelete
}: WorkspaceMemberGroupItemProps) {
  const users = useMemo(() => group.topMembers, [group.topMembers]);
  const dispatch = useDispatch();

  return (
    <div className="workspace_member_group_item">
      <div className="card">
        <div className="row">
          <div className="workspace_member_group_item__info">
            <div className="workspace_member_group_item__image">
              <Avatar
                size={40}
                shape="square"
                style={{
                  background: hashedColor(group.id, 'memberGroupAvatar')
                }}
              >
                {group.name[0].toUpperCase()}
              </Avatar>
            </div>
            <div className="workspace_member_group_item__copy">
              <div className="name">{group.name}</div>
              <div className="users_count">
                {group.membersCount}{' '}
                {group.membersCount === 1 ? 'user' : 'users'}{' '}
              </div>
            </div>
          </div>
          <div className="workspace_member_group_item_action">
            {workspace.permissions.manageMemberGroups && (
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 'invite',
                      label: 'Invite to group',
                      onClick: () => {
                        dispatch(
                          toggleWorkspaceMemberGroupInviteModal({
                            visible: true,
                            workspace,
                            group
                          })
                        );
                      }
                    },
                    {
                      key: 'edit',
                      label: 'Edit group',
                      onClick: () => {
                        dispatch(
                          toggleWorkspaceMemberGroupModal({
                            visible: true,
                            workspace,
                            group
                          })
                        );
                      }
                    },
                    {
                      key: 'delete',
                      label: 'Delete group',
                      onClick: () => onDelete()
                    }
                  ]
                }}
                overlayClassName="entities-dropdown-menu-overlay"
              >
                <div className="dropdown-menu-button-container">
                  <Button type="text">
                    <ActionSvg style={{ marginLeft: 0, marginRight: 0 }} />
                  </Button>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="row">
          <Button
            type="text"
            className="members"
            onClick={() => {
              dispatch(
                toggleWorkspaceMemberGroupModal({
                  visible: true,
                  workspace,
                  group
                })
              );
            }}
            style={{
              visibility: group.topMembers.length ? 'visible' : 'hidden'
            }}
          >
            <AvatarsGroup
              maxCount={5}
              totalCount={group.membersCount}
              avatarSize={40}
              users={users}
              membersType="member"
            />
          </Button>
          {!group.topMembers.length && <span />}
          {workspace.permissions.manageMemberGroups && (
            <Button
              onClick={() => {
                dispatch(
                  toggleWorkspaceMemberGroupInviteModal({
                    visible: true,
                    workspace,
                    group
                  })
                );
              }}
              type="link"
            >
              <PlusSvg /> Invite member
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});
