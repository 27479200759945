import React, { useState } from 'react';
import { Form, Button } from 'antd';

import { ReactComponent as SelectSvg } from '@assets/icons/select-menu-item.svg';

import './ProfileForm.less';

interface ProfileFormProps {
  children: React.ReactChild;
  initialValues?: object;
  onSubmit(data: any): void;
}

let successTime: ReturnType<typeof setTimeout>;

function ProfileForm({
  children,
  initialValues,
  onSubmit,
  ...props
}: ProfileFormProps): React.ReactElement {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [form] = Form.useForm();

  const showSuccessText = (): void => {
    setIsSuccess(true);
    successTime = setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
  };

  const onSubmitForm = async (data: any): Promise<void> => {
    setIsSuccess(false);
    setIsSubmit(true);
    clearInterval(successTime);
    try {
      await onSubmit(data);
      setIsSubmit(false);
      showSuccessText();
    } catch (error: any) {
      setIsSubmit(false);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onFinish={onSubmitForm}
      layout="vertical"
      className="profile-form"
      {...props}
    >
      {children}
      <Form.Item className="submit_form_bottom">
        <Button
          type="primary"
          htmlType="submit"
          className="submit_form submit_form_btn"
          size="middle"
          icon={isSuccess && <SelectSvg className="b-icon-select" />}
          loading={isSubmit}
        >
          {isSuccess ? 'Saved' : 'Save changes'}
        </Button>
      </Form.Item>
    </Form>
  );
}

ProfileForm.defaultProps = {
  initialValues: {}
};

export default ProfileForm;
