import React from 'react';

import { Tag } from 'antd';

const basicPlanTagStyle = {
  background: 'rgba(105, 195, 141, 0.1)',
  color: '#69C38D',
  padding: '1px 4px 3px',
  border: 'none'
};

function PlanTag({ planName }: { planName: string }) {
  return <Tag style={basicPlanTagStyle}>{planName}</Tag>;
}

export default PlanTag;
