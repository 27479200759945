import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import { useTypedSelector } from '@hooks';
import HeadSortPanel from '@components/Tasks/HeadSortPanel';
import TaskGroup from '@pages/Tasks/TaskGroup';
import './Tasks.less';
import { MemberDto, TaskItemCampaignDto } from '@api/Api';
import {
  tasksListSelector,
  workspaceStateSelector,
  tasksGroupsSelector
} from '@redux/selectors/tasks';
import {
  fetchCampaignGroups,
  fetchTaskBoardGroups,
  fetchTaskList,
  fetchTasksCount
} from '@redux/actions/tasks';
import {
  TaskListsSharedState,
  TaskListType,
  TasksGroupBy,
  TasksOrderBy
} from '@redux/types/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useFetch } from '@hooks/useFetch';
import { debounce } from '@helpers/debounce';
import {
  loadMoreTasks,
  openNewTaskModal,
  setGroupBy,
  setListParams,
  setListNeedRefresh,
  setScrollTop
} from '@redux/reducers/tasks';
import TasksListTable from '@pages/Tasks/TasksListTable';
import classNames from 'classnames';

function TasksInWorkspace(props: { page: TaskListType }) {
  const { page } = props;
  const dispatch = useTypedDispatch();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const { organizationId } = currentWorkspace;
  const workspaceId = currentWorkspace.id;
  const groupBy = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.groupBy
  );
  const canRestoreScroll = useTypedSelector(
    (state) =>
      !groupBy ||
      tasksGroupsSelector(state, { workspaceId, list: page, groupBy }).fetch
        .initiallyLoaded
  );
  const scrollTop = useTypedSelector(
    (state) => tasksListSelector(state, { workspaceId, list: page }).scrollTop
  );
  const scrollTopRef = useRef(scrollTop);
  scrollTopRef.current = scrollTop;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!scrollContainerRef.current) return;
    if (!canRestoreScroll) return;
    scrollContainerRef.current.scrollTop = scrollTopRef.current;
  }, [workspaceId, page, canRestoreScroll]);

  useEffect(() => {
    dispatch(setListNeedRefresh({ workspaceId, list: page }));
  }, [workspaceId, page, dispatch]);

  useFetch({
    key: `tasks-list-count-${workspaceId}-${page}`,
    disabled: !!groupBy,
    selector: (state) =>
      tasksListSelector(state, { workspaceId, list: page }).countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTasksCount({
          workspaceId,
          list: page,
          fetchType
        })
      )
  });
  const loading = useFetch({
    key: `tasks-list-${workspaceId}-${page}`,
    disabled: !!groupBy,
    selector: (state) =>
      tasksListSelector(state, { workspaceId, list: page }).fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchTaskList({
          workspaceId,
          list: page,
          fetchType
        })
      );
    }
  });
  useFetch({
    key: `tasks-list-campaign-groups-${workspaceId}-${page}`,
    disabled: groupBy !== 'campaign',
    selector: (state) =>
      tasksListSelector(state, { workspaceId, list: page }).groups.campaign
        .fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchCampaignGroups({
          workspaceId,
          list: page,
          fetchType
        })
      );
    }
  });
  useFetch({
    key: `tasks-list-board-groups-${workspaceId}-${page}`,
    disabled: groupBy !== 'board',
    selector: (state) =>
      tasksListSelector(state, { workspaceId, list: page }).groups.board.fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchTaskBoardGroups({
          workspaceId,
          list: page,
          fetchType
        })
      );
    }
  });

  const workspaceIdRef = useRef(workspaceId);
  workspaceIdRef.current = workspaceId;

  const pageRef = useRef(page);
  pageRef.current = page;

  const groupByRef = useRef(groupBy);
  groupByRef.current = groupBy;

  const onOrderByChange = useCallback(
    (value: TasksOrderBy) => {
      dispatch(
        setListParams({
          orderBy: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onGroupByChange = useCallback(
    (value: TasksGroupBy | null) => {
      dispatch(setGroupBy({ value, workspaceId: workspaceIdRef.current }));
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onFilterSearchQueryChange = useCallback(
    (value: string) => {
      dispatch(
        setListParams({
          searchQuery: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onFilterCampaignsChange = useCallback(
    (value: TaskItemCampaignDto[]) => {
      dispatch(
        setListParams({
          campaigns: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onFilterStatusesChange = useCallback(
    (value: TaskListsSharedState['statuses']) => {
      dispatch(
        setListParams({
          statuses: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onFilterDueDateChange = useCallback(
    (value: [string, string] | null) => {
      dispatch(
        setListParams({
          dueDate: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );

  const onFilterAssigneesChange = useCallback(
    (value: MemberDto[]) => {
      dispatch(
        setListParams({
          assignees: value,
          workspaceId: workspaceIdRef.current
        })
      );
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },
    [dispatch]
  );

  const onLoadMore = useCallback(() => {
    dispatch(
      loadMoreTasks({
        workspaceId: workspaceIdRef.current,
        list: pageRef.current
      })
    );
  }, [dispatch]);

  const onCreateTaskClick = useCallback(
    () =>
      dispatch(
        openNewTaskModal({
          organizationId,
          workspaceId
        })
      ),
    [dispatch, organizationId, workspaceId]
  );

  const onScroll = useMemo(
    () =>
      debounce((e: React.SyntheticEvent<HTMLDivElement>) => {
        const { scrollTop } = e.target as HTMLDivElement;
        scrollTopRef.current = scrollTop;
        dispatch(
          setScrollTop({
            value: scrollTop,
            workspaceId: workspaceIdRef.current,
            list: pageRef.current
          })
        );
      }, 500),
    [dispatch]
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeadSortPanel
        page={page}
        onSearchQueryChange={onFilterSearchQueryChange}
        onCampaignsChange={onFilterCampaignsChange}
        onStatusesChange={onFilterStatusesChange}
        onDueDateChange={onFilterDueDateChange}
        onAssigneesChange={onFilterAssigneesChange}
        onOrderByChange={onOrderByChange}
        onGroupByChange={onGroupByChange}
        onCreateTaskClick={onCreateTaskClick}
      />
      <div
        ref={scrollContainerRef}
        onScroll={onScroll}
        className={classNames({
          'task-table-container': true,
          'collapse-container': groupBy === 'board'
        })}
      >
        {!groupBy && (
          <TasksListTable
            key={page}
            page={page}
            loading={loading}
            onLoadMore={onLoadMore}
          />
        )}
        {groupBy && <TaskGroup key={page} page={page} groupBy={groupBy} />}
      </div>
    </div>
  );
}

function TasksList(props: { page: TaskListType }) {
  const { workspaceId: workspaceIdParam } = useParams();
  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const workspaceId = currentWorkspace?.id;
  const [allow404, setAllow404] = useState(!!workspaceId);
  useEffect(() => {
    if (!workspaceIdParam) return;
    if (workspaceIdParam === workspaceId) return;
    if (!defaultWorkspace) return;
    setCurrentWorkspace(workspaceIdParam).finally(() => setAllow404(true));
  }, [workspaceIdParam, workspaceId, defaultWorkspace, setCurrentWorkspace]);

  if (!defaultWorkspace) {
    return <Navigate to="/" replace />;
  }
  if (!workspaceIdParam) {
    const redirect = `/tasks/${props.page}/${defaultWorkspace.id}`;
    return <Navigate to={redirect} replace />;
  }
  if (!workspaceId) {
    if (!allow404) return null;
    return <Navigate to="/error-404" replace />;
  }
  return <TasksInWorkspace {...props} />;
}

export default TasksList;
