import { ReactComponent as EditSvg } from '@assets/icons/Edit.svg';
import {
  ViewLinksFieldProps,
  ViewStandardFieldProps
} from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/types';
import { CAMPAIGN_DETAILS_FIELDS } from '@helpers/campaignDetails';
import linkParser from '@helpers/linkParser';
import { useTypedSelector } from '@hooks';
import { Avatar, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

export function StandardViewField(props: ViewStandardFieldProps) {
  const { setScrollToNodeSelector, value, label, item, setIsEditMode } = props;
  return (
    <div className="campaign_details_custom_field">
      <div className="field_title">{label}</div>
      <button
        type="button"
        onClick={() => {
          if (!props.canEdit) return;
          setScrollToNodeSelector(`[data-field="${item}"]`);
          setIsEditMode(true);
        }}
        style={props.canEdit ? {} : { pointerEvents: 'none' }}
        className="field_description"
      >
        <div
          className={classNames(
            'field_content',
            'standard_field_content',
            value && 'field_content_not_empty'
          )}
        >
          {value || <span>Add {label.toLocaleLowerCase()}</span>}
        </div>

        <span className="edit-icon">
          <EditSvg />
        </span>
      </button>
    </div>
  );
}

export function LinksViewField(props: ViewLinksFieldProps) {
  const formatLink = (value: string) => {
    const link = document.createElement('a');
    link.href = value;

    const { hostname } = link;
    const hostNameArr = hostname.split('.');
    const tld = hostNameArr.pop();

    return (
      <>
        <span className="link__name">{`${hostNameArr.join('.')}.`}</span>
        <span className="link__tld">{tld}</span>
      </>
    );
  };
  return (
    <div className="campaign_details_custom_field">
      <div className="field_title">{props.label}</div>
      <button
        onClick={() => {
          if (!props.canEdit) return;
          props.setIsEditMode(true);
          props.setScrollToNodeSelector(`[data-field="${props.item}"]`);
          props.setNeedOpenLinksModal(true);
        }}
        type="button"
        style={props.canEdit ? {} : { pointerEvents: 'none' }}
        className="field_description field_description--links"
      >
        <div className="field_content">
          {(props.value || []).map((item, idx) => {
            const it = JSON.parse(item as any);

            return (
              <Tag
                key={idx}
                className="link"
                onClick={(e) => e.stopPropagation()}
              >
                <a target="_blank" href={linkParser(it.url)} rel="noreferrer">
                  {it.name || formatLink(it.url)}
                </a>
              </Tag>
            );
          })}
        </div>
        <span className="edit-icon" style={{ marginTop: 4 }}>
          <EditSvg />
        </span>
      </button>
    </div>
  );
}

export function ObjectivesViewField(props: any) {
  return (
    <div className="campaign_details_custom_field campaign_details_custom_field--objective">
      <div className="field_title">{props.label}</div>
      <button
        type="button"
        onClick={() => {
          if (!props.canEdit) return;
          props.setScrollToNodeSelector(`[data-field="${props.item}"]`);
          props.setIsEditMode(true);
        }}
        className="field_description field_description--objectives"
        style={props.canEdit ? {} : { pointerEvents: 'none' }}
      >
        <div
          className={classNames(
            'field_content',
            props.value && props.value.length && 'field_content_not_empty'
          )}
        >
          {props.value && props.value.length > 0 ? (
            <>
              {props.value.map((item: any, idx: any) => {
                return (
                  <span className="selected_item selected_item-view" key={idx}>
                    <span className="name main-body-text">{item}</span>
                  </span>
                );
              })}
            </>
          ) : (
            <span>Add {props.label.toLocaleLowerCase()}</span>
          )}
        </div>
        <span className="edit-icon">
          <EditSvg />
        </span>
      </button>
    </div>
  );
}

export function AgencyViewField(props: any) {
  let agencies = props.obj;
  if (agencies) {
    agencies = agencies.map((el: any) => {
      return el && el.length && JSON.parse(el);
    });
  }
  return (
    <div
      className="campaign_details_custom_field"
      style={{ display: 'inline-flex', flexDirection: 'column' }}
    >
      <div className="field_title">{props.label}</div>
      {agencies &&
        agencies.map((obj: any, idx: any) => (
          <div
            key={idx}
            className="field_description field_description--agency"
            onClick={() => {
              if (!props.canEdit) return;
              props.setNeedOpenAgencyModal({ ...obj, idx });
              props.setScrollToNodeSelector(`[data-field="${props.item}"]`);
              props.setIsEditMode(true);
            }}
            style={props.canEdit ? {} : { pointerEvents: 'none' }}
          >
            <div className="field_content">
              <div className="agency_field agency_field--display">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <strong className="agency_name">
                    <span className="main-body-text main-body-text--semibold">
                      {obj.name}
                    </span>
                  </strong>
                  {obj.contacts?.length === 0 ? (
                    <strong className="agency_no-contact">
                      <span className="main-body-text main-body-text--semibold main-body-text--dark-gray">
                        No agency contacts
                      </span>
                    </strong>
                  ) : (
                    <>
                      {obj.contacts?.map((el: any, idx: number) => (
                        <div key={idx} className="agency_tag">
                          <Tooltip
                            placement="topLeft"
                            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                            title={el.email}
                          >
                            <Avatar className="agency_tag__avatar" size={24}>
                              {el.name[0]}
                            </Avatar>
                            <span
                              style={{ color: '#161819' }}
                              className="agency_tag__name main-body-text"
                            >
                              {el.name} {obj.contacts.length - 1 !== idx && ';'}
                            </span>
                          </Tooltip>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <span className="edit-icon">
              <EditSvg />
            </span>
          </div>
        ))}
    </div>
  );
}

function View(props: any) {
  const [fields, setFields] = useState<any>(null);
  const modalState = useTypedSelector(({ modal }) => modal.campaignInfoModal);
  useEffect(() => {
    if (!props.initialValues) return;
    setFields(props.initialValues);
  }, [props.initialValues]);
  if (!fields) return null;

  return (
    <>
      {Object.keys(fields).map((key: any) => (
        <>
          {key === 'agencies' && (
            <AgencyViewField
              key={key}
              item={key}
              setIsEditMode={props.setIsEditMode}
              setScrollToNodeSelector={props.setScrollToNodeSelector}
              canEdit={modalState?.campaign?.permissions.changeDetails}
              setNeedOpenAgencyModal={props.setNeedOpenAgencyModal}
              label={
                CAMPAIGN_DETAILS_FIELDS.find((el) => el.key === key)?.label
              }
              obj={fields[key]}
            />
          )}
          {key === 'objectives' && (
            <ObjectivesViewField
              key={key}
              label={
                CAMPAIGN_DETAILS_FIELDS.find((el) => el.key === key)?.label
              }
              canEdit={modalState?.campaign?.permissions.changeDetails}
              item={key}
              setIsEditMode={props.setIsEditMode}
              setScrollToNodeSelector={props.setScrollToNodeSelector}
              value={fields[key]}
            />
          )}
          {key === 'links' && (
            <LinksViewField
              setIsEditMode={props.setIsEditMode}
              key={key}
              item={key}
              setNeedOpenLinksModal={props.setNeedOpenLinksModal}
              setScrollToNodeSelector={props.setScrollToNodeSelector}
              label={
                CAMPAIGN_DETAILS_FIELDS.find((el) => el.key === key)
                  ?.label as string
              }
              canEdit={modalState?.campaign?.permissions.changeDetails}
              value={fields[key]}
            />
          )}
          {key !== 'agencies' && key !== 'objectives' && key !== 'links' && (
            <StandardViewField
              key={key}
              value={fields[key]}
              item={key}
              label={
                CAMPAIGN_DETAILS_FIELDS.find((el) => el.key === key)
                  ?.label as string
              }
              canEdit={modalState?.campaign?.permissions.changeDetails}
              setIsEditMode={props.setIsEditMode}
              setScrollToNodeSelector={props.setScrollToNodeSelector}
            />
          )}
        </>
      ))}
    </>
  );
}

export default View;
