import AssetsList from '@pages/Assets/AssetsList';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

function Assets() {
  return (
    <Routes>
      <Route path="all">
        <Route index element={<AssetsList page="all" />} />
        <Route path=":workspaceId" element={<AssetsList page="all" />} />
      </Route>
      <Route path="my">
        <Route index element={<AssetsList page="my" />} />
        <Route path=":workspaceId" element={<AssetsList page="my" />} />
      </Route>
      <Route path="favorite">
        <Route index element={<AssetsList page="favorite" />} />
        <Route path=":workspaceId" element={<AssetsList page="favorite" />} />
      </Route>
      <Route path="deleted">
        <Route index element={<AssetsList page="deleted" />} />
        <Route path=":workspaceId" element={<AssetsList page="deleted" />} />
      </Route>
      <Route index element={<Navigate to="all" replace />} />
    </Routes>
  );
}

export default Assets;
