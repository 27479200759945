import moment from 'moment';

moment.locale('en-timeline-time', {
  parentLocale: 'en',
  relativeTime: {
    past: '%s',
    s: 'just now',
    ss: 'just now',
    m: '%d min ago',
    mm: '%d mins ago',
    h: '%d hr ago',
    hh: '%d hrs ago',
    d: '%d day ago',
    dd: '%d days ago',
    M: '%d mth ago',
    MM: '%d mths ago'
  }
});

export default function timeLineFromNow(time: string) {
  return moment(time).locale('en-timeline-time').fromNow();
}

export enum PAYLOAD_TYPES {
  CAMPAIGN_CREATED = 'campaign-created',
  CAMPAIGN_VISIBILITY_CHANGED = 'campaign-visibility-changed',
  CAMPAIGN_DETAILS_UPDATED = 'campaign-updated',
  TASK_CREATED = 'task-created',
  TASK_COMPLETED = 'task-completed',
  ASSET_UPLOADED = 'asset-uploaded',
  ASSET_VERSION_UPLOADED = 'asset-version-uploaded',
  ASSET_UPLOADED_MULTIPLE = 'asset-uploaded-multiple',
  ASSET_MADE_FINAL_DECISION = 'asset-made-final-decision',
  ASSET_UPLOADED_NEW_VERSION = 'asset-uploaded-new-version',
  APPROVAL_WORKFLOW_CREATED = 'aw-created',
  APPROVAL_WORKFLOW_UPDATED = 'aw-updated'
}

export const pluralize = (count: number, name: string) => {
  if (count === 1) {
    return name;
  }
  return `${name}s`;
};
