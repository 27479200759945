import React, { useCallback } from 'react';
import { Button, Collapse, Dropdown, Menu, Modal, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';

import { toggleNewAssetWithWorkflowModal } from '@redux/actions/modalAction';
import AidasImg from '@assets/images/adidas.png';
import './NewAssetWithWorkflowModal.less';
import '@components/Modals/ApprovalModal/components/ApprovalStages/ApprovalStages.less';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

function NewAssetWithWorkflowModal() {
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.newAssetWithWorkflow
  );

  const panelHeaderApprove = ({
    isVisibleTimePicker,
    name
  }: {
    isVisibleTimePicker: boolean;
    name: string;
  }) => (
    <div className="approval-stage-panel-header" style={{ padding: 12 }}>
      <div className="panel-content" style={{ marginLeft: 0 }}>
        <div className="panel-text-container">
          <img src={AidasImg} alt="adidas" className="panel-image" />
          <div className="name-container" style={{ marginLeft: 16 }}>
            <span>{name}</span>
          </div>
        </div>
        <div className="panel-settings-container" style={{ marginRight: 35 }}>
          <Tag
            color="#BFC5F4"
            className="tag-panel-container"
            style={{ marginRight: 0 }}
          >
            Submitted Decision
          </Tag>
          <div className="settings-line" style={{ margin: '0 16px' }} />
          {isVisibleTimePicker && (
            <>
              <div>
                <span>Jul 22, 2021</span>
              </div>
              <div className="settings-line" style={{ margin: '0 16px' }} />
            </>
          )}
          <span>1 of 3</span>
          <Dropdown
            overlay={
              <Menu style={{ padding: '20px', width: 200 }}>
                <Menu.Item>Delete stage</Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <ActionSvg
              className="panel-action"
              onClick={(event: any) => {
                event.stopPropagation();
              }}
              style={{ marginLeft: 24 }}
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );

  const onCloseModal = useCallback(() => {
    dispatch(
      toggleNewAssetWithWorkflowModal({
        visible: false,
        isSubmittedWorkflow: false
      })
    );
  }, [toggleNewAssetWithWorkflowModal]);

  return (
    <Modal
      visible={!!modalState?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      width={768}
      onCancel={onCloseModal}
      className="approval-modal"
      style={{ maxHeight: 532 }}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{
            justifyContent: 'center',
            fontSize: 22,
            marginBottom: '0.5em'
          }}
        >
          Upload new media
        </h2>
        <p
          className="modal_container__subtitle"
          style={{ textAlign: 'center', marginBottom: 0 }}
        >
          <span style={{ color: 'black' }}>
            Upload videos, images, PDFs, audio files and
          </span>{' '}
          <span
            style={{
              color: '#3553DB',
              background: '#F5F6F5',
              padding: '2px 4px',
              borderRadius: '4px'
            }}
          >
            other formats
          </span>
          <span style={{ color: 'black' }}>{' .'}</span>
        </p>
        <div className="image-container">
          <div className="image-content">
            <img src={AidasImg} alt="assetImg" />
            <span className="image-name">Coca-cola_promo.mp4</span>
            <span className="version-text">V3</span>
          </div>
          <span>172.7 KB</span>
        </div>
        <div className="workflow-container">
          <span>
            The current approval routing stage will restart from the beginning.
          </span>
          <div className="approval-stage-collapse-container">
            <Collapse
              expandIconPosition="end"
              className="wrapper-collapse-container"
            >
              <Collapse.Panel
                header={
                  modalState?.isSubmittedWorkflow &&
                  panelHeaderApprove({
                    name: 'Coca-cola_promo.mp4',
                    isVisibleTimePicker: true
                  })
                  // : panelHeader({
                  //     isVisibleTimePicker: true,
                  //     userCount: 8,
                  //     key: 2,
                  //     name: 'Copywriting check',
                  //     borderColor: '#9FC1F4',
                  //     isProgressStage: false
                  //   })
                }
                collapsible="disabled"
                key="2"
              />
            </Collapse>
          </div>
          <div className="new-asset-workflow-button-container">
            <span onClick={onCloseModal}>Cancel</span>
            <Button type="primary" onClick={onCloseModal}>
              Upload version
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewAssetWithWorkflowModal;
