import React, { memo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { setCurrentCampaign } from '@redux/actions/campaignAction';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { ReactComponent as TaskItemNotCheckedSvg } from '@assets/icons/task-table-item-circle.svg';
import { ReactComponent as TaskItemCheckedSvg } from '@assets/icons/task-table-item-checked-circle.svg';
import { ReactComponent as CommentSvg } from '@assets/icons/comment-icon.svg';
import { ReactComponent as TaskCommentIconSvg } from '@assets/icons/task-from-comment-icon.svg';
import { ReactComponent as AnnotationIconSvg } from '@assets/icons/task-annotation-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateTask } from '@hooks/tasks';
import { useDispatch } from 'react-redux';
import { openTaskModal } from '@redux/reducers/tasks';

interface NameColumnProps {
  taskId: string;
}

export default memo(function NameColumn(props: NameColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const dispatch = useDispatch();
  const updateTask = useUpdateTask();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className={classNames(
        'tasks-text-container',
        !!task.deletedAt && 'deleted-tasks-name-container'
      )}
      onClick={() => {
        dispatch(openTaskModal({ taskId }));
      }}
    >
      {(!!task.deletedAt || task.board.id === 'archived') && (
        <div
          style={{
            width: 4,
            height: 40,
            backgroundColor: `${task.deletedAt ? '#F75248' : '#DBDBDB'}`,
            borderRadius: 2,
            marginRight: 16
          }}
        />
      )}
      {!task.deletedAt &&
        task.board.id !== 'archived' &&
        (task.status !== 'done' ? (
          <TaskItemNotCheckedSvg
            onClick={(event: any) => {
              event.stopPropagation();
              updateTask({ id: task.id, status: 'done' });
            }}
          />
        ) : (
          <TaskItemCheckedSvg
            onClick={(event: any) => {
              event.stopPropagation();
              updateTask({ id: task.id, status: 'todo' });
            }}
          />
        ))}
      <div>
        <div className="task-breadcrumbs-container" style={{ maxWidth: 170 }}>
          <Tooltip
            overlayClassName="long-text-hint"
            title={task.campaign.name}
            trigger="hover"
            placement="top"
          >
            <span
              style={{ marginBottom: 0 }}
              className="task-breadcrumbs"
              onClick={(event) => {
                event.stopPropagation();
                if (task.deletedAt) return;
                dispatch(setCurrentCampaign(null));
                navigate(`/campaigns/single/${task.campaign.id}`);
              }}
            >
              {task.campaign?.name}
            </span>
          </Tooltip>
          <Tooltip
            overlayClassName="long-text-hint"
            title={task.asset?.path[0]?.name}
            trigger="hover"
            placement="top"
          >
            <span
              style={{ marginBottom: 0 }}
              className="task-breadcrumbs"
              onClick={(event) => {
                event.stopPropagation();
                if (task.deletedAt) return;
                dispatch(setCurrentCampaign(null));
                navigate(
                  `/campaign/${task.campaign.id}/${task.asset?.path[0].id}`
                );
              }}
            >
              {task.asset?.path[0] ? `/${task.asset?.path[0]?.name || ''}` : ''}
            </span>
          </Tooltip>
          <Tooltip
            overlayClassName="long-text-hint"
            title={`${task.asset?.lastVersion?.name}.${task.asset?.lastVersion?.extension}`}
            trigger="hover"
            placement="top"
          >
            <span
              style={{ marginBottom: 0 }}
              className={`task-breadcrumbs ${
                task.asset?.isDeleted ? 'b-line-through' : ''
              }`}
              onClick={(event) => {
                event.stopPropagation();
                if (task.deletedAt || task.asset?.isDeleted) return;
                dispatch(setCurrentCampaign(null));
                navigate(
                  `/media-viewer/${task.asset?.id}/${task.asset?.selectedVersion?.id}`,
                  {
                    state: {
                      returnTo: location.pathname + location.search
                    }
                  }
                );
              }}
            >
              {task.asset?.selectedVersion ? (
                <>
                  /
                  <span>
                    {task.asset?.selectedVersion?.name}.
                    {task.asset?.selectedVersion?.extension}
                  </span>
                </>
              ) : (
                ''
              )}
            </span>
          </Tooltip>
        </div>
        <div className="task-name-container">
          {task.assetVersionComment &&
            task.assetVersionComment?.hasAnnotations === true && (
              <Tooltip
                overlayClassName="task-group-settings-hint"
                title="This task was created from a comment"
                trigger="hover"
                placement="top"
              >
                <AnnotationIconSvg
                  style={{
                    marginRight: 6,
                    height: 20,
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            )}
          {task.assetVersionComment &&
            task.assetVersionComment?.hasAnnotations === false && (
              <Tooltip
                overlayClassName="task-group-settings-hint"
                title="This task was created from a comment"
                trigger="hover"
                placement="top"
              >
                <TaskCommentIconSvg
                  style={{
                    marginRight: 6,
                    height: 20,
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            )}
          <Tooltip
            overlayClassName="long-text-hint"
            overlayInnerStyle={{ maxWidth: 350 }}
            title={task.name}
            trigger="hover"
            placement="top"
          >
            <span
              className={classNames(
                task.status === 'done' && 'selected-tasks-name-text'
              )}
            >
              {task.name}
            </span>
          </Tooltip>
          {task.commentsCount !== 0 && !task.deletedAt && (
            <div className="task-comment-container">
              <CommentSvg />
              <span>{task.commentsCount.toString()}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
