import { AssetForApprovalDto } from '@api/Api';
import moment from 'moment';
import React, { memo, useMemo } from 'react';

type CreatedCellProps = {
  node: AssetForApprovalDto;
};

export default memo(function CreatedCell({ node }: CreatedCellProps) {
  const createdAt = useMemo(() => {
    return node.asset.versions[0].approvalWorkflow?.createdAt;
  }, [node.asset.versions]);
  return (
    <span className="time-state">
      {moment(createdAt).format('MMM DD, YYYY')}
    </span>
  );
});
