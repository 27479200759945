import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ReactComponent as PrivateEye } from '@assets/icons/eye-private.svg';
import { ReactComponent as Move } from '@assets/icons/bulkActions/move.svg';
import { ReactComponent as Delete } from '@assets/icons/bulkActions/delete.svg';
import { ReactComponent as Download } from '@assets/icons/bulkActions/download.svg';
import { ReactComponent as Share } from '@assets/icons/share.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { SimpleFolderItemDto } from '@api/Api';
import { assetGetSimpleFoldersList } from '@api/Asset';
import './BulkActions.less';
import { useDispatch } from 'react-redux';
import {
  deleteAssetAction,
  moveAssetToFolder,
  restoreDeletedAssetAction
} from '@redux/actions/assetsAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import {
  toggleMoveAssetToPrivateFolder,
  toggleShareAssetModal
} from '@redux/actions/modalAction';
import { updateCampaignCounters } from '@redux/actions/campaignAction';
import { downloadAll } from '@helpers/download';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

function BulkActions({
  selectedAssets,
  onClose,
  page
}: {
  selectedAssets: CheckboxValueType[] | string[] | any;
  onClose: () => void;
  page: string;
}) {
  const dispatch = useDispatch();
  const [isMovePopoverVisible, setIsMovePopoverVisible] =
    useState<boolean>(false);
  const [foldersList, setFoldersList] = useState<SimpleFolderItemDto[]>([]);
  const [canBulkMove, setCanBulkMove] = useState<boolean>(false);
  const [canBulkShare, setCanBulkShare] = useState<boolean>(false);
  const [canBulkDownload, setCanBulkDownload] = useState<boolean>(false);
  const [canBulkDelete, setCanBulkDelete] = useState<boolean>(false);

  const { length } = selectedAssets;

  const { currentOrganization } = useOrganization();
  const upgradeShare =
    length > 1 && !currentOrganization.entity?.features.shareMultipleAssets;

  useEffect(() => {
    if (!selectedAssets.length) return;
    let canMove = selectedAssets
      .map(({ node }: any) => node.campaignId)
      .every((it: any, idx: any, array: any) => it === array[0]);

    const canCurrSelectedDownload = selectedAssets.every(
      (asset: any) => asset?.node?.asset?.versions[0].permissions.download
    );

    const canCurrSelectedDelete = selectedAssets.every(
      (asset: any) => asset?.node?.asset?.permissions.delete
    );

    const canShareWithinCampaign = selectedAssets
      .map(({ node }: any) => node.campaignId)
      .every((it: any, idx: any, array: any) => it === array[0]);

    const canShareWithinPermissions = selectedAssets
      .map(({ node }: any) => node.asset.permissions)
      .every((it: any) => it.createReviews);

    setCanBulkDownload(canCurrSelectedDownload);
    setCanBulkDelete(canCurrSelectedDelete);
    setCanBulkShare(canShareWithinCampaign && canShareWithinPermissions);

    (async () => {
      if (canMove) {
        const {
          data: { list }
        } = await assetGetSimpleFoldersList({
          campaignId: selectedAssets[0].node.campaignId,
          workspaceId: selectedAssets[0].node.workspaceId
        });
        if (list.length === 0) canMove = false;
        setFoldersList(list);
      }

      setCanBulkMove(canMove);
    })();
  }, [selectedAssets]);

  return (
    <div
      className={
        length > 0
          ? 'bulk_actions_container bulk_actions_container_active'
          : 'bulk_actions_container'
      }
    >
      <div className="bulk_actions_container__body">
        <div className="items">
          <div className="list" />
          <div className="copy">{length} media selected</div>
        </div>
        <div className="close" onClick={onClose} />
      </div>
      <div className="bulk_actions_container__actions">
        {page === 'deleted' ? (
          <>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await deleteAssetAction(
                    {
                      assetId: asset.node.id,
                      permanent: true
                    },
                    asset.node.path[0]?.id
                  )(dispatch);
                  dispatch(updateCampaignCounters('deleteFile'));
                  dispatch(updateAssetsWorkspaceCounters('deletePermanent'));
                }
                onClose();
              }}
            >
              <Delete /> Delete forever
            </Button>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await restoreDeletedAssetAction({ assetId: asset.node.id })(
                    dispatch
                  );
                  dispatch(updateAssetsWorkspaceCounters('restore', asset));
                }
                onClose();
              }}
            >
              Restore
            </Button>
          </>
        ) : (
          <>
            <Popover
              placement="rightBottom"
              title="SELECT FOLDER"
              trigger={['click']}
              overlayClassName="bulk_actions_overlay"
              visible={isMovePopoverVisible}
              onVisibleChange={(v) => {
                if (!canBulkMove) return;
                setIsMovePopoverVisible(v);
              }}
              content={
                <div
                  style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    marginRight: -15,
                    paddingRight: 15
                  }}
                >
                  {foldersList?.map((el) => (
                    <p
                      key={el.id}
                      onClick={async () => {
                        if (el.isPrivate) {
                          const assetsName = selectedAssets.map(
                            (el: any) => el.node.asset.versions[0].name
                          );
                          setIsMovePopoverVisible(false);
                          dispatch(
                            toggleMoveAssetToPrivateFolder({
                              assetsName,
                              folderName: el.name,
                              visible: true,
                              callback: () => {
                                for (const asset of selectedAssets) {
                                  dispatch(
                                    moveAssetToFolder(
                                      {
                                        assetId: asset.node.id,
                                        folderId: el.id
                                      },
                                      page === 'campaign',
                                      asset.path
                                    )
                                  );
                                }
                              }
                            })
                          );
                          return;
                        }
                        for (const asset of selectedAssets) {
                          dispatch(
                            moveAssetToFolder(
                              {
                                assetId: asset.node.id,
                                folderId: el.id
                              },
                              page === 'campaign',
                              asset.path
                            )
                          );
                        }
                        setIsMovePopoverVisible(false);
                        onClose();
                      }}
                    >
                      {el.name} {el.isPrivate && <PrivateEye />}
                    </p>
                  ))}
                </div>
              }
            >
              <Button
                type="text"
                size="small"
                className="action"
                disabled={!canBulkMove}
                onClick={() => {
                  if (!canBulkMove) return;
                  setIsMovePopoverVisible(true);
                }}
              >
                <Move className="action__icon action__icon--move" />
                Move to
              </Button>
            </Popover>
            <UpgradePlanTooltip
              text=" to a Team plan to access this feature."
              placement="left"
              isCanVisible={upgradeShare}
            >
              <Button
                type="text"
                size="small"
                className="action"
                disabled={!canBulkShare || upgradeShare}
                onClick={() => {
                  const assets = selectedAssets.map((it: any) => ({
                    asset: it.node
                  }));
                  dispatch(
                    toggleShareAssetModal({
                      visible: true,
                      assets,
                      action: 'create'
                    })
                  );
                  onClose();
                }}
              >
                {upgradeShare && <UpgradePlanSvg className="action__icon" />}
                {!upgradeShare && <Share className="action__icon" />}
                Share
              </Button>
            </UpgradePlanTooltip>

            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDelete}
              onClick={async () => {
                if (!canBulkDelete) return;
                for (const asset of selectedAssets) {
                  await deleteAssetAction(
                    {
                      assetId: asset.node.id,
                      permanent: false
                    },
                    asset.node.path[0]?.id
                  )(dispatch);
                  dispatch(updateCampaignCounters('deleteFile'));
                  dispatch(updateAssetsWorkspaceCounters('delete', asset));
                }
                onClose();
              }}
            >
              <Delete className="action__icon" />
              Delete
            </Button>
            <Button
              type="text"
              size="small"
              className="action"
              disabled={!canBulkDownload}
              onClick={() => {
                if (!canBulkDownload) return;
                downloadAll(
                  selectedAssets.map(
                    (x: any) => x.node.asset.versions[0].sourceUrl
                  )
                );
                onClose();
              }}
            >
              <Download className="action__icon action__icon" />
              Download
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default BulkActions;
