import { Dropdown, Menu, Tooltip } from 'antd';
import React, { memo, useMemo } from 'react';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';

import './ItemsFilter.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { TasksInDashboardListType } from '@redux/types/tasks';

type DashboardTasksFilterProps = {
  listType: TasksInDashboardListType;
  onFilterChange: (v: TasksInDashboardListType) => void;
};

export default memo(function DashboardTasksFilter({
  listType,
  onFilterChange
}: DashboardTasksFilterProps) {
  const menuItems: ItemType[] = useMemo(
    () => [
      {
        key: 'all',
        label: 'All'
      },
      {
        key: 'assigned',
        label: 'Tasks assigned to me'
      },
      {
        key: 'own',
        label: 'Tasks I’ve created'
      }
    ],
    []
  );
  const selectedValueCopy = useMemo(() => {
    if (listType === 'all') return 'All';
    if (listType === 'assigned') return 'Tasks assigned to me';
    if (listType === 'own') return 'Tasks I’ve created';
  }, [listType]);
  return (
    <div className="items-filter-container">
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            selectedKeys={[listType]}
            items={menuItems}
            onClick={({ key }) =>
              onFilterChange(key as TasksInDashboardListType)
            }
          />
        }
        overlayClassName="entities-dropdown-menu-overlay"
      >
        <div className="items-filter-container--btn">
          <ArrowDownSvg className="arrow" />
          <Tooltip
            placement="topLeft"
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
            title={selectedValueCopy}
          >
            <div className="value">{selectedValueCopy}</div>
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
});
