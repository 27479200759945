import classNames from 'classnames';
import React from 'react';

import './Priority.less';
import { Tooltip } from 'antd';

interface IPriorityProps {
  priority: string | null;
  classNamePriorityContainer?: string | null;
}

export default function Priority({
  priority,
  classNamePriorityContainer
}: IPriorityProps) {
  const priorityClassName = classNames(
    'priority-container',
    priority === 'low' && 'medium-low',
    priority === 'medium' && 'medium-priority',
    priority === 'high' && 'high-priority',
    priority === 'critical' && 'greatest-priority',
    classNamePriorityContainer
  );

  return (
    <Tooltip
      overlayClassName="task-group-settings-hint"
      overlayStyle={{ textTransform: 'capitalize' }}
      title={priority}
      trigger="hover"
    >
      <div className={priorityClassName}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </Tooltip>
  );
}

Priority.defaultProps = {
  classNamePriorityContainer: ''
};
