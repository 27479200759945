import MemberStatusTag from '@components/MemberStatusTag/MemberStatusTag';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import React, { memo, useMemo } from 'react';

type StatusCellProps = {
  memberId: string;
};

export default memo(function StatusCell({ memberId }: StatusCellProps) {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );

  const memberStatus = useMemo(() => {
    if (!member) return 'pending';
    const status = member.user
      ? 'active'
      : member.invitationExpired
      ? 'expired'
      : 'pending';
    return status;
  }, [member]);

  if (!member) return null;
  return (
    <div className="status_cell">
      <MemberStatusTag status={memberStatus} />
    </div>
  );
});
