import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Divider,
  message,
  Input,
  Switch,
  Tooltip
} from 'antd';
import { ReactComponent as SelectSvg } from '@assets/icons/select-menu-item.svg';
import { ReactComponent as EyeOpenSvg } from '@assets/icons/eye-opened.svg';
import { ReactComponent as EyeCloseSvg } from '@assets/icons/eye-closed.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { useTypedSelector } from '@hooks';
import { ReactComponent as MagicianSvg } from '@assets/icons/magician-small.svg';
import { useDispatch } from 'react-redux';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { useOrganization } from '@components/OrganizationBoundary';

interface ISettingsProps {
  onSubmit(data: any): Promise<void>;
  onCloseModal(): void;
  handleOpenApprovalModal(): void;
  isLastVersionShared: boolean;
  settingPermissions: {
    allowDownloads: boolean;
    allowComments: boolean;
    allowAllVersions: boolean;
  };
  assetsCount: number;
}

function Settings({
  onSubmit,
  settingPermissions,
  isLastVersionShared,
  onCloseModal,
  handleOpenApprovalModal,
  assetsCount
}: ISettingsProps) {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization(false);
  const shareAssetModal = useTypedSelector(
    ({ modal }) => modal.shareAssetModal
  );

  const [form] = Form.useForm();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isPasswordSubmit, setIsPasswordSubmit] = useState<boolean>(false);
  const [isPasswordSuccess, setIsPasswordSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!shareAssetModal?.review) return;
    const { review } = shareAssetModal;
    form.setFieldsValue({ password: review.password });
    form.setFieldsValue({ addPassword: !!review.password });
    form.setFieldsValue({ allowDownloads: review.allowDownloads });
    form.setFieldsValue({ allowComments: review.allowComments });
    form.setFieldsValue({ allowAllVersions: review.allowAllVersions });
    setIsShowPassword(!!review.password);
  }, [shareAssetModal?.review, form, shareAssetModal]);

  const onChangeSwitchPassword = (val: boolean) => {
    setIsShowPassword(val);
    setIsPasswordSuccess(false);
    // reset password
    const password = form.getFieldValue('password');
    if (!val && password) {
      form.resetFields(['password']);
      form.submit();
    }
  };

  const onSubmitPasswordForm = async () => {
    const password = form.getFieldValue('password');
    if (!password) {
      form.setFields([
        {
          name: 'password',
          errors: ['Password required.']
        }
      ]);
      return;
    }
    try {
      setIsPasswordSuccess(false);
      setIsPasswordSubmit(true);
      await onSubmit(form.getFieldsValue());
      message.success({
        content: 'Password added!',
        className: 'message-dark-modal',
        icon: <SuccessSvg className="anticon" />
      });
      setIsPasswordSuccess(true);
    } catch (error: any) {
      if (error?.response?.data?.errorCode === 'feature_not_allowed') {
        form.resetFields(['password', 'addPassword']);
        setIsShowPassword(false);
      }
    } finally {
      setIsPasswordSubmit(false);
    }
  };

  const passwordIconRender = (visible: boolean) =>
    visible ? <EyeOpenSvg /> : <EyeCloseSvg />;

  const upgradePassword =
    !isShowPassword &&
    !currentOrganization?.entity?.features.passwordProtectedShareLinks;

  return (
    <Form
      form={form}
      className="share_asset_modal_form share_asset_modal_settings"
      onFinish={onSubmit}
      initialValues={settingPermissions}
    >
      <div>
        <p className="modal_container__copy">Link permissions</p>
        <Form.Item className="share_asset_modal_switch" label="Allow comments">
          <Tooltip
            placement="top"
            color="white"
            overlayClassName="share_asset_modal_tooltip"
            title="If you remove the ability to comment, this media becomes view only."
          >
            <Form.Item name="allowComments" valuePropName="checked">
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                className="share_asset_modal_switch-control"
                onChange={() => form.submit()}
              />
            </Form.Item>
          </Tooltip>
        </Form.Item>
        <Form.Item
          className="share_asset_modal_switch"
          label="Allow downloads"
          name="allowDownloads"
          valuePropName="checked"
        >
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            className="share_asset_modal_switch-control"
            onChange={() => form.submit()}
          />
        </Form.Item>
        <Form.Item
          className="share_asset_modal_switch"
          label="All versions"
          name="allowAllVersions"
          valuePropName="checked"
        >
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            className="share_asset_modal_switch-control"
            onChange={() => form.submit()}
          />
        </Form.Item>

        <Divider className="modal_container_divider" />
        <p className="modal_container__copy">Link settings</p>
        <Form.Item
          colon={false}
          className="share_asset_modal_switch"
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Add password
              {upgradePassword && <UpgradePlanSvg style={{ marginLeft: 8 }} />}
            </div>
          }
          name="addPassword"
          valuePropName="checked"
        >
          <UpgradePlanTooltip
            text=" to a Team plan to set a password."
            isCanVisible={upgradePassword}
            beforeUpgradeClick={() => onCloseModal()}
            innerStyle={{
              transform: 'translate(0, 10px)'
            }}
          >
            <Switch
              disabled={upgradePassword}
              onChange={onChangeSwitchPassword}
              checkedChildren="On"
              unCheckedChildren="Off"
              className="share_asset_modal_switch-control"
            />
          </UpgradePlanTooltip>
        </Form.Item>
        <div>
          {isShowPassword && (
            <Row wrap={false} gutter={12}>
              <Col className="share_asset_modal_form-left">
                <Form.Item name="password">
                  <Input.Password
                    placeholder="Set password"
                    size="large"
                    iconRender={passwordIconRender}
                  />
                </Form.Item>
              </Col>
              <Col className="share_asset_modal_form-right">
                <Button
                  type="primary"
                  htmlType="button"
                  className="submit_form submit_form_btn"
                  size="middle"
                  onClick={onSubmitPasswordForm}
                  icon={
                    isPasswordSuccess && <SelectSvg className="b-icon-select" />
                  }
                  loading={isPasswordSubmit}
                >
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {isLastVersionShared &&
        assetsCount === 1 &&
        currentOrganization?.entity?.features.approvalRouting && (
          <div
            className="share_asset_modal_bottom-info"
            style={{ marginTop: 50 }}
          >
            <Row gutter={16}>
              <Col className="share_asset_modal_bottom-info-icon">
                <MagicianSvg />
              </Col>
              <Col className="share_asset_modal_bottom-info-text">
                Looking to route the media through stakeholder approvals? Try it{' '}
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenApprovalModal();
                  }}
                >
                  here.
                </a>
              </Col>
            </Row>
          </div>
        )}
    </Form>
  );
}

export default Settings;
