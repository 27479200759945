import React, { useState, useEffect } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ErrorSvg } from '@assets/icons/contributors_limit_error_notification.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { useOrganization } from '@components/OrganizationBoundary';
import isTrialEnd from '@helpers/isTrialEnd';
import { useDispatch } from 'react-redux';
import { toggleBillingModal } from '@redux/actions/modalAction';

ExtraUsersAlert.defaultProps = {
  fullScreen: false
};

function ExtraUsersAlert({ fullScreen }: { fullScreen?: boolean }) {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization();
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!currentOrganization.entity?.permissions.billing) return;
    const result = isTrialEnd(currentOrganization.entity);
    if (result) return;
    if (
      !currentOrganization.entity.lockReasons.includes('members-limit-exceeded')
    )
      return;

    setIsAlertVisible(true);
  }, [currentOrganization]);

  if (!isAlertVisible) return null;

  return (
    <div
      className={classNames(
        'alert-container',
        fullScreen && 'alert-container-full-screen'
      )}
    >
      <div className="info">
        <div className="icon">
          <ErrorSvg />
        </div>
        <div className="copies">
          <span className="title">You&apos;ve exceeded your seat count</span>
          <span className="description">
            Your plan includes {currentOrganization.entity?.limits.orgMembers}{' '}
            team members max. Upgrade your plan or remove users to continue
            collaborating.
          </span>
        </div>
      </div>
      <div className="actions">
        <Button
          type="primary"
          onClick={() => dispatch(toggleBillingModal({ visible: true }))}
        >
          Update account
        </Button>
        <CrossSvg
          style={{ cursor: 'pointer' }}
          onClick={() => setIsAlertVisible(false)}
        />
      </div>
    </div>
  );
}

export default ExtraUsersAlert;
