import React, { memo } from 'react';
import { List, Skeleton } from 'antd';
import './TaskCampaignGroupSkeleton.less';

interface TaskCampaignGroupSkeletonProps {
  count: number;
}

export default memo(function TaskCampaignGroupSkeleton({
  count
}: TaskCampaignGroupSkeletonProps) {
  if (count <= 0) return null;
  return (
    <List
      className="task-campaign-group-skeleton"
      dataSource={new Array(count).fill(undefined).map((_, i) => ({ key: i }))}
      renderItem={() => (
        <List.Item>
          <Skeleton.Input />
        </List.Item>
      )}
    />
  );
});
