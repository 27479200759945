import React from 'react';
import './AssigneeUserItem.less';
import { Skeleton } from 'antd';

export default function AssigneeUserItemSkeleton() {
  return (
    <div className="assigned-users-menu-item">
      <span>
        <Skeleton.Avatar size="large" />
      </span>
      <div>
        <span>
          <Skeleton.Input />
        </span>
      </div>
    </div>
  );
}
