import React, { useCallback, useEffect, useRef, useState } from 'react';
import AssigneesMenuModal from '@components/Tasks/AssigneesMenuModal/AssigneesMenuModal';
import { ReactComponent as PlusCircleSvg } from '@assets/icons/plus-circle.svg';
import { ReactComponent as RemoveAssigneeUser } from '@assets/icons/remove-assignee-user.svg';
import { Avatar, Badge, Popover } from 'antd';
import AssigneeUserItem from '@components/Tasks/AssigneeUserItem';
import UserAvatar from '@components/UserAvatar';
import { useOrganization } from '@components/OrganizationBoundary';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { closeTaskModal, setTaskAssigneesSearch } from '@redux/reducers/tasks';
import { useFetch } from '@hooks/useFetch';
import { taskSelector, taskStateSelector } from '@redux/selectors/tasks';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useTypedSelector } from '@hooks';
import { fetchTaskAssignees } from '@redux/actions/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { MemberDto } from '@api/Api';
import { toggleReachedEntityLimitModal } from '@redux/actions/modalAction';

interface AssigneesListProps {
  taskId: string;
  fetchOnOpen: boolean;
  maxCount?: number;
  autoAssignMe?: boolean;
  onAdd: (member: MemberDto) => void;
  onRemove: (member: MemberDto) => void;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  placement?: TooltipPlacement;
  overlayInnerStyle?: React.CSSProperties;
  disableSelect?: boolean;
  disabled?: boolean;
}

export default function AssigneesList(props: AssigneesListProps) {
  const dispatch = useTypedDispatch();
  const { taskId, fetchOnOpen, autoAssignMe, maxCount, onAdd, ...menuProps } =
    props;
  const { currentOrganization } = useOrganization();

  const selected = useTypedSelector(
    (state) => taskSelector(state, { taskId }).assignees
  );
  const list = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).potentialAssignees.list
  );
  const loading = useTypedSelector(
    (state) =>
      taskStateSelector(state, { taskId }).potentialAssignees.fetch.newRequest
        ?.fetchType ?? false
  );
  const searchQuery = useTypedSelector(
    (state) =>
      taskStateSelector(state, { taskId }).potentialAssignees.searchQuery
  );

  const potentialAssigneesListRef = useRef(list);
  potentialAssigneesListRef.current = list;

  const needPlanUpgrade =
    selected.length >=
    (currentOrganization?.entity?.limits?.taskAssignees ??
      Number.POSITIVE_INFINITY);

  const [open, setOpen] = useState(false);
  useFetch({
    key: `task-assignees-${taskId}`,
    disabled: fetchOnOpen && !open,
    selector: (state) =>
      taskStateSelector(state, { taskId }).potentialAssignees.fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTaskAssignees({
          taskId,
          fetchType
        })
      )
  });

  const onSearch = useCallback(
    (value: string) => {
      dispatch(
        setTaskAssigneesSearch({
          value,
          taskId
        })
      );
    },
    [taskId, dispatch]
  );
  const searchQueryRef = useRef(searchQuery);
  searchQueryRef.current = searchQuery;
  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (!open && searchQueryRef.current) {
        dispatch(
          setTaskAssigneesSearch({
            value: '',
            taskId
          })
        );
      }
    },
    [dispatch, taskId]
  );

  useEffect(() => {
    if (!autoAssignMe || !potentialAssigneesListRef.current.length || !loading)
      return;
    const me = potentialAssigneesListRef.current.find((it) => it.user?.me);
    if (me) onAdd(me);
  }, [autoAssignMe, loading, onAdd]);

  return (
    <AssigneesMenuModal
      {...menuProps}
      onAdd={onAdd}
      open={open}
      onOpenChange={onOpenChange}
      disabled={menuProps.disabled || needPlanUpgrade}
      selected={selected}
      list={list}
      loading={!!loading}
      searchQuery={searchQuery}
      onSearch={onSearch}
    >
      <div
        className="assignee-users-settings-container"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          if (needPlanUpgrade) {
            dispatch(
              toggleReachedEntityLimitModal({
                visible: true,
                entity: 'task assignees'
              })
            );
          }
        }}
      >
        {!!selected.length && (
          <Avatar.Group
            maxCount={5}
            maxPopoverTrigger="click"
            maxPopoverPlacement="bottom"
          >
            {selected.slice(0, maxCount ?? selected.length).map((item) => {
              return (
                <Popover
                  key={item.id}
                  placement="bottom"
                  trigger="hover"
                  content={
                    <div className="task-table-assigned-user-single-item">
                      <AssigneeUserItem userData={item} />
                    </div>
                  }
                  overlayClassName="popover-container"
                  overlayInnerStyle={{ padding: '12px 4px' }}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    <Badge
                      count={
                        <RemoveAssigneeUser
                          onClick={() => menuProps.onRemove(item)}
                        />
                      }
                      className="assignee-user-item"
                      offset={[-15, 15]}
                    >
                      <UserAvatar
                        isActive={!item?.invitationEmail}
                        size="default"
                        src={item?.user?.picture?.url || ''}
                        userEmail={item?.user?.email || ''}
                        userName={item?.user?.name || ''}
                      />
                    </Badge>
                  </span>
                </Popover>
              );
            })}
          </Avatar.Group>
        )}
        {!!maxCount && maxCount > 0 && selected.length > maxCount && (
          <span className="max-assignee-counter-container">
            {selected.length - maxCount}+
          </span>
        )}
        {!menuProps.disableSelect && (
          <UpgradePlanTooltip
            text=" plans to add more assignees."
            isCanVisible={needPlanUpgrade}
            beforeUpgradeClick={(e) => {
              e.stopPropagation();
              dispatch(closeTaskModal());
            }}
            innerStyle={{ transform: 'translate(0, 15px)' }}
          >
            <PlusCircleSvg
              style={{
                marginLeft: selected.length ? undefined : 0
              }}
            />
          </UpgradePlanTooltip>
        )}
        {needPlanUpgrade && <UpgradePlanSvg style={{ marginLeft: 4 }} />}
      </div>
    </AssigneesMenuModal>
  );
}
