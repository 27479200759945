import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './DraggableItem.less';
import { nonNull } from '@helpers/non-null';

interface DraggableItemProps {
  id: string;
  type: string;
  droppable?: boolean | string | string[];
  className?: string;
  children: React.ReactElement;
  _onDragStart?: (e: React.DragEvent) => void;
  _onDragOver?: (e: React.DragEvent) => void;
  _onDragEnter?: (e: React.DragEvent) => void;
  _onDragLeave?: (e: React.DragEvent) => void;
  _onDragEnd?: () => void;
  _onDrop?: (e: React.DragEvent) => void;
  _isDragging?: boolean;
  _isOver?: false | OverPosition;
  _isDropTarget?: boolean;
  [key: string]: any;
}

type OverPosition =
  | 'center'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

const itemCls = 'draggable-item';
const beforeAfterContainerCls = 'draggable-item__before-after-container';
const animationContainerCls = 'draggable-item__animation-container';

export const DraggableItem = forwardRef<HTMLDivElement, DraggableItemProps>(
  function DraggableItem(props: DraggableItemProps, ref) {
    const {
      id,
      type,
      droppable: _droppable, // used by DraggableList
      children,
      className,
      _onDragStart,
      _onDragOver,
      _onDragEnter,
      _onDragLeave,
      _onDragEnd,
      _onDrop,
      _isDragging,
      _isOver,
      _isDropTarget,
      _isHidden,
      ...otherProps
    } = props;

    const containerRef = useRef<HTMLDivElement>(null);

    const onDragEndRef = React.useRef(_onDragEnd);
    onDragEndRef.current = _onDragEnd;
    const onDragEnd = useCallback(() => {
      const el = containerRef.current;
      el?.classList.remove(`${animationContainerCls}--dragging`);
      const onDragEnd = onDragEndRef.current;
      if (onDragEnd) onDragEnd();
    }, []);
    const isDraggingRef = React.useRef(_isDragging);
    isDraggingRef.current = _isDragging;

    useEffect(() => {
      return () => {
        const isDragging = isDraggingRef.current;
        if (isDragging) onDragEnd();
      };
    }, [onDragEnd]);

    return (
      <div
        {...otherProps}
        ref={ref}
        className={classNames(
          itemCls,
          className,
          _isDragging ? `${itemCls}--dragging` : null,
          _isOver ? `${itemCls}--over` : null,
          _isOver ? `${itemCls}--over-${_isOver}` : null,
          _isDropTarget ? `${itemCls}--drop-target` : null
        )}
        data-id={id}
        data-type={type}
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.items.clear();
          e.dataTransfer.setData('streamwork://id', id);
          e.dataTransfer.setData(`streamwork://id/${id}`, id);
          e.dataTransfer.setData('streamwork://type', type);
          e.dataTransfer.setData(`streamwork://type/${type}`, type);
          const el = nonNull(containerRef.current);
          el.classList.add(`${animationContainerCls}--drag-image-start`);
          requestAnimationFrame(() => {
            el.classList.remove(`${animationContainerCls}--drag-image-start`);
            el.classList.add(`${animationContainerCls}--drag-image-end`);
            requestAnimationFrame(() => {
              el.classList.remove(`${animationContainerCls}--drag-image-end`);
              el.classList.add(`${animationContainerCls}--dragging`);
            });
          });
          if (_onDragStart) _onDragStart(e);
        }}
        onDragOver={_onDragOver}
        onDragEnter={_onDragEnter}
        onDragLeave={_onDragLeave}
        onDragEnd={onDragEnd}
        onDrop={_onDrop}
      >
        <div className={beforeAfterContainerCls}>
          <div ref={containerRef} className={animationContainerCls}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);
