import { AssetForApprovalDto } from '@api/Api';
import Status from '@components/Approval/Status';
import React, { memo, useMemo } from 'react';

type ProgressCellProps = {
  node: AssetForApprovalDto;
};

export default memo(function ProgressCell({ node }: ProgressCellProps) {
  const aw = useMemo(
    () => node.asset.versions[0].approvalWorkflow,
    [node.asset.versions]
  );
  if (!aw) return null;
  return <Status approvalWorkflow={aw} />;
});
