import React, { memo, useMemo, useState } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import { Button, Input, Popover, Tooltip } from 'antd';
import { useUpdateTask } from '@hooks/tasks';
import { TaskFieldItemDto } from '@api/Api';
import { ReactComponent as HyperlinkSvg } from '@assets/icons/hyperlink-task-table-icon.svg';
import isUrlValidator from 'validator/lib/isURL';

interface HyperlinkColumnProps {
  taskId: string;
  field: TaskFieldItemDto;
}

export default memo(function HyperlinkColumn(props: HyperlinkColumnProps) {
  const { taskId, field } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const value =
    task.customFields.find((x) => x.id === field.id)?.value?.toString() ??
    JSON.stringify({ url: '', label: '' });
  const link = useMemo(() => {
    try {
      return JSON.parse(value) as { url: string; label: string };
    } catch (err) {
      console.error(err);
      return { url: '', label: '' };
    }
  }, [value]);
  const updateTask = useUpdateTask();
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState<string>(link.url);
  const [label, setLabel] = useState<string>(link.label);
  const isUrlValid = !url || isUrlValidator(url);
  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) {
      setUrl(link.url);
      setLabel(link.label);
    }
  };
  return (
    <Popover
      placement="left"
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}
      overlayClassName="popover-container"
      overlayStyle={{ width: 400, padding: 24 }}
      content={
        <div className="hyperlink-menu-container">
          <span>Web address</span>
          <Input
            size="large"
            placeholder="www.example.com"
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />
          <span>Text to display</span>
          <Input
            size="large"
            placeholder="Enter text"
            value={label}
            onChange={(event) => setLabel(event.target.value)}
          />
          <div className="hyperlink-menu-button-container">
            <Button type="text" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!isUrlValid}
              onClick={() => {
                updateTask({
                  id: taskId,
                  customFields: {
                    add: url
                      ? [
                          {
                            id: field.id,
                            type: field.type,
                            name: field.name,
                            value: JSON.stringify({
                              url:
                                !url.startsWith('http://') &&
                                !url.startsWith('https://')
                                  ? `https://${url}`
                                  : url,
                              label
                            })
                          }
                        ]
                      : [],
                    remove: !url ? [{ id: field.id }] : []
                  }
                });
                onOpenChange(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={classNames(
          'task-table-item-container',
          task.board.id === 'archived' && 'deleted-task-container'
        )}
        style={{ width: 140 }}
      >
        {link.url && (
          <Tooltip
            overlayClassName="task-group-settings-hint"
            title={link.label || link.url}
            trigger="hover"
          >
            <span
              className="task-table-hyperlink-label"
              onClick={(event) => {
                event.stopPropagation();
                let { url } = link;
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                  url = `https://${url}`;
                }
                window.open(url);
              }}
            >
              {link.label || link.url}
            </span>
          </Tooltip>
        )}
        {!link.url && <HyperlinkSvg />}
      </div>
    </Popover>
  );
});
