import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Typography
} from 'antd';
import './DeleteTaskGroupModal.less';
import '@components/Modals/ProfileModal/ProfileModalContainer.less';
import axios from 'axios';
import { apiClient } from '@api/client';
import { useCurrentWorkspace } from '@hooks/workspace';
import classNames from 'classnames';

const { Title, Text } = Typography;

interface IDeleteTaskGroupModal {
  onCancel(): void;
  onConfirm(deleteAllTasks: boolean): void;
  campaignId: string;
  boardId: string | null;
  deleteAllTasks: boolean;
  open: boolean;
}

function DeleteTaskGroupModal({
  onCancel,
  onConfirm,
  campaignId,
  boardId,
  deleteAllTasks,
  open
}: IDeleteTaskGroupModal) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const [form] = Form.useForm();
  const [completedCount, setCompletedCount] = useState(0);
  const [incompleteCount, setIncompleteCount] = useState(0);
  const [secondConfirm, setSecondConfirm] = useState(deleteAllTasks);
  const [keepTasks, setKeepTasks] = useState(!deleteAllTasks);
  const [loading, setLoading] = useState(false);
  const abortTasksCountFetch = useRef(() => {
    /* do nothing */
  });
  const fetchTasksCount = async () => {
    abortTasksCountFetch.current();
    setLoading(true);
    try {
      if (!boardId) return;
      const cts = axios.CancelToken.source();
      abortTasksCountFetch.current = () => cts.cancel();
      const [completedCount, allCount] = await Promise.all([
        apiClient.task.taskControllerCountTasks(
          {
            workspaceId: currentWorkspace.id,
            campaignIds: [campaignId],
            boardIds: [boardId],
            statuses: ['done']
          },
          { cancelToken: cts.token }
        ),
        apiClient.task.taskControllerCountTasks(
          {
            workspaceId: currentWorkspace.id,
            campaignIds: [campaignId],
            boardIds: [boardId]
          },
          { cancelToken: cts.token }
        )
      ]);
      setCompletedCount(completedCount.data.count);
      setIncompleteCount(allCount.data.count - completedCount.data.count);
    } finally {
      setLoading(false);
    }
  };

  const prevOpen = useRef(open);
  const prevBoardId = useRef(boardId);
  const prevDeleteAllTasks = useRef(deleteAllTasks);
  if (
    open !== prevOpen.current ||
    boardId !== prevBoardId.current ||
    deleteAllTasks !== prevDeleteAllTasks.current
  ) {
    prevOpen.current = open;
    prevBoardId.current = boardId;
    prevDeleteAllTasks.current = deleteAllTasks;
    if (open && boardId) {
      setCompletedCount(0);
      setIncompleteCount(0);
      setSecondConfirm(deleteAllTasks);
      setKeepTasks(!deleteAllTasks);
      form.resetFields();
      fetchTasksCount();
    }
  }

  return (
    <Modal
      footer={null}
      centered={true}
      width={528}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      wrapClassName={classNames({
        'delete-modal': true,
        'delete-modal--loading': loading
      })}
    >
      {!secondConfirm ? (
        <div className="delete_task_modal_container">
          <h2>Delete task group</h2>
          <div className="delete_task_modal_container__description">
            Do you want to delete this task group? It includes
            <span> {completedCount} completed</span> and
            <span> {incompleteCount} incompleted tasks.</span>
          </div>
          <Radio.Group
            className="radio-group-container"
            onChange={(event) => {
              setKeepTasks(event.target.value);
            }}
            value={keepTasks}
          >
            <Space direction="vertical">
              <Radio value={true}>
                Delete task group and keep these
                <span> {completedCount + incompleteCount}</span> tasks
              </Radio>
              <Radio value={false}>
                Delete task group with all
                <span> {completedCount + incompleteCount}</span> tasks
              </Radio>
            </Space>
          </Radio.Group>
          <div className="delete_task_modal_container__button_container">
            <Button type="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (keepTasks) onConfirm(false);
                else setSecondConfirm(true);
              }}
              className="delete-button"
            >
              Delete board
            </Button>
          </div>
        </div>
      ) : (
        <div className="profile_modal_container">
          <Form
            form={form}
            onFinish={async () => onConfirm(true)}
            layout="vertical"
          >
            <Title level={4} className="profile_modal_title">
              {deleteAllTasks ? 'Delete all tasks' : 'Delete task group'}
            </Title>
            {!deleteAllTasks && (
              <Text className="profile_modal_text">
                Are you sure you want to delete this task group? All data and
                tasks in this task group will be deleted. This can not be
                undone.
              </Text>
            )}
            {deleteAllTasks && (
              <Text className="profile_modal_text">
                Are you sure you want to delete all tasks in this group?
              </Text>
            )}
            <Form.Item
              label="Please type “Delete” to confirm"
              name="text"
              rules={[
                {
                  required: true,
                  message: 'Please, type “Delete” to confirm".'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value === 'Delete') {
                      return Promise.resolve();
                    }
                    return Promise.reject('Please, type “Delete” to confirm');
                  }
                })
              ]}
            >
              <Input placeholder="Delete" size="large" />
            </Form.Item>
            <Row gutter={24} justify="end" className="profile_modal_bottom">
              <Col>
                <Button
                  type="text"
                  htmlType="button"
                  size="middle"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="b-btn-primary"
                >
                  {deleteAllTasks ? 'Delete all tasks' : 'Delete task group'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Modal>
  );
}

export default DeleteTaskGroupModal;
