import classNames from 'classnames';
import React, { memo, useCallback, useRef, useState } from 'react';
import moment, { Moment } from 'moment';

import { Dropdown, Input, Menu, Popover, Button } from 'antd';

import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as SelectMenuItemSvg } from '@assets/icons/select-menu-item.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as DeletedSvg } from '@assets/icons/deleted-tasks-head-icon.svg';
import './HeadSortPanel.less';
import PageSortPanel from '@components/PageSortPanel';
import { MemberDto, TaskItemCampaignDto } from '@api/Api';
import InlineRangePicker from '@components/DatePickerComponent/InlineRangePicker';
import AssigneesMenuModal from '@components/Tasks/AssigneesMenuModal';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useTypedSelector } from '@hooks';
import {
  filterAssigneesSelector,
  filterCampaignsSelector,
  tasksTotalCountSelector,
  workspaceStateSelector
} from '@redux/selectors/tasks';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  TaskListsSharedState,
  TaskListType,
  TasksGroupBy,
  TasksOrderBy
} from '@redux/types/tasks';
import { useFetch } from '@hooks/useFetch';
import {
  fetchAssigneesForFilter,
  fetchCampaignsForFilter
} from '@redux/actions/tasks';
import { setListAssigneesSearch } from '@redux/reducers/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';

const SHOW_ALL_KEY = '__SHOW_ALL__';

interface SearchInputProps {
  onChange: (value: string) => void;
}

const SearchInput = memo(function SearchInput({ onChange }: SearchInputProps) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const searchQuery = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.searchQuery
  );
  return (
    <div className="panel-search-input-container">
      <Input
        style={{ width: 90 }}
        placeholder="Search"
        prefix={<SearchSvg />}
        size="large"
        value={searchQuery ?? ''}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
});

interface CampaignsFilterProps {
  onChange: (value: TaskItemCampaignDto[]) => void;
}

const CampaignsFilter = memo(function CampaignsFilter({
  onChange
}: CampaignsFilterProps) {
  const dispatch = useTypedDispatch();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const campaigns = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.campaigns.list
  );
  const selectedCampaigns = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared
        .selectedCampaigns
  );

  useFetch({
    key: `tasks-filter-campaigns-${workspaceId}`,
    selector: (state) => filterCampaignsSelector(state, { workspaceId }).fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchCampaignsForFilter({
          workspaceId,
          fetchType
        })
      )
  });

  const [open, setOpen] = useState(false);
  const dropdownTitle = selectedCampaigns.map((x) => x.name).join(', ');

  const menuItems: ItemType[] = [
    {
      key: SHOW_ALL_KEY,
      label: 'Show all',
      className: 'status-menu-item'
    },
    ...campaigns.map((x) => ({
      key: x.id,
      label: <span>{x.name}</span>,
      className: 'status-menu-item'
    }))
  ];

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
      overlay={
        <Menu
          style={{ padding: '16px 8px', width: 200 }}
          selectedKeys={
            selectedCampaigns.length
              ? selectedCampaigns.map((x) => x.id)
              : [SHOW_ALL_KEY]
          }
          onSelect={({ key }) => {
            if (key === SHOW_ALL_KEY) {
              onChange([]);
            } else {
              const campaign = campaigns.find((x) => x.id === key);
              if (
                campaign &&
                !selectedCampaigns.some((x) => x.id === campaign.id)
              ) {
                onChange([...selectedCampaigns, campaign]);
              }
            }
            setOpen(false);
          }}
          onDeselect={({ key }) => {
            onChange(selectedCampaigns.filter((x) => x.id !== key));
            setOpen(false);
          }}
          selectable
          multiple
          items={menuItems}
        />
      }
    >
      <div className="filter-item" style={{ width: '100%', maxWidth: 130 }}>
        {selectedCampaigns.length > 0 && (
          <Button
            type="text"
            className="filter-item__clear"
            onClick={(e) => {
              e.stopPropagation();
              onChange([]);
            }}
          >
            <CrossSvg />
          </Button>
        )}
        <span
          className={classNames(
            'filter-item__name',
            selectedCampaigns.length > 0 && 'filter-item__active-name'
          )}
        >
          {selectedCampaigns.length > 0 ? dropdownTitle : 'Campaigns'}
        </span>
        <div className="filter-item-icon">
          <DownArrowSvg />
        </div>
      </div>
    </Dropdown>
  );
});

const statusItems = [
  {
    key: SHOW_ALL_KEY,
    name: 'Show all',
    label: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#161819' }} />
          <span>Show all</span>
        </div>
        <SelectMenuItemSvg />
      </>
    ),
    className: 'status-menu-item'
  },
  {
    key: 'todo',
    name: 'To do',
    label: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#9BD3FC' }} />
          <span>To do</span>
        </div>
        <SelectMenuItemSvg />
      </>
    ),
    className: 'status-menu-item'
  },
  {
    key: 'in_progress',
    name: 'In progress',
    label: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#FACE8B' }} />
          <span>In progress</span>
        </div>
        <SelectMenuItemSvg />
      </>
    ),
    className: 'status-menu-item'
  },
  {
    key: 'done',
    name: 'Done',
    label: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#84CDB3' }} />
          <span>Done</span>
        </div>
        <SelectMenuItemSvg />
      </>
    ),
    className: 'status-menu-item'
  },
  {
    key: 'stuck',
    name: 'Blocked',
    label: (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundColor: '#FEB2A1' }} />
          <span>Blocked</span>
        </div>
        <SelectMenuItemSvg />
      </>
    ),
    className: 'status-menu-item'
  }
];

interface StatusesFilterProps {
  onChange: (value: TaskListsSharedState['statuses']) => void;
}

const StatusesFilter = memo(function StatusesFilter({
  onChange
}: StatusesFilterProps) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const statuses = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.statuses
  );
  const [open, setOpen] = useState(false);
  const selectedStatuses = statusItems.filter((x) =>
    statuses.includes(x.key as any)
  );
  const dropdownTitle = selectedStatuses.map((x) => x.name).join(', ');

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
      overlay={
        <Menu
          style={{ padding: '16px 8px', width: 192 }}
          selectedKeys={selectedStatuses.length ? statuses : [SHOW_ALL_KEY]}
          onSelect={({ key }) => {
            const status = key as TaskListsSharedState['statuses'][number];
            onChange(key === SHOW_ALL_KEY ? [] : [...statuses, status]);
            setOpen(false);
          }}
          onDeselect={({ key }) => {
            onChange(statuses.filter((x) => x !== key));
            setOpen(false);
          }}
          selectable
          multiple
          items={statusItems}
        />
      }
    >
      <div className="filter-item" style={{ width: '100%', maxWidth: 150 }}>
        {selectedStatuses.length > 0 && (
          <Button
            type="text"
            className="filter-item__clear"
            onClick={() => onChange([])}
          >
            <CrossSvg />
          </Button>
        )}
        <span
          className={classNames(
            'filter-item__name',
            selectedStatuses.length > 0 && 'filter-item__active-name'
          )}
        >
          {selectedStatuses.length > 0 ? dropdownTitle : 'Status'}
        </span>
        <div className="filter-item-icon">
          <DownArrowSvg />
        </div>
      </div>
    </Dropdown>
  );
});

interface DueDateFilterProps {
  onChange: (value: [string, string] | null) => void;
}

const DueDateFilter = memo(function DueDateFilter({
  onChange
}: DueDateFilterProps) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const dueDate = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.dueDate
  );
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<[Moment, Moment] | null>(() =>
    dueDate ? [moment(dueDate), moment(dueDate)] : null
  );
  const prevDueDate = useRef(dueDate);
  if (dueDate !== prevDueDate.current) {
    prevDueDate.current = dueDate;
    setRange(dueDate ? [moment(dueDate), moment(dueDate)] : null);
  }

  const selectRange = (from: Moment, to: Moment) => {
    setRange([from, to]);
    const newDueDate: [string, string] = [from.toISOString(), to.toISOString()];
    onChange(newDueDate);
    prevDueDate.current = newDueDate;
    setOpen(false);
  };

  const reset = () => {
    if (dueDate !== null) onChange(null);
    if (range !== null) {
      prevDueDate.current = null;
      setRange(null);
    }
  };

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) reset();
      }}
      trigger="click"
      placement="right"
      overlayClassName="popover-container date-picker-popover-container"
      content={
        <>
          <div className="range-picker-header-container">
            <span>Choose a time</span>
            <span
              onClick={() =>
                selectRange(moment().startOf('day'), moment().endOf('day'))
              }
            >
              Today
            </span>
            <span
              onClick={() =>
                selectRange(
                  moment().add(1, 'day').startOf('day'),
                  moment().add(1, 'day').endOf('day')
                )
              }
            >
              Tomorrow
            </span>
            <span
              onClick={() =>
                selectRange(
                  moment().add(1, 'week').startOf('week'),
                  moment().add(1, 'week').endOf('week')
                )
              }
            >
              Next week
            </span>
            <span
              onClick={() =>
                selectRange(
                  moment().add(1, 'month').startOf('month'),
                  moment().add(1, 'month').endOf('month')
                )
              }
            >
              Next month
            </span>
          </div>
          <InlineRangePicker value={range} onChange={setRange} />
          <div className="range-picker-footer-container">
            <Button
              type="text"
              onClick={() => {
                setRange(dueDate ? [moment(dueDate), moment(dueDate)] : null);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const newDueDate: [string, string] | null = range
                  ? [range[0].toISOString(), range[1].toISOString()]
                  : null;
                onChange(newDueDate);
                prevDueDate.current = newDueDate;
                setOpen(false);
              }}
            >
              Save
            </Button>
          </div>
        </>
      }
    >
      <div
        className="filter-item"
        id="due-date-range-filter"
        style={{ width: '100%', maxWidth: 154 }}
      >
        {range && range[1] && (
          <Button
            type="text"
            className="filter-item__clear"
            onClick={(e) => {
              e.stopPropagation();
              reset();
            }}
          >
            <CrossSvg />
          </Button>
        )}
        <span
          style={{ whiteSpace: 'nowrap' }}
          className={classNames(
            'filter-item__name',
            range && range[1] && 'filter-item__active-name'
          )}
        >
          {range && range[1]
            ? moment(range[1]).format('MMM DD, YYYY')
            : 'Due Date'}
        </span>
        <div className="filter-item-icon">
          <DownArrowSvg />
        </div>
      </div>
    </Popover>
  );
});

interface AssigneesFilterProps {
  onChange: (value: MemberDto[]) => void;
}

const AssigneesFilter = memo(function AssigneesFilter({
  onChange
}: AssigneesFilterProps) {
  const dispatch = useTypedDispatch();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const selected = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.assignees
  );
  const searchQuery = useTypedSelector(
    (state) => filterAssigneesSelector(state, { workspaceId }).searchQuery
  );
  const list = useTypedSelector(
    (state) => filterAssigneesSelector(state, { workspaceId }).list
  );

  const loading = useFetch({
    key: `tasks-filter-assignees-${workspaceId}`,
    selector: (state) => filterAssigneesSelector(state, { workspaceId }).fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchAssigneesForFilter({
          workspaceId,
          fetchType
        })
      )
  });
  const onSearch = useCallback(
    (value: string) => {
      dispatch(
        setListAssigneesSearch({
          value,
          workspaceId
        })
      );
    },
    [workspaceId, dispatch]
  );

  return (
    <AssigneesMenuModal
      placement="bottomLeft"
      searchQuery={searchQuery ?? ''}
      selected={selected}
      list={list}
      loading={!!loading}
      onSearch={onSearch}
      onAdd={(member) => onChange([...selected, member])}
      onRemove={(member) =>
        onChange(selected.filter((x) => x.id !== member.id))
      }
    >
      <div style={{ width: '100%', maxWidth: 120 }} className="filter-item">
        {selected.length > 0 && (
          <Button
            type="text"
            className="filter-item__clear"
            onClick={(e) => {
              e.stopPropagation();
              onChange([]);
            }}
          >
            <CrossSvg />
          </Button>
        )}
        <span
          className={classNames(
            'filter-item__name',
            selected.length > 0 && 'filter-item__active-name'
          )}
        >
          {selected.length > 0
            ? selected
                .map((item) => item?.user?.name || item?.invitationEmail)
                .join(', ')
            : 'Assignee'}
        </span>
        <div className="filter-item-icon">
          <DownArrowSvg />
        </div>
      </div>
    </AssigneesMenuModal>
  );
});

const orderByGroupByOptions = [
  {
    key: 'sort-by',
    type: 'group' as const,
    label: 'SORT BY',
    children: [
      {
        key: 'orderBy:createdAt:DESC',
        label: (
          <>
            <div className="menu-item-dot" />
            Date created
          </>
        )
      },
      {
        key: 'orderBy:dueDate:ASC',
        label: (
          <>
            <div className="help_dropdown__dot" />
            Due date
          </>
        )
      },
      {
        key: 'orderBy:status:ASC',
        label: (
          <>
            <div className="menu-item-dot" />
            Status
          </>
        )
      },
      {
        key: 'orderBy:lastEventTime:DESC',
        label: (
          <>
            <div className="menu-item-dot" />
            Date modified
          </>
        )
      }
    ]
  },
  {
    key: 'group-by',
    type: 'group' as const,
    label: 'GROUP BY',
    children: [
      {
        key: 'groupBy:campaign',
        label: (
          <>
            <div className="menu-item-dot" />
            Campaign
          </>
        )
      },
      {
        key: 'groupBy:board',
        label: (
          <>
            <div className="menu-item-dot" />
            Task board
          </>
        )
      }
    ]
  }
];

interface OrderSelectorProps {
  onOrderByChange: (value: TasksOrderBy) => void;
  onGroupByChange: (value: TasksGroupBy | null) => void;
}

const OrderSelector = memo(function OrderSelector({
  onOrderByChange,
  onGroupByChange
}: OrderSelectorProps) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const orderBy = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.orderBy
  );
  const groupBy = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).listsShared.groupBy
  );
  const selectedKeys = [`orderBy:${orderBy}`];
  if (groupBy) selectedKeys.push(`groupBy:${groupBy}`);
  return (
    <div className="sort-menu-list-container">
      <Menu
        selectedKeys={selectedKeys}
        onClick={({ key }) => {
          if (key.startsWith('orderBy:')) {
            onOrderByChange(key.substring('orderBy:'.length) as TasksOrderBy);
          }
          if (key.startsWith('groupBy:')) {
            const newGroupBy = key.substring('groupBy:'.length) as TasksGroupBy;
            onGroupByChange(groupBy === newGroupBy ? null : newGroupBy);
          }
        }}
        items={orderByGroupByOptions}
      />
    </div>
  );
});

const pageCopy = {
  all: {
    title: 'ALL TASKS',
    description: 'View all tasks created across all campaigns here.'
  },
  my: {
    title: 'TASKS I CREATED',
    description: 'This is where you can view all the tasks you created.'
  },
  assigned: {
    title: 'ASSIGNED TO ME',
    description: 'This is where you can view all tasks assigned to you.'
  },
  deleted: {
    title: 'DELETED',
    description:
      'Tasks are kept for 30 days before being permanently deleted. You can restore deletions during the 30 days.'
  }
};

interface HeadSortPanelProps {
  page: TaskListType;
  onSearchQueryChange: (value: string) => void;
  onCampaignsChange: (value: TaskItemCampaignDto[]) => void;
  onStatusesChange: (value: TaskListsSharedState['statuses']) => void;
  onDueDateChange: (value: [string, string] | null) => void;
  onAssigneesChange: (value: MemberDto[]) => void;
  onOrderByChange: (value: TasksOrderBy) => void;
  onGroupByChange: (value: TasksGroupBy | null) => void;
  onCreateTaskClick: () => void;
}

const HeadSortPanel = memo(function HeadSortPanel({
  page,
  onSearchQueryChange,
  onCampaignsChange,
  onStatusesChange,
  onDueDateChange,
  onAssigneesChange,
  onOrderByChange,
  onGroupByChange,
  onCreateTaskClick
}: HeadSortPanelProps) {
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const tasksCount = useTypedSelector((state) =>
    tasksTotalCountSelector(state, { workspaceId, list: page })
  );
  const anyCampaignExists = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, {
        workspaceId
      }).listsShared.campaigns.list.filter((it) => !it.demo).length > 0
  );
  const searchInput = <SearchInput onChange={onSearchQueryChange} />;
  const sortMenu = (
    <OrderSelector
      onOrderByChange={onOrderByChange}
      onGroupByChange={onGroupByChange}
    />
  );
  return (
    <div>
      <div className="inner-header">
        <div className="inner-header__name">
          {page === 'deleted' && <DeletedSvg className="inner-header__icon" />}
          <span className="inner-header__title">{pageCopy[page].title}</span>
          <div className="inner-header__count">{tasksCount}</div>
        </div>
      </div>
      <span className="description-title-text">
        {pageCopy[page].description}
      </span>
      <div className="sort-panel-container">
        <div className="filter-panels-container">
          <CampaignsFilter onChange={onCampaignsChange} />
          <StatusesFilter onChange={onStatusesChange} />
          <DueDateFilter onChange={onDueDateChange} />
          <AssigneesFilter onChange={onAssigneesChange} />
        </div>
        <PageSortPanel
          isVisibleSortStyle={false}
          sortMenuModal={sortMenu}
          classNameSearchContainer="sort-panel-search-container"
          entity="tasks"
          searchInputComponent={searchInput}
          onAddClick={page !== 'deleted' ? onCreateTaskClick : undefined}
          isActionButtonDisabled={!anyCampaignExists}
          actionButtonDisabledReason={
            !anyCampaignExists
              ? 'You should create campaign before you can add new tasks'
              : undefined
          }
          actionButtonName="Add new task"
        />
      </div>
    </div>
  );
});

export default HeadSortPanel;
