import { Skeleton } from 'antd';
import React, { memo } from 'react';

type AssetItemSkeletonProps = {
  count: number;
};
export default memo(function AssetItemSkeleton({
  count
}: AssetItemSkeletonProps) {
  const list = new Array(count).fill(undefined);
  return (
    <div style={{ height: 'calc(100vh - 311px)' }}>
      {list.map((_, idx) => (
        <Skeleton.Input key={idx} className="asset-list--skeleton" />
      ))}
    </div>
  );
});
