import { AssetForApprovalDto } from '@api/Api';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';
import React, { memo, useMemo } from 'react';

type ManagersCellProps = {
  node: AssetForApprovalDto;
};

export default memo(function ManagersCell({ node }: ManagersCellProps) {
  const users = useMemo(() => {
    const users = (
      node.asset.versions[0].approvalWorkflow?.stages.map((el) => el.members) ||
      []
    )
      .flat()
      .filter((user) => user.canManageAW);

    const uniqueMembers = [
      ...new Map(users.map((user) => [user.email, user])).values()
    ];
    return uniqueMembers;
  }, [node.asset.versions]);
  return (
    <AvatarsGroup
      maxCount={3}
      avatarSize={36}
      users={users}
      membersType="aw_stage_members"
    />
  );
});
