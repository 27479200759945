import { Tag } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { ReactComponent as ClockSvg } from '@assets/icons/clock.svg';
import React, { memo, useEffect, useMemo, useState } from 'react';

import './DueDateTag.less';

type DueDateTagProps = {
  deadline?: string;
  simple?: boolean;
};

export default memo(function DueDateTag({
  deadline = '',
  simple = false
}: DueDateTagProps) {
  const deadlineMoment = useMemo(
    () => moment(deadline).startOf('minute'),
    [deadline]
  );
  const [isPastDueDate, setIsPastDueDate] = useState(
    moment().startOf('minute').isSameOrAfter(deadlineMoment)
  );

  useEffect(() => {
    if (simple) return;
    const intervalId = setInterval(() => {
      setIsPastDueDate(
        moment().startOf('minute').isSameOrAfter(deadlineMoment)
      );
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [deadline, deadlineMoment, simple]);
  if (simple)
    return (
      <div
        className="is-expired-due-date-tag"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <ClockSvg />
        <span style={{ marginRight: 6 }}>Overdue</span>
      </div>
    );
  return (
    <Tag
      className={classNames(
        'due-date-tag',
        isPastDueDate && 'is-expired-due-date-tag'
      )}
      color={isPastDueDate ? '#F9F4F3' : '#F5F6F5'}
    >
      {isPastDueDate && <span style={{ marginRight: 6 }}>Overdue</span>}
      <ClockSvg />
      <span>
        {moment(deadline).isSame(moment(deadline).endOf('day'), 'minutes')
          ? moment(deadline).format('MMM DD')
          : moment(deadline).format('MMM DD, h:mma')}
      </span>
    </Tag>
  );
});
