import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EyePublicSvg } from '@assets/icons/eye-public.svg';
import { ReactComponent as EyePrivateSvg } from '@assets/icons/eye-private.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';

import { setCampaignVisibilityAction } from '@redux/actions/campaignAction';
import './CampaignBreadcrumbs.less';
import { ActionToast } from '@components/Toasts';
import { Dropdown, Menu, Tooltip } from 'antd';
import {
  toggleBillingModal,
  toggleCampaignInfoModal
} from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';
import classNames from 'classnames';
import SelectCampaign from '@components/CampaignBreadcrumbs/SelectCampaign';

type dropdownTitleProps = {
  [id: string]: React.ReactElement;
};

const DropdownTitle: dropdownTitleProps = {
  shared: <EyePublicSvg className="eye" style={{ marginRight: 4 }} />,
  private: <EyePrivateSvg className="eye" style={{ marginRight: 4 }} />
};

function CampaignBreadcrumbs({ showSearch }: { showSearch: boolean }) {
  const params = useParams();
  const location = useLocation();

  const { currentOrganization } = useOrganization();
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const folder = useTypedSelector(({ assets }) => assets.selectedFolder);

  const [campaignId, setCampaignId] = useState<string | undefined>(undefined);
  const [folderId, setFolderId] = useState<string | undefined>(undefined);

  // const selectedFolder = useTypedSelector(
  //   ({ assetInCampaign }) => assetInCampaign.selectedFolder
  // );
  const dispatch = useDispatch();
  const [campaignAccess, setCampaignAccess] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!params['*']) return;
    const [_, campaignId, folderId] = params['*'].split('/');
    setCampaignId(campaignId);
    setFolderId(folderId);
  }, [params]);

  useEffect(() => {
    if (!campaign) {
      return;
    }
    const copy = campaign.isPrivate ? 'private' : 'shared';
    setCampaignAccess(copy);
  }, [campaign]);

  const upgradePrivateCampaigns =
    !currentOrganization.entity?.features.privateCampaigns;

  const toggleCampaignAccess = async (newType: string) => {
    if (!campaign) return;
    const newIsPrivateValue = newType === 'private';
    if (newIsPrivateValue && upgradePrivateCampaigns) return;
    await setCampaignVisibilityAction({
      campaignId: campaign?.id || '',
      isPrivate: newIsPrivateValue
    })(dispatch);

    if (newIsPrivateValue) {
      const newCampaign = {
        ...campaign,
        isPrivate: newIsPrivateValue
      };
      dispatch(
        toggleCampaignInfoModal({
          campaign: newCampaign,
          activeTab: 'members',
          visible: true
        })
      );
    }
    toast(
      <ActionToast
        title="Access updated"
        description={
          <>
            <span className="task">{campaign?.name}</span> is now {newType}
          </>
        }
        onUndo={async () => {
          await setCampaignVisibilityAction({
            campaignId: campaign?.id || '',
            isPrivate: !newIsPrivateValue
          })(dispatch);

          if (newIsPrivateValue) {
            const newCampaign = {
              ...campaign,
              isPrivate: !newIsPrivateValue
            };
            dispatch(
              toggleCampaignInfoModal({
                campaign: newCampaign,
                activeTab: 'members',
                visible: true
              })
            );
          }
        }}
        closeToast={undefined}
      />
    );
  };

  if (showSearch) return null;
  if (campaign?.id !== campaignId) return null;
  return (
    <div className="campaign_breadcrumbs">
      {campaignId && (
        <>
          <SelectCampaign currentCampaignId={campaignId} />
          <div className="campaign_breadcrumbs__body">
            <div
              className="campaign_breadcrumbs__item campaign_breadcrumbs__item_entry"
              onClick={() => navigate(`/campaigns/all`)}
            >
              <span className="item-name">Campaigns</span>
            </div>

            <div
              className={`campaign_breadcrumbs__item ${
                folder?.id ? '' : 'campaign_breadcrumbs__current_item'
              }`}
              onClick={() => navigate(`/campaigns/single/${campaign?.id}`)}
            >
              <div>
                <ArrowDownSvg
                  style={{
                    transform: 'rotate(-90deg)',
                    verticalAlign: 'middle'
                  }}
                />
              </div>
              <Tooltip
                title={campaign?.name}
                overlayClassName="toggle_favorite_overlay  toggle_favorite_overlay--small"
              >
                <div className="item-name">{campaign?.name}</div>
              </Tooltip>
            </div>
            {folder?.folder?.name && (
              <div className="campaign_breadcrumbs__item campaign_breadcrumbs__current_item">
                <div>
                  <ArrowDownSvg
                    style={{
                      transform: 'rotate(-90deg)',
                      verticalAlign: 'middle'
                    }}
                  />
                </div>
                <Tooltip
                  title={folder?.folder?.name}
                  overlayClassName="toggle_favorite_overlay   toggle_favorite_overlay--small"
                >
                  <div className="item-name">{folder?.folder?.name}</div>
                </Tooltip>
              </div>
            )}
          </div>
          {campaign?.permissions.setVisibility && campaignId && !folderId && (
            <div className="campaign_access-tag">
              <Dropdown
                overlayClassName="campaign_access_overlay"
                trigger={['click']}
                overlay={
                  <Menu
                    selectedKeys={[campaign?.isPrivate ? 'private' : 'public']}
                    onClick={({ key }) => toggleCampaignAccess(key)}
                    style={{ minWidth: 300 }}
                  >
                    <Menu.Item key="public">
                      <div>
                        <div className="item_title">
                          <EyePublicSvg />
                          Shared campaign
                        </div>
                        <div className="item_description">
                          Anyone in this workspace can access shared campaigns
                        </div>
                      </div>
                    </Menu.Item>
                    <Menu.Item key="private" disabled={upgradePrivateCampaigns}>
                      <Tooltip
                        title={
                          upgradePrivateCampaigns && (
                            <>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  if (
                                    !currentOrganization.entity?.permissions
                                      .billing
                                  )
                                    return;
                                  dispatch(
                                    toggleBillingModal({ visible: true })
                                  );
                                }}
                              >
                                Upgrade
                              </span>{' '}
                              to a Team plan to set campaign as private.
                            </>
                          )
                        }
                        overlayClassName="toggle_favorite_overlay"
                        trigger={['hover']}
                      >
                        <div
                          className={classNames(
                            upgradePrivateCampaigns && 'disabled'
                          )}
                        >
                          <div className="item_title">
                            <EyePrivateSvg />
                            Private campaign
                            {upgradePrivateCampaigns && (
                              <UpgradePlanSvg className="upgrade-icon" />
                            )}
                          </div>
                          <div className="item_description">
                            Only invited members and contributors can access
                            private campaigns
                          </div>
                        </div>
                      </Tooltip>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span>{DropdownTitle[campaignAccess]}</span>
              </Dropdown>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CampaignBreadcrumbs;
