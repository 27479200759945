import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { toggleRenameEntityModal } from '@redux/actions/modalAction';
import {
  renameAssetAction,
  renameFolderAction
} from '@redux/actions/assetsAction';
import { removeExtraSpaces } from '@helpers/stringTransformers';
import { AssetItemDto, CampaignItemDto } from '@api/Api';
import { renameCampaign } from '@redux/actions/campaignAction';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';

const COPIES = {
  folder: 'Rename folder',
  media: 'Rename media',
  campaign: 'Rename campaign'
};

function RenameEntityModal() {
  const [form] = Form.useForm();
  const assetNameRef = useRef<any>();
  const dispatch = useDispatch();
  const { user } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const [entityName, setEntityName] = useState<string>('');
  const [entityNameLengthInPx, setEntityNameLengthInPx] = useState<number>(0);
  const entityRenameModal = useTypedSelector(
    ({ modal }) => modal.entityRenameModal
  );

  const onFinish = async () => {
    const newName = removeExtraSpaces(entityName);

    if (entityRenameModal?.page === 'folder') {
      const asset = entityRenameModal?.entity as AssetItemDto;
      await renameFolderAction({ name: newName, folderId: asset?.id || '' })(
        dispatch
      );
    }

    if (entityRenameModal?.page === 'media') {
      const asset = entityRenameModal?.entity as AssetItemDto;
      if (!asset?.asset) return;
      const assetVersionId =
        entityRenameModal.versionId || asset.asset.versions[0].id;
      await renameAssetAction({
        name: newName,
        assetId: asset?.id || '',
        assetVersionId
      })(dispatch);
    }

    if (entityRenameModal?.page === 'campaign') {
      const campaign = entityRenameModal.entity as CampaignItemDto;
      await renameCampaign({ id: campaign.id, name: newName })(dispatch);
      await MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.MANAGE_CAMPAIGNS,
        {
          campaignName: newName,
          deleteCampaign: !!campaign.deletedAt,
          newCampaign: false,
          privateCampaign: campaign.isPrivate,
          sharedCampaign: !campaign.isPrivate,
          addTaskGroup: false,
          addContributor: false
        }
      );
    }
    dispatch(toggleRenameEntityModal(null));
  };

  useEffect(() => {
    form.setFieldsValue({ name: '' });
    setEntityName('');
    if (!entityRenameModal?.visible) return;
    if (entityRenameModal.page === 'folder') {
      const asset = entityRenameModal?.entity as AssetItemDto;
      form.setFieldsValue({ name: asset.folder?.name || '' });
      setEntityName(asset.folder?.name || '');
    }
    if (entityRenameModal.page === 'media') {
      const asset = entityRenameModal?.entity as AssetItemDto;
      if (!asset.asset) return;
      const assetName = entityRenameModal.versionId
        ? asset.asset.versions.find(
            (el) => el.id === entityRenameModal.versionId
          )?.name
        : asset.asset.versions[0].name;
      form.setFieldsValue({
        name: assetName
      });
      setEntityName(assetName || '');
    }
    if (entityRenameModal.page === 'campaign') {
      const campaign = entityRenameModal.entity as CampaignItemDto;
      form.setFieldsValue({
        name: campaign.name || ''
      });
      setEntityName(campaign.name || '');
    }
  }, [entityRenameModal]);

  useEffect(() => {
    setTimeout(() => {
      setEntityNameLengthInPx(assetNameRef.current?.offsetWidth ?? 0);
    }, 1);
  }, [entityName]);

  const assetExt = useCallback(() => {
    if (entityRenameModal?.page !== 'media') return;
    const asset = entityRenameModal?.entity as AssetItemDto;
    return asset.asset?.versions[0].extension;
  }, [entityRenameModal])();
  return (
    <Modal
      visible={entityRenameModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      onCancel={() => dispatch(toggleRenameEntityModal(null))}
    >
      <div className="modal_container">
        <Form form={form} onFinish={onFinish} validateTrigger="onChange">
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, marginBottom: '0.5em' }}
          >
            {COPIES[entityRenameModal?.page || 'folder']}
          </h2>
          <div style={{ position: 'relative', height: 78 }}>
            <Form.Item
              className="enitity-creation-modal__name-item enitity-creation-modal__name-item--counter"
              name="name"
              rules={[
                {
                  whitespace: true,
                  message: "'name' cannot be empty."
                },
                {
                  max: 60,
                  message: `${entityName.length}/60 characters. Name has exceeded character limit.`
                },
                {
                  pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                  message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                },
                {
                  required: true,
                  message: 'Name is required.'
                }
              ]}
            >
              <Input
                size="large"
                value={entityName}
                onChange={(e) => setEntityName(e.target.value)}
                showCount={{
                  formatter: ({ count }) => (
                    <span className="main-body-text main-body-text--semibold main-body-text--small main-body-text--dark-gray">
                      {count}/60 characters
                    </span>
                  )
                }}
                maxLength={60}
              />
            </Form.Item>
            {entityRenameModal?.page === 'media' && (
              <>
                {' '}
                <span
                  style={{
                    position: 'absolute',
                    top: 13,
                    zIndex: 2,
                    left: 13 + entityNameLengthInPx,
                    color: '#969696'
                  }}
                >
                  .{assetExt}
                </span>
                <span
                  style={{
                    whiteSpace: 'pre-wrap',
                    position: 'fixed',
                    display: 'inline-block',
                    height: 0,
                    overflow: 'hidden'
                  }}
                  ref={assetNameRef}
                >
                  {entityName}
                </span>
              </>
            )}
          </div>
          <br />
          <div className="modal_container__actions">
            <Button
              type="text"
              size="large"
              style={{
                marginRight: '16px',
                border: 'none',
                height: 48,
                padding: '8px 16px'
              }}
              onClick={() => dispatch(toggleRenameEntityModal(null))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 148, height: 48, padding: '8px 15px' }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default RenameEntityModal;
