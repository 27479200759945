import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { Button, Dropdown, Menu, Tooltip, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddMediaSVG } from '@assets/icons/add-media.svg';
import { ReactComponent as FavoriteSVG } from '@assets/icons/favorite.svg';
import { ReactComponent as EyePrivateSVG } from '@assets/icons/eye-private.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import moment from 'moment';

import './CampaignCard.less';
import { AssetPreviewDto, CampaignItemDto } from '@api/Api';
import { useDispatch } from 'react-redux';
import {
  restoreRemovedCampaign,
  setCurrentCampaign,
  setFavoriteCampaignAction
} from '@redux/actions/campaignAction';
import { updateCampaignsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import {
  toggleEntityDeletionModal,
  toggleCampaignInfoModal,
  toggleCampaignInvitationModal,
  toggleRenameEntityModal,
  toggleBillingModal
} from '@redux/actions/modalAction';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import Loader from '@components/Loader';
import { useCurrentWorkspace } from '@hooks/workspace';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

const CampaignCard = forwardRef<
  HTMLDivElement,
  { campaign: CampaignItemDto; page: string }
>(function CampaignCard({ campaign, page }, ref) {
  const [currentWorkspace] = useCurrentWorkspace();
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assetsArr: AssetPreviewDto[] = useMemo(
    () => campaign?.topAssets || [],
    [campaign]
  );
  const assetsCount: number = useMemo(
    () => campaign.totalAssetsCount || 0,
    [campaign]
  );

  return (
    <div
      ref={ref}
      onClick={() => {
        if (campaign.deletedAt) return;
        dispatch(setCurrentCampaign(campaign));
        navigate(`/campaigns/single/${campaign.id}`);
      }}
      className="campaign_card_container"
    >
      <Tooltip
        placement="bottomRight"
        overlayClassName="toggle_favorite_overlay"
        title={
          !campaign.deletedAt
            ? !campaign.isFavorite
              ? 'Add to favorite'
              : 'Remove from favorite'
            : ''
        }
      >
        <div
          className={classNames(
            campaign.deletedAt && 'favorite_icon_deleted',
            'favorite_icon'
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (campaign.deletedAt) return;
            if (!campaign) return;
            if (!campaign?.isFavorite) {
              dispatch(
                setFavoriteCampaignAction(
                  {
                    campaignId: campaign.id || '',
                    isFavorite: !campaign.isFavorite
                  },
                  false
                )
              );

              dispatch(
                updateCampaignsWorkspaceCounters(
                  campaign?.isFavorite ? 'unsetFavorite' : 'setFavorite',
                  campaign
                )
              );

              return;
            }
            dispatch(
              setFavoriteCampaignAction(
                {
                  campaignId: campaign.id || '',
                  isFavorite: !campaign.isFavorite
                },
                false
              )
            );

            dispatch(
              updateCampaignsWorkspaceCounters(
                campaign?.isFavorite ? 'unsetFavorite' : 'setFavorite',
                campaign
              )
            );
            toast(
              <ActionToast
                title="Campaign has been removed from favorites"
                onUndo={undefined}
                description={undefined}
                closeToast={undefined}
              />,
              {
                hideProgressBar: true,
                bodyClassName: 'toast_container--invitation'
              }
            );
          }}
        >
          <FavoriteSVG
            className={classNames(
              campaign.isFavorite ? 'favorite' : 'non_favorite'
            )}
          />
        </div>
      </Tooltip>
      <div
        className={classNames(
          'assets',
          `assets_${assetsCount > 4 ? 4 : assetsArr.length || 0}`,
          { isCampaignDeleted: campaign.deletedAt }
        )}
      >
        {assetsArr.length === 0 && (
          <div className="empty">
            <AddMediaSVG />
          </div>
        )}
        {assetsArr.map((el, idx) => {
          const hasMore = assetsCount > 4 && idx === 3;
          if (idx < 4) {
            const imageUrl = assetPreviewThumbnails({
              url: el.previewUrl || '',
              status: el.status,
              type: el.type,
              format: 'img'
            });

            return (
              <div key={idx} className={classNames('item')}>
                {hasMore && !campaign.isPrivate && (
                  <div className="more_overlay">+ {assetsCount - 4}</div>
                )}
                {typeof imageUrl === 'string' ? (
                  imageUrl === 'pending' ? (
                    <Loader
                      isSpinning
                      spinSize={40}
                      classNameWrapper="campaign_asset_loader"
                    />
                  ) : (
                    <img draggable={false} src={imageUrl || ''} alt="" />
                  )
                ) : (
                  imageUrl
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="campaign_info">
        <div className="title">
          <Tooltip
            placement="topLeft"
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
            title={campaign.name}
          >
            <div className="copy">
              {campaign.isPrivate && <EyePrivateSVG className="private" />}
              <span>{campaign.name}</span>
            </div>
          </Tooltip>
          <Dropdown
            disabled={!!campaign.deletedAt && !campaign.permissions.delete}
            overlay={
              <Menu style={{ minWidth: 200 }}>
                {campaign.deletedAt ? (
                  <>
                    {campaign.permissions.delete && (
                      <Menu.Item
                        key="Delete forever"
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          dispatch(
                            toggleEntityDeletionModal({
                              entity: campaign,
                              page: 'campaign',
                              permanent: true,
                              visible: true
                            })
                          );
                        }}
                      >
                        Delete forever
                      </Menu.Item>
                    )}
                    {campaign.permissions.delete && (
                      <Menu.Item
                        key="Restore"
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          dispatch(
                            restoreRemovedCampaign({ campaignId: campaign.id })
                          );
                          dispatch(
                            updateCampaignsWorkspaceCounters(
                              'restore',
                              campaign
                            )
                          );
                        }}
                      >
                        Restore
                      </Menu.Item>
                    )}
                  </>
                ) : (
                  <>
                    <Menu.Item
                      key="Campaign info"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        dispatch(
                          toggleCampaignInfoModal({
                            activeTab: 'details',
                            campaign,
                            visible: true
                          })
                        );
                      }}
                    >
                      Campaign info
                    </Menu.Item>
                    {campaign.permissions.changeDetails && (
                      <Menu.Item
                        key="Rename"
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          dispatch(
                            toggleRenameEntityModal({
                              entity: campaign,
                              page: 'campaign',
                              visible: true
                            })
                          );
                        }}
                      >
                        Rename
                      </Menu.Item>
                    )}
                    {campaign.permissions.inviteContributors && (
                      <Menu.Item
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          if (
                            !currentOrganization.entity?.features
                              .externalContributors
                          ) {
                            if (currentOrganization.entity?.permissions.billing)
                              dispatch(toggleBillingModal({ visible: true }));
                            return;
                          }
                          dispatch(
                            toggleCampaignInvitationModal({
                              visible: true,
                              campaign
                            })
                          );
                        }}
                        key="Add сontributor"
                      >
                        <UpgradePlanTooltip
                          text=" to a paid plan to add external contributors."
                          isCanVisible={
                            !currentOrganization.entity?.features
                              .externalContributors
                          }
                          placement="right"
                          innerStyle={{
                            transform: 'translate(-30px, 0)'
                          }}
                        >
                          <div
                            style={
                              !currentOrganization.entity?.features
                                .externalContributors
                                ? {
                                    display: 'flex',
                                    alignItems: 'center'
                                  }
                                : undefined
                            }
                          >
                            Add сontributor{' '}
                            {!currentOrganization.entity?.features
                              .externalContributors && (
                              <UpgradePlanSvg style={{ marginLeft: 4 }} />
                            )}
                          </div>
                        </UpgradePlanTooltip>
                      </Menu.Item>
                    )}
                    {campaign?.permissions.changeAdditionalPermissions && (
                      <Menu.Item
                        key="Сontributor permissions"
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          if (
                            !currentOrganization.entity?.features
                              .externalContributors
                          ) {
                            if (currentOrganization.entity?.permissions.billing)
                              dispatch(toggleBillingModal({ visible: true }));
                            return;
                          }
                          dispatch(
                            toggleCampaignInfoModal({
                              activeTab: 'settings',
                              campaign,
                              visible: true
                            })
                          );
                        }}
                      >
                        <UpgradePlanTooltip
                          text=" to a paid plan to manage contributor permissions."
                          isCanVisible={
                            !currentOrganization.entity?.features
                              .externalContributors
                          }
                          placement="right"
                          innerStyle={{
                            transform: 'translate(-30px, 0)'
                          }}
                        >
                          <div
                            style={
                              !currentOrganization.entity?.features
                                .externalContributors
                                ? {
                                    display: 'flex',
                                    alignItems: 'center'
                                  }
                                : undefined
                            }
                          >
                            Сontributor permissions{' '}
                            {!currentOrganization.entity?.features
                              .externalContributors && (
                              <UpgradePlanSvg style={{ marginLeft: 4 }} />
                            )}
                          </div>
                        </UpgradePlanTooltip>
                      </Menu.Item>
                    )}

                    <Menu.Item
                      key="Toggle favorite"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        if (!campaign) return;
                        if (!campaign?.isFavorite) {
                          dispatch(
                            setFavoriteCampaignAction(
                              {
                                campaignId: campaign.id || '',
                                isFavorite: !campaign.isFavorite
                              },
                              false
                            )
                          );

                          dispatch(
                            updateCampaignsWorkspaceCounters(
                              campaign?.isFavorite
                                ? 'unsetFavorite'
                                : 'setFavorite',
                              campaign
                            )
                          );

                          return;
                        }

                        dispatch(
                          setFavoriteCampaignAction(
                            {
                              campaignId: campaign.id || '',
                              isFavorite: !campaign.isFavorite
                            },
                            false
                          )
                        );

                        dispatch(
                          updateCampaignsWorkspaceCounters(
                            campaign?.isFavorite
                              ? 'unsetFavorite'
                              : 'setFavorite',
                            campaign
                          )
                        );
                        toast(
                          <ActionToast
                            title="Campaign has been removed from favorites"
                            onUndo={undefined}
                            description={undefined}
                            closeToast={undefined}
                          />,
                          {
                            hideProgressBar: true,
                            bodyClassName: 'toast_container--invitation'
                          }
                        );
                      }}
                    >
                      {!campaign.isFavorite
                        ? 'Add to favorite'
                        : 'Remove from favorite'}
                    </Menu.Item>
                    <Menu.Item
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        navigator.clipboard.writeText(
                          `${window.location.origin}/campaigns/single/${campaign.id}`
                        );
                        message.success({
                          content: 'Copied link to clipboard',
                          className: 'message-dark-modal',
                          icon: <SuccessSvg className="anticon" />
                        });
                      }}
                    >
                      Share campaign link
                    </Menu.Item>
                    {campaign.permissions.delete && (
                      <Menu.Item
                        key="Delete"
                        onClick={({ domEvent }) => {
                          domEvent.stopPropagation();
                          dispatch(
                            toggleEntityDeletionModal({
                              entity: campaign,
                              page: 'campaign',
                              permanent: false,
                              visible: true
                            })
                          );
                        }}
                      >
                        Delete
                      </Menu.Item>
                    )}
                  </>
                )}
              </Menu>
            }
            overlayClassName="campaign_menu_overlay"
            placement="bottomLeft"
            trigger={['click']}
          >
            <Button
              type="text"
              size="small"
              style={{ padding: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <ActionSvg style={{ marginRight: 0 }} />
            </Button>
          </Dropdown>
        </div>
        <div className="edited">
          {campaign.deletedAt
            ? moment(campaign.deletedAt).fromNow()
            : `Edited ${moment(campaign.lastEventTime).fromNow()}`}
        </div>
      </div>
    </div>
  );
});

export default CampaignCard;
