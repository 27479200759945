import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import FlipMove from 'react-flip-move';
import { nonNull } from '@helpers/non-null';
import { useDebounced } from '@helpers/useDebounced';
import { DraggableItem } from './DraggableItem';
import './DraggableList.less';

interface DraggableListProps {
  gap?: number;
  disableReorder?: boolean;
  containerClassName?: string;
  className?: string;
  onReorder?: (e: ReorderEvent) => void;
  onDropInside?: (e: DropInsideEvent) => void;
  flipDuration?: number;
  children: React.ReactElement[];
}

export type ReorderEvent = {
  item: { id: string; type: string };
  prevIndex: number;
  newIndex: number;
};

export type DropInsideEvent = {
  item: { id: string; type: string };
  insideItem: { id: string; type: string };
};

type DragPosition =
  | 'center'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

type Dragging = {
  id: string;
  type: string;
};

type Over = {
  id: string;
  type: string;
  position: DragPosition;
};

export function DraggableList(props: DraggableListProps) {
  const {
    gap,
    containerClassName,
    className,
    onReorder,
    onDropInside,
    flipDuration,
    children
  } = props;
  const [nativeDragging, setNativeDragging] = useState<Dragging | null>(null);
  const [foreignDragging, setForeignDragging] = useState<Dragging | null>(null);
  const foreignDraggingRef = useRef(foreignDragging);
  foreignDraggingRef.current = foreignDragging;
  const dragging = nativeDragging ?? foreignDragging;

  const [over, setOver] = useState<Over | null>(null);

  const disableReorder =
    props.disableReorder || (!nativeDragging && !!foreignDragging);
  const debouncedOver = useDebounced(over, disableReorder ? 0 : 250);

  const isOverDroppable = checkOverDroppable(debouncedOver, dragging, children);
  const isDroppingInside =
    isOverDroppable && (debouncedOver?.position === 'center' || disableReorder);

  const childrenRef = useRef(children);
  childrenRef.current = children;
  const initialOrder = useRef(children.map((child) => child.key));
  initialOrder.current = children.map((child) => child.key);
  const [order, setOrder] = useState(initialOrder.current);
  const orderRef = useRef(order);
  orderRef.current = order;

  const oneItemChangeAnimation = useMemo(() => {
    // Enable animation if adding/removing one item
    const prevChildrenKeys = new Set(orderRef.current);
    const newChildrenKeys = new Set(initialOrder.current);
    const allKeys = new Set([
      ...Array.from(prevChildrenKeys),
      ...Array.from(newChildrenKeys)
    ]);
    const commonKeys = new Set(
      [...Array.from(prevChildrenKeys)].filter((key) =>
        newChildrenKeys.has(key)
      )
    );
    const diffKeys = new Set(
      [...Array.from(allKeys)].filter((key) => !commonKeys.has(key))
    );
    if (diffKeys.size === 1) {
      const diffKey = Array.from(diffKeys)[0];
      const prevOrder = Array.from(prevChildrenKeys).filter(
        (key) => key !== diffKey
      );
      const newOrder = Array.from(newChildrenKeys).filter(
        (key) => key !== diffKey
      );
      const isOrderSame = prevOrder.every(
        (key, index) => newOrder[index] === key
      );
      if (isOrderSame) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const [animationEnabled, setAnimationEnabled] = useState(false);
  const isAnimating = useRef(false);
  const prevOneItemChangeAnimation = useRef(oneItemChangeAnimation);
  if (oneItemChangeAnimation !== prevOneItemChangeAnimation.current) {
    prevOneItemChangeAnimation.current = oneItemChangeAnimation;
    setAnimationEnabled(oneItemChangeAnimation);
  }

  useEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      setAnimationEnabled(false);
    });
    // Reset order
    setOrder(initialOrder.current);
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [children]);

  const orderedChildren = order
    .map((key) => children.find((child) => child.key === key))
    .filter((x) => !!x)
    .map((x) => nonNull(x));

  const disableReorderRef = useRef(disableReorder);
  disableReorderRef.current = disableReorder;

  useEffect(() => {
    setAnimationEnabled(true);
    const animationFrameId = requestAnimationFrame(() => {
      setAnimationEnabled(false);
    });
    const over = debouncedOver;
    if (!nativeDragging || !over || disableReorderRef.current) {
      setOrder(initialOrder.current);
      return;
    }
    if (over.position === 'center') return;

    const draggingKey = childrenRef.current.find(
      (x) => x.type === DraggableItem && x.props.id === nativeDragging.id
    )?.key;
    const overKey = childrenRef.current.find(
      (x) => x.type === DraggableItem && x.props.id === over.id
    )?.key;
    if (!draggingKey || !overKey) return;

    const currentIndex = orderRef.current.indexOf(draggingKey);
    const overIndex = orderRef.current.indexOf(overKey);
    const adjustedOverIndex =
      overIndex > currentIndex ? overIndex - 1 : overIndex;
    if (currentIndex !== -1 && overIndex !== -1 && currentIndex !== overIndex) {
      const newOrder = [...orderRef.current];
      const [removed] = newOrder.splice(currentIndex, 1);
      if (
        over.position === 'left' ||
        over.position === 'top' ||
        over.position === 'top-left' ||
        over.position === 'bottom-left'
      ) {
        newOrder.splice(adjustedOverIndex, 0, removed);
      } else if (
        over.position === 'right' ||
        over.position === 'bottom' ||
        over.position === 'top-right' ||
        over.position === 'bottom-right'
      ) {
        newOrder.splice(adjustedOverIndex + 1, 0, removed);
      }
      setOrder(newOrder);
    }
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [nativeDragging, debouncedOver]);

  useEffect(() => {
    if (isDroppingInside) {
      document.body.classList.add('draggable-list--dropping-inside');
    } else document.body.classList.remove('draggable-list--dropping-inside');
  }, [isDroppingInside]);

  const ref = useRef<HTMLDivElement>(null);
  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      const type = e.dataTransfer.types
        .find((x) => x.startsWith('streamwork://type/'))
        ?.split('/')
        .slice(-1)[0];
      const id = e.dataTransfer.types
        .find((x) => x.startsWith('streamwork://id/'))
        ?.split('/')
        .slice(-1)[0];

      if (!type || !id) return null;

      const isForeignItem = !childrenRef.current.some(
        (x) =>
          x.type === DraggableItem && x.props.id === id && x.props.type === type
      );
      if (
        isForeignItem &&
        (foreignDraggingRef.current?.id !== id ||
          foreignDraggingRef.current?.type !== type)
      ) {
        const foreignDragging = { id, type };
        setForeignDragging(foreignDragging);
        foreignDraggingRef.current = foreignDragging;
      }
      const canReorder = !disableReorder && !isForeignItem;

      const listEl = nonNull(ref.current);
      const items = Array.from(
        listEl.querySelectorAll(':scope > div > .draggable-item')
      ) as HTMLElement[];
      const pointerX = e.clientX;
      const pointerY = e.clientY;
      const maxBorderOffset = nonNull(gap) + 1;
      const closestItem = items.reduce(
        (closest, child) => {
          const childId = nonNull(child.getAttribute('data-id'));
          const childType = nonNull(child.getAttribute('data-type'));
          const reactEl = childrenRef.current.find(
            (x) =>
              x.type === DraggableItem &&
              x.props.id === childId &&
              x.props.type === childType
          );
          if (!reactEl) return closest;
          const canDropInside =
            (reactEl.props.droppable === true && reactEl.props.type === type) ||
            (typeof reactEl.props.droppable === 'string' &&
              reactEl.props.droppable === type) ||
            (Array.isArray(reactEl.props.droppable) &&
              reactEl.props.droppable.includes(type));
          const isSupported = canDropInside || canReorder;
          if (!isSupported) return closest;

          const box = child.getBoundingClientRect();
          const verticalOffset = pointerY - box.top - box.height / 2;
          const horizontalOffset = pointerX - box.left - box.width / 2;
          const centerOffset = Math.sqrt(
            verticalOffset ** 2 + horizontalOffset ** 2
          );
          const isInBox =
            pointerX > box.left &&
            pointerX < box.right &&
            pointerY > box.top &&
            pointerY < box.bottom;
          let borderOffset = Number.POSITIVE_INFINITY;
          if (pointerY < box.top) {
            borderOffset = Math.abs(box.top - pointerY); // distance to top border
          } else if (pointerY > box.bottom) {
            borderOffset = Math.abs(box.bottom - pointerY); // distance to bottom border
          } else if (pointerX < box.left) {
            borderOffset = Math.abs(box.left - pointerX); // distance to left border
          } else if (pointerX > box.right) {
            borderOffset = Math.abs(box.right - pointerX); // distance to right border
          }
          if (
            centerOffset < closest.offset &&
            (borderOffset < maxBorderOffset || isInBox)
          ) {
            return { offset: centerOffset, element: child };
          }
          return closest;
        },
        {
          offset: Number.POSITIVE_INFINITY,
          element: null as HTMLElement | null
        }
      ).element;
      if (closestItem !== null) {
        const id = nonNull(closestItem.getAttribute('data-id'));
        const type = nonNull(closestItem.getAttribute('data-type'));
        const box = closestItem.getBoundingClientRect();
        const borderArea = 1 / 4;
        const isTop = e.clientY < box.top + box.height * borderArea;
        const isBottom = e.clientY > box.bottom - box.height * borderArea;
        const isLeft = e.clientX < box.left + box.width * borderArea;
        const isRight = e.clientX > box.right - box.width * borderArea;
        let edge: DragPosition;

        if (isTop) {
          edge = isLeft ? 'top-left' : 'top-right';
        } else if (isBottom) {
          edge = isLeft ? 'bottom-left' : 'bottom-right';
        } else if (isLeft) {
          edge = 'left';
        } else if (isRight) {
          edge = 'right';
        } else {
          edge = 'center';
        }
        return { id, type, position: edge };
      }
      return null;
    },
    [gap, disableReorder]
  );

  const dragEnterCount = useRef(0);

  return (
    <div
      ref={ref}
      className={classNames(
        'draggable-list',
        containerClassName,
        nativeDragging ? 'draggable-list--dragging' : null,
        disableReorder ? null : 'draggable-list--reorder-enabled'
      )}
      style={{ '--column-gap': `${gap}px` } as any}
      onDragOver={async (e) => {
        e.preventDefault();
        if (isAnimating.current) {
          return;
        }
        const newOver = onDragOver(e);
        e.dataTransfer.dropEffect = newOver ? 'move' : 'none';
        if (
          newOver?.id !== over?.id ||
          newOver?.type !== over?.type ||
          newOver?.position !== over?.position
        ) {
          setOver(newOver);
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        dragEnterCount.current++;
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        dragEnterCount.current--;
        if (dragEnterCount.current === 0) {
          setOver(null);
          setForeignDragging(null);
        }
      }}
      onDrop={(e) => {
        e.preventDefault();
        dragEnterCount.current = 0;
        setOver(null);
        setNativeDragging(null);
        setForeignDragging(null);
        if (
          onReorder &&
          nativeDragging &&
          over &&
          !isDroppingInside &&
          !disableReorder
        ) {
          const draggingKey = childrenRef.current.find(
            (x) => x.type === DraggableItem && x.props.id === nativeDragging.id
          )?.key;
          if (!draggingKey) return;
          const prevIndex = initialOrder.current.indexOf(draggingKey);
          const newIndex = order.indexOf(draggingKey);
          if (prevIndex === -1 || newIndex === -1) return;
          if (prevIndex === newIndex) return;
          onReorder({
            item: { id: nativeDragging.id, type: nativeDragging.type },
            prevIndex,
            newIndex
          });
        }
        if (onDropInside && dragging && over && isDroppingInside) {
          onDropInside({
            item: { id: dragging.id, type: dragging.type },
            insideItem: { id: over.id, type: over.type }
          });
        }
      }}
    >
      <FlipMove
        className={className}
        duration={flipDuration}
        disableAllAnimations={!animationEnabled && !isAnimating.current}
        onStartAll={() => {
          isAnimating.current = true;
        }}
        onFinishAll={() => {
          isAnimating.current = false;
        }}
      >
        {orderedChildren.map((child) => {
          if (child.type === DraggableItem) {
            const { id, type } = child.props;
            const isDragging =
              nativeDragging?.id === id && nativeDragging?.type === type;
            const isOver = over?.id === id && over?.type === type;
            return React.cloneElement(child, {
              _isDragging: isDragging,
              _isOver: isOver ? nonNull(over).position : false,
              _isDropTarget: isOver && isDroppingInside,
              _onDragStart: (e: React.DragEvent) => {
                e.dataTransfer.dropEffect = 'move';
                setNativeDragging({ id, type });
                setForeignDragging(null);
                setOver(null);
              },
              _onDragEnd: () => {
                dragEnterCount.current = 0;
                setOver(null);
                setNativeDragging(null);
                setForeignDragging(null);
              }
            });
          }
          return child;
        })}
      </FlipMove>
    </div>
  );
}

DraggableList.defaultProps = {
  flipDuration: 350,
  gap: 16
};

function checkOverDroppable(
  over: Over | null,
  dragging: Dragging | null,
  children: React.ReactElement[]
) {
  return (
    !!over &&
    !!dragging &&
    (over.id !== dragging.id || over.type !== dragging.type) &&
    children.some(
      (x) =>
        x.type === DraggableItem &&
        x.props.id === over.id &&
        x.props.type === over.type &&
        ((x.props.droppable === true && x.props.type === dragging.type) ||
          (typeof x.props.droppable === 'string' &&
            x.props.droppable === dragging.type) ||
          (Array.isArray(x.props.droppable) &&
            x.props.droppable.includes(dragging.type)))
    )
  );
}
