import { Modal } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Video from '@pages/Start/components/Modals/WatchVideoModal/Video';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

import { OnboardingStepsType } from '@context/OnboardingProcessProvider';
import { StartOnboardingSteps } from '@helpers/StartOnboardingSteps';

type WatchVideoModalProps = {
  visible: OnboardingStepsType | false;
  setVisible: (v: OnboardingStepsType | false) => void;
};

export default memo(function WatchVideoModal({
  visible,
  setVisible
}: WatchVideoModalProps) {
  const [src, setSrc] = useState<unknown>();

  const title = useMemo(() => {
    if (!visible) return '';
    return StartOnboardingSteps.find((it) => it.type === visible)?.title;
  }, [visible]);

  const onCloseModal = useCallback(() => {
    if (!visible) return;
    setVisible(false);
  }, [visible, setVisible]);

  useEffect(() => {
    (async () => {
      if (!visible) return;
      const { default: src } = await import(
        `../../../../../assets/videos/${visible}.mp4`
      );
      setSrc(src);
    })();
    return () => {
      setSrc(undefined);
    };
  }, [visible]);

  return (
    <Modal
      open={!!visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={883}
      onCancel={onCloseModal}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{
            fontSize: 32,
            fontWeight: 700,
            marginBottom: '0.5em'
          }}
        >
          {title}
        </h2>

        <div className="modal_container__watch-video">
          <Video src={src as string} type={visible} />
        </div>
      </div>
    </Modal>
  );
});
