import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { useTypedSelector } from '@hooks';
import { Button } from 'antd';
import { toast } from 'react-toastify';
import { WorkspaceNoAvailableToast } from '@components/Toasts';

import { ReactComponent as MagicianSvg } from '@assets/icons/magician.svg';

import { Navigate, useParams } from 'react-router-dom';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import { toggleWorkspaceCreationModal } from '@redux/actions/modalAction';
import { AssetsList } from '@components/Assets';
import { loadMoreAssets, setAssetList } from '@redux/actions/assetsAction';
import { AssetsActionTypes } from '@redux/types/assetsType';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import LottieComponent from '@components/Lottie';
import ApprovalsCard from '@pages/Dashboard/components/ApprovalsCard/ApprovalsCard';
import TasksCard from '@pages/Dashboard/components/TasksCard/TasksCard';

function DashboardNoWorkspace() {
  const dispatch = useDispatch();
  return (
    <div className="dashboard_container__welcome">
      <div className="description">
        <div className="icon">
          <MagicianSvg />
        </div>
        <div className="copy">
          <div className="copy__title">Welcome!</div>
          <div className="copy__description">
            To start using StreamWork, you need to create a workspace.
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          dispatch(
            toggleWorkspaceCreationModal({ isEditing: false, visible: true })
          );
        }}
        type="primary"
        size="large"
      >
        Create workspace
      </Button>
    </div>
  );
}

function useTrackDashboardTime() {
  const { user } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const mixpanelRef = useRef(mixpanel);
  mixpanelRef.current = mixpanel;

  const userId = user.id;
  useEffect(() => {
    let usageTimerStopped = true;
    const startUsageTimer = () => {
      if (!usageTimerStopped) return;
      usageTimerStopped = false;
      MixpanelService.timeEvent(
        mixpanelRef.current,
        userId,
        MixpanelEventType.DASHBOARD_USAGE
      );
    };
    startUsageTimer();
    const stopUsageTimer = () => {
      if (usageTimerStopped) return;
      usageTimerStopped = true;
      MixpanelService.track(
        mixpanelRef.current,
        userId,
        MixpanelEventType.DASHBOARD_USAGE
      );
    };
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        stopUsageTimer(); // Send event when the user switches to the different tab
      } else if (document.visibilityState === 'visible') {
        startUsageTimer(); // Restart timer when the user comes back to the current tab
      }
    };
    const onPageHide = () => {
      stopUsageTimer(); // Send event when the user navigates away, reload or close the page
    };
    document.addEventListener('visibilitychange', onVisibilityChange, {
      capture: true
    });
    window.addEventListener('pagehide', onPageHide, { capture: true });
    const timeoutId = setTimeout(() => {
      // Also send event every N minutes,
      // so we don't need to wait too long till the user leaves the dashboard
      if (document.visibilityState === 'visible') {
        stopUsageTimer();
        startUsageTimer();
      }
    }, 15 * 60 * 1000);
    return () => {
      stopUsageTimer(); // Send event when the user navigates away by react router
      clearTimeout(timeoutId);
      document.removeEventListener('visibilitychange', onVisibilityChange, {
        capture: true
      });
      window.removeEventListener('pagehide', onPageHide, { capture: true });
    };
  }, [userId]);
}

function DashboardInWorkspace() {
  useTrackDashboardTime();
  const dispatch = useDispatch();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const assetsData = useTypedSelector(({ assets }) => assets.files);
  const recentlyOpenedAssetsCount = useTypedSelector(
    ({ workspaceCounters }) => workspaceCounters.recentlyOpenedAssetsCount
  );
  const [countToFetchFiles, setCountToFetchFiles] = useState<number>(0);

  useEffect(() => {
    if (!currentWorkspace?.id) return;
    (async () => {
      await setAssetList({
        workspaceId: currentWorkspace?.id,
        orderBy: ['openedAt:DESC'],
        flatten: true,
        isFolder: false,
        isOpened: true
      })(dispatch);
    })();

    return () => {
      dispatch({
        type: AssetsActionTypes.SET_ASSETS_LIST,
        payload: { edges: null, count: null }
      });
    };
  }, [currentWorkspace]);

  const loadMore = async () => {
    if (!currentWorkspace?.id) return;

    const count = Math.min(
      (assetsData?.count || 0) - (assetsData?.edges?.length || 0),
      25
    );
    setCountToFetchFiles(count);

    await loadMoreAssets({
      workspaceId: currentWorkspace?.id,
      orderBy: ['openedAt:DESC'],
      flatten: true,
      isFolder: false,
      isOpened: true,
      after: assetsData.endCursor
    })(dispatch);
    setCountToFetchFiles(0);
  };

  return (
    <div className="dashboard_container__main" id="MAIN_LAYOUT">
      <TasksCard />
      <ApprovalsCard />
      <div className="card_container">
        <div className="card_container__header" style={{ border: 'none' }}>
          <span className="copy">
            <LottieComponent className="icon" isLooped={false} view="star" />
            Recently Opened Media
          </span>
        </div>
        <div
          className="card_container__body"
          style={{ height: 'auto', borderTop: '2px solid #161819' }}
        >
          <InfiniteScroll
            dataLength={assetsData.edges?.length || 0}
            next={loadMore}
            hasMore={assetsData.hasNext || false}
            loader={<></>}
            scrollableTarget="MAIN_LAYOUT"
            style={{
              overflowX: 'hidden'
            }}
          >
            <AssetsList
              searchQuery=""
              assetsData={assetsData}
              displayAssetsCount={recentlyOpenedAssetsCount}
              isTileView={true}
              page="recent"
              countToFetch={countToFetchFiles}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

function Dashboard() {
  const { user } = useAuth(true, 'internal');
  const { workspaceId: workspaceIdParam } = useParams();

  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const [loading, setLoading] = useState<boolean>(true);

  const workspaceId =
    workspaceIdParam ?? currentWorkspace?.id ?? defaultWorkspace?.id;

  useEffect(() => {
    (async () => {
      try {
        if (workspaceId) await setCurrentWorkspace(workspaceId);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [workspaceId, setCurrentWorkspace, currentWorkspace?.id, user.id]);

  useEffect(() => {
    if (!currentWorkspace && !defaultWorkspace && !loading) {
      toast(<WorkspaceNoAvailableToast />, {
        hideProgressBar: true,
        style: { height: 66, width: 420 }
      });
    }
  }, [currentWorkspace, defaultWorkspace, loading]);

  const needRedirect = !!workspaceId && workspaceId !== workspaceIdParam;

  if (needRedirect) {
    return <Navigate to={workspaceId} replace />;
  }

  return (
    <div className="dashboard_container">
      {!defaultWorkspace && <DashboardNoWorkspace />}
      {!!currentWorkspace && <DashboardInWorkspace />}
    </div>
  );
}

export default Dashboard;
