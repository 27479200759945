import React, { useState, useEffect, useCallback } from 'react';
import { AutoComplete, Input, Dropdown, Menu, Button, Spin } from 'antd';
import { ReactComponent as Audio } from '@assets/icons/PlatformSearch/audio.svg';
import { ReactComponent as CampaignsSvg } from '@assets/icons/PlatformSearch/campaigns.svg';
import { ReactComponent as Document } from '@assets/icons/PlatformSearch/document.svg';
import { ReactComponent as TasksSvg } from '@assets/icons/tasks.svg';
import { ReactComponent as CommentSvg } from '@assets/icons/PlatformSearch/comment.svg';

import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as HistorySvg } from '@assets/icons/history-search.svg';
import './SearchBar.less';
import {
  workspaceSearchAssetComments,
  workspaceSearchAssetCommentsCount,
  workspaceSearchWorkspaceItems
} from '@api/Workspace';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  AssetItemDto,
  AssetVersionCommentSearchItemDto,
  CampaignItemDto,
  TaskItemDto
} from '@api/Api';
import { getAssetCount, getAssetList } from '@api/Asset';
import { getCampaignCount, getCampaignList } from '@api/Campaign';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openTaskModal } from '@redux/reducers/tasks';
import { apiClient } from '@api/client';

const RECENT_ITEMS_KEY = 'recent-items';

function SearchBar({
  showSearch,
  setShowSearch
}: {
  showSearch: boolean;
  setShowSearch: (a: boolean) => void;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [recent, setRecent] = useState<string[]>([]);
  const [currentWorkspace] = useCurrentWorkspace(false);
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);
  const [entryFilter, setEntryFilter] = useState<
    'Everything' | 'Campaigns' | 'Media' | 'Tasks' | 'Comments'
  >('Everything');
  const [backToResults, setBackToResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [apiItems, setApiItems] = useState<any>(null);
  const [searchedItems, setSearchedItems] = useState<any>([]);

  useEffect(() => {
    let recent: any = localStorage.getItem(RECENT_ITEMS_KEY);
    if (recent) recent = JSON.parse(recent);
    else recent = [];
    setRecent(recent);
  }, []);

  const handleSearchClick = () => {
    setShowSearch(true);
    setIsSearchOverlayOpen(true);
  };
  const onSearch = (searchText: string) => {
    setIsSearchOverlayOpen(true);
    setSearchValue(searchText);
    setBackToResults(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!searchValue) return;
      let newRecent = [searchValue, ...recent].filter((el, idx) => idx <= 25);
      newRecent = [...new Set(newRecent)];

      setRecent(newRecent);
      localStorage.setItem(RECENT_ITEMS_KEY, JSON.stringify(newRecent));
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const onSelect = (value: string) => {
    if (!value || apiItems) {
      setSearchValue('');
      setShowSearch(false);
      setEntryFilter('Everything');
      return;
    }

    setSearchValue(value);
  };
  const renderHead = useCallback(
    (count: number) => {
      return (
        <div
          style={{ position: 'sticky', top: '1px' }}
          className="search_bar_auto_complete__head"
        >
          <div className="items_count">{count} results </div>
          <Dropdown
            overlay={
              <Menu
                selectedKeys={[entryFilter]}
                onClick={({ key }) => {
                  setBackToResults(false);
                  setEntryFilter(key as any);
                }}
              >
                <Menu.Item key="Everything">Everything</Menu.Item>
                <Menu.Item key="Campaigns">Campaigns</Menu.Item>
                <Menu.Item key="Assets">Media</Menu.Item>
                <Menu.Item key="Tasks">Tasks</Menu.Item>
                <Menu.Item key="Comments">Comments</Menu.Item>
              </Menu>
            }
            overlayClassName="items_selecter_menu_overlay"
            placement="bottomRight"
            trigger={['click']}
          >
            <div className="items_selecter">{entryFilter || 'Everything'}</div>
          </Dropdown>
        </div>
      );
    },
    [entryFilter]
  );
  const renderMore = useCallback(
    (entity: 'Everything' | 'Campaigns' | 'Media' | 'Tasks' | 'Comments') => (
      <div
        className="search_bar_auto_complete__toggle_more_results"
        onClick={() => {
          setBackToResults(!backToResults);
          setEntryFilter(backToResults ? 'Everything' : entity);
        }}
      >
        {backToResults ? 'Back to all results' : 'Show more'}
      </div>
    ),
    [backToResults]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!searchValue || !currentWorkspace) {
        setApiItems(null);
        const options = recent.map((el) => ({
          value: el,
          label: (
            <>
              <HistorySvg />
              <span>{el}</span>
            </>
          )
        }));
        const _recent = {
          options,
          label: 'Recent searches'
        };
        setSearchedItems([_recent]);
        setLoading(false);
        return;
      }
      let _data;
      let count = 0;
      if (entryFilter === 'Everything') {
        const { data } = await workspaceSearchWorkspaceItems({
          searchQuery: searchValue,
          workspaceId: currentWorkspace.id
        });
        setApiItems(data);

        _data = Object.keys(data)
          .map((key: string) => {
            if (key === 'assets')
              return {
                label: 'Media',
                count: data.assetsCount,
                options: data[key]
              };
            if (key === 'campaigns')
              return {
                label: 'Campaigns',
                count: data.campaignsCount,
                options: data[key]
              };
            if (key === 'tasks')
              return {
                label: 'Tasks',
                count: data.tasksCount,
                options: data[key]
              };
            if (key === 'comments')
              return {
                label: 'Comments',
                count: data.commentsCount,
                options: data[key]
              };
            return null;
          })
          .filter((el) => el?.count);
        if (data) {
          count +=
            data.assetsCount +
            data.campaignsCount +
            data.commentsCount +
            data.tasksCount;
        }
      }
      if (entryFilter === 'Media') {
        const { data } = await getAssetList({
          searchQuery: searchValue,
          workspaceId: currentWorkspace?.id || '',
          flatten: true
        });
        const assetsCount = await getAssetCount({
          searchQuery: searchValue,
          workspaceId: currentWorkspace?.id || '',
          flatten: true
        });
        count = assetsCount.data.count;
        setApiItems(data);
        _data = [
          {
            label: entryFilter,
            count,
            options: data.edges.map((el) => el.node)
          }
        ];
      }
      if (entryFilter === 'Campaigns') {
        const { data } = await getCampaignList({
          workspaceId: currentWorkspace?.id || '',
          searchQuery: searchValue
        });
        const campaignCount = await getCampaignCount({
          searchQuery: searchValue,
          workspaceId: currentWorkspace?.id || ''
        });
        count = campaignCount.data.count;
        setApiItems(data);
        _data = [
          {
            label: entryFilter,
            count,
            options: data.edges.map((el) => el.node)
          }
        ];
      }
      if (entryFilter === 'Tasks') {
        const { data } = await apiClient.task.taskControllerListTasks({
          workspaceId: currentWorkspace?.id || '',
          searchQuery: searchValue
        });
        const campaignCount = await apiClient.task.taskControllerCountTasks({
          searchQuery: searchValue,
          workspaceId: currentWorkspace?.id || ''
        });
        count = campaignCount.data.count;
        setApiItems(data);
        _data = [
          {
            label: entryFilter,
            count,
            options: data.edges.map((el) => el.node)
          }
        ];
      }
      if (entryFilter === 'Comments') {
        const { data } = await workspaceSearchAssetComments({
          workspaceId: currentWorkspace?.id || '',
          searchQuery: searchValue
        });
        const commentsCount = await workspaceSearchAssetCommentsCount({
          searchQuery: searchValue,
          workspaceId: currentWorkspace?.id || ''
        });
        count = commentsCount.data.count;
        setApiItems(data);
        _data = [
          {
            label: entryFilter,
            count,
            options: data.edges.map((el) => el.node)
          }
        ];
      }

      if (!_data) {
        setLoading(false);
        return;
      }
      const IT = _data.map((it) => {
        if (!it)
          return {
            label: '',
            options: []
          };

        const hasMore = it.options.length < it.count;
        const label = renderTitle(it.label);
        const options: any = it.options.map((el: any, idx: any) => {
          return renderItem(el, it.label as any);
        });

        if (entryFilter === 'Everything' && hasMore) {
          options.push({ label: renderMore(it.label as any), options: [] });
        }

        if (backToResults)
          options.push({ label: renderMore('Everything'), options: [] });

        return {
          label,
          options
        };
      });

      const options = [{ label: renderHead(count), options: [] }, ...IT];
      setLoading(false);
      setSearchedItems(options);
    })();
  }, [
    currentWorkspace,
    renderHead,
    renderMore,
    backToResults,
    searchValue,
    entryFilter
  ]);

  const renderTitle = (title: string) => <span>{title}</span>;
  const renderItem = (
    item:
      | AssetItemDto
      | CampaignItemDto
      | TaskItemDto
      | AssetVersionCommentSearchItemDto,
    label: 'Campaigns' | 'Media' | 'Tasks' | 'Comments' | 'Everything'
  ) => {
    switch (label) {
      case 'Campaigns': {
        const _item = item as CampaignItemDto;
        return {
          value: JSON.stringify({
            key: _item.id,
            value: _item.name
          }),
          label: (
            <div
              onClick={() => {
                setIsSearchOverlayOpen(false);
                navigate(`/campaigns/single/${_item.id}`);
              }}
              className="search_bar_auto_complete__item"
            >
              <div className="icon">
                <CampaignsSvg className="compaigns_svg" />
              </div>
              <div className="title">{_item.name}</div>
            </div>
          )
        };
      }
      case 'Media': {
        const _item = item as AssetItemDto;
        const isPreviewValid = !!_item.asset?.versions[0].metadata.preview?.url;
        const preview = _item.asset?.versions[0].metadata.preview?.url;
        const isAudio = _item.asset?.versions[0].metadata.type === 'audio';

        return {
          value: JSON.stringify({
            key: _item.id,
            value: _item.asset?.versions[0].name
          }),
          label: (
            <div
              onClick={() => {
                navigate(
                  `/media-viewer/${_item.id}/${_item.asset?.versions[0].id}`,
                  {
                    state: {
                      returnTo: location.pathname + location.search
                    }
                  }
                );
                setIsSearchOverlayOpen(false);
              }}
              className="search_bar_auto_complete__item"
            >
              <div className="icon">
                {isPreviewValid ? (
                  <img src={preview as string} alt="" />
                ) : isAudio ? (
                  <Audio />
                ) : (
                  <Document />
                )}
              </div>
              <div className="title">{_item.asset?.versions[0].name}</div>
              <div className="path">
                {' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/campaign/${_item.campaignId}`);
                    setIsSearchOverlayOpen(false);
                  }}
                >
                  {_item.campaignName}
                </span>{' '}
              </div>
            </div>
          )
        };
      }
      case 'Comments': {
        const _item = item as AssetVersionCommentSearchItemDto;
        let commentText = _item.text;
        _item.mentions.forEach((el: any) => {
          commentText = commentText.replaceAll(
            `{{${el.id}}}`,
            `@${el.firstName} ${el.lastName}`
          );
        });
        commentText = commentText.replaceAll(
          /{{(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}}/g,
          `@Deleted User`
        );
        return {
          value: JSON.stringify({
            key: _item.id,
            value: commentText
          }),
          label: (
            <div
              onClick={() => {
                setIsSearchOverlayOpen(false);
                navigate(
                  `/media-viewer/${_item.assetVersion.assetId}/${_item.assetVersion.id}?commentId=${_item.id}`,
                  {
                    state: {
                      returnTo: location.pathname + location.search
                    }
                  }
                );
              }}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div className="search_bar_auto_complete__item">
                <CommentSvg />
                <div className="title">{commentText}</div>
                <div className="path">
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/campaign/${_item.assetVersion.campaign.id}`);
                      setIsSearchOverlayOpen(false);
                    }}
                  >
                    {_item.assetVersion.campaign.name}
                  </span>{' '}
                  {_item.assetVersion.name && (
                    <>
                      {' '}
                      /{' '}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsSearchOverlayOpen(false);
                          navigate(
                            `/media-viewer/${_item.assetVersion.assetId}/${_item.assetVersion.id}`,
                            {
                              state: {
                                returnTo: location.pathname + location.search
                              }
                            }
                          );
                        }}
                      >
                        {_item.assetVersion.name}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        };
      }
      case 'Tasks': {
        const _item = item as TaskItemDto;
        return {
          value: JSON.stringify({
            key: _item.id,
            value: _item.name
          }),
          label: (
            <div
              onClick={() => {
                dispatch(openTaskModal({ taskId: _item.id }));
                setIsSearchOverlayOpen(false);
              }}
              className="search_bar_auto_complete__item"
            >
              <div className="icon">
                <TasksSvg className="tasks_svg" />
              </div>
              <div className="title">{_item.name}</div>
              <div className="path">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/campaign/${_item.campaign.id}`);
                    setIsSearchOverlayOpen(false);
                  }}
                >
                  {_item.campaign.name}
                </span>
                {_item.asset && (
                  <>
                    {' '}
                    /{' '}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/media-viewer/${_item.asset?.id}/${_item.asset?.selectedVersion.id}`,
                          {
                            state: {
                              returnTo: location.pathname + location.search
                            }
                          }
                        );
                        setIsSearchOverlayOpen(false);
                      }}
                    >
                      {_item.asset.selectedVersion.name}
                    </span>
                  </>
                )}
              </div>
            </div>
          )
        };
      }
      default: {
        return {
          value: null,
          label: null
        };
      }
    }
  };

  return (
    <div
      className="search_bar_container"
      style={showSearch ? { width: '100%' } : {}}
    >
      {!showSearch ? (
        <Button
          type="text"
          className="search_bar_container__btn"
          style={{
            padding: 12,
            height: 40,
            width: 40
          }}
          onClick={handleSearchClick}
        >
          <SearchSvg />
        </Button>
      ) : (
        <div className="search_bar_container__input">
          <AutoComplete
            onBlur={() => {
              setIsSearchOverlayOpen(false);
              setShowSearch(false);
              setSearchValue('');
              setEntryFilter('Everything');
            }}
            options={searchedItems}
            onSelect={onSelect}
            onSearch={onSearch}
            onPopupScroll={async (event: any) => {
              if (!apiItems) return;
              const element = event.target;
              if (
                element.scrollHeight - element.scrollTop ===
                element.clientHeight
              ) {
                if (entryFilter === 'Everything') return;

                if (!apiItems.hasNext) return;

                setLoading(true);
                let _data: any;
                if (entryFilter === 'Media') {
                  const { data } = await getAssetList({
                    searchQuery: searchValue,
                    workspaceId: currentWorkspace?.id || '',
                    flatten: true,
                    after: apiItems.endCursor
                  });
                  _data = data;
                }
                if (entryFilter === 'Campaigns') {
                  const { data } = await getCampaignList({
                    searchQuery: searchValue,
                    workspaceId: currentWorkspace?.id || '',
                    after: apiItems.endCursor
                  });
                  _data = data;
                }
                if (entryFilter === 'Tasks') {
                  const { data } = await apiClient.task.taskControllerListTasks(
                    {
                      searchQuery: searchValue,
                      workspaceId: currentWorkspace?.id || '',
                      after: apiItems.endCursor
                    }
                  );
                  _data = data;
                }
                if (entryFilter === 'Comments') {
                  const { data } = await workspaceSearchAssetComments({
                    searchQuery: searchValue,
                    workspaceId: currentWorkspace?.id || '',
                    after: apiItems.endCursor
                  });
                  _data = data;
                }
                setApiItems((prev: any) => ({
                  ...prev,
                  ..._data,
                  edges: [...prev.edges, ..._data.edges]
                }));
                const row = [
                  {
                    label: entryFilter,
                    count: _data.edges.length,
                    options: _data.edges.map((el: any) => el.node)
                  }
                ];
                const IT = row.map((it) => {
                  if (!it) return [];

                  return it.options.map((el: any, idx: any) => {
                    return renderItem(el, it.label as any);
                  });
                });
                setLoading(false);
                setSearchedItems((prev: any) => {
                  const headOpt = prev[0];
                  const itemsOpt = prev[1];
                  const last = itemsOpt.options.slice(-1)[0];
                  itemsOpt.options.pop();
                  const newList = {
                    ...itemsOpt,
                    options: [...itemsOpt.options, ...IT.flat(1), last]
                  };

                  return [headOpt, newList];
                });

                setLoading(false);
              }
            }}
            value={searchValue}
            autoFocus
            defaultOpen
            listHeight={560}
            dropdownClassName="search_bar_auto_complete"
            dropdownRender={(dropdown) => {
              return (
                <div style={{ position: 'relative' }}>
                  {loading && (
                    <>
                      <div className="background" />
                      <Spin />
                    </>
                  )}

                  {dropdown}
                </div>
              );
            }}
            // open={isSearchOverlayOpen}
            open={true}
          >
            <Input
              placeholder="Search campaigns, media, tasks and more..."
              prefix={<SearchSvg />}
              size="large"
            />
          </AutoComplete>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
