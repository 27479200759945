import NotSupportedImg from '@assets/icons/AssetPlaceholder/unsupportedfile.png';
import DocumentPlaceholder from '@assets/icons/AssetPlaceholder/documentfile.png';
import PDFPlaceholder from '@assets/icons/AssetPlaceholder/pdffile.png';
import VideoPlaceholder from '@assets/icons/AssetPlaceholder/videofile.png';
import AudioPlaceholder from '@assets/icons/AssetPlaceholder/audiofile.png';

const SUPPORTED_IMAGE_PREVIEW_MIME_TYPES: string[] = [
  'image/png',
  'image/svg+xml',
  'image/gif',
  'image/jpeg',
  'image/webp'
];

const SUPPORTED_DOCUMENT_TYPES: string[] = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const isPDF = (mimeType: string): boolean => mimeType === 'application/pdf';

const isCanPreview = (mimeType: string): boolean =>
  SUPPORTED_IMAGE_PREVIEW_MIME_TYPES.includes(mimeType);

const isVideoMimeType = (mimeType: string): boolean =>
  mimeType.includes('video');

const isAudioMimeType = (mimeType: string): boolean =>
  mimeType.includes('audio');

const isDocumentMimeType = (mimeType: string): boolean =>
  SUPPORTED_DOCUMENT_TYPES.includes(mimeType);

interface IPreviewList {
  preview: string;
  validator: (mimeType: string) => boolean;
}

const previewList: IPreviewList[] = [
  {
    preview: 'preview',
    validator: isCanPreview
  },
  {
    preview: PDFPlaceholder,
    validator: isPDF
  },
  {
    preview: VideoPlaceholder,
    validator: isVideoMimeType
  },
  {
    preview: AudioPlaceholder,
    validator: isAudioMimeType
  },
  {
    preview: DocumentPlaceholder,
    validator: isDocumentMimeType
  }
];

export function getThumbnail(mimeType: string, url: string) {
  const preview = previewList.reduce((thumb: string, curr) => {
    if (curr.validator(mimeType)) thumb = curr.preview;
    return thumb;
  }, 'unknown');

  if (preview === 'preview') {
    return url;
  }

  if (preview === 'unknown') {
    return NotSupportedImg;
  }

  return preview;
}
