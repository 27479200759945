import React, { memo, useMemo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import DatePickerPopover from '@components/DatePickerComponent/DatePickerPopover';
import { ReactComponent as DueDateSvg } from '@assets/icons/due-date-task-table-icon.svg';
import moment from 'moment';
import { useUpdateTask } from '@hooks/tasks';
import { useCurrentTime } from '@hooks/useCurrentTime';
import { isDateSameOrBefore } from '@helpers/compareDueDate';
import { useOrganization } from '@components/OrganizationBoundary';
import { useCurrentWorkspace } from '@hooks/workspace';

interface DueDateColumnProps {
  taskId: string;
}

export default memo(function DueDateColumn(props: DueDateColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const updateTask = useUpdateTask();
  const now = useCurrentTime();
  const isCompletedTask = task.status === 'done';
  const isDeletedTask = !!task.deletedAt;
  const { currentOrganization } = useOrganization(true);
  const [currentWorkspace] = useCurrentWorkspace(true);
  const editable =
    task.permissions.update &&
    !task.deletedAt &&
    !task.archived &&
    (!!currentOrganization.entity?.features.contributorsCanSetTaskDeadline ||
      currentWorkspace.role !== 'workspace://contributor');

  const isPastDueDate = useMemo(() => {
    return isDateSameOrBefore({
      dateToCompare: task.dueDate,
      baseDate: now
    });
  }, [now, task.dueDate]);
  return (
    <div
      className={classNames(
        'task-table-item-container',
        !editable && 'task-table-item-readonly',
        (!!task.deletedAt || task?.board?.id === 'archived') &&
          'deleted-task-container'
      )}
    >
      <DatePickerPopover
        placement="bottomRight"
        className="task-table-item-container"
        trigger="click"
        showTime
        clearable
        value={task.dueDate ? moment(task.dueDate) : null}
        onChange={(value) => {
          const newDate = value?.toISOString() ?? null;
          updateTask({ id: task.id, dueDate: newDate });
        }}
        timeText="Time due"
        selectTimeText="Add time due"
        disabledDate={(v) => v.isBefore(moment())}
        disabledTime={(v) => v.isBefore(moment())}
      >
        {task.dueDate && (
          <span
            className={classNames(
              'due-date-column-value',
              isCompletedTask && 'selected-tasks-date-text',
              isDeletedTask && 'deleted-task-container',
              !isCompletedTask && isPastDueDate && 'overdue-task-container'
            )}
          >
            {moment(task.dueDate).format(
              `${
                moment(task.dueDate).minutes() === 59
                  ? 'MMM DD'
                  : 'MMM DD, h:mm a'
              }`
            )}
          </span>
        )}
        {!task.dueDate && <DueDateSvg />}
      </DatePickerPopover>
    </div>
  );
});
