import React, { memo } from 'react';
import { List, Skeleton } from 'antd';
import './TaskBoardSkeleton.less';

interface TaskBoardSkeletonProps {
  count: number;
}

export default memo(function TaskBoardSkeleton({
  count
}: TaskBoardSkeletonProps) {
  if (count <= 0) return null;
  return (
    <List
      className="task-board-skeleton"
      dataSource={new Array(count).fill(undefined).map((_, i) => ({ key: i }))}
      renderItem={() => (
        <List.Item>
          <Skeleton.Input />
        </List.Item>
      )}
    />
  );
});
