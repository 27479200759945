import { AssetForApprovalDto } from '@api/Api';
import ApprovalListSkeleton from '@components/Approval/ApprovalListSkeleton';
import React, { memo } from 'react';
import ApprovalItem from '@pages/Dashboard/components/ApprovalsCard/ApprovalItem';
import ApprovalEmptyStateWrapper from '@components/Approval/ApprovalEmptyStateWrapper/ApprovalEmptyStateWrapper';

type ApprovalListProps = {
  isLoading: boolean;
  list: AssetForApprovalDto[] | undefined;
  getApprovalsData: () => void;
};

export default memo(function ApprovalsList({
  list,
  isLoading,
  getApprovalsData
}: ApprovalListProps) {
  if (!list || isLoading) return <ApprovalListSkeleton />;
  return (
    <ApprovalEmptyStateWrapper
      listLength={list.length}
      getApprovalsData={getApprovalsData}
    >
      <>
        {list.map((it) => {
          return <ApprovalItem key={it.id} asset={it} />;
        })}
      </>
    </ApprovalEmptyStateWrapper>
  );
});
