import { AWStageMemberDto } from '@api/Api';
import UserAvatar from '@components/UserAvatar';
import { ReactComponent as ViewEye } from '@assets/icons/view-eye.svg';
import React, { memo } from 'react';
import { Badge, Tooltip } from 'antd';

type NameCellProps = {
  member: AWStageMemberDto;
};
export default memo(function NameCell({ member }: NameCellProps) {
  return (
    <div className="user">
      <Tooltip
        title={member.lastViewDate && 'Link opened'}
        placement="bottom"
        overlayClassName="toggle_favorite_overlay"
      >
        <Badge
          offset={[-10, 30]}
          count={member.lastViewDate ? <ViewEye /> : <></>}
        >
          <UserAvatar
            isActive={true}
            size={36}
            src={member.picture.url}
            userEmail={member.email}
            userName={member.name || ''}
          />
        </Badge>
      </Tooltip>
      <div className="user-info">
        <div className="name">{member.name}</div>
        <div className="email">{member.email}</div>
      </div>
    </div>
  );
});
