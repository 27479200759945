import React from 'react';
import { Modal, Button, Form } from 'antd';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as PrivateLock } from '@assets/icons/make-private.svg';
import { ReactComponent as PublicLock } from '@assets/icons/make-public.svg';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { toggleChangeFolderAccessModal } from '@redux/actions/modalAction';
import { setFolderAccessability } from '@redux/actions/assetsAction';

const accessCopy = {
  private: {
    title: 'Change folder to private',
    description: 'Only members you choose can access private folders.'
  },
  public: {
    title: 'Change folder to public',
    description:
      'Make this folder public so all members can access all public media.'
  }
};

function ToggleFolderAccessModal() {
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.changeFolderAccessModal
  );
  const onConfirm = async () => {
    if (!modalState?.folder) return;
    dispatch(toggleChangeFolderAccessModal(null));
    await setFolderAccessability({
      folderId: modalState?.folder.id,
      isPrivate: !modalState.folder.isPrivate
    })(dispatch);
    const folder = {
      ...modalState.folder,
      isPrivate: !modalState.folder.isPrivate
    };
    modalState.callback(folder);
  };
  const { title, description } =
    accessCopy[!modalState?.folder?.isPrivate ? 'private' : 'public'];
  return (
    <Modal
      visible={modalState?.visible}
      destroyOnClose
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      width={464}
      onCancel={() => dispatch(toggleChangeFolderAccessModal(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          {!modalState?.folder?.isPrivate ? (
            <PrivateLock style={{ marginRight: 12 }} />
          ) : (
            <PublicLock style={{ marginRight: 12 }} />
          )}
          {title}
        </h2>
        <p className="modal_container__subtitle">{description}</p>
        <Form layout="vertical" onFinish={onConfirm}>
          <div className="modal_container__actions">
            <Button
              type="text"
              size="large"
              onClick={() => dispatch(toggleChangeFolderAccessModal(null))}
              style={{
                marginRight: '29px',
                height: 48,
                padding: '8px 19px'
              }}
            >
              No
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 147, height: 48, padding: '8px 15px' }}
            >
              Yes
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ToggleFolderAccessModal;
