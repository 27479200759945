import React from 'react';
import { Modal, Button, Avatar } from 'antd';

import { WorkspaceMemberGroupItemDto } from '@api/Api';
import { hashedColor } from '@helpers/hashedColor';

interface IDeleteMemeberModal {
  group?: WorkspaceMemberGroupItemDto | null;
  visible: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function DeleteMemberGroupModal({
  group,
  visible,
  loading,
  onCancel,
  onConfirm
}: IDeleteMemeberModal) {
  if (!group) return null;
  return (
    <Modal
      open={visible}
      footer={null}
      destroyOnClose
      centered={true}
      width={500}
      onCancel={() => onCancel()}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 12 }}
        >
          Delete group
        </h2>
        <div className="modal_container__entity_info">
          <Avatar
            size={40}
            shape="square"
            style={{
              background: hashedColor(group.id, 'memberGroupAvatar'),
              marginRight: 16
            }}
          >
            {group.name[0].toUpperCase()}
          </Avatar>
          <span>{group.name}</span>
        </div>
        <p className="modal_container__subtitle" style={{ marginBottom: 32 }}>
          Do you want to delete this group?
        </p>
        <div className="modal_container__actions">
          <Button
            type="text"
            size="large"
            style={{
              marginRight: 12,
              border: 'none',
              height: 48,
              padding: '12px 19px'
            }}
            onClick={() => onCancel()}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => onConfirm()}
            style={{ width: 216, height: 48, padding: '8px 15px' }}
            loading={loading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteMemberGroupModal;
