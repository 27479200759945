import React, { memo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import AssigneesList from '@components/Tasks/AssigneesList';
import { useUpdateTask } from '@hooks/tasks';

interface AssigneesColumnProps {
  taskId: string;
}

export default memo(function AssigneesColumn(props: AssigneesColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const updateTask = useUpdateTask();
  return (
    <div
      className={classNames(
        'task-table-item-container',
        (!!task.deletedAt || task.board?.id === 'archived') &&
          'deleted-task-container'
      )}
    >
      <AssigneesList
        maxCount={3}
        placement="left"
        taskId={taskId}
        fetchOnOpen={true}
        onAdd={(member) =>
          updateTask({
            id: taskId,
            assignees: { add: [{ ...member, hasAccessToTask: true }] }
          })
        }
        onRemove={(member) =>
          updateTask({
            id: taskId,
            assignees: { remove: [member.id] }
          })
        }
      />
    </div>
  );
});
