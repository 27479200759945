import React from 'react';
import { Button, Col, Modal, Row } from 'antd';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

export default function UnsavedChangesModal({
  visible,
  onClose,
  onSave,
  onCancel
}: {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  onCancel: () => void;
}) {
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      onCancel={onClose}
      width={464}
      zIndex={1006}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          Unsaved changes
        </h2>
        <div
          className="modal_container__subtitle"
          style={{ marginBottom: '2em', width: 364 }}
        >
          This tab has unsaved changes. Do you want to save them?
        </div>
        <Row justify="end" gutter={0}>
          <Col>
            <Button
              style={{
                marginRight: 12,
                border: 'none',
                height: 48,
                padding: '12px 19px'
              }}
              onClick={onCancel}
            >
              Don&apos;t save
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ minWidth: '164px' }}
              onClick={onSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
