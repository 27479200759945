import React, { useEffect } from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { toggleOrganizationRenameModal } from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { removeExtraSpaces } from '@helpers/stringTransformers';
import { useRenameOrganization } from '@hooks/organization';

function RenameOrganizationModal() {
  const dispatch = useDispatch();
  const renameOrganization = useRenameOrganization();
  const organizationRenameModal = useTypedSelector(
    ({ modal }) => modal.organizationRenameModal
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (organizationRenameModal?.visible) {
      form.setFields([
        {
          name: 'name',
          value: organizationRenameModal?.organization?.name
        }
      ]);
    } else {
      form.setFields([]);
    }
  }, [organizationRenameModal, form]);

  const onFinish = (values: { name: string }) => {
    if (values?.name?.length > 0 && organizationRenameModal?.organization) {
      const name = removeExtraSpaces(values?.name || '');
      const organizationId = organizationRenameModal?.organization.id || '';
      renameOrganization({
        name,
        organizationId
      });
    }
    dispatch(toggleOrganizationRenameModal(null));
  };

  return (
    <Modal
      visible={organizationRenameModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={464}
      onCancel={() => dispatch(toggleOrganizationRenameModal(null))}
    >
      <div className="modal_container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, marginBottom: 24 }}
          >
            Rename organization
          </h2>
          <Form.Item
            name="name"
            label="Enter a new organization name"
            style={{ marginBottom: 32 }}
            rules={[
              {
                whitespace: true,
                message: `Organizaion name cannot be empty`
              },
              {
                max: 60,
                message: `Name has exceeded character limit.`
              },
              {
                pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
              },
              {
                required: true,
                message: 'Name is required.'
              }
            ]}
          >
            <Input size="large" type="text" placeholder="Organization name" />
          </Form.Item>
          <div className="modal_container__actions">
            <Button
              type="text"
              size="large"
              style={{
                marginRight: '28px',
                border: 'none',
                height: 48,
                padding: '8px 20px'
              }}
              onClick={() => dispatch(toggleOrganizationRenameModal(null))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 216, height: 48, padding: '8px 15px' }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default RenameOrganizationModal;
