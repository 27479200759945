import React, { useState } from 'react';
import { message, Row, Col, Button } from 'antd';

import ProfileModalContainer from '@components/Modals/ProfileModal';
// import ChangeEmailModal from '@components/Modals/ProfileModal/ChangeEmailModal';
import ConfirmEmailModal from '@components/Modals/ProfileModal/ConfirmEmailModal';
import DeleteAccountModal from '@components/Modals/ProfileModal/DeleteAccountModal';
import { profileDelete } from '@api/Profile';

type TypeModal =
  | 'delete'
  | 'changeEmail'
  | 'confirmChangeEmail'
  | 'two-factor'
  | '';

function Actions(): React.ReactElement {
  const [typeModal, setTypeModal] = useState<TypeModal>('');

  const onShowPopup = (type: TypeModal) => {
    setTypeModal(type);
  };

  const onClosePopup = () => {
    setTypeModal('');
  };

  /* const onChangeEmail = async (data: { email: string; password: string }) => {
    try {
      message.info('Change Email');
      setTypeModal('confirmChangeEmail');
    } catch (error) {
      console.warn(error, 'Change Email');
      message.error('Something went wrong.');
    }
  }; */

  const onConfirmEmail = async (data: { code: string }) => {
    try {
      message.info('Confirm Email');
      setTypeModal('');
    } catch (error) {
      console.warn(error, 'Confirm Email');
      message.error('Something went wrong.');
    }
  };

  return (
    <div className="profile_actions_container">
      {/* <Row gutter={24} justify="space-between" wrap={false}>
        <Col>
          <div className="profile_settings_subtitle">
            Change email
          </div>
          <Text>{user?.email}</Text>
        </Col>
        <Col>
          <Button
            size="middle"
            className="profile_actions_btn"
            onClick={() => onShowPopup('changeEmail')}
          >
            Change email
          </Button>
        </Col>
      </Row> */}
      <Row gutter={24} justify="space-between" align="middle" wrap={false}>
        <Col>
          <div className="profile_settings_subtitle">Delete my account</div>
        </Col>
        <Col>
          <Button
            size="middle"
            className="profile_actions_btn"
            onClick={() => onShowPopup('delete')}
          >
            Delete account
          </Button>
        </Col>
      </Row>
      {/* Change Email Modal */}
      {/* <ProfileModalContainer
        visible={typeModal === 'changeEmail'}
        onClose={onClosePopup}
      >
        <ChangeEmailModal onSubmit={onChangeEmail} onClose={onClosePopup} />
      </ProfileModalContainer> */}
      {/* Confirm Email Modal */}
      <ProfileModalContainer
        visible={typeModal === 'confirmChangeEmail'}
        onClose={onClosePopup}
      >
        <ConfirmEmailModal onSubmit={onConfirmEmail} />
      </ProfileModalContainer>
      {/* Delete Account Modal */}
      <ProfileModalContainer
        visible={typeModal === 'delete'}
        onClose={onClosePopup}
      >
        <DeleteAccountModal onSubmit={profileDelete} onClose={onClosePopup} />
      </ProfileModalContainer>
    </div>
  );
}

export default Actions;
