import { Tag } from 'antd';
import React, { memo, useMemo } from 'react';

type InvoiceStatusTagProps = {
  status: 'draft' | 'open' | 'paid' | 'void' | 'uncollectible';
};

export default memo(function InvoiceStatusTag({
  status
}: InvoiceStatusTagProps) {
  const tagData = useMemo(() => {
    if (status === 'draft') return { status, color: 'default' };
    if (status === 'open') return { status, color: 'processing' };
    if (status === 'paid') return { status, color: '#8DD2B9' };
    if (status === 'void')
      return { status: 'Not charged - free trial canceled', color: '#F5C8BE' };
    if (status === 'uncollectible') return { status: 'Not charged', color: '' };
  }, [status]);
  return (
    <Tag
      color={tagData?.color}
      style={{ color: '#161819', fontWeight: 600, margin: '18px 0px' }}
    >
      {tagData?.status}
    </Tag>
  );
});
