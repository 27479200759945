import React from 'react';

import { Menu } from 'antd';
import { ReactComponent as SelectMenuItemSvg } from '@assets/icons/select-menu-item.svg';

export interface IPriorityItem {
  name: string;
  background: string;
  priority: 'high' | 'medium' | 'low' | 'critical' | null | undefined;
}

export const priorityMenuList: Array<IPriorityItem> = [
  {
    name: 'Critical',
    background: '#FB7F63',
    priority: 'critical'
  },
  {
    name: 'High',
    background: '#F8B54E',
    priority: 'high'
  },
  {
    name: 'Medium',
    background: '#53CDA1',
    priority: 'medium'
  },
  {
    name: 'Low',
    background: '#65BBFB',
    priority: 'low'
  }
];

export function priorityMenu(
  selectedPriority: string | null,
  handleChangeTaskPriority: (item: IPriorityItem, ...props: any) => void
) {
  return (
    <Menu className="priority-menu">
      {priorityMenuList.map((item: IPriorityItem, index: number) => (
        <Menu.Item
          className="menu-item"
          onClick={() => handleChangeTaskPriority(item)}
          key={index.toString()}
        >
          <div>
            <div style={{ backgroundColor: item.background }} />
            <span>{item.name}</span>
          </div>
          {selectedPriority === item.priority && <SelectMenuItemSvg />}
        </Menu.Item>
      ))}
    </Menu>
  );
}
