import { hashedColor } from '@helpers/hashedColor';
import { Tooltip, Input, Popover, Menu, Tag } from 'antd';
import React, { memo, useState } from 'react';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as WarningSvg } from '@assets/icons/warning.svg';
import { ReactComponent as DueDateSvg } from '@assets/icons/due-date.svg';
import {
  dueSoonTasksCountCopy,
  overdueTasksCountCopy
} from '@helpers/tasksDueDateCopy';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';

interface TaskBoardItemHeaderProps {
  boardId: string;
  name: string;
  tasksCount: number;
  overdueTaskCount: number;
  dueSoonTaskCount: number;
  open: boolean;
  canDeleteAllTasks: boolean;
  canDeleteTaskBoards: boolean;
  canRenameTaskBoards: boolean;
  getPopupContainer: (triggerNode: HTMLElement) => HTMLElement;
  onNewTaskClick: () => void;
  onRename: (newName: string) => void;
  onDelete: (deleteAllTasks: boolean) => void;
}

export default memo(function TaskBoardItemHeader(
  props: TaskBoardItemHeaderProps
) {
  const {
    boardId,
    name,
    tasksCount,
    overdueTaskCount,
    dueSoonTaskCount,
    open,
    canDeleteAllTasks,
    canDeleteTaskBoards,
    canRenameTaskBoards,
    getPopupContainer,
    onNewTaskClick,
    onRename,
    onDelete
  } = props;
  const [newName, setNewName] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const isArchivedBoard = boardId === 'archived';
  const isNoAssignedBoard = boardId === 'no_assigned';
  const isSystemBoard = isArchivedBoard || isNoAssignedBoard;

  const actions: MenuItemType[] = [];
  if (isSystemBoard) {
    if (canDeleteAllTasks) {
      actions.push({
        key: 'delete-all-tasks',
        className: 'action-panel-menu-item',
        label: 'Delete all tasks',
        onClick: () => onDelete(true)
      });
    }
  } else {
    if (canRenameTaskBoards) {
      actions.push({
        key: 'rename-task-group',
        className: 'action-panel-menu-item',
        label: 'Rename task group',
        onClick: () => setNewName(name)
      });
    }
    if (canDeleteTaskBoards) {
      actions.push({
        key: 'delete',
        className: 'action-panel-menu-item',
        label: 'Delete',
        onClick: () => onDelete(false)
      });
    }
  }

  return (
    <div
      className={classNames({
        'panel-header-container': true,
        'panel-header-container--open': open
      })}
    >
      <div
        className="panel-header-colored"
        style={{
          backgroundColor: isNoAssignedBoard
            ? '#FDDFA6'
            : isArchivedBoard
            ? '#DBDBDB'
            : hashedColor(boardId, 'taskGroup')
        }}
      />
      <div className="panel-header-text">
        {newName === null && (
          <Tooltip
            overlayClassName="long-text-hint"
            title={name}
            trigger="hover"
            placement="topLeft"
            overlayInnerStyle={{ maxWidth: 400 }}
          >
            <span
              className="task-group-name"
              style={open ? { fontWeight: 700 } : {}}
              onClick={(e) => {
                e.stopPropagation();
                setNewName(name);
              }}
            >
              {name}
            </span>
          </Tooltip>
        )}
        {newName !== null && (
          <Input
            autoFocus
            className="renamed-panel-input task-group-name"
            value={newName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(event) => setNewName(event.target.value)}
            htmlSize={Math.max(newName.length, name.length)}
            onBlur={() => {
              setNewName(null);
              if (newName) onRename(newName);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                setNewName(null);
                if (newName) onRename(newName);
              }
            }}
            style={open ? { fontWeight: 700 } : {}}
            maxLength={60}
          />
        )}
        <div className="count">{tasksCount}</div>
      </div>
      {dueSoonTaskCount > 0 && (
        <div className="panel-header-due-soon">
          <Tooltip
            title={dueSoonTasksCountCopy(dueSoonTaskCount)}
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
            placement="bottom"
          >
            <Tag color="#F6E8DB">
              <DueDateSvg />
              <span>{dueSoonTaskCount}</span>
            </Tag>
          </Tooltip>
        </div>
      )}
      {overdueTaskCount > 0 && (
        <div className="panel-header-overdue">
          <Tooltip
            title={overdueTasksCountCopy(overdueTaskCount)}
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
            placement="bottom"
          >
            <Tag color="#F5C8BE">
              <WarningSvg />
              <span>{overdueTaskCount}</span>
            </Tag>
          </Tooltip>
        </div>
      )}
      {open && (
        <div
          className="panel-header-action-container"
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(false);
          }}
        >
          {!isArchivedBoard && (
            <div
              className="panel-action-icon"
              onClick={(event) => {
                event.stopPropagation();
                onNewTaskClick();
              }}
            >
              <span>Add task</span> <PlusSvg />
            </div>
          )}
          {actions.length && (
            <Popover
              getPopupContainer={getPopupContainer}
              content={
                <Menu
                  style={{ padding: '12px 8px', width: 180 }}
                  selectable={false}
                  items={actions}
                />
              }
              open={menuOpen}
              onOpenChange={setMenuOpen}
              placement="right"
              trigger={['click']}
              overlayClassName="popover-container"
              overlayInnerStyle={{ padding: 0 }}
              overlayStyle={{ padding: 0 }}
            >
              <div
                className="dots-panel-container"
                onClick={(event) => event.stopPropagation()}
              >
                <ActionSvg />
              </div>
            </Popover>
          )}
        </div>
      )}
    </div>
  );
});
