import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form, Input, Select } from 'antd';
import { findWorkspaceMembersToInviteInGroup } from '@api/Workspace';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import {
  toggleReachedEntityLimitModal,
  toggleWorkspaceMemberGroupCreateModal
} from '@redux/actions/modalAction';
import { WorkspaceMemberGroupCreateModalStateProps } from '@redux/types/modalType';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { WorkspaceRole } from '@helpers/userRoles';
import { useTypedSelector } from '@hooks';
import { apiClient } from '@api/client';

type userRoleCopyProps = {
  [id: string]: string;
};

export const userRoleCopy: userRoleCopyProps = {
  'workspace://team-lead': 'Manager',
  'workspace://member': 'Member',
  'workspace://contributor': 'Contributor'
};

function WorkspaceMemberGroupCreateModal() {
  const modalState = useTypedSelector(
    ({ modal }) => modal.workspaceMemberGroupCreateModal
  );
  if (!modalState) return null;
  return (
    <WorkspaceMemberGroupCreateModalInner
      {...modalState}
      key={modalState.key}
    />
  );
}

function WorkspaceMemberGroupCreateModalInner(
  props: WorkspaceMemberGroupCreateModalStateProps
) {
  const dispatch = useDispatch();
  const [customMessageIsOpen, setCustomMessageIsOpen] =
    useState<boolean>(false);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      name,
      userList,
      role,
      message
    }: {
      name: string;
      userList: SelectedItem[];
      role: WorkspaceRole;
      message: string;
    }) => {
      await apiClient.workspace.workspaceControllerCreateWorkspaceMemberGroup({
        workspaceId: props.workspace.id,
        name,
        users: userList
          .filter((x) => x.type === 'new' || x.type === 'member')
          .map((x) => ({
            memberIdOrEmail: x.type === 'new' ? x.email : x.id,
            role,
            customMessage: message
          }))
      });
    },
    onError: (e: any) => {
      if (e?.response?.data?.errorCode === 'subscription_users_limit_error') {
        dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity: 'user'
          })
        );
        dispatch(toggleWorkspaceMemberGroupCreateModal(null));
      }
      if (e.response?.data?.errorCode === 'member_group_already_exists') {
        form.setFields([
          {
            name: 'name',
            errors: ['Group with this name already exists']
          }
        ]);
      }
    },
    onSuccess: () => {
      dispatch(toggleWorkspaceMemberGroupCreateModal(null));
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['workspace', 'members']),
        queryClient.invalidateQueries(['workspace', 'member-groups'])
      ]);
    }
  });

  const userList = Form.useWatch(['userList'], form) as
    | SelectedItem[]
    | undefined;
  const hasNewMembers = !!userList?.some((x) => x.type === 'new');

  return (
    <Modal
      open={props.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={600}
      onCancel={() => {
        dispatch(toggleWorkspaceMemberGroupCreateModal(null));
      }}
      zIndex={1005}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: '22px', marginBottom: 24 }}
        >
          Create group
        </h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={mutation.mutate}
          requiredMark={false}
          initialValues={{
            name: '',
            userList:
              props.members?.map((x) => ({ ...x, type: 'member' })) ?? [],
            message: '',
            role: 'workspace://member'
          }}
        >
          <Form.Item
            label="Group name"
            name="name"
            rules={[{ required: true, message: 'Group name is required' }]}
          >
            <Input size="large" placeholder="e.g Marketing" maxLength={255} />
          </Form.Item>
          <Form.Item name="userList" label="Members">
            <InviteMembersSelect
              key={props.workspace.id}
              queryEnabled={props.visible}
              membersQueryKey={[
                'workspace',
                'members',
                'find-to-invite',
                'group',
                props.workspace.id
              ]}
              membersQueryFn={async ({ pageParam, searchQuery, signal }) => {
                const { data } = await findWorkspaceMembersToInviteInGroup(
                  {
                    workspaceId: props.workspace.id,
                    after: pageParam,
                    searchQuery
                  },
                  { signal }
                );
                return data;
              }}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Workspace role for new members"
            style={{
              display: hasNewMembers ? 'block' : 'none'
            }}
          >
            <Select
              popupClassName="select_role_dropdown"
              size="large"
              listItemHeight={64}
              options={[
                {
                  value: 'workspace://team-lead',
                  disabled: !props.workspace.permissions.inviteTeamLead,
                  label: (
                    <>
                      <p className="title">Workspace lead</p>
                      <p className="description">Manages all campaigns</p>
                    </>
                  )
                },
                {
                  value: 'workspace://member',
                  disabled: !props.workspace.permissions.inviteMember,
                  label: (
                    <>
                      <p className="title">Member</p>
                      <p className="description">Can access campaigns</p>
                    </>
                  )
                },
                {
                  value: 'workspace://contributor',
                  disabled: !props.workspace.permissions.inviteContributor,
                  label: (
                    <>
                      <p className="title">Contributor</p>
                      <p className="description">
                        Can access specific campaigns
                      </p>
                    </>
                  )
                }
              ]}
            />
          </Form.Item>
          <Button
            className="modal_container__custom_message"
            type="link"
            onClick={() => setCustomMessageIsOpen((value) => !value)}
          >
            <PlusSvg /> Add custom message
          </Button>
          {customMessageIsOpen && (
            <Form.Item name="message" style={{ position: 'relative' }} noStyle>
              <Input.TextArea
                rows={4}
                placeholder="Type your message here..."
                style={{ resize: 'none', padding: 12, marginTop: 8 }}
              />
            </Form.Item>
          )}
          <div className="modal_container__actions" style={{ marginTop: 32 }}>
            <Button
              type="text"
              size="large"
              style={{
                marginRight: 16,
                border: 'none',
                height: 48,
                padding: '8px 32px'
              }}
              onClick={() =>
                dispatch(toggleWorkspaceMemberGroupCreateModal(null))
              }
            >
              Cancel
            </Button>
            <Button
              loading={mutation.isLoading}
              type="primary"
              size="large"
              className="submit_form"
              htmlType="submit"
              style={{
                width: 216,
                height: 48,
                padding: '8px 15px'
              }}
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default WorkspaceMemberGroupCreateModal;
