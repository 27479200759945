import { AWStageMemberDto } from '@api/Api';
import MemberDecision from '@components/Approval/MemberDecision';
import React, { memo } from 'react';

type DecisionCellProps = {
  member: AWStageMemberDto;
  stageDeadline?: string | null;
};
export default memo(function DecisionCell({
  member,
  stageDeadline
}: DecisionCellProps) {
  if (!member.canSubmitDecision) return null;
  return (
    <MemberDecision
      lastViewDate={member.lastViewDate}
      stageDeadline={stageDeadline}
      decision={member.decision || undefined}
    />
  );
});
