import { ReactComponent as CommentsSvg } from '@assets/icons/aw-comments.svg';
import React, { memo } from 'react';

type CommentsCellProps = {
  commentsCount: number;
};
export default memo(function CommentsCell({
  commentsCount
}: CommentsCellProps) {
  return (
    <div className="comments-count-tag">
      <CommentsSvg />
      {commentsCount}
    </div>
  );
});
