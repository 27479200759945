import React from 'react';

import { Select, Tag, Table, Button, Popover, Avatar } from 'antd';
import {
  ResendWorkspaceInvitationMemberDto,
  WorkspaceMemberDto
} from '@api/Api';
import {
  OrganizationRoleCopy,
  WorkspaceRole,
  WorkspaceRoleCopy
} from '@helpers/userRoles';
import { ReactComponent as FolderSvg } from '@assets/icons/folder.svg';
import { ReactComponent as FolderPrivateSvg } from '@assets/icons/folder-private.svg';

import UserAvatar from '@components/UserAvatar';
import { useOrganization } from '@components/OrganizationBoundary';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { hashedColor } from '@helpers/hashedColor';
import { useDispatch } from 'react-redux';
import { toggleWorkspaceMemberGroupModal } from '@redux/actions/modalAction';
import { useCurrentWorkspace } from '@hooks/workspace';

const activeStyle = {
  background: '#92D1BA',
  color: '#161819',
  border: 'none',
  padding: '2px 9px'
};

const pendingStyle = {
  background: '#FAECCC',
  color: '#161819',
  border: 'none',
  padding: '2px 9px'
};

const expiredStyle = {
  background: '#F5C8BE',
  color: '#161819',
  border: 'none',
  padding: '2px 9px'
};

interface IWorkspaceMembersTable {
  edges: WorkspaceMemberDto[] | undefined | null;
  count: number;
  selectedIds: string[];
  setMembersToManage?: (
    selectedIds: string[],
    members: WorkspaceMemberDto[],
    isAllSelect: boolean
  ) => void;
  setShowDeleteMemberModal?: (a: boolean) => void;
  onChangeWorkspaceMembersRole?: (a: string, b: WorkspaceRole) => void;
  onResendInvitationEmail?: (
    member: ResendWorkspaceInvitationMemberDto
  ) => void;
  onSort: (value: string) => void;
  isLoading: boolean;
}

export const MemberStatus = (status: string) => {
  const selectTag = (status: string) => {
    switch (status) {
      case 'active':
        return <Tag style={activeStyle}>{status}</Tag>;
      case 'pending':
        return <Tag style={pendingStyle}>{status}</Tag>;

      case 'expired':
        return <Tag style={expiredStyle}>{status}</Tag>;
      default:
        return null;
    }
  };
  return selectTag(status);
};

function WorkspaceMembersTable({
  onChangeWorkspaceMembersRole,
  setShowDeleteMemberModal,
  onResendInvitationEmail,
  setMembersToManage,
  onSort,
  count,
  edges,
  isLoading,
  selectedIds
}: IWorkspaceMembersTable) {
  const { currentOrganization } = useOrganization();
  const [currentWorkspace] = useCurrentWorkspace();
  const dispatch = useDispatch();
  const maxSeatsReached =
    currentOrganization.seatsCount.count >=
    (currentOrganization.entity?.limits.orgMembers ?? Number.POSITIVE_INFINITY);
  const [visibleGroupsPopover, setVisibleGroupsPopover] = React.useState<
    string | null
  >(null);
  const columns = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => {
        const copy = (
          <>
            User <span className="users_count">{count}</span>
          </>
        );
        return copy;
      },
      dataIndex: 'name',
      key: 'name',
      render: (text: string, node: WorkspaceMemberDto) => {
        const { invitationEmail, user, workspaceRole, invitationExpired } =
          node;
        return (
          <div className="user_record">
            <div className="user_record__avatar">
              <UserAvatar
                isActive={!invitationEmail}
                size="large"
                src={user?.picture.url || ''}
                userEmail={user?.email || ''}
                userName={user?.name || ''}
              />
            </div>
            <div className="user_record__info">
              <div className="name">{user?.name}</div>
              <div className="email main-body-text main-body-text--tiny main-body-text--dark-gray">
                {user?.email || invitationEmail}
                {!!invitationEmail && onResendInvitationEmail && (
                  <Button
                    className="email-resend"
                    type="link"
                    size="small"
                    disabled={maxSeatsReached}
                    onClick={() => {
                      if (invitationEmail) {
                        onResendInvitationEmail({
                          invitationEmail
                        });
                      }
                    }}
                  >
                    Resend
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: string, node: WorkspaceMemberDto) => {
        const { organizationRole, workspaceRole, id, permissions } = node;
        const role =
          organizationRole === 'organization://workspace-member'
            ? workspaceRole
            : organizationRole;
        if (!permissions.changeRole || !onChangeWorkspaceMembersRole) {
          const isAdmin = node.organizationRole === 'organization://admin';
          return workspaceRole && !isAdmin
            ? WorkspaceRoleCopy[workspaceRole]
            : OrganizationRoleCopy[organizationRole];
        }
        return (
          <div className="user_role">
            <Select
              value={role}
              style={{ width: 128 }}
              dropdownMatchSelectWidth={180}
              onChange={(e) => {
                onChangeWorkspaceMembersRole(id, e as WorkspaceRole);
              }}
            >
              <Select.Option value="workspace://team-lead">
                Workspace lead
              </Select.Option>
              <Select.Option value="workspace://member">Member</Select.Option>
              <Select.Option value="workspace://contributor">
                Contributor
              </Select.Option>
              <Select.Option disabled value="organization://admin'">
                Admin
              </Select.Option>
            </Select>
          </div>
        );
      },
      width: 190,
      sorter: true
    },
    {
      title: 'Campaigns',
      key: 'campaigns',
      dataIndex: 'campaigns',
      width: 90,
      render: (_: any, node: WorkspaceMemberDto) => {
        const { campaigns } = node;
        return (
          <Popover
            content={
              <div>
                <div className="user_campaigns__title">
                  Campaigns ({campaigns.length})
                </div>
                {campaigns.map((campaign) => {
                  return (
                    <div key={campaign.id} className="user_campaigns__list">
                      <Link
                        className="user_campaigns__name"
                        to={`/campaigns/single/${campaign.id}`}
                        target="_blank"
                      >
                        {campaign.name}
                      </Link>
                      {campaign?.folders?.map((folder) => (
                        <Link
                          key={folder.id}
                          className="user_campaigns__folder"
                          to={`/campaigns/single/${campaign.id}/${folder.id}`}
                          target="_blank"
                        >
                          <div className="user_campaigns__folder-icon">
                            {folder.isPrivate ? (
                              <FolderPrivateSvg />
                            ) : (
                              <FolderSvg />
                            )}
                          </div>
                          <div className="user_campaigns__folder-name">
                            {folder.name}
                          </div>
                        </Link>
                      ))}
                    </div>
                  );
                })}
              </div>
            }
            className={classNames({
              user_campaigns: true,
              'user_campaigns--empty': !campaigns.length
            })}
            placement="bottomLeft"
            overlayClassName="user_campaigns__popover"
            trigger={campaigns.length ? ['hover'] : []}
          >
            <span>{campaigns.length}</span>
          </Popover>
        );
      }
    },
    {
      title: 'Groups',
      key: 'groups',
      dataIndex: 'groups',
      width: 90,
      render: (_: any, node: WorkspaceMemberDto) => {
        const { groups } = node;
        return (
          <Popover
            content={
              <div>
                <div className="user_campaigns__title">
                  Groups ({groups.length})
                </div>
                {groups.map((group) => {
                  return (
                    <div key={group.id} className="user_campaigns__list">
                      <Button
                        block
                        className="user_campaigns__name"
                        type="link"
                        onClick={() => {
                          setVisibleGroupsPopover(null);
                          dispatch(
                            toggleWorkspaceMemberGroupModal({
                              group,
                              workspace: currentWorkspace,
                              visible: true
                            })
                          );
                        }}
                      >
                        <Avatar
                          size="small"
                          shape="square"
                          style={{
                            background: hashedColor(
                              group.id,
                              'memberGroupAvatar'
                            ),
                            marginRight: 16
                          }}
                        >
                          {group.name[0].toUpperCase()}
                        </Avatar>
                        {group.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            }
            className={classNames({
              user_campaigns: true,
              'user_campaigns--empty': !groups.length
            })}
            placement="bottomLeft"
            overlayClassName="user_campaigns__popover"
            trigger={groups.length ? ['hover'] : []}
            open={visibleGroupsPopover === node.id}
            onOpenChange={(open) => {
              if (open) {
                setVisibleGroupsPopover(node.id);
              } else {
                setVisibleGroupsPopover(null);
              }
            }}
          >
            <span>{groups.length}</span>
          </Popover>
        );
      }
    },
    {
      title: 'Status',
      key: 'isActive',
      dataIndex: 'status',
      render: (text: string, node: WorkspaceMemberDto) => {
        const status = node.user
          ? 'active'
          : node.invitationExpired
          ? 'expired'
          : 'pending';
        return MemberStatus(status);
      },
      width: 90,
      sorter: true
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 89,
      render: (text: string, node: WorkspaceMemberDto) => {
        const { permissions } = node;

        if (
          !permissions.delete ||
          !setShowDeleteMemberModal ||
          !setMembersToManage
        ) {
          return;
        }

        return (
          <Button
            type="link"
            onClick={() => {
              setMembersToManage([node.id], [node], false);
              setShowDeleteMemberModal(true);
            }}
            style={{ cursor: 'pointer', float: 'right' }}
            className="main-body-text main-body-text--semibold main-body-text--dark-gray"
          >
            Remove
          </Button>
        );
      }
    }
  ];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={edges || []}
      rowKey={(node) => node.id}
      loading={isLoading || !edges}
      rowSelection={
        setMembersToManage
          ? {
              type: 'checkbox',
              selectedRowKeys: selectedIds,
              preserveSelectedRowKeys: true,
              onChange: (selectedRowKeys, selectedRows, info) => {
                setMembersToManage(
                  selectedRowKeys as string[],
                  selectedRows,
                  info.type === 'all'
                );
              }
            }
          : undefined
      }
      onChange={(pagination, filters, sorterResult) => {
        const sorter = Array.isArray(sorterResult)
          ? sorterResult[0]
          : sorterResult;
        if (sorter) {
          onSort(
            `${sorter.columnKey}:${sorter.order === 'ascend' ? 'ASC' : 'DESC'}`
          );
        } else {
          onSort('createdAt:DESC');
        }
      }}
    />
  );
}

export default WorkspaceMembersTable;
