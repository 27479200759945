import React, { memo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import classNames from 'classnames';
import { statusMenu } from '@components/Tasks/Status/statusConfig';
import { Popover } from 'antd';
import Status from '@components/Tasks/Status';
import { useUpdateTask } from '@hooks/tasks';

interface StatusColumnProps {
  taskId: string;
}

export default memo(function StatusColumn(props: StatusColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const updateTask = useUpdateTask();
  return (
    <Popover
      placement="right"
      trigger="click"
      content={statusMenu(task.status, (newStatus) =>
        updateTask({ id: task.id, status: newStatus.status })
      )}
      overlayClassName="popover-container"
    >
      <div
        className={classNames(
          'task-table-item-container',
          (!!task.deletedAt || task.board?.id === 'archived') &&
            'deleted-task-container'
        )}
      >
        <Status status={task.status} />
      </div>
    </Popover>
  );
});
