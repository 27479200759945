import React from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as CombineIcon } from '@assets/icons/combine-assets-icon.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { addAssetToStackAction } from '@redux/actions/assetsAction';
import { updateCampaignCounters } from '@redux/actions/campaignAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { toggleCombineAssetsModal } from '@redux/actions/modalAction';
import './CombineAssetsModal.less';

function CombineAssetsModal() {
  const modalState = useTypedSelector(({ modal }) => modal.combineAssetsModal);
  const dispatch = useDispatch();
  const assetsList = useTypedSelector(({ assets }) => assets.files.edges);

  const handleCombineAsset = () => {
    if (!modalState) return;
    dispatch(
      addAssetToStackAction({
        assetId: modalState.assetId,
        toAssetId: modalState.toAssetId
      })
    );
    dispatch(updateCampaignCounters('deleteFile'));
    const assetToRemove = assetsList?.find(
      (el: any) => el.node.id === modalState.assetId
    )?.node;
    dispatch(updateAssetsWorkspaceCounters('addToStack', assetToRemove));
    dispatch(toggleCombineAssetsModal(null));
  };

  return (
    <Modal
      className="combine-assets-modal"
      open={modalState?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      onCancel={() => dispatch(toggleCombineAssetsModal(null))}
      width={464}
    >
      <CrossSvg
        className="close-task-modal"
        onClick={() => dispatch(toggleCombineAssetsModal(null))}
      />
      <div className="combine-assets-title">
        <CombineIcon />
        <span>Combine media</span>
      </div>
      <div className="combine-description-text">
        <span className="combine-description-asset">
          {modalState?.toAssetName}
        </span>{' '}
        has an approval in progress. Do you want to add this new media to that
        approval routing?
      </div>
      <div className="combine-button-container">
        <Button
          type="text"
          onClick={() => dispatch(toggleCombineAssetsModal(null))}
        >
          No
        </Button>
        <Button type="primary" onClick={() => handleCombineAsset()}>
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export default CombineAssetsModal;
