import { AssetForApprovalDto } from '@api/Api';
import React, { memo, useMemo } from 'react';

type DecisionsCellProps = {
  node: AssetForApprovalDto;
};
export default memo(function DecisionsCell({ node }: DecisionsCellProps) {
  const decisions = useMemo(() => {
    const aw = node.asset.versions[0].approvalWorkflow;
    if (!aw) return null;
    const currentStage = aw.stages.find((el) => el.id === aw.currentStageId);
    if (!currentStage) return null;
    let decisionsTaken = 0;
    let decisionsToBeMade = 0;
    currentStage.members.forEach((it) => {
      if (it.decision) decisionsTaken += 1;
      if (it.canSubmitDecision) decisionsToBeMade += 1;
    });
    return { decisionsTaken, decisionsToBeMade };
  }, [node.asset.versions]);

  if (!decisions) return null;

  return (
    <span className="time-state">
      {decisions.decisionsTaken} of {decisions.decisionsToBeMade}
    </span>
  );
});
