import React, { memo, useState } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { useUpdateTask } from '@hooks/tasks';
import { TaskFieldItemDto } from '@api/Api';
import { Input, Menu, Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import { ReactComponent as TagsSvg } from '@assets/icons/tags-task-table-icon.svg';
import { ReactComponent as DeleteCustomColumnSvg } from '@assets/icons/delete-task-table-custom-column.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface TagColumnProps {
  taskId: string;
  field: TaskFieldItemDto;
}

export default memo(function TagColumn(props: TagColumnProps) {
  const { taskId, field } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const value = (task.customFields.find((x) => x.id === field.id)?.value ??
    []) as string[];
  const updateTask = useUpdateTask();
  const [open, setOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const availableTags: ItemType[] = (field.tags ?? [])
    .filter((x) => !value.includes(x))
    .filter((x) => x.includes(newTag))
    .map((x) => ({
      key: x,
      className: 'tag-item',
      label: x,
      onClick: () => {
        setNewTag('');
        updateTask({
          id: taskId,
          customFields: {
            add: [
              {
                id: field.id,
                name: field.name,
                type: field.type,
                value: x
              }
            ]
          }
        });
      }
    }));
  return (
    <Popover
      placement="left"
      trigger={task.board.id === 'archived' ? [] : 'click'}
      open={open}
      onOpenChange={setOpen}
      overlayClassName="popover-container"
      overlayStyle={{ width: 400, padding: 24 }}
      content={
        <div className="task-table-tags-menu-container">
          {!!value.length && (
            <div className="selected-tags-container">
              {value.map((item) => (
                <div key={item}>
                  <Tooltip
                    overlayClassName="task-group-settings-hint"
                    title={item}
                    trigger="hover"
                  >
                    <span>{item}</span>
                  </Tooltip>
                  <DeleteCustomColumnSvg
                    onClick={() => {
                      updateTask({
                        id: taskId,
                        customFields: {
                          remove: [{ id: field.id, value: item }]
                        }
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          <div style={{ position: 'relative' }}>
            <Input
              value={newTag}
              size="large"
              placeholder="Create tag"
              onChange={(event) => setNewTag(event.target.value)}
              onKeyPress={async (event) => {
                if (event.key === 'Enter' && newTag.trim()) {
                  setNewTag('');
                  updateTask({
                    id: taskId,
                    customFields: {
                      add: [
                        {
                          id: field.id,
                          name: field.name,
                          type: field.type,
                          value: newTag.trim()
                        }
                      ]
                    }
                  });
                }
              }}
            />
            {!!newTag.trim() && (
              <div
                className="add-task-table-button-container add-tag-button-container"
                onClick={() => {
                  setNewTag('');
                  updateTask({
                    id: taskId,
                    customFields: {
                      add: [
                        {
                          id: field.id,
                          name: field.name,
                          type: field.type,
                          value: newTag.trim()
                        }
                      ]
                    }
                  });
                }}
              >
                <PlusSvg />
                <span>{`Add tag "${newTag.trim()}"`}</span>
              </div>
            )}
          </div>
          {!!availableTags.length && (
            <Menu className="tags-item-container" items={availableTags} />
          )}
        </div>
      }
    >
      {!value.length && (
        <div
          className={classNames(
            'task-table-item-container',
            task.board.id === 'archived' && 'deleted-task-container'
          )}
        >
          <TagsSvg />
        </div>
      )}
      {!!value.length && (
        <div
          className={classNames(
            'task-table-tags-item',
            task.board.id === 'archived' && 'deleted-task-container'
          )}
        >
          <span style={{ cursor: 'pointer' }}>{value[0]}</span>
          {value.length > 1 && (
            <div
              className={classNames(
                'tag-counter-container',
                open && 'active-tag-counter-container'
              )}
            >{`${value.length - 1}+`}</div>
          )}
        </div>
      )}
    </Popover>
  );
});
