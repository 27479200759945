import React, { memo } from 'react';

type UserCellTitleProps = {
  count: number;
};

export default memo(function UserCellTitle({ count }: UserCellTitleProps) {
  return (
    <div className="user-cell-title">
      User <span>{count}</span>
    </div>
  );
});
