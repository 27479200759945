import React from 'react';
import { Button } from 'antd';

import './FolderEmptyState.less';

import { useDispatch } from 'react-redux';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';
import { useCurrentWorkspace } from '@hooks/workspace';
import LottieComponent from '@components/Lottie';

function FolderEmptyState() {
  const dispatch = useDispatch();
  const [currentWorkspace] = useCurrentWorkspace();
  return (
    <div
      className="folder-empty"
      onClick={() =>
        dispatch(
          toggleUploadAssetModal({
            visible: true,
            workspaceId: currentWorkspace.id
          })
        )
      }
    >
      <div className="folder-empty__wrapper">
        <LottieComponent
          className="folder-empty__icon"
          view="addMediaInFolder"
        />
        {/* <AddMedia className="folder-empty__icon" /> */}
        <p className="folder-empty__description main-body-text main-body-text--dark-gray">
          Upload the media and start collaborating!
        </p>

        <Button className="folder-empty__btn" type="primary">
          Upload media
        </Button>
      </div>
    </div>
  );
}

export default FolderEmptyState;
