import React from 'react';
import { Row, Col } from 'antd';
import UploadImage from '@components/UploadImage';
import { removePicture, uploadPictureParams } from '@api/Profile';
import UserAvatar from '@components/UserAvatar';

import { UppyFile } from '@uppy/core';
import { useAuth } from '@hooks/useAuth';

import './ProfileAvatar.less';
import { useOrganization } from '@components/OrganizationBoundary';

function ProfileAvatar(): React.ReactElement {
  const { user, updateUserProfile } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization();
  const onChangeAvatar = (file: UppyFile | null | string) => {
    let _picture = file || '';
    if (_picture && typeof _picture !== 'string') {
      _picture = URL.createObjectURL(_picture.data);
    }
    updateUserProfile({
      pictureUrl: _picture || null
    });
  };

  return (
    <div className="profile_avatar_container">
      <Row align="middle" gutter={24}>
        <Col>
          <div className="profile_avatar_image">
            {user && !user.pictureUrl && (
              <UserAvatar
                isActive={true}
                size={88}
                src={user.pictureUrl}
                userEmail={user.email}
                userName={user.name}
              />
            )}
            <UploadImage
              onUploadPicture={uploadPictureParams}
              onRemovePicture={removePicture}
              picture={user.pictureUrl ?? undefined}
              setPicture={onChangeAvatar}
              uploadButtonText="Upload new photo"
              removeButtonText="Delete photo"
              tooltipPlacement="right"
              options={{
                autoProceed: true
              }}
            />
          </div>
        </Col>
        <Col>
          <div className="profile_avatar_user-name">{user.name}</div>
          {currentOrganization.entity?.permissions.inviteAdmin && (
            <div className="profile_avatar_user-role">Admin</div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ProfileAvatar;
