import { List, Skeleton } from 'antd';
import React, { memo } from 'react';
import './MemberItemSkeleton.less';

type MemberItemSkeletonProps = {
  count: number;
};
export default memo(function MemberItemSkeleton({
  count
}: MemberItemSkeletonProps) {
  if (count <= 0) return null;
  return (
    <List
      className="member-list-skeleton"
      dataSource={new Array(count).fill(undefined).map((_, i) => ({ key: i }))}
      renderItem={() => (
        <List.Item>
          <Skeleton.Input style={{ height: 73 }} />
        </List.Item>
      )}
    />
  );
});
