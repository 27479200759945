import { Tag } from 'antd';
import React, { memo, useMemo } from 'react';

type MemberStatusTagProps = {
  status: 'active' | 'expired' | 'pending';
};

const sharedStyle = {
  border: 'none',
  padding: '2px 9px'
};

const activeStyle = {
  background: '#92D1BA',
  color: '#161819'
};

const pendingStyle = {
  background: '#FAECCC',
  color: '#161819'
};

const expiredStyle = {
  background: '#F5C8BE',
  color: '#161819'
};

export default memo(function MemberStatusTag({ status }: MemberStatusTagProps) {
  const tagStyle = useMemo(() => {
    let style = sharedStyle;
    if (status === 'active') style = { ...style, ...activeStyle };
    if (status === 'expired') style = { ...style, ...expiredStyle };
    if (status === 'pending') style = { ...style, ...pendingStyle };
    return style;
  }, [status]);
  return <Tag style={tagStyle}>{status}</Tag>;
});
