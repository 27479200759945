import React, { useEffect, useRef } from 'react';
import { Modal, Button, Form, Input, Divider, Space } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as DeleteSvg } from '@assets/icons/delete.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const NEW_CONTACT = {
  name: '',
  email: ''
};

const NEW_AGENCY = {
  name: '',
  contacts: [NEW_CONTACT]
};

export function AgencyModel({
  visible,
  onClose,
  agency,
  onSave,
  onDelete
}: {
  visible: boolean;
  onClose: (a: boolean, idx: any) => void;
  onSave: (a: any) => void;
  onDelete: (a: any) => void;
  agency: {
    idx: string;
    name: string;
    contacts: { name: string; email: string }[];
  };
}) {
  const [form] = Form.useForm();
  const contactListScrollerRef = useRef<any>(null);
  useEffect(() => {
    if (!visible) return;
    form.setFieldsValue({ name: agency?.name, contacts: agency?.contacts });
  }, [visible]);
  const onFinish = (v: any) => {
    const isEdit = !!agency?.name;
    const contacts = v?.contacts.filter((item: any) => {
      return item?.name && item?.email;
    });
    const name = v?.name || '';
    onSave({ isEdit, idx: agency.idx, name, contacts });
  };
  return (
    <Modal
      className="campaign_agency_modal"
      visible={visible}
      destroyOnClose
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      width={492}
      onCancel={() => onClose(!!agency?.name, agency.idx)}
      zIndex={1006}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em', textAlign: 'center' }}
        >
          {agency?.name ? 'Edit agency' : 'Add agency'}
        </h2>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ name: agency?.name, contacts: agency?.contacts }}
        >
          <div className="campaign_agency_modal__content">
            <Form.Item
              rules={[
                {
                  pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                  message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                },
                {
                  required: true,
                  message: 'Agency name required.'
                }
              ]}
              name="name"
              label="Agency name"
              validateTrigger="onSubmit"
            >
              <Input size="large" placeholder="Enter agency name" />
            </Form.Item>
            <Divider />
            <Form.List name="contacts">
              {(fields, { add, remove }) => (
                <div className="campaign_agency_modal__body">
                  <span
                    style={{
                      display: 'inline-block',
                      marginBottom: 14,
                      paddingLeft: 8,
                      paddingRight: 24
                    }}
                    className="main-body-text main-body-text--h5 main-body-text--bold"
                  >
                    Contacts
                  </span>
                  <OverlayScrollbarsComponent
                    ref={contactListScrollerRef}
                    style={{
                      flexGrow: 1
                    }}
                    options={{
                      callbacks: {
                        onContentSizeChanged: () => {
                          const osInstance =
                            contactListScrollerRef?.current?.osInstance();
                          if (!osInstance) return;
                          const {
                            max: { y: scrollHeight }
                          } = osInstance.scroll();
                          osInstance.scroll([0, scrollHeight], 150);
                        }
                      }
                    }}
                  >
                    <div className="campaign_agency_modal__contacts">
                      {fields &&
                        fields.map(({ key, name, ...restField }, idx) => (
                          <Space
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="start"
                            size={[8, 0]}
                          >
                            <Form.Item
                              style={{ marginBottom: 0, marginRight: 4 }}
                              {...restField}
                              name={[name, 'name']}
                              rules={[
                                {
                                  pattern:
                                    /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                                  message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                                },
                                {
                                  whitespace: true,
                                  max: 256,
                                  message: 'Please enter a name'
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value?.length &&
                                      getFieldValue('contacts')[idx]?.email
                                        ?.length > 0
                                    ) {
                                      return Promise.reject(
                                        new Error('Fill contact name')
                                      );
                                    }

                                    return Promise.resolve();
                                  }
                                })
                              ]}
                              validateTrigger="onSubmit"
                            >
                              <Input
                                size="large"
                                placeholder="Enter contact name"
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'email']}
                              rules={[
                                {
                                  type: 'email',
                                  max: 256,
                                  message: 'Check the spelling of email'
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value?.length &&
                                      getFieldValue('contacts')[idx]?.name
                                        ?.length > 0
                                    ) {
                                      return Promise.reject(
                                        new Error('Fill contact email')
                                      );
                                    }

                                    return Promise.resolve();
                                  }
                                })
                              ]}
                              style={{ marginBottom: 0, marginLeft: 4 }}
                              validateTrigger="onSubmit"
                            >
                              <Input
                                size="large"
                                placeholder="Enter contact email"
                              />
                            </Form.Item>
                            <Button
                              type="text"
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() => {
                                remove(name);
                              }}
                              className="campaign_agency_modal__delete"
                            >
                              <DeleteSvg style={{ margin: 0 }} />
                            </Button>
                          </Space>
                        ))}
                    </div>
                    <Button
                      type="text"
                      size="small"
                      icon={<PlusSvg />}
                      onClick={(e) => {
                        add();
                        if (document.activeElement instanceof HTMLElement) {
                          document.activeElement.blur();
                        }
                      }}
                      style={{ paddingLeft: 0 }}
                    >
                      Add Contact
                    </Button>
                  </OverlayScrollbarsComponent>
                </div>
              )}
            </Form.List>
          </div>

          <div className="modal_container__actions">
            {agency?.name && (
              <Button
                type="text"
                style={{
                  border: 'none',
                  height: 48,
                  padding: '12px 19px',
                  marginLeft: -19
                }}
                onClick={() => {
                  let action = true;
                  toast(
                    <ActionToast
                      title="Agency deleted"
                      description={`You have removed ${agency?.name}`}
                      closeToast={() => {
                        action = true;
                      }}
                      onUndo={() => {
                        action = false;
                      }}
                    />,
                    {
                      onClose: async () => {
                        if (!action) return;
                        await onDelete(agency.idx);
                      }
                    }
                  );
                }}
              >
                Delete agency
              </Button>
            )}

            <div className="modal_container__actions-wrapper">
              <Button
                type="text"
                size="large"
                style={{
                  marginRight: 12,
                  border: 'none',
                  height: 48,
                  padding: '12px 19px'
                }}
                onClick={() => onClose(!!agency?.name, agency.idx)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="submit_form"
                htmlType="submit"
                style={{ width: 152, height: 48, padding: '8px 15px' }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
