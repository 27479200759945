import { resendOrganizationInvitation } from '@api/Organization';
import { useOrganization } from '@components/OrganizationBoundary';
import { ActionToast } from '@components/Toasts';
import UserAvatar from '@components/UserAvatar';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import { Button } from 'antd';
import React, { memo, useCallback } from 'react';
import { toast } from 'react-toastify';

type UserCellProps = {
  memberId: string;
};
export default memo(function UserCell({ memberId }: UserCellProps) {
  const { currentOrganization } = useOrganization(true);
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;
  const { seatsCount } = currentOrganization;
  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );
  const onResendInvitation = useCallback(() => {
    if (!member?.invitationEmail) return;
    const users = [{ email: member.invitationEmail }];
    resendOrganizationInvitation({ organizationId, users });
    toast(
      <ActionToast
        title="Invitation sent!"
        onUndo={undefined}
        description={undefined}
        closeToast={undefined}
      />,
      {
        hideProgressBar: true,
        style: { width: '345px', height: 80 },
        bodyClassName: 'toast_container--invitation'
      }
    );
  }, [organizationId, member]);

  const maxSeatsReached =
    seatsCount.count >=
    (organization.limits.orgMembers ?? Number.POSITIVE_INFINITY);

  if (!member) return null;
  return (
    <div className="user-cell">
      <UserAvatar
        isActive={!member.invitationEmail}
        size={40}
        src={member.user?.picture.url || ''}
        userEmail={member.user?.email || ''}
        userName={member.user?.name || ''}
      />
      <div className="user-info">
        <div className="name">{member.user?.name}</div>
        <div className="email">
          {member.user?.email || member.invitationEmail}
          {member.invitationEmail && !member.invitationExpired && (
            <Button
              className="email-resend"
              type="link"
              size="small"
              onClick={onResendInvitation}
              disabled={maxSeatsReached}
            >
              Resend
            </Button>
          )}
        </div>
        {member.invitationExpired && (
          <div className="email">
            invitation is expired, please
            <Button
              className="email-resend"
              type="link"
              size="small"
              onClick={onResendInvitation}
              disabled={maxSeatsReached}
            >
              resend it
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});
