import OrganizationInfo from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationInfo';
import React, { memo } from 'react';
import OrganizationTabs from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/OrganizationTabs';

export default memo(function OrganizationTab() {
  return (
    <div className="organization_container">
      <OrganizationInfo />
      <OrganizationTabs />
    </div>
  );
});
