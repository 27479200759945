import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { WorkspaceMembersTable } from '@components/Tables';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import {
  toggleWorkspaceMemberGroupInviteModal,
  toggleWorkspaceMemberGroupModal
} from '@redux/actions/modalAction';
import { useTypedSelector } from '@hooks';
import {
  workspaceCountMembers,
  workspaceListMembers,
  resendInviteWorkspaceMembers
} from '@api/Workspace';
import {
  WorkspaceMemberDto,
  ResendWorkspaceInvitationMemberDto
} from '@api/Api';
import DeleteMemberModal from '@components/Modals/DeleteMemberModal';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { useDebounced } from '@helpers/useDebounced';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import classNames from 'classnames';
import { WorkspaceMemberGroupModalStateProps } from '@redux/types/modalType';
import { apiClient } from '@api/client';

function WorkspaceMemberGroupModal() {
  const modalState = useTypedSelector(
    ({ modal }) => modal.workspaceMemberGroupModal
  );
  if (!modalState) return null;
  return (
    <WorkspaceMemberGroupModalInner {...modalState} key={modalState.key} />
  );
}

function WorkspaceMemberGroupModalInner({
  workspace,
  visible,
  group
}: WorkspaceMemberGroupModalStateProps) {
  const dispatch = useDispatch();
  const [membersToManage, setMembersToManage] = useState<WorkspaceMemberDto[]>(
    []
  );
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);

  const [membersSearchQuery, setMembersSearchQuery] = useState<string>('');
  const debouncedMembersSearchQuery = useDebounced(membersSearchQuery, 250);
  const [membersSort, setMembersSort] = useState<
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'role:ASC'
    | 'role:DESC'
    | 'isActive:ASC'
    | 'isActive:DESC'
  >('createdAt:DESC');

  const membersQuery = useInfiniteQuery({
    queryKey: [
      'workspace',
      'members',
      'list',
      workspace.id,
      group.id,
      debouncedMembersSearchQuery,
      membersSort
    ],
    keepPreviousData: true,
    enabled: visible,
    queryFn: async ({ pageParam, signal }) => {
      const { data } = await workspaceListMembers(
        {
          workspaceId: workspace.id,
          groupId: group.id,
          searchQuery: debouncedMembersSearchQuery,
          after: pageParam,
          orderBy: [membersSort]
        },
        { signal }
      );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersCountQuery = useQuery({
    queryKey: [
      'workspace',
      'members',
      'count',
      workspace.id,
      group.id,
      debouncedMembersSearchQuery
    ],
    keepPreviousData: true,
    enabled: visible,
    queryFn: async ({ signal }) => {
      const { data } = await workspaceCountMembers(
        {
          workspaceId: workspace.id,
          groupId: group.id,
          searchQuery: debouncedMembersSearchQuery
        },
        { signal }
      );
      return data.count;
    }
  });
  const membersList = useMemo(
    () =>
      membersQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => ({
          ...x.node,
          permissions: {
            ...x.node.permissions,
            delete: workspace.permissions.manageMemberGroups
          }
        }))
      ) ?? [],
    [membersQuery.data, workspace.permissions.manageMemberGroups]
  );
  const membersCount = membersCountQuery.data ?? 0;

  const queryClient = useQueryClient();
  const [initialGroupName, setInitialGroupName] = useState(group.name);
  const [groupName, setGroupName] = useState(group.name);
  const renameGroupMutation = useMutation({
    mutationKey: ['ws-member-group-rename'],
    mutationFn: async (name: string) => {
      await apiClient.workspace.workspaceControllerRenameWorkspaceMemberGroup({
        workspaceId: workspace.id,
        groupId: group.id,
        name
      });
    },
    onSuccess: (_, name: string) => {
      setInitialGroupName(name);
    },
    onSettled: () => {
      setTimeout(() => {
        if (
          !queryClient.isMutating({ mutationKey: ['ws-member-group-rename'] })
        ) {
          queryClient.invalidateQueries(['workspace', 'member-groups']);
          queryClient.invalidateQueries(['workspace', 'members']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
        }
      });
    }
  });

  const deleteMemberMutation = useMutation({
    mutationKey: ['ws-member-delete-from-group'],
    mutationFn: async (memberIds: string[]) => {
      await apiClient.workspace.workspaceControllerDeleteWorkspaceMemberGroupMembers(
        {
          workspaceId: workspace.id,
          groupId: group.id,
          memberId: memberIds
        }
      );
    },
    onMutate: async (memberIds) => {
      setMembersToManage([]);
      const queryKey = ['workspace', 'members', 'list', workspace.id, group.id];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData<typeof membersQuery.data>(queryKey, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.filter(
                (edge) => !memberIds.includes(edge.node.id)
              )
            };
          })
        };
      });
    },
    onSettled: () => {
      setTimeout(() => {
        if (
          !queryClient.isMutating({
            mutationKey: ['ws-member-delete-from-group']
          })
        ) {
          queryClient.invalidateQueries(['workspace', 'member-groups']);
          queryClient.invalidateQueries(['workspace', 'members']);
          queryClient.invalidateQueries(['campaign', 'members']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
          queryClient.invalidateQueries(['folder', 'members']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
        }
      });
    }
  });

  const onResendInvitationEmail = useCallback(
    async (member: ResendWorkspaceInvitationMemberDto) => {
      if (!workspace.id) return;
      await resendInviteWorkspaceMembers({
        workspaceId: workspace.id,
        users: [member]
      });
      toast(
        <ActionToast
          title="Invitation sent!"
          onUndo={undefined}
          description={undefined}
          closeToast={undefined}
        />,
        {
          hideProgressBar: true,
          style: { width: '345px', height: 80 },
          bodyClassName: 'toast_container--invitation'
        }
      );
    },
    [workspace.id]
  );

  const handleSort = useCallback((value: string) => {
    switch (value) {
      case 'createdAt:ASC':
      case 'createdAt:DESC':
      case 'role:ASC':
      case 'role:DESC':
      case 'isActive:ASC':
      case 'isActive:DESC': {
        setMembersSort(value);
        break;
      }
      default: {
        setMembersSort('createdAt:DESC');
      }
    }
  }, []);

  const showFooter = membersToManage.length > 0;

  return (
    <Modal
      open={visible}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={1000}
      zIndex={1005}
      onCancel={() => dispatch(toggleWorkspaceMemberGroupModal(null))}
      className="members-modal group-modal"
      footer={
        <div
          className={classNames({
            'members-modal__footer': true,
            'members-modal__footer--visible': showFooter
          })}
        >
          <p className="main-body-text main-body-text--small main-body-text--dark-gray">
            {membersToManage.length} members
            {membersToManage.length > 1 ? 's' : ''} selected
          </p>
          {workspace.permissions.manageMemberGroups && (
            <Button
              type="link"
              className="main-body-text main-body-text--small main-body-text--semibold main-body-text--dark-gray"
              onClick={() => setShowDeleteMemberModal(true)}
            >
              Remove selected
            </Button>
          )}
        </div>
      }
    >
      <div className="modal_container">
        <div className="group-name-input" style={{ marginBottom: 20 }}>
          <Input
            size="large"
            value={groupName}
            readOnly={!workspace.permissions.manageMemberGroups}
            onChange={(e) => setGroupName(e.target.value)}
            onBlur={() => {
              const name = groupName.trim();
              if (name === initialGroupName) return;
              if (!name) {
                setGroupName(initialGroupName);
                return;
              }
              renameGroupMutation.mutate(name);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                (e.target as HTMLInputElement).blur();
              }
            }}
            maxLength={255}
          />
        </div>
        <div style={{ display: 'flex', gap: 24, marginBottom: 20 }}>
          <Input
            placeholder="Search by name or email"
            prefix={<SearchSvg />}
            size="large"
            style={{ width: '100%', borderRadius: 8 }}
            value={membersSearchQuery}
            onChange={(e) => setMembersSearchQuery(e.target.value)}
          />
          {workspace.permissions.manageMemberGroups && (
            <Button
              type="primary"
              size="large"
              style={{
                width: 184,
                height: 48,
                padding: '8px 15px'
              }}
              onClick={() =>
                dispatch(
                  toggleWorkspaceMemberGroupInviteModal({
                    visible: true,
                    workspace,
                    group
                  })
                )
              }
            >
              Invite members
            </Button>
          )}
        </div>
        <div className="modal_container__workspace_members">
          <OverlayScrollbarsComponent
            className="table_container"
            style={{ marginRight: -20 }}
          >
            <InfiniteScroll
              loading={membersQuery.isFetchingNextPage ? 'more' : false}
              next={membersQuery.fetchNextPage}
              hasMore={membersQuery.hasNextPage ?? false}
            >
              <WorkspaceMembersTable
                setShowDeleteMemberModal={setShowDeleteMemberModal}
                setMembersToManage={
                  workspace.permissions.manageMemberGroups
                    ? async (ids, members, isAllSelect) => {
                        let query = membersQuery;
                        if (isAllSelect && ids.length) {
                          while (query.hasNextPage) {
                            query = await query.fetchNextPage();
                          }
                        }
                        setMembersToManage((prev) => {
                          const isDeselect =
                            isAllSelect &&
                            (!ids.length ||
                              prev.some((x) => ids.includes(x.id)));
                          if (isDeselect) return [];
                          if (isAllSelect) {
                            return (
                              query.data?.pages.flatMap((x) =>
                                x.edges.map((x) => x.node)
                              ) ?? []
                            );
                          }
                          const newMembers = members.filter(
                            (x) => !prev.some((y) => x.id === y.id)
                          );
                          return [...prev, ...newMembers].filter((x) =>
                            ids.includes(x.id)
                          );
                        });
                      }
                    : undefined
                }
                onResendInvitationEmail={onResendInvitationEmail}
                onSort={handleSort}
                count={membersCount}
                edges={membersList}
                selectedIds={membersToManage.map((x) => x.id)}
                isLoading={
                  membersQuery.isLoading ||
                  membersQuery.isPreviousData ||
                  membersQuery.isFetchingNextPage
                }
              />
            </InfiniteScroll>
          </OverlayScrollbarsComponent>
        </div>
        <DeleteMemberModal
          members={membersToManage}
          visible={showDeleteMemberModal}
          handleShowDeleteMemberModal={setShowDeleteMemberModal}
          handleDeleteMembers={deleteMemberMutation.mutate}
        />
      </div>
    </Modal>
  );
}

export default WorkspaceMemberGroupModal;
