import React, { useEffect, useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import { ReactComponent as CampaingSvg } from '@assets/icons/campaing.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import {
  toggleCampaignInvitationModal,
  toggleReachedEntityLimitModal
} from '@redux/actions/modalAction';
import {
  findCampaignMemberGroupsToInvite,
  findCampaignMembersToInvite,
  inviteMemberToCampaign
} from '@api/Campaign';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { useAuth } from '@hooks/useAuth';
import { MixpanelService, MixpanelEventType } from '@services/mixpanelService';
import { nonNull } from '@helpers/non-null';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function InviteToCampaignModal() {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');
  const campaignInvitationModal = useTypedSelector(
    ({ modal }) => modal.campaignInvitationModal
  );
  const [customMessage, setCustomMessage] = useState<string | false>(false);
  const [selected, setSelected] = useState<SelectedItem[]>([]);

  useEffect(() => {
    setSelected([]);
    setCustomMessage(false);
  }, [campaignInvitationModal]);

  const queryClient = useQueryClient();
  const inviteMutation = useMutation({
    mutationFn: () =>
      inviteMemberToCampaign({
        campaignId: campaignInvitationModal?.campaign?.id || '',
        message: customMessage || undefined,
        memberIdsOrEmails: selected
          .filter((x) => x.type === 'new' || x.type === 'member')
          .map((x) => (x.type === 'new' ? x.email : x.id)),
        groupIds: selected.filter((x) => x.type === 'group').map((x) => x.id)
      }),
    onSuccess: () => {
      toast(
        <ActionToast
          title={`Invitation${selected.length > 1 ? 's' : ''} sent!`}
          onUndo={undefined}
          description={undefined}
          closeToast={undefined}
        />,
        {
          hideProgressBar: true,
          style: { width: '345px', height: 80 },
          bodyClassName: 'toast_container--invitation'
        }
      );
      const campaign = nonNull(campaignInvitationModal?.campaign);
      MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.MANAGE_CAMPAIGNS,
        {
          campaignName: campaign.name,
          deleteCampaign: false,
          newCampaign: false,
          privateCampaign: campaign.isPrivate,
          sharedCampaign: !campaign.isPrivate,
          addTaskGroup: false,
          addContributor: true
        }
      );
      dispatch(toggleCampaignInvitationModal(null));
    },
    onError: (e: any) => {
      if (e?.response?.data?.errorCode === 'subscription_users_limit_error')
        dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity: 'contributor'
          })
        );
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['workspace', 'members']),
        queryClient.invalidateQueries(['campaign', 'members']),
        queryClient.invalidateQueries(['workspace', 'member-groups']),
        queryClient.invalidateQueries(['campaign', 'member-groups'])
      ]);
    }
  });

  return (
    <Modal
      visible={campaignInvitationModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={468}
      onCancel={() => {
        dispatch(toggleCampaignInvitationModal(null));
      }}
      zIndex={1006}
    >
      <div className="modal_container">
        <h2 className="modal_container__title" style={{ fontSize: '22px' }}>
          Add contributor
        </h2>
        <div className="modal_container__entity_info">
          <div className="campaign_icon">
            <CampaingSvg />
          </div>
          <span className="campaign_title">
            {campaignInvitationModal?.campaign.name}
          </span>
        </div>
        <div style={{ paddingBottom: 8 }}>
          Invite contributor with email or username
        </div>
        <InviteMembersSelect
          key={campaignInvitationModal?.campaign.id}
          value={selected}
          onChange={setSelected}
          queryEnabled={!!campaignInvitationModal?.visible}
          membersQueryKey={[
            'campaign',
            'members',
            'find-to-invite',
            campaignInvitationModal?.campaign.id
          ]}
          membersQueryFn={async ({ pageParam, searchQuery, signal }) => {
            const { data } = await findCampaignMembersToInvite(
              {
                targetId: nonNull(campaignInvitationModal).campaign.id,
                after: pageParam,
                searchQuery
              },
              { signal }
            );
            return data;
          }}
          groupsQueryKey={[
            'campaign',
            'groups',
            'find-to-invite',
            campaignInvitationModal?.campaign.id
          ]}
          groupsQueryFn={async ({ pageParam, searchQuery, signal }) => {
            const { data } = await findCampaignMemberGroupsToInvite(
              {
                targetId: nonNull(campaignInvitationModal).campaign.id,
                after: pageParam,
                searchQuery
              },
              { signal }
            );
            return data;
          }}
        />
        <Button
          className="modal_container__custom_message"
          type="link"
          onClick={() => setCustomMessage('')}
        >
          <PlusSvg /> Add custom message
        </Button>
        {customMessage !== false && (
          <Input.TextArea
            onChange={(e) => setCustomMessage(e.target.value)}
            rows={4}
          />
        )}
        <div className="modal_container__actions" style={{ marginTop: 24 }}>
          <Button
            type="text"
            size="large"
            style={{
              marginRight: '16px',
              border: 'none',
              height: 48,
              padding: '8px 16px'
            }}
            onClick={() => dispatch(toggleCampaignInvitationModal(null))}
          >
            Cancel
          </Button>
          <Button
            disabled={selected.length === 0}
            type="primary"
            size="large"
            className="submit_form"
            style={{ width: 216, height: 48, padding: '8px 15px' }}
            onClick={() => inviteMutation.mutate()}
            loading={inviteMutation.isLoading}
          >
            Invite to campaign
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default InviteToCampaignModal;
