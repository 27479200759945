import React from 'react';
import ProfileAvatar from '@pages/ProfileSettings/Profile/components/ProfileAvatar';
import UserInfo from '@pages/ProfileSettings/Profile/components/UserInfo';

function Profile(): React.ReactElement {
  return (
    <div className="profile_container">
      <ProfileAvatar />
      <UserInfo />
    </div>
  );
}

export default Profile;
