import LottieComponent from '@components/Lottie';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { memo, useState, useEffect, useCallback } from 'react';
import { useCurrentWorkspace } from '@hooks/workspace';
import { AssetForApprovalDto, PageDto } from '@api/Api';
import {
  getCountListAssetsWithApproval,
  getListAssetsWithApproval
} from '@api/Approval';

import './ApprovalsCard.less';

import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardApprovalsFilter from '@pages/Dashboard/components/DashboardFilter/DashboardApprovalsFilter';
import ApprovalsList from '@pages/Dashboard/components/ApprovalsCard/ApprovalsList';

export default memo(function ApprovalsCard() {
  const [currentWorkspace] = useCurrentWorkspace(false);
  const [listType, setListType] = useState<'all' | 'assigned' | 'own'>('all');
  const [approvalsCount, setApprovalsCount] = useState<number>(0);
  const [approvalsList, setApprovalsList] = useState<
    PageDto & {
      edges: {
        node: AssetForApprovalDto;
        cursor: string;
      }[];
    }
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getApprovalsData = useCallback(async () => {
    if (!currentWorkspace?.id) return;
    const { data } = await getListAssetsWithApproval({
      workspaceId: currentWorkspace.id,
      needMyDecision: listType === 'assigned' ? true : undefined,
      own: listType === 'own' ? true : undefined,
      my: listType === 'all' ? true : undefined
    });
    const {
      data: { count }
    } = await getCountListAssetsWithApproval({
      workspaceId: currentWorkspace.id,
      needMyDecision: listType === 'assigned' ? true : undefined,
      own: listType === 'own' ? true : undefined,
      my: listType === 'all' ? true : undefined
    });
    setApprovalsCount(count);
    setApprovalsList(data);
  }, [currentWorkspace, listType]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getApprovalsData();
      setIsLoading(false);
    })();
  }, [getApprovalsData]);

  const loadMoreApprovals = useCallback(async () => {
    if (!currentWorkspace?.id || !approvalsList?.endCursor) return;
    const { data } = await getListAssetsWithApproval({
      workspaceId: currentWorkspace.id,
      needMyDecision: listType === 'assigned' ? true : undefined,
      own: listType === 'own' ? true : undefined,
      my: listType === 'all' ? true : undefined,
      after: approvalsList?.endCursor
    });
    setApprovalsList((prevState) => ({
      ...data,
      edges: [...(prevState?.edges || []), ...data.edges]
    }));
  }, [currentWorkspace?.id, approvalsList?.endCursor, listType]);

  const onApprovalsFilterChange = useCallback(
    (v: 'all' | 'assigned' | 'own') => setListType(v),
    []
  );

  return (
    <div className="card_container">
      <div className="card_container__header">
        <span className="copy">
          <LottieComponent className="icon" isLooped={false} view="star" />
          MY APPROVALS
          <div className="count">{approvalsCount}</div>
        </span>
        <DashboardApprovalsFilter
          listType={listType}
          onFilterChange={onApprovalsFilterChange}
        />
      </div>
      <OverlayScrollbarsComponent>
        <div className="card_container__body">
          <div className="approval-container" id="approval-container">
            <InfiniteScroll
              dataLength={approvalsList?.edges.length || 0}
              hasMore={!!approvalsList?.hasNext}
              next={loadMoreApprovals}
              loader={<></>}
            >
              <ApprovalsList
                list={approvalsList?.edges.map((it) => it.node)}
                getApprovalsData={getApprovalsData}
                isLoading={isLoading}
              />
            </InfiniteScroll>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
});
