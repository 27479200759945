import { OrganizationItemDto } from '@api/Api';
import moment from 'moment';

export default function isTrialEnd(
  organization: OrganizationItemDto
): 'members-limit-exceeded' | 'asset-summary-size-limit-exceeded' | null {
  if (organization.planType !== 'free') return null;

  //   const nowT = new Date('2022-11-07T22:00:00.000Z').getTime();
  //   const trialT = new Date('2022-11-06T22:00:00.000Z').getTime();
  //   const lockT = new Date('2022-11-14T22:00:00.000Z').getTime() - 6.912e8;

  const now = moment(new Date().toISOString().split('T')[0]).toISOString();
  const nowInSeconds = new Date(now).getTime();

  const trialEndDate = moment(organization.trialEndDate?.split('T')[0])
    .subtract(8, 'days')
    .toISOString();
  const trialEndDateInSeconds = new Date(trialEndDate).getTime();

  const lockDateSubtractEight = moment(organization.lockDate?.split('T')[0])
    .subtract(8, 'days')
    .toISOString();
  const lockDateSubtractEightInSeconds = new Date(
    lockDateSubtractEight
  ).getTime();

  //   if (nowT >= trialT && trialT >= lockT) {
  //     return 'asset-summary-size-limit-exceeded';
  //     if (organization.lockReasons.includes('members-limit-exceeded'))
  //       return 'asset-summary-size-limit-exceeded';

  //     if (organization.lockReasons.includes('members-limit-exceeded'))
  //       return 'members-limit-exceeded';
  //   }

  if (
    nowInSeconds >= trialEndDateInSeconds &&
    trialEndDateInSeconds >= lockDateSubtractEightInSeconds
  ) {
    if (organization.lockReasons.includes('members-limit-exceeded'))
      return 'asset-summary-size-limit-exceeded';

    if (organization.lockReasons.includes('members-limit-exceeded'))
      return 'members-limit-exceeded';
  }
  return null;
}
