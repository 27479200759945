/* eslint-disable react/no-children-prop */
import React from 'react';
import './AssetsEmptyState.less';
import { Button, Upload } from 'antd';
import { useTypedSelector } from '@hooks';
import { useAssetsUppy } from '@context/AssetsUppyContext';
import LottieComponent from '@components/Lottie';

function AssetsEmptyState({
  isSearchEmptyState
}: {
  isSearchEmptyState?: boolean;
}) {
  const uppy = useAssetsUppy();
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const folder = useTypedSelector(({ assets }) => assets.selectedFolder);
  const onAddFile = async (data: any) => {
    if (!campaign?.id) return;
    uppy.addFile({
      name: data.file.name,
      type: data.file.type,
      data: data.file,
      meta: {
        relativePath: new Date().toISOString(),
        campaignId: campaign.id,
        folderId: folder?.id
      }
    });
    await uppy.upload();
  };

  const showUpload =
    !isSearchEmptyState && !!campaign?.permissions.createAssets;
  return showUpload ? (
    <Upload
      multiple
      showUploadList={false}
      customRequest={(data) => {
        onAddFile(data);
      }}
      className="assets_empty_state_upload_area"
    >
      <div className="assets_empty_state">
        <div className="block">
          <LottieComponent className="icon" view="upload" />

          <p>Drag & drop files or folders</p>
          <div className="divider">or</div>
          <Button
            style={{ width: 152, height: 48, padding: '8px 15px' }}
            type="primary"
          >
            Browse files
          </Button>
        </div>
      </div>
    </Upload>
  ) : (
    <div className="assets_empty_state assets_empty_state--search">
      <div className="block">
        <LottieComponent className="icon" view="noSearchingResult" />
        <strong
          className="main-body-text main-body-text--bold"
          style={{ marginBottom: 8 }}
        >
          No results found
        </strong>
        <p className="main-body-text main-body-text--dark-gray">
          It seems we can’t find any results based on your search
        </p>
      </div>
    </div>
  );
}

AssetsEmptyState.defaultProps = {
  isSearchEmptyState: false
};

export default AssetsEmptyState;
