import {
  AWStageMemberDto,
  CampaignMemberDto,
  MemberDto,
  TaskItemMemberDto
} from '@api/Api';
import UserAvatar from '@components/UserAvatar';
import { Popover } from 'antd';
import React, { memo, useCallback, useMemo } from 'react';
import './AvatarsGroup.less';

type UsersProps =
  | AWStageMemberDto[]
  | CampaignMemberDto[]
  | TaskItemMemberDto[]
  | MemberDto[];
type UserProps =
  | AWStageMemberDto
  | CampaignMemberDto
  | TaskItemMemberDto
  | MemberDto;

type AvatarsGroupProps = {
  maxCount: number;
  avatarSize: number;
  users: UsersProps;
  membersType:
    | 'aw_stage_members'
    | 'campaign_members'
    | 'task_member'
    | 'member';
  totalCount?: number;
};

export default memo(function AvatarsGroup({
  users,
  maxCount,
  avatarSize,
  membersType,
  totalCount
}: AvatarsGroupProps) {
  const meFirstUserList = useCallback(
    (users) => {
      const me = users.find(
        (it: UserProps) =>
          (it as AWStageMemberDto).me ||
          (it as CampaignMemberDto | TaskItemMemberDto).user?.me
      );
      if (!me) return users;
      const filteredList = users.filter((it: UserProps) => {
        if (membersType === 'aw_stage_members')
          return !(it as AWStageMemberDto).me;
        return !(it as CampaignMemberDto | TaskItemMemberDto).user?.me;
      });
      return [me, ...filteredList];
    },
    [membersType]
  );

  const maxUsersFormatted = useMemo(() => {
    const list = meFirstUserList(users);
    return list.slice(0, maxCount) as UsersProps;
  }, [maxCount, users, meFirstUserList]);
  const restUsersFormatted = useMemo(() => {
    const list = meFirstUserList(users);
    return list.slice(maxCount) as UsersProps;
  }, [maxCount, users, meFirstUserList]);
  const totalUsersCount = Math.max(totalCount ?? 0, users.length);
  const restUsersCount = Math.max(0, totalUsersCount - maxCount);

  const generateUserObject = useCallback(
    (user: UserProps) => {
      if (membersType === 'campaign_members') {
        const _users = user as CampaignMemberDto;
        return {
          id: _users.id,
          isActive: _users.isActive,
          src: _users.user?.picture.url || '',
          userEmail: _users.user?.email || _users.invitationEmail || '',
          userName: _users.user?.name || ''
        };
      }
      if (membersType === 'aw_stage_members') {
        const _users = user as AWStageMemberDto;
        return {
          id: _users.email,
          isActive: true,
          src: _users.picture.url || '',
          userEmail: _users.email,
          userName: _users.name || ''
        };
      }
      if (membersType === 'task_member') {
        const _users = user as TaskItemMemberDto;
        return {
          id: _users.id,
          isActive: _users.isActive,
          src: _users.user?.picture.url || '',
          userEmail: _users.user?.email || _users.invitationEmail || '',
          userName: _users.user?.name || ''
        };
      }
      if (membersType === 'member') {
        const _users = user as MemberDto;
        return {
          id: _users.id,
          isActive: _users.isActive,
          src: _users.user?.picture.url || '',
          userEmail: _users.user?.email || _users.invitationEmail || '',
          userName: _users.user?.name || ''
        };
      }
      return null;
    },
    [membersType]
  );

  const containerWidth = useMemo(() => {
    const iconsCount = maxUsersFormatted.length;
    if (iconsCount === 1) return avatarSize;
    const width = iconsCount * avatarSize - (iconsCount - 1) * 8;
    return width;
  }, [avatarSize, maxUsersFormatted.length]);

  return (
    <div className="avatars-group-container">
      <div
        className="avatars-group-container--items"
        style={{ width: containerWidth }}
      >
        {maxUsersFormatted.map((it, idx) => {
          const user = generateUserObject(it);
          if (!user) return null;
          return (
            <div
              key={user.id}
              className="item"
              style={{
                left: -(idx * 8),
                zIndex: maxUsersFormatted.length - idx
              }}
            >
              <UserAvatar
                isActive={user.isActive}
                size={avatarSize}
                src={user.src}
                userEmail={user.userEmail}
                userName={user.userName}
                showUserPopover={true}
              />
            </div>
          );
        })}
      </div>
      {restUsersCount > 0 && (
        <Popover
          content={
            <div className="users-list-popover">
              {restUsersFormatted.map((it) => {
                const user = generateUserObject(it);
                if (!user) return null;
                return (
                  <div key={user.id} className="users-list-popover--item">
                    <UserAvatar
                      isActive={true}
                      size={42}
                      src={user.src}
                      userEmail={user.userEmail}
                      userName={user.userName}
                    />
                    <div className="user-information">
                      <div className="user-information--name">
                        {user.userEmail}
                      </div>
                      <div className="user-information--email">
                        {user.userName}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
          trigger={restUsersFormatted.length ? ['hover'] : []}
          placement="bottom"
        >
          <div className="avatars-group-container--rest">+{restUsersCount}</div>
        </Popover>
      )}
    </div>
  );
});
