import React, { memo } from 'react';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { useDeleteTask, useRestoreTask, useUpdateTask } from '@hooks/tasks';

interface ActionsColumnProps {
  taskId: string;
}

export default memo(function ActionsColumn(props: ActionsColumnProps) {
  const { taskId } = props;
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();
  const restoreTask = useRestoreTask();

  if (!task.permissions.delete && !task.permissions.update) return null;
  const isDone = task.status === 'done';
  const menuItems = [
    task.deletedAt && task.permissions.delete
      ? {
          key: 'restore',
          onClick: () => {
            setTimeout(() => {
              restoreTask({ id: task.id });
            }, 500);
          },
          label: 'Restore'
        }
      : null,
    !isDone && !task.deletedAt && task.permissions.update
      ? {
          key: '',
          onClick: () =>
            updateTask({
              id: task.id,
              status: 'done'
            }),
          label: 'Mark as complete'
        }
      : null,
    task.permissions.delete
      ? {
          key: 'delete',
          onClick: () => {
            setTimeout(() => {
              deleteTask({ id: task.id, permanent: !!task.deletedAt });
            }, 500);
          },
          label: task.deletedAt ? 'Delete forever' : 'Delete'
        }
      : null
  ].filter((item) => Boolean(item));
  if (!menuItems.length) return null;
  return (
    <Dropdown
      overlay={
        <Menu
          selectable={false}
          style={{ padding: '16px 24px', width: 250 }}
          items={menuItems}
        />
      }
      overlayClassName="popover-container"
      overlayStyle={{ paddingRight: 8, paddingLeft: 8 }}
      placement="bottomLeft"
      trigger={['click']}
    >
      <div
        className="action-icon-content"
        id={`task-table-action-content-${task.id}`}
      >
        <ActionSvg />
      </div>
    </Dropdown>
  );
});
