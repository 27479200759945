import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Modal, Tabs } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import './CampaignInfoModal.less';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { toggleCampaignInfoModal } from '@redux/actions/modalAction';

import { renameCampaign } from '@redux/actions/campaignAction';
import { useSearchParams } from 'react-router-dom';
import CampaignDetailsTab from './CampaignDetailsTab';
import CampaignSettingsTab from './CampaignSettingsTab';
import CampaignSharedLinksTab from './CampaignSharedLinksTab';
import CampaignMembersTab from './CampaignMembersTab';
import UnsavedChangesModal from './UnsavedChangesModal';

const { TabPane } = Tabs;

function CampaignInfoModal() {
  const [detailsForm] = Form.useForm();
  const [campaignNameForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const selectedCampaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const campaignEdges = useTypedSelector(({ campaign }) => campaign.edges);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>('details');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [needChangeTab, setNeedChangeTab] = useState<string | false>(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

  const detailsTabScrollerRef = useRef<any>(null);

  const onModalClose = () => {
    const touched = detailsForm.isFieldsTouched();
    const isLinkTouched =
      detailsForm.getFieldValue('links') &&
      JSON.stringify(detailsForm.getFieldValue('links')) !==
        JSON.stringify(campaignInfoModal?.campaign?.links);

    const isAgenciesTouched =
      detailsForm.getFieldValue('agencies') &&
      JSON.stringify(
        detailsForm
          .getFieldValue('agencies')
          .map((el: any) => (typeof el === 'string' ? JSON.parse(el) : el))
      ) !== JSON.stringify(campaignInfoModal?.campaign?.agencies);

    if (isEditMode && (touched || isLinkTouched || isAgenciesTouched)) {
      setShowUnsavedModal(true);
    } else {
      setShowUnsavedModal(false);
      dispatch(toggleCampaignInfoModal(null));
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    if (!campaignInfoModal) return;
    let campaign = selectedCampaign;
    if (!campaign)
      campaign =
        campaignEdges?.find(
          (el) => el.node.id === campaignInfoModal.campaign?.id
        )?.node || null;
    if (!campaign) return;
    dispatch(
      toggleCampaignInfoModal({
        ...campaignInfoModal,
        campaign
      })
    );
  }, [selectedCampaign, campaignEdges]);

  useEffect(() => {
    if (campaignInfoModal?.visible) {
      setSelectedTab(campaignInfoModal?.activeTab);
      campaignNameForm.setFieldsValue({
        campaignName: campaignInfoModal.campaign?.name || ''
      });
      setNeedChangeTab(false);
    }
  }, [campaignInfoModal?.visible]);

  const onRenameCampaign = () => {
    const errors = campaignNameForm.getFieldError('campaignName');
    if (errors.length) return;
    const name = campaignNameForm.getFieldValue('campaignName');
    dispatch(
      renameCampaign({ id: campaignInfoModal?.campaign?.id || '', name })
    );
  };

  return (
    <>
      <Modal
        visible={campaignInfoModal?.visible}
        footer={null}
        centered={true}
        destroyOnClose
        closeIcon={<CrossSvg />}
        width={912}
        bodyStyle={{ height: 912, overflow: 'auto' }}
        onCancel={onModalClose}
        className="campaign-info-modal"
        zIndex={1005}
      >
        <div className="modal_container">
          <h2
            className="modal_container__title"
            style={{
              fontSize: 22,
              marginBottom: 8,
              wordBreak: 'break-word',
              marginRight: 60
            }}
          >
            <Form form={campaignNameForm} style={{ height: 'auto' }}>
              <Form.Item
                className="enitity-creation-modal__name-item"
                name="campaignName"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    whitespace: true,
                    message: "'name' cannot be empty."
                  },
                  {
                    pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                    message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                  },
                  {
                    required: true,
                    message: 'Name is required.'
                  }
                ]}
              >
                <Input.TextArea
                  style={
                    campaignInfoModal?.campaign?.permissions.changeDetails
                      ? {}
                      : { pointerEvents: 'none' }
                  }
                  className="campaign_name_text_area"
                  size="large"
                  placeholder="Campaign name"
                  onBlur={onRenameCampaign}
                  maxLength={60}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                />
              </Form.Item>
            </Form>
          </h2>
          <div className="modal_container__campaign_info">
            <Tabs
              onChange={(activeKey) => {
                if (selectedTab !== 'details') {
                  setSelectedTab(activeKey);
                  return;
                }
                const touched = detailsForm.isFieldsTouched();
                const isLinkTouched =
                  detailsForm.getFieldValue('links') &&
                  JSON.stringify(detailsForm.getFieldValue('links')) !==
                    JSON.stringify(campaignInfoModal?.campaign?.links);

                const isAgenciesTouched =
                  detailsForm.getFieldValue('agencies') &&
                  JSON.stringify(
                    detailsForm
                      .getFieldValue('agencies')
                      .map((el: any) =>
                        typeof el === 'string' ? JSON.parse(el) : el
                      )
                  ) !== JSON.stringify(campaignInfoModal?.campaign?.agencies);

                if (
                  selectedTab === 'details' &&
                  isEditMode &&
                  (touched || isLinkTouched || isAgenciesTouched)
                ) {
                  setShowUnsavedModal(true);
                  setNeedChangeTab(activeKey);
                  return;
                }
                setSelectedTab(activeKey);
              }}
              activeKey={selectedTab}
            >
              <TabPane tab="Details" key="details" style={{ height: '100%' }}>
                <OverlayScrollbarsComponent
                  ref={detailsTabScrollerRef}
                  className="campaign-info-modal__tab-item campaign-info-modal__tab-item--details"
                >
                  <CampaignDetailsTab
                    detailsForm={detailsForm}
                    scrollerInstance={detailsTabScrollerRef?.current?.osInstance()}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                  />
                </OverlayScrollbarsComponent>
              </TabPane>
              <TabPane tab="Members" key="members">
                <div className="campaign-info-modal__tab-item campaign-info-modal__tab-item--members">
                  <CampaignMembersTab
                    selectedTab={selectedTab}
                    changeSelectedTab={setSelectedTab}
                  />
                </div>
              </TabPane>
              <TabPane tab="Shared links" key="shared_links">
                <OverlayScrollbarsComponent className="campaign-info-modal__tab-item">
                  <CampaignSharedLinksTab />
                </OverlayScrollbarsComponent>
              </TabPane>
              <TabPane
                tab="Settings"
                key="settings"
                style={{ height: '100%', paddingBottom: 32 }}
              >
                <OverlayScrollbarsComponent className="campaign-info-modal__tab-item">
                  <CampaignSettingsTab
                    campaign={campaignInfoModal?.campaign as any}
                  />
                </OverlayScrollbarsComponent>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Modal>

      <UnsavedChangesModal
        visible={showUnsavedModal}
        onCancel={() => {
          setShowUnsavedModal(false);
          setIsEditMode(false);
          detailsForm.resetFields();
          if (needChangeTab) setSelectedTab(needChangeTab);
          else dispatch(toggleCampaignInfoModal(null));
        }}
        onClose={() => {
          setShowUnsavedModal(false);
        }}
        onSave={() => {
          detailsForm.submit();
          setShowUnsavedModal(false);

          setTimeout(() => {
            if (needChangeTab) setSelectedTab(needChangeTab);
          }, 200);
        }}
      />
    </>
  );
}

export default CampaignInfoModal;
