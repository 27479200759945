import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { TasksTable } from '@components/Tables';
import { CampaignItemDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import '@components/Tasks/TaskModal/TaskModal.less';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  campaignStateSelector,
  tasksBoardSelector
} from '@redux/selectors/tasks';
import {
  createTaskBoard,
  deleteTaskBoard,
  moveTaskBoard
} from '@redux/actions/tasks';
import TaskBoardSkeleton from '@components/Tasks/TaskBoardSkeleton';
import { nonNull } from '@helpers/non-null';
import TaskBoardItem, {
  TaskBoardItemProps
} from '@pages/Campaigns/CampaignItem/components/TasksTab/TaskBoardItem';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { Button } from 'antd';
import { useOrganization } from '@components/OrganizationBoundary';
import { toggleReachedEntityLimitModal } from '@redux/actions/modalAction';
import DeleteTaskGroupModal from '@pages/Campaigns/CampaignItem/components/DeleteTaskGroupModal';
import {
  TaskDragHandle,
  TaskDragHandleContextProvider
} from '@components/Tables/TasksTable';
import store from '@redux/store';
import { useUpdateTask } from '@hooks/tasks';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { useSearchParams } from 'react-router-dom';
import { CompleteListFetchType } from '@redux/types/tasks';
import TaskBoardEmptyState from '@pages/Campaigns/CampaignItem/components/TasksTab/TaskBoardEmptyState';

interface TasksTabProps {
  boardsLoading: false | CompleteListFetchType;
  campaign: CampaignItemDto;
}

export default memo(function TasksTab(props: TasksTabProps) {
  const { campaign, boardsLoading } = props;
  const campaignId = campaign.id;
  const dispatch = useTypedDispatch();
  const updateTask = useUpdateTask();
  const { currentOrganization } = useOrganization(true);
  const [searchParams] = useSearchParams();
  const { onStartTour } = useContext(OnboardingProcessContext);
  const boardsCount = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).boards.count
  );
  const boardIds = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).boards.ids
  );
  const initiallyLoaded = useTypedSelector(
    (state) =>
      campaignStateSelector(state, { campaignId }).boards.fetch.initiallyLoaded
  );
  const taskDraggable = useTypedSelector(
    (state) =>
      campaignStateSelector(state, { campaignId }).orderBy === 'order:ASC'
  );
  const disableFetch = useTypedSelector(
    (state) =>
      !!campaignStateSelector(state, { campaignId }).boards.fetch.newRequest
  );
  const skeletonsCount = initiallyLoaded ? 0 : Math.max(boardsCount, 1);

  const exceededBoardsLimit =
    boardsCount >=
    (currentOrganization.entity?.limits.campaignTaskBoards ??
      Number.POSITIVE_INFINITY);

  const [draggingTaskId, setDraggingTaskId] = useState<string | null>(null);
  const [overTaskId, setOverTaskId] = useState<string | null>(null);
  const [dropTaskAfter, setDropTaskAfter] = useState<boolean>(false);
  const [draggingBoardId, setDraggingBoardId] = useState<string | null>(null);
  const [overBoardId, setOverBoardId] = useState<string | null>(null);

  const boardIdsRef = useRef(boardIds);
  boardIdsRef.current = boardIds;

  const draggingBoardIdRef = useRef(draggingBoardId);
  draggingBoardIdRef.current = draggingBoardId;

  const overBoardIdRef = useRef(overBoardId);
  overBoardIdRef.current = overBoardId;

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  useEffect(() => {
    if (boardsLoading) return;
    const startTour = searchParamsRef.current.get('startTour') ?? undefined;

    if (startTour === 'create-task') {
      onStartTour({ type: startTour as any });
    }
  }, [onStartTour, boardsLoading]);

  const onDragStart = useCallback((boardId: string) => {
    setDraggingBoardId(boardId);
    setOverBoardId(boardId);
  }, []);
  const onDragEnter = useCallback((boardId: string) => {
    setOverBoardId(boardId);
  }, []);
  const onDragEnd = useCallback(() => {
    if (draggingBoardIdRef.current && overBoardIdRef.current) {
      if (draggingBoardIdRef.current !== overBoardIdRef.current) {
        const beforeBoardIndex =
          boardIdsRef.current.indexOf(overBoardIdRef.current) + 1;
        const beforeBoardId = nonNull(boardIdsRef.current[beforeBoardIndex]);
        if (beforeBoardId !== draggingBoardIdRef.current) {
          dispatch(
            moveTaskBoard({
              campaignId,
              id: draggingBoardIdRef.current,
              beforeBoardId
            })
          );
        }
      }
    }
    setDraggingBoardId(null);
    setOverBoardId(null);
  }, [campaignId, dispatch]);

  const draggingTaskIdRef = useRef(draggingTaskId);
  draggingTaskIdRef.current = draggingTaskId;

  const overTaskIdRef = useRef(overTaskId);
  overTaskIdRef.current = overTaskId;

  const dropTaskAfterRef = useRef(dropTaskAfter);
  dropTaskAfterRef.current = dropTaskAfter;

  const hasVisibleBoards = useMemo(() => {
    const state = store.getState();
    return boardIds.some((it) => {
      const { count } = tasksBoardSelector(state, {
        campaignId,
        boardId: it
      });

      return (it === 'no_assigned' && count > 0) || it !== 'no_assigned';
    });
  }, [boardIds, campaignId]);

  const onTaskDragStart = useCallback((boardId: string, taskId: string) => {
    setOverBoardId(boardId);
    setDraggingTaskId(taskId);
    setOverTaskId(taskId);
  }, []);
  const onTaskDragEnter = useCallback(
    (boardId: string, taskId: string | null) => {
      requestIdleCallback(() => {
        if (taskId && draggingTaskIdRef.current) {
          const state = store.getState();
          const boardState = tasksBoardSelector(state, {
            campaignId,
            boardId
          });
          const { taskIds } = boardState;
          const overTaskIndex = taskIds.indexOf(taskId);
          const draggingTaskIndex = taskIds.indexOf(draggingTaskIdRef.current);

          setDropTaskAfter(overTaskIndex > draggingTaskIndex);
        }
        setOverBoardId(boardId);
        setOverTaskId(taskId);
      });
    },
    [campaignId]
  );
  const onTaskDragEnd = useCallback(() => {
    if (draggingTaskIdRef.current && overBoardIdRef.current) {
      if (draggingTaskIdRef.current !== overTaskIdRef.current) {
        const state = store.getState();
        const boardState = tasksBoardSelector(state, {
          campaignId,
          boardId: overBoardIdRef.current
        });
        const putAfter = dropTaskAfterRef.current ? 1 : 0;
        const { taskIds } = boardState;
        const beforeTaskIndex = overTaskIdRef.current
          ? taskIds.indexOf(overTaskIdRef.current) + putAfter
          : 0;
        const beforeTaskId: string | null = taskIds[beforeTaskIndex] ?? null;
        if (beforeTaskId !== draggingTaskIdRef.current) {
          updateTask({
            id: draggingTaskIdRef.current,
            board: {
              id: overBoardIdRef.current,
              name: boardState.name,
              beforeTaskId
            }
          });
        }
      }
    }
    setDraggingTaskId(null);
    setOverBoardId(null);
    setOverTaskId(null);
  }, [campaignId, updateTask]);

  const [deleteConfirm, setDeleteConfirm] = useState<{
    boardId: string;
    deleteAllTasks: boolean;
  } | null>(null);

  const onDelete = useCallback(
    (boardId: string, tasksCount: number, deleteAllTasks: boolean) => {
      if (tasksCount === 0) {
        dispatch(
          deleteTaskBoard({
            id: boardId,
            campaignId,
            deleteAllTasks: false
          })
        );
      } else setDeleteConfirm({ boardId, deleteAllTasks });
    },
    [campaignId, dispatch]
  );

  const itemProps: Omit<TaskBoardItemProps, 'boardId'> = {
    campaign,
    disableFetch,
    taskDraggable,
    draggingTaskId,
    draggingBoardId,
    overBoardId,
    overTaskId,
    dropTaskAfter,
    onDragStart,
    onDragEnter,
    onDragEnd,
    onDelete,
    onTaskDragStart,
    onTaskDragEnter,
    onTaskDragEnd,
    draggable: true,
    canDeleteAllTasks: true,
    canDeleteTaskBoards: true,
    canMoveTaskBoards: true,
    canRenameTaskBoards: true
  };
  if (initiallyLoaded && !hasVisibleBoards)
    return (
      <TaskBoardEmptyState
        exceededBoardsLimit={exceededBoardsLimit}
        campaignId={campaignId}
      />
    );
  return (
    <TaskDragHandleContextProvider>
      <TaskBoardSkeleton count={skeletonsCount} />
      {initiallyLoaded && !boardIds.length && (
        <TasksTable
          organizationId={campaign.organizationId}
          workspaceId={campaign.workspaceId}
          isExistCampaign
          campaign={campaign}
          taskIds={[]}
        />
      )}

      {boardIds.map((groupId) => (
        <TaskBoardItem {...itemProps} key={groupId} boardId={groupId} />
      ))}
      {initiallyLoaded && (
        <UpgradePlanTooltip
          text="to add more task boards."
          isCanVisible={exceededBoardsLimit}
          innerStyle={{
            transform: 'translateY(30px)'
          }}
          placement="topLeft"
        >
          <div style={{ display: 'inline-block' }}>
            <Button
              className="add-group-button"
              onClick={() => {
                if (exceededBoardsLimit) {
                  dispatch(
                    toggleReachedEntityLimitModal({
                      visible: true,
                      entity: 'task group'
                    })
                  );
                } else {
                  dispatch(
                    createTaskBoard({
                      campaignId,
                      name: 'Name task group'
                    })
                  );
                }
              }}
              style={
                exceededBoardsLimit
                  ? { padding: '8px 15px 8px 10px' }
                  : { padding: '8px 25px 8px 25px' }
              }
            >
              <PlusSvg />
              <span>Add task group</span>
              {exceededBoardsLimit && (
                <UpgradePlanSvg style={{ marginLeft: 4 }} />
              )}
            </Button>
          </div>
        </UpgradePlanTooltip>
      )}

      <DeleteTaskGroupModal
        campaignId={campaignId}
        open={!!deleteConfirm}
        boardId={deleteConfirm?.boardId ?? null}
        deleteAllTasks={!!deleteConfirm?.deleteAllTasks}
        onCancel={() => setDeleteConfirm(null)}
        onConfirm={(deleteAllTasks) => {
          setDeleteConfirm(null);
          const boardId = deleteConfirm?.boardId;
          if (!boardId) return;
          dispatch(
            deleteTaskBoard({
              id: boardId,
              campaignId,
              deleteAllTasks
            })
          );
        }}
      />
      {draggingTaskId && (
        <TaskDragHandle taskId={draggingTaskId} campaign={campaign} />
      )}
    </TaskDragHandleContextProvider>
  );
});
