import { useMemo } from 'react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import {
  multipartUploadAbort,
  multipartUploadComplete,
  multipartUploadParts,
  multipartUploadPartsUploadParams
} from '@api/MultipartUpload';
import { apiClient } from '@api/client';

function createUppy(campaignId: string) {
  const uppy = new Uppy({
    autoProceed: true
  });
  uppy.use(AwsS3Multipart, {
    getChunkSize() {
      const MB = 1024 * 1024;
      return 20 * MB;
    },
    async createMultipartUpload(file) {
      const {
        data: { attachmentId, uploadId, key }
      } = await apiClient.attachment.attachmentControllerInitAttachmentUpload({
        targetId: campaignId,
        type: 'campaign-attachment',
        fileName: file.name,
        fileSize: file.size
      });

      uppy.setFileMeta(file.id, { attachmentId });

      return { uploadId, key };
    },
    async listParts(file, { key, uploadId }) {
      const {
        data: { parts }
      } = await multipartUploadParts({ key, uploadId });
      return parts;
    },
    async prepareUploadParts(file, { key, uploadId, parts }) {
      try {
        const {
          data: { presignedUrls, headers }
        } = await multipartUploadPartsUploadParams({
          key,
          uploadId,
          partNumber: parts.map((x) => x.number)
        });
        return { presignedUrls, headers };
      } catch (err) {
        console.warn(err, '-> prepareUploadParts');
        throw err;
      }
    },
    async abortMultipartUpload(file, { key, uploadId }) {
      await multipartUploadAbort({ key, uploadId });
    },
    async completeMultipartUpload(file, { key, uploadId, parts }: any) {
      const { data } = await multipartUploadComplete({ key, uploadId, parts });
      return data;
    }
  });
  return uppy;
}

export function useUppy(campaignId: string) {
  return useMemo(() => createUppy(campaignId), [campaignId]);
}
