import ActionCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/ActionCell';
import AddedCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/AddedCell';
import RoleCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/RoleCell';
import StatusCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/StatusCell';
import UserCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/UserCell';
import UserCellTitle from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/UserCellTitle';
import WorkspacesCell from '@pages/AdminSettings/components/OrganizationTab/components/OrganizationTabs/components/MembersTable/components/WorkspacesCell';
import { Table } from 'antd';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';

import './MembersTable.less';

type MembersTableProps = {
  list: string[];
  count: number;
  onOrderChange: (orderBy: string) => void;
};

export default memo(function MembersTable({
  list,
  count,
  onOrderChange
}: MembersTableProps) {
  const columns = useMemo(
    () => [
      {
        title: <UserCellTitle count={count} />,
        dataIndex: 'id',
        key: 'user',
        width: 290,
        render: (id: string) => {
          return <UserCell memberId={id} />;
        }
      },
      {
        title: 'Role',
        dataIndex: 'id',
        key: 'role',
        width: 100,
        sorter: true,
        render: (id: string) => {
          return <RoleCell memberId={id} />;
        }
      },
      {
        title: 'Workspaces',
        dataIndex: 'id',
        key: 'workspacesCount',
        align: 'center' as const,
        width: 170,
        sorter: true,
        render: (id: string) => {
          return <WorkspacesCell memberId={id} />;
        }
      },
      {
        title: 'Added',
        dataIndex: 'id',
        key: 'createdAt',
        width: 200,
        sorter: true,
        render: (id: string) => {
          return <AddedCell memberId={id} />;
        }
      },
      {
        title: 'Status',
        dataIndex: 'id',
        key: 'isActive',
        width: 180,
        sorter: true,
        render: (id: string) => {
          return <StatusCell memberId={id} />;
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'action',
        render: (id: string) => {
          return <ActionCell memberId={id} />;
        }
      }
    ],
    [count]
  );
  const dataSource = useMemo(() => list.map((id) => ({ id })), [list]);
  const onTableChange = useCallback(
    (pagination, filters, sorter: any, extra) => {
      const { order, columnKey } = sorter;
      const _order = order === 'ascend' ? 'ASC' : 'DESC';
      const orderBy = `${columnKey}:${_order}`;
      onOrderChange(orderBy);
    },
    [onOrderChange]
  );

  return (
    <div
      className={classNames(
        'table_container ',
        'table_container_organization_members',
        !dataSource.length &&
          'table_container_organization_members--hide-placeholder'
      )}
    >
      <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        rowKey={({ id }) => id}
        onChange={onTableChange}
      />
    </div>
  );
});
