import { Button, Modal } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as EmptySvg } from '@assets/icons/empty-campaigns.svg';
import { ReactComponent as DemoSvg } from '@assets/icons/demo-campaigns.svg';

import React, { memo, useCallback, useEffect, useState } from 'react';

import './modals.less';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useNavigate } from 'react-router-dom';
import { SimpleCampaignItemDto } from '@api/Api';
import classNames from 'classnames';
import { apiClient } from '@api/client';
import { useDispatch } from 'react-redux';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';

type RouteForApprovalModalProps = {
  visible: boolean;
  setVisible: (v: boolean) => void;
  demoCampaign: SimpleCampaignItemDto | undefined;
};

export default memo(function RouteForApprovalModal({
  visible,
  setVisible,
  demoCampaign
}: RouteForApprovalModalProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const [demoAssetId, setDemoAssetId] = useState<string>();

  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;

  useEffect(() => {
    if (!visible) return;
    setLoading(true);
    (async () => {
      const [demo, own] = await Promise.all([
        apiClient.asset.assetControllerListAssets({
          isFolder: false,
          workspaceId,
          isDemo: true,
          flatten: true,
          hasApproval: false
        }),
        apiClient.asset.assetControllerListAssets({
          isFolder: false,
          workspaceId,
          flatten: true,
          hasApproval: false
        })
      ]);
      const demoAssetId = demo.data.edges.find((it) => {
        const v = it.node.asset?.versions[0];
        return !!v;
      })?.node.id;

      const ownAssetId = own.data.edges.find((it) => {
        const v = it.node.asset?.versions[0];
        return !!v;
      })?.node.id;

      if (demoAssetId) setDemoAssetId(demoAssetId);
      if (ownAssetId) {
        navigate(
          `/media-viewer/${ownAssetId}/${ownAssetId}?startTour=route-for-approval`
        );
      }

      setLoading(false);
    })();
  }, [visible, workspaceId, demoCampaign, navigate]);

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onOwnAssetClick = useCallback(() => {
    dispatch(
      toggleUploadAssetModal({
        visible: true,
        postAction: 'redirect-to-aw',
        workspaceId: currentWorkspace.id
      })
    );
    onCloseModal();
  }, [onCloseModal, dispatch, currentWorkspace.id]);
  const onDemoAssetClick = useCallback(() => {
    if (!demoAssetId) return;
    navigate(
      `/media-viewer/${demoAssetId}/${demoAssetId}?startTour=route-for-approval`
    );
  }, [navigate, demoAssetId]);
  return (
    <Modal
      open={visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={740}
      onCancel={onCloseModal}
      className={classNames({ 'onboarding-modal-loading': loading })}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{
            fontSize: 22,
            marginBottom: '0.5em',
            justifyContent: 'center'
          }}
        >
          Upload an media
        </h2>

        <p
          className="modal_container__subtitle"
          style={{
            textAlign: 'center',
            width: '404px',
            margin: '0 auto 32px'
          }}
        >
          Choose what you want to do first.
        </p>
        <div className="modal_container__create-task-in-campaign">
          <div className="item-block">
            <div className="item-block-wrapper">
              <div className="item-block-wrapper__icon">
                <EmptySvg />
              </div>
              <div className="item-block-wrapper-copies">
                <div className="item-block-wrapper-copies__title">
                  Upload media
                </div>
                <div className="item-block-wrapper-copies__description">
                  Upload new your media to collabrate
                </div>
                <Button type="primary" onClick={onOwnAssetClick}>
                  Upload
                </Button>
              </div>
            </div>
          </div>
          <div className="item-block">
            <div className="item-block-wrapper">
              <div className="item-block-wrapper__icon">
                <DemoSvg />
              </div>
              <div className="item-block-wrapper-copies">
                <div className="item-block-wrapper-copies__title">
                  Use demo media
                </div>
                <div className="item-block-wrapper-copies__description">
                  Get trained on a demo media
                </div>
                <Button
                  size="middle"
                  type="primary"
                  onClick={onDemoAssetClick}
                  disabled={!demoAssetId}
                >
                  Continue with demo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
