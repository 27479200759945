import { AssetForApprovalDto, PageDto } from '@api/Api';
import ApprovalEmptyStateWrapper from '@components/Approval/ApprovalEmptyStateWrapper/ApprovalEmptyStateWrapper';
import LottieComponent from '@components/Lottie';
import ApprovalsTable from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/ApprovalsTable';

import React, { memo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type ApprovalsTabProps = {
  approvals: PageDto & {
    edges: { node: AssetForApprovalDto; cursor: string }[];
  };
  searchQuery: string | undefined;
  activeTabKey: string;
  setApprovals: any;
  setApprovalsCount: any;
  campaignId: string;
  loading: boolean;
  loadMoreApprovals: () => void;
  getApprovalsData: () => void;
};

export default memo(function ApprovalsTab({
  approvals,
  loadMoreApprovals,
  getApprovalsData,
  campaignId,
  setApprovals,
  loading,
  setApprovalsCount,
  activeTabKey,
  searchQuery
}: ApprovalsTabProps) {
  useEffect(() => {
    if (activeTabKey === 'approvals') getApprovalsData();
  }, [activeTabKey, getApprovalsData]);
  if (!approvals.edges.length && searchQuery)
    return (
      <div className="empty_state empty_state--search">
        <LottieComponent className="icon" view="noSearchingResult" />

        <strong
          className="main-body-text main-body-text--bold"
          style={{ marginBottom: 8 }}
        >
          No results found
        </strong>
        <p className="copy main-body-text main-body-text--dark-gray">
          It seems we can’t find any results based on your search
        </p>
      </div>
    );

  return (
    <InfiniteScroll
      dataLength={approvals.edges.length}
      next={loadMoreApprovals}
      hasMore={approvals.hasNext || false}
      loader={<></>}
      height="calc(100vh - 284px)"
      style={{ overflowX: 'hidden' }}
    >
      <ApprovalEmptyStateWrapper
        listLength={loading ? 1 : approvals.edges.length}
        getApprovalsData={getApprovalsData}
        campaignId={campaignId}
        wholePage={true}
      >
        <div>
          {!!approvals.edges.length && (
            <ApprovalsTable
              setApprovalsCount={setApprovalsCount}
              setApprovals={setApprovals}
              approvals={approvals}
            />
          )}
        </div>
      </ApprovalEmptyStateWrapper>
    </InfiniteScroll>
  );
});
