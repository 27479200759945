import React from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as LightningSvg } from '@assets/icons/lightning.svg';

type NewWorkspaceToastProps = {
  userName: string;
  workspaceName: string;
  closeToast: any;
};

function NewWorkspaceToast({
  userName,
  workspaceName,
  closeToast
}: NewWorkspaceToastProps) {
  return (
    <div className="toast_container">
      <div>
        <div className="toast_container__icon">
          <LightningSvg />
        </div>
        <div className="toast_container__body">
          <div className="title">Welcome, {userName}!</div>
          <div className="description">
            You are now part of the{' '}
            <span className="workspace">{workspaceName}</span> workspace.
          </div>
        </div>
      </div>
      <div className="toast_container__action">
        <span
          className="close"
          onClick={() => {
            closeToast();
          }}
        >
          <CrossSvg style={{ marginLeft: 8 }} />
        </span>
      </div>
    </div>
  );
}

export default NewWorkspaceToast;
