import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Modal, Button, Input, Form, Tooltip } from 'antd';
import { useTypedSelector } from '@hooks';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as EyePublicSvg } from '@assets/icons/eye-public.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as EyePrivateSvg } from '@assets/icons/eye-private.svg';
import { useCurrentWorkspace } from '@hooks/workspace';
import { removeExtraSpaces } from '@helpers/stringTransformers';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  toggleCampaignCreatedModal,
  toggleCampaignFolderCreationModal,
  toggleManageFolderAccessModal,
  toggleReachedEntityLimitModal
} from '@redux/actions/modalAction';
import {
  createNewCampaign,
  updateCampaignCounters
} from '@redux/actions/campaignAction';
import { updateCampaignsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import { createFolderAction } from '@redux/actions/assetsAction';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { useNavigate } from 'react-router-dom';

type descriptionProps = {
  [id: string]: { sharedCopy: string; privateCopy: string };
};

const description: descriptionProps = {
  folder: {
    sharedCopy: 'All campaign members can access this folder',
    privateCopy: 'Only members you choose can access private folders.'
  },
  campaign: {
    sharedCopy: 'Anyone in this workspace can access shared campaigns',
    privateCopy:
      'Only invited members and contributors can access private campaigns'
  }
};

function CampaignFolderCreationModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const { onStartTour } = useContext(OnboardingProcessContext);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isUpgradePlanTooltipVisible, setIsUpgradePlanTooltipVisible] =
    useState<boolean>(false);
  const [canCreateCampaign, setCanCreateCampaign] = useState<boolean>(false);
  const [canCreatePrivate, setCanCreatePrivate] = useState<boolean>(false);
  const { currentOrganization } = useOrganization();
  const [currentWorkspace] = useCurrentWorkspace(false);
  const selectedCampaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const [nameValueLength, setNameValueLength] = useState<number>(0);
  const campaignFolderCreationModal = useTypedSelector(
    ({ modal }) => modal.campaignFolderCreationModal
  );
  const [form] = Form.useForm();

  useEffect(() => {
    setIsPrivate(false);
    setCanCreateCampaign(false);
    setCanCreatePrivate(false);
    setNameValueLength(0);
    form.setFieldsValue({ name: undefined });
  }, [campaignFolderCreationModal?.visible]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValueLength = e.target.value.length;
    setNameValueLength(newValueLength);
  };

  useEffect(() => {
    if (campaignFolderCreationModal?.entity === 'campaign') {
      if (!isPrivate && currentWorkspace?.permissions.createCampaigns)
        setCanCreateCampaign(true);
      if (isPrivate && currentWorkspace?.permissions.createPrivateCampaigns)
        setCanCreateCampaign(true);
      setCanCreatePrivate(
        !!currentOrganization.entity?.features.privateCampaigns &&
          !!currentWorkspace?.permissions.createPrivateCampaigns
      );
    }
    if (campaignFolderCreationModal?.entity === 'folder') {
      setCanCreateCampaign(true);
      setCanCreatePrivate(
        !!selectedCampaign?.permissions?.createPrivateFolders &&
          !!currentOrganization.entity?.features.privateFolders
      );
    }
  }, [
    selectedCampaign,
    campaignFolderCreationModal,
    currentWorkspace,
    isPrivate
  ]);

  const onFinish = async ({ name }: { name: string }) => {
    const newName = removeExtraSpaces(name);
    if (campaignFolderCreationModal?.onFinish) {
      await campaignFolderCreationModal.onFinish({ name, isPrivate });
      dispatch(updateCampaignsWorkspaceCounters('create'));
      dispatch(toggleCampaignFolderCreationModal(null));
      return;
    }
    if (campaignFolderCreationModal?.entity === 'campaign') {
      try {
        const { campaign } = await createNewCampaign({
          workspaceId: currentWorkspace?.id || '',
          isPrivate,
          name: newName,
          forOnboarding: campaignFolderCreationModal.createFromOnboarding
        })(dispatch);
        dispatch(updateCampaignsWorkspaceCounters('create', campaign));
        if (!campaignFolderCreationModal.createFromOnboarding)
          dispatch(
            toggleCampaignCreatedModal({
              visible: true,
              campaignId: campaign.id
            })
          );
        MixpanelService.track(
          mixpanel,
          user.id,
          MixpanelEventType.MANAGE_CAMPAIGNS,
          {
            campaignName: newName,
            deleteCampaign: false,
            newCampaign: true,
            privateCampaign: isPrivate,
            sharedCampaign: !isPrivate,
            addTaskGroup: false,
            addContributor: false
          }
        );
        if (campaignFolderCreationModal.createFromOnboarding) {
          navigate(`/campaigns/single/${campaign.id}`);
          setTimeout(() => {
            onStartTour({ type: 'create-campaign' });
          }, 200);
        }
      } catch (e) {
        dispatch(
          toggleReachedEntityLimitModal({ visible: true, entity: 'campaign' })
        );
      }
    } else {
      if (!selectedCampaign) return;

      const folder = await createFolderAction({
        campaignId: selectedCampaign.id,
        name: newName,
        isPrivate
      })(dispatch);
      if (folder.isPrivate)
        dispatch(toggleManageFolderAccessModal({ folder, visible: true }));
      dispatch(updateCampaignCounters('addFolder'));
    }

    dispatch(toggleCampaignFolderCreationModal(null));
  };
  const isUpgradeCanVisible = useMemo(() => {
    if (campaignFolderCreationModal?.entity === 'campaign')
      return !currentOrganization.entity?.features.privateCampaigns;

    if (campaignFolderCreationModal?.entity === 'folder')
      return !currentOrganization.entity?.features.privateFolders;

    return false;
  }, [
    currentOrganization.entity?.features.privateFolders,
    currentOrganization.entity?.features.privateCampaigns,
    campaignFolderCreationModal?.entity
  ]);
  const { sharedCopy, privateCopy } =
    description[campaignFolderCreationModal?.entity || 'campaign'];
  return (
    <Modal
      visible={campaignFolderCreationModal?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={556}
      zIndex={1001}
      onCancel={() => dispatch(toggleCampaignFolderCreationModal(null))}
    >
      <div className="modal_container enitity-creation-modal">
        <Form form={form} onFinish={onFinish} validateTrigger="onChange">
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, marginBottom: 32 }}
          >
            New {campaignFolderCreationModal?.entity}
          </h2>
          <Form.Item
            className="enitity-creation-modal__name-item enitity-creation-modal__name-item--counter"
            name="name"
            rules={[
              {
                whitespace: true,
                message: "'name' cannot be empty."
              },
              {
                max: 60,
                message: `${nameValueLength}/60 characters. Name has exceeded character limit.`
              },
              {
                pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
              },
              {
                required: true,
                message: 'Name is required.'
              }
            ]}
            style={{ margin: '32px 0px 24px', height: 78 }}
          >
            <Input
              onChange={onNameChange}
              placeholder={`Add ${campaignFolderCreationModal?.entity} name`}
              size="large"
              showCount={{
                formatter: ({ count }) => (
                  <span className="main-body-text main-body-text--semibold main-body-text--small main-body-text--dark-gray">
                    {count}/60 characters
                  </span>
                )
              }}
              maxLength={60}
            />
          </Form.Item>
          <div className="modal_container__campaign">
            <p className="copy">
              {' '}
              <span>{campaignFolderCreationModal?.entity}</span> access
            </p>
            <div className="types">
              <button
                type="button"
                className={`type_block type_block--public ${
                  !isPrivate ? 'type_block_selected' : ''
                }`}
                onClick={() => setIsPrivate(false)}
              >
                <div className="title">
                  <EyePublicSvg />
                  Shared {campaignFolderCreationModal?.entity}
                </div>
                <div className="description">{sharedCopy}</div>
              </button>
              <UpgradePlanTooltip
                text=" to a Team plan to access this feature."
                isCanVisible={isUpgradeCanVisible}
                innerStyle={{
                  transform: 'translate(-20px, 25px)'
                }}
              >
                <div>
                  <button
                    type="button"
                    className={`type_block type_block--private ${
                      isPrivate ? 'type_block_selected' : ''
                    } ${!canCreatePrivate ? 'disabled' : ''}`}
                    onClick={() => {
                      if (!currentOrganization.subscription) return;
                      setIsUpgradePlanTooltipVisible(false);
                      if (!canCreatePrivate) return;
                      setIsPrivate(true);
                    }}
                  >
                    <div className="title">
                      <EyePrivateSvg /> Private{' '}
                      {campaignFolderCreationModal?.entity}{' '}
                      {isUpgradeCanVisible && (
                        <UpgradePlanSvg className="upgrade-icon" />
                      )}
                    </div>
                    <div className="description">{privateCopy}</div>
                  </button>
                </div>
              </UpgradePlanTooltip>
            </div>
          </div>
          <div className="modal_container__actions">
            <Button
              type="text"
              onClick={() => dispatch(toggleCampaignFolderCreationModal(null))}
              style={{
                marginRight: 12,
                border: 'none',
                height: 48,
                padding: '12px 19px'
              }}
            >
              Cancel
            </Button>
            <Tooltip
              overlayClassName="long-text-hint"
              overlayInnerStyle={{ maxWidth: 285 }}
              title={
                !currentWorkspace?.permissions.createCampaigns &&
                'Your current role in StreamWork doesn’t allow you to create a campaign.'
              }
              trigger="hover"
              placement="bottom"
            >
              <Button
                type="primary"
                disabled={!canCreateCampaign}
                size="large"
                htmlType="submit"
                style={{ width: 152, height: 48, padding: '8px 12px' }}
              >
                Create
              </Button>
            </Tooltip>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default CampaignFolderCreationModal;
