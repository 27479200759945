import React, { memo } from 'react';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { Button } from 'antd';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { toggleReachedEntityLimitModal } from '@redux/actions/modalAction';
import { createTaskBoard } from '@redux/actions/tasks';
import LottieComponent from '@components/Lottie';
import { useTypedSelector } from '@hooks';
import { campaignStateSelector } from '@redux/selectors/tasks';

type TaskBoardEmptyStateProps = {
  exceededBoardsLimit: boolean;
  campaignId: string;
};

export default memo(function TaskBoardEmptyState({
  exceededBoardsLimit,
  campaignId
}: TaskBoardEmptyStateProps) {
  const searchQuery = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).searchQuery
  );
  const count = useTypedSelector(
    (state) => campaignStateSelector(state, { campaignId }).boards.count
  );

  const fetchType = useTypedSelector(
    (state) =>
      campaignStateSelector(state, { campaignId }).boards.fetch.newRequest
        ?.fetchType
  );

  const dispatch = useTypedDispatch();
  if (fetchType === 'reload') return null;
  if (searchQuery && !!count)
    return (
      <div className="empty_state empty_state--search">
        <LottieComponent className="icon" view="noSearchingResult" />

        <strong
          className="main-body-text main-body-text--bold"
          style={{ marginBottom: 8 }}
        >
          No results found
        </strong>
        <p className="copy main-body-text main-body-text--dark-gray">
          It seems we can’t find any results based on your search
        </p>
      </div>
    );
  return (
    <div className="task-board-empty-state">
      <div className="task-board-empty-state-wrapper">
        <div className="task-board-empty-state-wrapper-list">
          <div
            className="task-board-empty-state-wrapper-list-item"
            style={{ borderColor: '#9FC1F4' }}
          >
            <div>
              <div className="title">Updated Logos</div>
              <div className="count">4</div>
            </div>
            <div>
              <div className="arrow arrow-down">
                <ArrowDownSvg />
              </div>
            </div>
          </div>
          <div
            className="task-board-empty-state-wrapper-list-item"
            style={{ borderColor: '#FDDFA6' }}
          >
            <div>
              <div className="title">30 sec video</div>
              <div className="count">2</div>
              <div className="add-task">Add task</div>
              <div className="plus">
                <PlusSvg />
              </div>
              <div className="more">
                <ActionSvg />
              </div>
            </div>
            <div>
              <div className="arrow arrow-up">
                <ArrowDownSvg />
              </div>
            </div>
          </div>
          <div className="task-board-empty-state-wrapper-list-item--collapsed">
            <div>
              <div className="circle" />
              <div className="copies">
                <div className="title">Summer campaign</div>
                <div className="description">Review final script</div>
              </div>
            </div>
            <div>
              <div className="tag">To do</div>
            </div>
            <div>
              <div className="skeleton" />
              <div className="skeleton" />
            </div>
          </div>
          <div
            className="task-board-empty-state-wrapper-list-item--collapsed"
            style={{ marginBottom: 12 }}
          >
            <div>
              <div className="circle" />
              <div className="copies">
                <div className="title">Summer campaign</div>
                <div className="description">Update lower third</div>
              </div>
            </div>
            <div>
              <div className="tag">To do</div>
            </div>
            <div>
              <div className="skeleton" />
              <div className="skeleton" />
            </div>
          </div>
          <div
            className="task-board-empty-state-wrapper-list-item"
            style={{ borderColor: '#A6DCAB' }}
          >
            <div>
              <div className="title">Summary Presentation</div>
              <div className="count">13</div>
            </div>
            <div>
              {/* <div className="picture">
                <img src={picture} alt="" />
              </div> */}
              <div className="arrow arrow-down">
                <ArrowDownSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="task-board-empty-state-wrapper-copy">
          Start task managing your campaign deliverables.
        </div>
        <div className="task-board-empty-state-wrapper-button">
          <UpgradePlanTooltip
            text="to add more task boards."
            isCanVisible={exceededBoardsLimit}
            innerStyle={{
              transform: 'translateY(30px)'
            }}
            placement="topLeft"
          >
            <div style={{ display: 'inline-flex' }}>
              <Button
                className="add-group-button"
                onClick={() => {
                  if (exceededBoardsLimit) {
                    dispatch(
                      toggleReachedEntityLimitModal({
                        visible: true,
                        entity: 'task group'
                      })
                    );
                  } else {
                    dispatch(
                      createTaskBoard({
                        campaignId,
                        name: 'Name task group'
                      })
                    );
                  }
                }}
                style={
                  exceededBoardsLimit
                    ? { padding: '8px 15px 8px 10px' }
                    : { padding: '8px 25px 8px 25px' }
                }
              >
                <PlusSvg />
                <span>Add task group</span>
                {exceededBoardsLimit && (
                  <UpgradePlanSvg style={{ marginLeft: 4 }} />
                )}
              </Button>
            </div>
          </UpgradePlanTooltip>
        </div>
      </div>
    </div>
  );
});
