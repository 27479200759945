import React, { useEffect, useState } from 'react';
import Edit from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/Edit';

import { FormInstance } from 'antd';
import View from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/View';
import './View.less';

export function CampaignDetailsField({
  scrollSelector,
  isEditMode,
  setIsEditMode,
  setScrollToNodeSelector,
  form,
  availableCampaignDetailsFields,
  setAvailableCampaignDetailsFields,
  onSaveDetails,
  scrollerInstance,
  needOpenAgencyModal,
  needOpenLinksModal,
  setNeedOpenAgencyModal,
  setNeedOpenLinksModal
}: {
  scrollSelector: any;
  isEditMode: boolean;
  setIsEditMode: (v: boolean) => void;
  setScrollToNodeSelector: any;
  form: FormInstance;
  availableCampaignDetailsFields: any;
  setAvailableCampaignDetailsFields: any;
  onSaveDetails: (values: any) => void;
  scrollerInstance: any;
  needOpenAgencyModal: any;
  needOpenLinksModal: boolean;
  setNeedOpenAgencyModal: (a: any) => void;
  setNeedOpenLinksModal: (v: boolean) => void;
}) {
  const [initialValues, setInitialValues] = useState<any>();
  useEffect(() => {
    let initialValues = {};

    availableCampaignDetailsFields.forEach((el: any) => {
      if (el.key === 'agencies' || el.key === 'links') {
        initialValues = {
          ...initialValues,
          [el.key]: el.value.map((el: any) =>
            typeof el === 'string' ? el : JSON.stringify(el)
          )
        };
      } else {
        initialValues = {
          ...initialValues,
          [el.key]: el.value
        };
      }
    });
    setInitialValues(initialValues);
  }, [availableCampaignDetailsFields]);

  return (
    <>
      {isEditMode ? (
        <Edit
          form={form}
          availableCampaignDetailsFields={availableCampaignDetailsFields}
          setAvailableCampaignDetailsFields={setAvailableCampaignDetailsFields}
          onSaveDetails={onSaveDetails}
          initialValues={initialValues}
          scrollSelector={scrollSelector}
          scrollerInstance={scrollerInstance}
          needOpenAgencyModal={needOpenAgencyModal}
          needOpenLinksModal={needOpenLinksModal}
          setNeedOpenAgencyModal={setNeedOpenAgencyModal}
          setNeedOpenLinksModal={setNeedOpenLinksModal}
        />
      ) : (
        <View
          initialValues={initialValues}
          setIsEditMode={setIsEditMode}
          setScrollToNodeSelector={setScrollToNodeSelector}
          setNeedOpenAgencyModal={setNeedOpenAgencyModal}
          setNeedOpenLinksModal={setNeedOpenLinksModal}
        />
      )}
    </>
  );
}
