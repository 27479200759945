import React, { forwardRef, RefObject, useEffect, useState } from 'react';

import { ReactComponent as Expand } from '@assets/icons/PlayerControlBar/expand.svg';
import { ReactComponent as Collapse } from '@assets/icons/PlayerControlBar/collapse.svg';
import { ReactComponent as Play } from '@assets/icons/PlayerControlBar/play.svg';
import { ReactComponent as Pause } from '@assets/icons/PlayerControlBar/pause.svg';
import { ReactComponent as Volume } from '@assets/icons/PlayerControlBar/volume.svg';
import classNames from 'classnames';
import { formatDuration } from '@helpers/formatDuration';
import { Popover, Slider } from 'antd';
import { VideoJsPlayer } from 'video.js';

const Controls = forwardRef((_, ref) => {
  const videoRef = ref as RefObject<VideoJsPlayer>;
  const player = videoRef.current as VideoJsPlayer;

  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);

  useEffect(() => {
    const onPause = () => {
      setIsPaused(true);
    };
    const onPlay = () => {
      setIsPaused(false);
    };
    const onTimeUpdate = () => {
      const currentTime = player.currentTime();
      setCurrentTime(currentTime);
    };
    const onVolumeChange = () => {
      const volume = player.volume();
      setVolume(volume);
    };
    const onEnded = () => {
      setIsPaused(true);
    };
    const onLoadedMetadata = () => {
      setDuration(player.duration());
    };
    const onFullscreenChange = () => {
      setIsExpand(player.isFullscreen());
    };
    player.on('pause', onPause);
    player.on('play', onPlay);
    player.on('timeupdate', onTimeUpdate);
    player.on('volumechange', onVolumeChange);
    player.on('ended', onEnded);
    player.on('loadedmetadata', onLoadedMetadata);
    player.on('fullscreenchange', onFullscreenChange);
    return () => {
      player.off('pause', onPause);
      player.off('play', onPlay);
      player.off('timeupdate', onTimeUpdate);
      player.off('volumechange', onVolumeChange);
      player.off('ended', onEnded);
      player.off('loadedmetadata', onLoadedMetadata);
      player.off('fullscreenchange', onFullscreenChange);
    };
  }, [player]);

  const onSliderChange = (value: number) => {
    const time = (duration / 100) * value;
    player.currentTime(time);
  };
  const onPlayPause = (value: boolean) => {
    if (value) {
      player.play();
    } else {
      player.pause();
    }
  };
  const onVolumeChange = (volume: number) => {
    player.volume(volume);
  };
  const onFullscreenChange = (value: boolean) => {
    if (value) player.requestFullscreen();
    else player.exitFullscreen();
  };

  return (
    <div
      className={classNames({
        'modal_container__watch-video-controls': true,
        'modal_container__watch-video-controls--fullscreen': isExpand
      })}
    >
      <div
        className={classNames({
          'modal_container__watch-video-controls__play': true,
          'modal_container__watch-video-controls__play-expanded': isExpand
        })}
        onClick={() => onPlayPause(isPaused)}
      />

      <div className="modal_container__watch-video-controls__track">
        <Slider
          value={(currentTime / duration) * 100}
          tooltip={{ open: false }}
          step={0.1}
          handleStyle={{ display: 'none' }}
          trackStyle={{ backgroundColor: '#3553db' }}
          onChange={onSliderChange}
        />
      </div>
      <div className="modal_container__watch-video-controls__buttons">
        <div>
          <span onClick={() => onPlayPause(isPaused)}>
            {isPaused ? <Play /> : <Pause />}
          </span>
          <span>
            <Popover
              getPopupContainer={() =>
                document.querySelector(
                  '.modal_container__watch-video-controls__buttons'
                ) as HTMLElement
              }
              placement="right"
              trigger={['hover']}
              content={
                <Slider
                  value={volume * 100}
                  tooltip={{ open: false }}
                  onChange={(v) => {
                    onVolumeChange(v / 100);
                  }}
                />
              }
              overlayClassName="volume_controller_overlay"
            >
              <Volume
                className={classNames(
                  volume <= 0.5 && volume > 0
                    ? 'half'
                    : volume === 0
                    ? 'muted'
                    : volume > 0.5
                    ? 'hight'
                    : ''
                )}
                onClick={() => {
                  if (!volume) onVolumeChange(0.5);
                  else onVolumeChange(0);
                }}
              />
            </Popover>
          </span>
        </div>
        <div style={{ gap: '4px' }}>
          <span style={{ color: '#FFFFFF' }}>
            {formatDuration(currentTime)}
          </span>
          <span style={{ color: '#FFFFFF' }}>/</span>
          <span style={{ color: '#969696' }}>{formatDuration(duration)}</span>
        </div>
        <div>
          <span onClick={() => onFullscreenChange(!isExpand)}>
            {isExpand ? <Collapse /> : <Expand />}
          </span>
        </div>
      </div>
    </div>
  );
});

export default Controls;
