import React, { useMemo } from 'react';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ContributorsLimitErrorSvg } from '@assets/icons/contributors_limit_error_notification.svg';
import { Button, Modal } from 'antd';
import {
  toggleBillingModal,
  toggleReachedEntityLimitModal
} from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';

const COPIES = {
  default: 'You have reached the limit for your account.',
  unknown: 'You have reached the limit for your account.',
  workspace:
    'You have reached the workspace limit for your account. Upgrade to create more workspaces.',
  campaign:
    'You’ve reached the campaign limit for your account. Upgrade to create additional campaigns. ',
  contributor:
    'You have reached the contributor limit for your account. Upgrade to create more contributor.',
  'task group':
    'You have reached the task group limit for your account. Upgrade to add more task groups.',
  'assets size':
    'You have reached the media size limit for your account. Upgrade to upload more media.',
  'task assignees':
    'Your current plan allows one assignee per task. Upgrade to add more assignees.',
  user: 'You have reached the user limit for your account. Upgrade to add more users',
  stages:
    'You have reached the stages limit for your account. Upgrade to add more stages',
  'stage members':
    'You have reached the stage members limit for your account. Upgrade to add more stage members.',
  admins:
    'You have reached the admin limit for your account. Upgrade to add more admins'
};

function ReachedEntityLimitModal() {
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.reachedEntityLimitModal
  );
  const copy = useMemo(() => {
    if (!modalState?.entity) return COPIES.default;
    return COPIES[modalState.entity];
  }, [modalState?.entity]);
  return (
    <Modal
      open={modalState?.visible}
      destroyOnClose
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      width={512}
      zIndex={1011}
      onCancel={() => dispatch(toggleReachedEntityLimitModal(null))}
    >
      <div className="modal_container">
        <div className="modal_container-with-logo">
          <ContributorsLimitErrorSvg />
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, width: 360 }}
          >
            {copy}
          </h2>
        </div>
        {!currentOrganization.entity?.permissions.billing && (
          <p style={{ marginLeft: 57 }} className="modal_container__subtitle">
            Contact your account owner to upgrade{' '}
            <strong>{currentOrganization.entity?.owner.email}</strong>
          </p>
        )}
        <div className="modal_container__actions">
          <Button
            type="text"
            size="large"
            style={{
              marginRight: '16px',
              border: 'none',
              height: 48,
              padding: '8px 32px'
            }}
            onClick={() => dispatch(toggleReachedEntityLimitModal(null))}
          >
            Cancel
          </Button>
          {currentOrganization.entity?.permissions.billing && (
            <Button
              type="primary"
              size="large"
              style={{ width: 147, height: 48, padding: '8px 15px' }}
              onClick={() => {
                dispatch(toggleReachedEntityLimitModal(null));
                if (modalState?.hideUpgradeButton) return;

                dispatch(toggleBillingModal({ visible: true }));
              }}
            >
              {modalState?.hideUpgradeButton ? 'Ok' : 'Upgrade'}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ReachedEntityLimitModal;
