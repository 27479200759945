import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Menu,
  Skeleton,
  Table,
  Tooltip,
  message
} from 'antd';
import { ReactComponent as FavoriteSVG } from '@assets/icons/favorite.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as AddMediaSVG } from '@assets/icons/add-media.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';

import moment from 'moment';

import './Table.less';
import { ICampaignState } from '@redux/types/campaignType';
import { CampaignItemDto } from '@api/Api';
import { useDispatch } from 'react-redux';
import {
  restoreRemovedCampaign,
  setCurrentCampaign,
  setFavoriteCampaignAction
} from '@redux/actions/campaignAction';
import { updateCampaignsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import {
  toggleBillingModal,
  toggleCampaignInfoModal,
  toggleCampaignInvitationModal,
  toggleEntityDeletionModal,
  toggleRenameEntityModal
} from '@redux/actions/modalAction';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

function CampaignsListSkeleton() {
  return (
    <div className="assets_card_container" style={{ height: 56 }}>
      <div className="assets_card_container__preview">
        <Skeleton.Input style={{ width: '100%', height: 56 }} />
      </div>
    </div>
  );
}

function CampaignsTable({
  campaignsData,
  countToLoad,
  page,
  countToFetch,
  listLoading
}: {
  campaignsData: ICampaignState;
  countToLoad: string[];
  page: string;
  countToFetch: number;
  listLoading: boolean;
}) {
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 480,
      render: (text: string, { node }: { node: CampaignItemDto }) => {
        const isCampaignDeleted = node.deletedAt;
        const assetsArr = [...(node?.topAssets || [])];
        const assetsCount = node?.totalAssetsCount || 0;
        const imageUrl = assetPreviewThumbnails({
          url: assetsArr?.[0]?.previewUrl || '',
          status: assetsArr?.[0]?.status,
          type: assetsArr?.[0]?.type
        });

        return (
          <div className="campaing_name_container">
            <div>
              {assetsArr.length > 0 ? (
                <div className={classNames('asset', { isCampaignDeleted })}>
                  {assetsCount > 1 && !node.isPrivate && (
                    <div className="hasMore">+{assetsCount - 1}</div>
                  )}
                  {typeof imageUrl === 'string' ? (
                    <img src={imageUrl || ''} alt="" />
                  ) : (
                    imageUrl
                  )}
                </div>
              ) : (
                <div
                  className={classNames('asset_empty', { isCampaignDeleted })}
                >
                  <AddMediaSVG />
                </div>
              )}

              <div className="title">{node.name}</div>
            </div>
            {node && !isCampaignDeleted && (
              <div>
                <Tooltip
                  placement="bottomRight"
                  overlayClassName="toggle_favorite_overlay"
                  title={
                    !node.isFavorite
                      ? 'Add to favorite'
                      : 'Remove from favorite'
                  }
                >
                  <div
                    className="favorite_icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isCampaignDeleted) return;
                      if (!node?.isFavorite) {
                        dispatch(
                          setFavoriteCampaignAction(
                            {
                              campaignId: node.id,
                              isFavorite: !node.isFavorite
                            },
                            page === 'favorite'
                          )
                        );
                        dispatch(
                          updateCampaignsWorkspaceCounters(
                            node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                            node
                          )
                        );

                        return;
                      }
                      dispatch(
                        setFavoriteCampaignAction(
                          {
                            campaignId: node.id,
                            isFavorite: !node.isFavorite
                          },
                          page === 'favorite'
                        )
                      );
                      dispatch(
                        updateCampaignsWorkspaceCounters(
                          node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                          node
                        )
                      );
                      toast(
                        <ActionToast
                          title="Campaign has been removed from favorites"
                          onUndo={undefined}
                          description={undefined}
                          closeToast={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          bodyClassName: 'toast_container--invitation'
                        }
                      );
                    }}
                  >
                    <FavoriteSVG
                      className={classNames(
                        node.isFavorite ? 'favorite' : 'non_favorite'
                      )}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Privacy',
      dataIndex: 'privacy',
      key: 'privacy',
      width: 190,
      render: (text: string, { node }: { node: CampaignItemDto }) => {
        return (
          <div className="campaing_name_container">
            <div className="title">{node.isPrivate ? 'Private' : 'Shared'}</div>
          </div>
        );
      }
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      width: 190,
      render: (text: string, { node }: { node: CampaignItemDto }) => {
        return (
          <div className="campaing_name_container">
            <div className="title">
              {moment(node.createdAt).format('DD/MM/YYYY')}
            </div>
          </div>
        );
      }
    },
    {
      title: 'Modified',
      key: 'modified',
      dataIndex: 'modified',
      width: 160,
      render: (text: string, { node }: { node: CampaignItemDto }) => (
        <div className="campaing_name_container">
          <div className="title">{moment(node.lastEventTime).fromNow()}</div>
        </div>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text: string, { node }: { node: CampaignItemDto }) => (
        <Dropdown
          overlayClassName="asset_links_overlay"
          disabled={!!node.deletedAt && !node.permissions.delete}
          overlay={
            <Menu style={{ padding: '19px 12px' }}>
              {node.deletedAt ? (
                <>
                  {node.permissions.delete && (
                    <Menu.Item
                      key="delete"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'campaign',
                            permanent: true,
                            visible: true
                          })
                        );
                      }}
                    >
                      Delete forever
                    </Menu.Item>
                  )}
                  {node.permissions.delete && (
                    <Menu.Item
                      key="restore"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        dispatch(
                          restoreRemovedCampaign({ campaignId: node.id })
                        );
                        dispatch(
                          updateCampaignsWorkspaceCounters('restore', node)
                        );
                      }}
                    >
                      Restore
                    </Menu.Item>
                  )}
                </>
              ) : (
                <>
                  <Menu.Item
                    key="campaign_info"
                    onClick={({ domEvent }) => {
                      domEvent.stopPropagation();
                      dispatch(
                        toggleCampaignInfoModal({
                          activeTab: 'details',
                          campaign: node,
                          visible: true
                        })
                      );
                    }}
                  >
                    Campaign info
                  </Menu.Item>
                  {node.permissions.changeDetails && (
                    <Menu.Item
                      key="Rename"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        dispatch(
                          toggleRenameEntityModal({
                            entity: node,
                            page: 'campaign',
                            visible: true
                          })
                        );
                      }}
                    >
                      Rename
                    </Menu.Item>
                  )}
                  {node.permissions.inviteContributors && (
                    <Menu.Item
                      key="add_сontributor"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        if (
                          !currentOrganization.entity?.features
                            .externalContributors
                        ) {
                          if (!currentOrganization.entity?.permissions.billing)
                            return;
                          dispatch(toggleBillingModal({ visible: true }));
                          return;
                        }
                        dispatch(
                          toggleCampaignInvitationModal({
                            visible: true,
                            campaign: node
                          })
                        );
                      }}
                    >
                      <UpgradePlanTooltip
                        text="  to a paid plan to add external contributors."
                        isCanVisible={
                          !currentOrganization.entity?.features
                            .externalContributors
                        }
                        placement="right"
                        innerStyle={{
                          transform: 'translate(-30px, 0)'
                        }}
                      >
                        <div
                          style={
                            !currentOrganization.entity?.features
                              .externalContributors
                              ? {
                                  display: 'flex',
                                  alignItems: 'center'
                                }
                              : undefined
                          }
                        >
                          Add сontributor{' '}
                          {!currentOrganization.entity?.features
                            .externalContributors && (
                            <UpgradePlanSvg style={{ marginLeft: 4 }} />
                          )}
                        </div>
                      </UpgradePlanTooltip>
                    </Menu.Item>
                  )}
                  {node.permissions.changeAdditionalPermissions && (
                    <Menu.Item
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        if (
                          !currentOrganization.entity?.features
                            .externalContributors
                        ) {
                          if (!currentOrganization.entity?.permissions.billing)
                            return;
                          dispatch(toggleBillingModal({ visible: true }));
                          return;
                        }
                        dispatch(
                          toggleCampaignInfoModal({
                            activeTab: 'settings',
                            campaign: node,
                            visible: true
                          })
                        );
                      }}
                      key="contributor_permissions"
                    >
                      <UpgradePlanTooltip
                        text=" to a paid plan to manage contributor permissions."
                        isCanVisible={
                          !currentOrganization.entity?.features
                            .externalContributors
                        }
                        placement="right"
                        innerStyle={{
                          transform: 'translate(-30px, 0)'
                        }}
                      >
                        <div
                          style={
                            !currentOrganization.entity?.features
                              .externalContributors
                              ? {
                                  display: 'flex',
                                  alignItems: 'center'
                                }
                              : undefined
                          }
                        >
                          Сontributor permissions{' '}
                          {!currentOrganization.entity?.features
                            .externalContributors && (
                            <UpgradePlanSvg style={{ marginLeft: 4 }} />
                          )}
                        </div>
                      </UpgradePlanTooltip>
                    </Menu.Item>
                  )}

                  <Menu.Item
                    key="toggle_favorite"
                    onClick={({ domEvent }) => {
                      domEvent.stopPropagation();
                      if (!node?.isFavorite) {
                        dispatch(
                          setFavoriteCampaignAction(
                            {
                              campaignId: node.id || '',
                              isFavorite: !node.isFavorite
                            },
                            false
                          )
                        );

                        dispatch(
                          updateCampaignsWorkspaceCounters(
                            node?.isFavorite ? 'unsetFavorite' : 'setFavorite',
                            node
                          )
                        );

                        return;
                      }
                      dispatch(
                        setFavoriteCampaignAction(
                          {
                            campaignId: node.id || '',
                            isFavorite: !node.isFavorite
                          },
                          false
                        )
                      );

                      dispatch(
                        updateCampaignsWorkspaceCounters(
                          node?.isFavorite ? 'unsetFavorite' : 'setFavorite',
                          node
                        )
                      );
                      toast(
                        <ActionToast
                          title="Campaign has been removed from favorites"
                          onUndo={undefined}
                          description={undefined}
                          closeToast={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          bodyClassName: 'toast_container--invitation'
                        }
                      );
                    }}
                  >
                    {!node.isFavorite
                      ? 'Add to favorite'
                      : 'Remove from favorite'}
                  </Menu.Item>
                  <Menu.Item
                    onClick={({ domEvent }) => {
                      domEvent.stopPropagation();
                      navigator.clipboard.writeText(
                        `${window.location.origin}/campaigns/single/${node.id}`
                      );
                      message.success({
                        content: 'Copied link to clipboard',
                        className: 'message-dark-modal',
                        icon: <SuccessSvg className="anticon" />
                      });
                    }}
                  >
                    Share campaign link
                  </Menu.Item>
                  {node.permissions.delete && (
                    <Menu.Item
                      key="delete"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'campaign',
                            permanent: false,
                            visible: true
                          })
                        );
                      }}
                    >
                      Delete
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button
            onClick={(e) => e.stopPropagation()}
            type="text"
            size="small"
            style={{ padding: 0 }}
          >
            <ActionSvg style={{ marginRight: 0 }} />
          </Button>
        </Dropdown>
      )
    }
  ];
  return (
    <div
      className="table_container"
      style={{
        paddingRight: 34,
        paddingLeft: 34,
        marginRight: -34,
        marginLeft: -34
      }}
    >
      {!listLoading && countToLoad.length > 0 ? (
        <Table
          locale={{
            emptyText: countToLoad.map((_, i) => (
              <Fragment key={i}>
                <CampaignsListSkeleton /> <br />
              </Fragment>
            ))
          }}
          onRow={(record) => ({
            onClick: () => {
              if (!record.node.deletedAt) {
                dispatch(setCurrentCampaign(record.node));
                navigate(`/campaigns/single/${record.node.id}`);
              }
            }
          })}
          rowKey={({ node }: { node: CampaignItemDto }) => node?.id}
          pagination={false}
          columns={columns}
          dataSource={campaignsData.edges || []}
        />
      ) : (
        countToLoad.map((el, idx) => (
          <div key={idx} className="skeleton">
            <Skeleton.Input
              style={{ width: '100%', height: 56, marginBottom: 27 }}
            />{' '}
            <br />{' '}
          </div>
        ))
      )}
      {new Array(countToFetch).fill('').map((el, idx) => (
        <div key={idx} className="skeleton">
          <Skeleton.Input
            style={{ width: '100%', height: 56, marginBottom: 27 }}
          />{' '}
          <br />{' '}
        </div>
      ))}
    </div>
  );
}

export default CampaignsTable;
