import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { Empty, Input, Menu, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import AssigneeUserItem from '@components/Tasks/AssigneeUserItem';
import { MemberDto } from '@api/Api';
import { TooltipPlacement } from 'antd/lib/tooltip';
import AssigneeUserItemSkeleton from '@components/Tasks/AssigneeUserItem/AssigneeUserItemSkeleton';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';

export interface AssigneesMenuModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  selected: MemberDto[];
  list: MemberDto[];
  loading: boolean;
  searchQuery: string;
  onSearch: (query: string) => void;
  onAdd: (member: MemberDto) => void;
  onRemove: (member: MemberDto) => void;
  disableSelect?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  placement?: TooltipPlacement;
  overlayInnerStyle?: React.CSSProperties;
  children: ReactElement;
  disabled?: boolean;
}

export default function AssigneesMenuModal(props: AssigneesMenuModalProps) {
  const {
    getPopupContainer,
    placement,
    overlayInnerStyle,
    children,
    disabled,
    disableSelect,
    searchQuery,
    loading,
    onSearch
  } = props;
  const onAdd = useRef(props.onAdd);
  onAdd.current = props.onAdd;
  const onRemove = useRef(props.onRemove);
  onRemove.current = props.onRemove;
  const selected = useRef<MemberDto[]>(undefined as never);
  selected.current = props.selected;
  const selectedKeys = selected.current.map((x) => x.id);
  const [open, setOpen] = useState(props.open);

  const _onOpenChange = useRef(props.onOpenChange);
  _onOpenChange.current = props.onOpenChange;

  if (props.open !== undefined && props.open !== open) {
    setOpen(props.open);
  }

  const sortBySelected = useCallback(
    (list: MemberDto[]) =>
      list
        .map((x, i) => ({ item: x, index: i }))
        .sort((a, b) => {
          const selectedIndexA = selected.current.findIndex(
            (x) => x.id === a.item.id
          );
          const selectedIndexB = selected.current.findIndex(
            (x) => x.id === b.item.id
          );
          if (selectedIndexA !== -1 && selectedIndexB !== -1)
            return selectedIndexA - selectedIndexB;
          if (selectedIndexA !== -1 && selectedIndexB === -1) return -1;
          if (selectedIndexA === -1 && selectedIndexB !== -1) return 1;
          return a.index - b.index;
        })
        .map((x) => x.item),
    []
  );

  const [list, setList] = useState(() =>
    sortBySelected([
      ...(searchQuery
        ? []
        : selected.current.filter(
            (a) => !props.list.some((b) => a.id === b.id)
          )),
      ...props.list
    ])
  );

  const prevList = useRef(props.list);
  if (props.list !== prevList.current) {
    prevList.current = props.list;
    setList(
      sortBySelected([
        ...(searchQuery
          ? []
          : selected.current.filter(
              (a) => !props.list.some((b) => a.id === b.id)
            )),
        ...props.list
      ])
    );
  }

  const popupRef = useRef<HTMLDivElement>(null);
  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (open) {
        setList((prev) => sortBySelected(prev));
        requestAnimationFrame(() => {
          const menu = popupRef.current?.querySelector('.ant-menu');
          menu?.scrollTo(0, 0);
        });
      }
      if (_onOpenChange.current) _onOpenChange.current(open);
    },
    [sortBySelected]
  );

  const onSelect = useCallback(
    ({ key }: { key: string }) => {
      const item = list.find((x) => x.id === key);
      if (item) onAdd.current(item);
      setOpen(false);
      if (_onOpenChange.current) _onOpenChange.current(false);
    },
    [list]
  );

  const onDeselect = useCallback(
    ({ key }: { key: string }) => {
      const item = list.find((x) => x.id === key);
      if (item) onRemove.current(item);
    },
    [list]
  );

  const menuItems = useMemo(() => {
    const result: MenuItemType[] = list.map((x) => ({
      key: x.id,
      label: <AssigneeUserItem userData={x} />
    }));
    if (loading && !list.length) {
      result.push({
        key: 'skeleton',
        label: <AssigneeUserItemSkeleton />
      });
    } else if (!list.length) {
      result.push({
        key: 'empty',
        disabled: true,
        label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      });
    }
    return result;
  }, [list, loading]);

  return (
    <Popover
      trigger={disabled ? [] : 'click'}
      open={open}
      onOpenChange={onOpenChange}
      getPopupContainer={getPopupContainer}
      placement={placement}
      overlayClassName="popover-container"
      overlayInnerStyle={overlayInnerStyle}
      content={
        <div ref={popupRef}>
          {!disableSelect && (
            <div
              className="campaign-menu-search-container"
              style={{ borderBottom: 'none' }}
            >
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search by name or email"
                size="large"
                value={searchQuery}
                onChange={(e) => onSearch(e.target.value)}
                allowClear
              />
            </div>
          )}
          <Menu
            className="assignees-menu"
            onSelect={onSelect}
            onDeselect={onDeselect}
            selectedKeys={selectedKeys}
            multiple
            selectable={!disableSelect}
            items={menuItems}
          />
        </div>
      }
    >
      {children}
    </Popover>
  );
}
