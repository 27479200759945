/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef
} from 'react';
import { Button, Menu, Skeleton, Tooltip } from 'antd';
import PageSortPanel from '@components/PageSortPanel';

import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import { Navigate, useParams } from 'react-router-dom';
import { ReactComponent as DeletedSvg } from '@assets/icons/deleted-tasks-head-icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTypedSelector } from '@hooks';
import { CampaignActionTypes } from '@redux/types/campaignType';
import { useDispatch } from 'react-redux';
import {
  loadMoreCampaigns,
  reorderCampaigns,
  setCampaignList,
  updateCampaignAction
} from '@redux/actions/campaignAction';
import { CampaignsTable } from '@components/Tables';
import {
  toggleCampaignFolderCreationModal,
  toggleCampaignInfoModal
} from '@redux/actions/modalAction';
import CampaignCard from '@components/CampaignCard';
import { useLayout } from '@layouts/PageLayout';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import {
  updateCampaignsWorkspaceCounters,
  updateWorkspaceCounters
} from '@redux/actions/workspaceCountersAction';
import { getCampaign } from '@api/Campaign';
import LottieComponent from '@components/Lottie';
import { DraggableItem } from '@components/DraggableList';
import { DraggableList } from '@components/DraggableList/DraggableList';

function CampaignsListEmptyState({ canCreateCampaign = true }) {
  const dispatch = useDispatch();
  return (
    <div className="empty_state">
      <LottieComponent className="campaign-icon" view="addCampaign" />
      <p className="copy">Create a campaign and start collaborating!</p>
      <Tooltip
        overlayClassName="long-text-hint"
        overlayInnerStyle={{ maxWidth: 285, textAlign: 'left' }}
        title={
          !canCreateCampaign &&
          'Your current role in StreamWork doesn’t allow you to create a campaign.'
        }
        trigger="hover"
        placement="bottom"
      >
        <Button
          type="primary"
          disabled={!canCreateCampaign}
          onClick={() =>
            dispatch(
              toggleCampaignFolderCreationModal({
                visible: true,
                entity: 'campaign'
              })
            )
          }
        >
          New campaign
        </Button>
      </Tooltip>
    </div>
  );
}

const CampaignsListSkeleton = forwardRef<HTMLDivElement>(
  function CampaignsListSkeleton(props, ref) {
    return (
      <div ref={ref} className="campaign_card_container">
        <>
          <div className="assets assets_0">
            <Skeleton.Input style={{ width: '100%', height: 136 }} />
          </div>
          <div className="campaign_info">
            <Skeleton.Input style={{ width: '100%', height: 42 }} />
          </div>
        </>
      </div>
    );
  }
);

function modifyParams(
  page: 'all' | 'my' | 'favorite' | 'deleted',
  workspaceId: string
) {
  let params: any = {
    workspaceId
  };
  if (page === 'my')
    params = {
      ...params,
      own: true
    };

  if (page === 'favorite')
    params = {
      ...params,
      isFavorite: true
    };
  if (page === 'deleted')
    params = {
      ...params,
      isDeleted: true
    };

  return params;
}

const draggableOrder = 'order:DESC';
const defaultOrder = draggableOrder;
const orderByKey = 'campaigns-order-by';

function CampaignsListInWorkspace({
  page
}: {
  page: 'all' | 'my' | 'favorite' | 'deleted';
}) {
  const { currentView } = useLayout();
  const infiniteScrollRef = useRef<any>();
  const urlParams = useParams();
  const dispatch = useDispatch();
  const [currentWorkspace] = useCurrentWorkspace(false);
  const campaignsData = useTypedSelector(({ campaign }) => campaign);
  const workspaceCounters = useTypedSelector(
    ({ workspaceCounters }) => workspaceCounters
  );
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState<string>(
    () => localStorage.getItem(orderByKey) || defaultOrder
  );
  const orderByRef = useRef<string>(orderBy);
  orderByRef.current = orderBy;

  const [countToFetch, setCountToFetch] = useState<number>(0);
  const [listLoading, setListLoading] = useState<boolean>(false);

  const setCounters = (page: string, count: number) => {
    let entity = '';

    if (page === 'all') {
      entity = 'allCampaignsCount';
    }
    if (page === 'deleted') {
      entity = 'deletedCampaignsCount';
    }
    if (page === 'favorite') {
      entity = 'favoriteCampaignsCount';
    }
    if (page === 'my') {
      entity = 'myCampaignsCount';
    }
    dispatch(
      updateWorkspaceCounters({
        entity,
        count
      })
    );
  };

  useEffect(() => {
    (async () => {
      const params = modifyParams(page, urlParams.workspaceId as string);
      const { count } = await setCampaignList({
        ...params,
        orderBy: orderByRef.current
      })(dispatch);
      setCounters(page, count);
    })();

    return () => {
      if (infiniteScrollRef.current?.el)
        infiniteScrollRef.current.el.scrollTo(0, 0);
      dispatch({
        type: CampaignActionTypes.SET_CAMPAIGN_LIST,
        payload: { edges: null, count: null }
      });
    };
  }, [page, currentWorkspace?.permissions]);

  const loadMoreData = async () => {
    let params = modifyParams(page, urlParams.workspaceId as string);
    params = {
      ...params,
      searchQuery,
      orderBy,
      after: campaignsData.endCursor
    };
    const count = Math.min(
      (campaignsData?.count || 0) - (campaignsData?.edges?.length || 0),
      25
    );
    setCountToFetch(count);
    await loadMoreCampaigns(params)(dispatch);
    setCountToFetch(0);
  };

  const displayCampaignsCount = useCallback(() => {
    let count = 0;

    switch (page) {
      case 'all':
        count = workspaceCounters.allCampaignsCount;
        break;
      case 'deleted':
        count = workspaceCounters.deletedCampaignsCount;
        break;
      case 'favorite':
        count = workspaceCounters.favoriteCampaignsCount;
        break;
      case 'my':
        count = workspaceCounters.myCampaignsCount;
        break;
      default:
        count = 0;
    }
    return count;
  }, [page, workspaceCounters])();

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.causedByMe) return;
    const {
      data: { campaign }
    } = await getCampaign({ campaignId: msg.payload.campaignId });
    const isRestoring = msg.payload.reason === 'restored-from-recycle-bin';
    dispatch(
      updateCampaignsWorkspaceCounters(
        isRestoring ? 'restore' : 'create',
        campaign
      )
    );

    if (page === 'deleted' && isRestoring) {
      dispatch({
        type: CampaignActionTypes.DELETE_CAMPAIGN,
        payload: { campaignId: msg.payload.campaignId }
      });
      return;
    }

    const me = campaign.owner?.user?.me;
    const { isFavorite } = campaign;
    if (
      (page === 'favorite' && isFavorite) ||
      (page === 'my' && me) ||
      page === 'all'
    ) {
      dispatch({
        type: CampaignActionTypes.CREATE_CAMPAIGN,
        payload: { campaign }
      });
    }
  }, 'campaign-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.causedByMe && msg.payload.reason !== 'upload') return;

    const {
      data: { campaign }
    } = await getCampaign({
      campaignId: msg.payload.campaignId
    });
    dispatch(updateCampaignAction(campaign));
  }, 'asset-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.payload.reason === 'moved-to-folder') return;
    if (msg.causedByMe) return;
    const {
      data: { campaign }
    } = await getCampaign({
      campaignId: msg.payload.campaignId
    });
    dispatch(updateCampaignAction(campaign));
  }, 'asset-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const {
        data: { campaign }
      } = await getCampaign({
        campaignId: lastMessage.payload.campaignId
      });

      dispatch(updateCampaignAction(campaign));
    },
    1000,
    (msg) => {
      if (msg.payload.workspaceId !== urlParams.workspaceId) return false;
      if (msg.causedByMe && msg.payload.reason !== 'conversion-status-changed')
        return false;
      return true;
    },
    'asset-updated'
  );

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.causedByMe) return;

    const isCampaignRemovedFromDBList =
      msg.payload.reason === 'deleted-permanently';
    if (isCampaignRemovedFromDBList) {
      dispatch(updateCampaignsWorkspaceCounters('deletePermanent'));

      dispatch({
        type: CampaignActionTypes.DELETE_CAMPAIGN,
        payload: { campaignId: msg.payload.campaignId }
      });
      return;
    }

    const {
      data: { campaign }
    } = await getCampaign({
      isDeleted: true,
      campaignId: msg.payload.campaignId
    });
    dispatch(updateCampaignsWorkspaceCounters('delete', campaign));
    if (page === 'deleted') {
      dispatch({
        type: CampaignActionTypes.CREATE_CAMPAIGN,
        payload: { campaign }
      });
      return;
    }
    dispatch({
      type: CampaignActionTypes.DELETE_CAMPAIGN,
      payload: { campaignId: msg.payload.campaignId }
    });
  }, 'campaign-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const {
        data: { campaign }
      } = await getCampaign({
        campaignId: lastMessage.payload.campaignId
      });

      dispatch(updateCampaignAction(campaign));
    },
    1000,
    (msg) => {
      if (msg.payload.workspaceId !== urlParams.workspaceId) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { campaignId, reason } = lastMessage.payload;
      try {
        const {
          data: { campaign }
        } = await getCampaign({
          campaignId
        });
        if (reason === 'maybe-lost-access') {
          dispatch(updateCampaignAction(campaign));
          return;
        }
        dispatch(updateCampaignsWorkspaceCounters('create', campaign));
        dispatch({
          type: CampaignActionTypes.CREATE_CAMPAIGN,
          payload: { campaign }
        });
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          if (campaignInfoModal?.visible)
            dispatch(toggleCampaignInfoModal(null));
          dispatch(updateCampaignsWorkspaceCounters('lostAccess'));
          dispatch({
            type: CampaignActionTypes.DELETE_CAMPAIGN,
            payload: { campaignId }
          });
        }
      }
    },
    1000,
    (msg) => {
      if (msg.payload.workspaceId !== urlParams.workspaceId) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-access-changed'
  );

  return (
    <>
      <div className="inner-header">
        <div className="inner-header__name">
          {page === 'deleted' && <DeletedSvg className="inner-header__icon" />}
          <span className="inner-header__title">
            {page.toUpperCase()} CAMPAIGNS
          </span>
          <div className="inner-header__count">
            {campaignsData.count?.toString() || displayCampaignsCount}
          </div>
        </div>
        {page === 'deleted' && (
          <span className="inner-header__description main-body-text main-body-text--dark-gray">
            Campaigns will be permanently deleted after{' '}
            <span className="main-body-text--main-color">30&nbsp;days</span>
          </span>
        )}
      </div>
      <PageSortPanel
        isActionButtonDisabled={!currentWorkspace?.permissions.createCampaigns}
        actionButtonDisabledReason={
          !currentWorkspace?.permissions.createCampaigns
            ? 'Your current role in StreamWork doesn’t allow you to create a campaign.'
            : undefined
        }
        entity="campaign"
        onSearch={(e: any) => {
          setSearchQuery(e);
          const params = modifyParams(page, urlParams.workspaceId as string);
          dispatch(
            setCampaignList({
              ...params,
              searchQuery: e,
              orderBy: orderByRef.current
            })
          );
        }}
        classNameSearchContainer={undefined}
        classNameActionButton={undefined}
        sortMenuModal={
          <Menu
            selectedKeys={[orderBy]}
            onClick={async ({ key: orderBy }) => {
              setOrderBy(orderBy);
              localStorage.setItem(orderByKey, orderBy);
              const params = modifyParams(
                page,
                urlParams.workspaceId as string
              );
              setListLoading(true);
              await setCampaignList({
                ...params,
                searchQuery,
                orderBy
              })(dispatch);
              setListLoading(false);
            }}
          >
            <span className="help_dropdown__title">Sort by</span>
            <Menu.Item key="order:DESC">
              <span className="help_dropdown__dot" />
              Custom
            </Menu.Item>
            <Menu.Item key="createdAt:DESC">
              <span className="help_dropdown__dot" />
              Date created
            </Menu.Item>
            <Menu.Item key="lastEventTime:DESC">
              <span className="help_dropdown__dot" />
              Date modified
            </Menu.Item>
          </Menu>
        }
        searchInputComponent={undefined}
        classNamePanel={undefined}
        actionButtonName={undefined}
        onAddClick={
          page === 'all'
            ? () =>
                dispatch(
                  toggleCampaignFolderCreationModal({
                    visible: true,
                    entity: 'campaign'
                  })
                )
            : undefined
        }
      />
      <div className="campaign_cards">
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={campaignsData.edges?.length || 0}
          next={loadMoreData}
          hasMore={campaignsData.hasNext || false}
          loader={<></>}
          height="calc(100vh - 260px)"
          style={{
            paddingRight: 34,
            paddingLeft: 34,
            marginRight: -34,
            marginLeft: -34
          }}
        >
          {displayCampaignsCount === 0 && page === 'all' ? (
            <CampaignsListEmptyState
              canCreateCampaign={currentWorkspace?.permissions.createCampaigns}
            />
          ) : (
            <>
              {displayCampaignsCount === 0 ||
              campaignsData.edges?.length === 0 ? (
                <div className="empty_state empty_state--search">
                  <LottieComponent className="icon" view="noSearchingResult" />

                  <strong
                    className="main-body-text main-body-text--bold"
                    style={{ marginBottom: 8 }}
                  >
                    No results found
                  </strong>
                  <p className="copy main-body-text main-body-text--dark-gray">
                    It seems we can’t find any results based on your search
                  </p>
                </div>
              ) : (
                <>
                  {currentView === 'tile' ? (
                    <DraggableList
                      className="campaign_card_list"
                      gap={16}
                      disableReorder={
                        !currentWorkspace?.permissions.changeCampaignsOrder ||
                        page === 'deleted' ||
                        orderBy !== draggableOrder
                      }
                      onReorder={(e) => {
                        const newList = [...(campaignsData.edges ?? [])];
                        const movedItem = newList.splice(e.prevIndex, 1)[0];
                        newList.splice(e.newIndex, 0, movedItem);
                        const beforeCampaignId =
                          newList[e.newIndex - 1]?.node.id;
                        dispatch(
                          reorderCampaigns(
                            { id: e.item.id, beforeCampaignId },
                            newList
                          )
                        );
                      }}
                    >
                      {!listLoading && campaignsData?.edges
                        ? campaignsData.edges
                            .concat(new Array(countToFetch).fill(''))
                            .map(({ node }, i) => {
                              if (!node) {
                                return (
                                  <CampaignsListSkeleton
                                    key={`skeleton-${i}`}
                                  />
                                );
                              }
                              return (
                                <DraggableItem
                                  key={node.id}
                                  id={node.id}
                                  type="campaign"
                                >
                                  <CampaignCard campaign={node} page={page} />
                                </DraggableItem>
                              );
                            })
                        : new Array(displayCampaignsCount)
                            .fill('')
                            .map((el, i) => (
                              <CampaignsListSkeleton key={`skeleton-${i}`} />
                            ))}
                    </DraggableList>
                  ) : (
                    <CampaignsTable
                      campaignsData={campaignsData}
                      countToFetch={countToFetch}
                      countToLoad={
                        new Array(displayCampaignsCount).fill('') || []
                      }
                      page={page}
                      listLoading={listLoading}
                    />
                  )}
                </>
              )}
            </>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
}

function CampaignsList(props: any) {
  const { workspaceId: workspaceIdParam } = useParams();
  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const workspaceId =
    workspaceIdParam ?? currentWorkspace?.id ?? defaultWorkspace?.id;
  useEffect(() => {
    if (workspaceId) setCurrentWorkspace(workspaceId);
  }, [workspaceId, setCurrentWorkspace]);
  const needRedirect = !workspaceId || workspaceId !== workspaceIdParam;
  if (needRedirect) {
    return <Navigate to={workspaceId ?? '/dashboard'} replace />;
  }
  return currentWorkspace ? <CampaignsListInWorkspace {...props} /> : null;
}

export default CampaignsList;
