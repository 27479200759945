import React from 'react';
import { Modal } from 'antd';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

import './ProfileModalContainer.less';

interface IProfileModalContainerProps {
  children: React.ReactChild;
  visible: boolean;
  onClose(): void;
}

function ProfileModalContainer({
  visible,
  onClose,
  children,
  ...props
}: IProfileModalContainerProps) {
  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={465}
      onCancel={onClose}
      className="profile_modal_container"
      {...props}
    >
      {children}
    </Modal>
  );
}

export default ProfileModalContainer;
