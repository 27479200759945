import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useOrganization } from '@components/OrganizationBoundary';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import {
  toggleBillingModal,
  toggleUploadAssetModal
} from '@redux/actions/modalAction';

import { ReactComponent as ContributorEmptySvg } from '@assets/icons/ApprovalEmptyStateIcons/contributors.svg';
import { ReactComponent as FreePanSvg } from '@assets/icons/ApprovalEmptyStateIcons/free-plan.svg';
import { ReactComponent as UploadSvg } from '@assets/icons/ApprovalEmptyStateIcons/upload.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/approvals-empty.svg';
import './ApprovalEmptyStateWrapper.less';
import { getAssetCount } from '@api/Asset';
import { useWebSocketSubscription } from '@hooks/useWebSocketClient';
import classNames from 'classnames';
import ApprovalEmptyStateView from '@components/Approval/ApprovalEmptyStateWrapper/ApprovalEmptyStateView';
import AssetsListModal from '@components/Approval/ApprovalEmptyStateWrapper/AssetsListModal';

type ApprovalEmptyStateWrapperProps = {
  listLength: number;

  campaignId?: string;
  wholePage?: boolean;
  children: ReactElement;
  getApprovalsData: () => void;
};

export default memo(function ApprovalEmptyStateWrapper({
  listLength,
  campaignId,
  wholePage,
  children,
  getApprovalsData
}: ApprovalEmptyStateWrapperProps) {
  const dispatch = useDispatch();
  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAssetsModalOpen, setIsAssetsModalOpen] = useState<boolean>(false);

  const { currentOrganization } = useOrganization(true);
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  useEffect(() => {
    if (!currentWorkspace?.id) return;
    (async () => {
      setLoading(true);
      const assets = await getAssetCount({
        flatten: true,
        workspaceId: currentWorkspace.id,
        campaignId
      });
      setLoading(false);
      setAssetsCount(assets.data.count);
    })();
  }, [currentWorkspace?.id, campaignId]);

  useWebSocketSubscription(async (msg) => {
    if (msg.causedByMe && msg.payload.reason !== 'upload') return;
    const assets = await getAssetCount({
      flatten: true,
      workspaceId: currentWorkspace.id,
      campaignId
    });
    setAssetsCount(assets.data.count);
  }, 'asset-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.campaignId !== campaignId) return;
    const assets = await getAssetCount({
      flatten: true,
      workspaceId: currentWorkspace.id,
      campaignId
    });
    setAssetsCount(assets.data.count);
  }, 'asset-removed');

  const isFreePlan =
    (currentOrganization.entity?.planType ?? 'free') === 'free';

  const isContributor = useMemo(
    () => currentWorkspace.role === 'workspace://contributor',
    [currentWorkspace.role]
  );
  if (loading) return null;

  if (isFreePlan && !isContributor)
    return (
      <div
        className={classNames(
          'approval_empty',
          wholePage && 'approval_empty_whole_page'
        )}
      >
        <div className="approval_empty-container">
          {wholePage ? (
            <ApprovalEmptyStateView />
          ) : (
            <FreePanSvg className="approval_empty-icon" />
          )}

          <div className="approval_empty-text">
            Upgrade to route creative media through approvals.
          </div>
          <Button
            type="primary"
            onClick={() => dispatch(toggleBillingModal({ visible: true }))}
          >
            Upgrade
          </Button>
        </div>
      </div>
    );

  if (!listLength && isContributor)
    return (
      <div
        className={classNames(
          'approval_empty',
          wholePage && 'approval_empty_whole_page'
        )}
      >
        <div className="approval_empty-container">
          {wholePage ? (
            <ApprovalEmptyStateView />
          ) : (
            <ContributorEmptySvg className="approval_empty-icon" />
          )}
          <div className="approval_empty-text" style={{ width: 290 }}>
            Creative media you&apos;ve been invited to review will appear here.
          </div>
        </div>
      </div>
    );
  if (!listLength && assetsCount <= 0)
    return (
      <div
        className={classNames(
          'approval_empty',
          wholePage && 'approval_empty_whole_page'
        )}
      >
        <div className="approval_empty-container">
          {wholePage ? (
            <ApprovalEmptyStateView />
          ) : (
            <UploadSvg className="approval_empty-icon" />
          )}
          <div className="approval_empty-text">
            Upload a creative media to route through stakeholder approval.
          </div>
          <Button
            type="primary"
            onClick={() =>
              dispatch(
                toggleUploadAssetModal({
                  visible: true,
                  workspaceId
                })
              )
            }
          >
            Upload media
          </Button>
        </div>
      </div>
    );

  if (!listLength && assetsCount > 0)
    return (
      <>
        <div
          className={classNames(
            'approval_empty',
            wholePage && 'approval_empty_whole_page'
          )}
        >
          <div className="approval_empty-container">
            {wholePage && <ApprovalEmptyStateView />}
            {!wholePage && <SuccessSvg className="approval_empty-icon" />}
            <div className="approval_empty-text">
              Get your first creative media approved in a few easy steps.
            </div>
            <Button type="primary" onClick={() => setIsAssetsModalOpen(true)}>
              Start approval routing
            </Button>
          </div>
        </div>
        <AssetsListModal
          open={isAssetsModalOpen}
          workspaceId={workspaceId}
          assetsCount={assetsCount}
          campaignId={campaignId}
          getApprovalsData={getApprovalsData}
          onCloseModal={() => setIsAssetsModalOpen(false)}
        />
      </>
    );

  //   if (!listLength)
  //     return (
  //       <div className="approval_empty">
  //         <div className="approval_empty-container">
  //           <ApprovalsEmptySvg className="approval_empty-icon" />
  //           <div className="approval_empty-text">
  //             You’re all caught up on approvals!
  //           </div>
  //         </div>
  //       </div>
  //     );

  return children;
});
