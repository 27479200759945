import React from 'react';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ContributorsLimitErrorSvg } from '@assets/icons/contributors_limit_error_notification.svg';
import { Button, Modal } from 'antd';
import { toggleAssetStackErrorModal } from '@redux/actions/modalAction';

function AssetStackErrorModal() {
  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.assetStackErrorModal
  );

  return (
    <Modal
      visible={modalState?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={512}
      zIndex={1010}
      onCancel={() => dispatch(toggleAssetStackErrorModal(null))}
    >
      <div className="modal_container">
        <div className="modal_container-with-logo">
          <ContributorsLimitErrorSvg />
          <h2
            className="modal_container__title"
            style={{ fontSize: 22, width: 360 }}
          >
            Can’t merge
          </h2>
        </div>
        <p style={{ marginTop: 16 }} className="modal_container__subtitle">
          {modalState?.copy}
        </p>

        <br />
        <br />
        <div className="modal_container__actions">
          <Button
            type="primary"
            size="large"
            style={{ width: 147, height: 48, padding: '8px 15px' }}
            onClick={() => {
              dispatch(toggleAssetStackErrorModal(null));
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AssetStackErrorModal;
