import React, { useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Tabs } from 'antd';

import Profile from '@pages/ProfileSettings/Profile';
import Account from '@pages/ProfileSettings/Account';
import Notifications from '@pages/ProfileSettings/Notifications';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import './ProfileSettings.less';
import { nonNull } from '@helpers/non-null';

const { TabPane } = Tabs;

const tabs = {
  profile: '',
  accountSettings: 'account',
  notifications: 'notifications'
} as const;

const defaultTab = tabs.profile;

function ProfileSettings() {
  const params = useParams();
  const navigate = useNavigate();
  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const tabParam = params.tab ?? defaultTab;
  const tab = Object.values(tabs).includes(tabParam as any)
    ? tabParam
    : defaultTab;
  const workspaceIdParam = params.workspaceId ?? defaultWorkspace?.id;
  const prevWorkspaceIdParam = useRef<string>();
  if (workspaceIdParam !== prevWorkspaceIdParam.current) {
    prevWorkspaceIdParam.current = workspaceIdParam;
    setCurrentWorkspace(workspaceIdParam);
  } else if (!currentWorkspace) {
    setCurrentWorkspace(defaultWorkspace?.id);
  }
  if (
    tab === tabs.notifications &&
    currentWorkspace &&
    params.workspaceId !== currentWorkspace.id
  ) {
    return (
      <Navigate to={`${tabs.notifications}/${currentWorkspace.id}`} replace />
    );
  }
  const onTabChange = (key: string) => {
    if (key === tabs.notifications)
      navigate(`${key}/${nonNull(currentWorkspace).id}`, { replace: true });
    else navigate(key, { replace: true });
  };
  return (
    <div className="admin_settings_container profile_settings_container">
      <Tabs defaultActiveKey={tab} onChange={onTabChange}>
        <TabPane tab="Profile" key={tabs.profile}>
          <Profile />
        </TabPane>
        <TabPane tab="Account settings" key={tabs.accountSettings}>
          <Account />
        </TabPane>
        <TabPane tab="Notifications" key={tabs.notifications}>
          <Notifications />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default ProfileSettings;
