import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  Form,
  Input,
  Divider,
  Button,
  Dropdown,
  Menu,
  Row,
  Col,
  FormInstance
} from 'antd';
import moment from 'moment';

import { ReactComponent as CheckSvg } from '@assets/icons/check.svg';
import { ReactComponent as EditSvg } from '@assets/icons/Edit.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';

import CampaignAttachments from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/CampaignAttachments';
import { useTypedSelector } from '@hooks';
import { CAMPAIGN_DETAILS_FIELDS } from '@helpers/campaignDetails';
import CampaignDetailsField from '@components/Modals/CampaignInfoModal/CampaignDetailsFields';
import { updateCampaignDetails } from '@redux/actions/campaignAction';
import { toggleCampaignInfoModal } from '@redux/actions/modalAction';
import UserAvatar from '@components/UserAvatar';
import DatePickerInput from '@components/DatePickerComponent/DatePickerInput';

const CAMPAIGN_TAG_AUTOCOMPLETE_FIX_TAG_LIST = [
  'Grow Brand Awareness',
  'Increase Engagement',
  'Increase Leads',
  'Drive Sales'
];

function CampaignDetailsTab({
  scrollerInstance,
  detailsForm,
  isEditMode,
  setIsEditMode
}: {
  scrollerInstance: any;
  detailsForm: FormInstance;
  isEditMode: boolean;
  setIsEditMode: (v: boolean) => void;
}) {
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );

  const dispatch = useDispatch();
  const [availableCampaignDetailsFields, setAvailableCampaignDetailsFields] =
    useState<{ key: string; label: string; isDefault?: boolean; value: any }[]>(
      []
    );
  const [dueDate, setDueDate] = useState<string | null>(null);
  const [needOpenAgencyModal, setNeedOpenAgencyModal] = useState<any>(false);
  const [needOpenLinksModal, setNeedOpenLinksModal] = useState<boolean>(false);
  const campaignModalFieldsRef = useRef<any>(null);
  const [scrollToNodeSelector, setScrollToNodeSelector] = useState<any>(null);

  useEffect(() => {
    setAvailableCampaignDetailsFields([]);
    setIsEditMode(false);
    setScrollToNodeSelector(null);
    if (!campaignInfoModal?.visible) {
      return;
    }
    const { campaign }: any = campaignInfoModal;
    setDueDate(campaign?.deadline || null);
    const list = CAMPAIGN_DETAILS_FIELDS.filter((el) => el.isDefault)
      .concat(
        CAMPAIGN_DETAILS_FIELDS.filter(
          (el) =>
            !el.isDefault && campaign[el.key] && campaign[el.key].length > 0
        )
      )
      .map((el) => ({ ...el, value: campaign[el.key] }));
    setAvailableCampaignDetailsFields([...list]);
  }, [campaignInfoModal]);

  // useEffect(() => {
  //   if (!campaignInfoModal?.visible) detailsForm.resetFields();
  // }, [campaignInfoModal?.visible]);

  const onSaveDetails = (data: any) => {
    const storeData = {
      ...data,
      kpis: data.kpis || null,
      finalDeliverables: data.finalDeliverables || null,
      description: data.description || null,
      targetAudience: data.targetAudience || null,
      otherNotes: data.otherNotes || null,
      budget: data.budget || null,
      links: data.links?.length
        ? data.links.map((el: any) => JSON.parse(el))
        : [],
      objectives: data.objectives || [],
      agencies: data.agencies?.length
        ? data.agencies.map((el: any) => JSON.parse(el))
        : []
    };
    dispatch(
      updateCampaignDetails({
        ...storeData,
        id: campaignInfoModal?.campaign?.id || ''
      })
    );

    dispatch(
      toggleCampaignInfoModal({
        activeTab: 'details',
        visible: true,
        campaign: {
          ...(campaignInfoModal?.campaign || {}),
          ...storeData
        }
      })
    );
    detailsForm.setFieldsValue({ ...storeData });
  };
  const updateDueDate = (newDate: any) => {
    if (!campaignInfoModal?.campaign) return;
    dispatch(
      updateCampaignDetails({
        deadline: newDate,
        name: campaignInfoModal.campaign.name,
        id: campaignInfoModal.campaign.id
      })
    );

    dispatch(
      toggleCampaignInfoModal({
        activeTab: 'details',
        visible: true,
        campaign: {
          ...campaignInfoModal.campaign,
          deadline: newDate as string
        }
      })
    );
  };
  const updateFields = (key: string, label: string) => {
    let value = null;
    if (key === 'agencies') {
      value = [];
      setNeedOpenAgencyModal('new');
    }
    if (key === 'links') {
      value = [JSON.stringify({ name: '', url: '' })];
      setNeedOpenLinksModal(true);
    }
    const newLines = [
      ...availableCampaignDetailsFields,
      { key, label, isDefault: false, value }
    ];
    detailsForm.setFieldsValue({
      [key]: value
    });
    setAvailableCampaignDetailsFields(newLines);
  };

  const handleSelectDate = (date: string | null) => {
    setDueDate(date);
    updateDueDate(date);
  };

  const formattedDueDate = dueDate ? moment(dueDate).format('ll') : '';
  const formattedDueTime =
    dueDate && moment(dueDate).minutes() !== 59
      ? moment(dueDate).format('LT')
      : '';

  return (
    <>
      <div className="campaign_model_body">
        <div className="campaign_info">
          <p className="main-body-text main-body-text--semibold">Created by</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar
              isActive={true}
              size={40}
              src={campaignInfoModal?.campaign?.owner?.user?.picture?.url || ''}
              userEmail={campaignInfoModal?.campaign?.owner?.user?.email || ''}
              userName={campaignInfoModal?.campaign?.owner?.user?.name || ''}
            />
            <span
              className="main-body-text main-body-text--semibold"
              style={{ marginLeft: 16 }}
            >
              {campaignInfoModal?.campaign?.owner?.user?.name}
            </span>
          </div>
        </div>
        <Form layout="vertical" style={{ display: 'flex', maxWidth: '444px' }}>
          <Form.Item label="Creation date" style={{ marginRight: 16 }}>
            <Input
              className="campaign_model_body__date"
              style={{ pointerEvents: 'none' }}
              value={moment(campaignInfoModal?.campaign?.createdAt).format(
                'MMM DD, YYYY'
              )}
              size="large"
            />
          </Form.Item>
          <Form.Item
            className="deadline"
            label={dueDate ? 'Due date' : 'Set a due date'}
          >
            <DatePickerInput
              size="large"
              placeholder="Due Date"
              readonly={!campaignInfoModal?.campaign?.permissions.changeDetails}
              clearable
              showTime
              value={dueDate ? moment(dueDate) : null}
              onChange={(value) => {
                handleSelectDate(value?.toISOString() ?? null);
              }}
              timeText="Time due"
              selectTimeText="Add time due"
              disabledDate={(v) => v.isBefore(moment())}
              disabledTime={(v) => v.isBefore(moment())}
            />
          </Form.Item>
        </Form>
      </div>
      <Divider style={{ margin: '0px 0px 24px 0' }} />

      <div className="campaign_model_body__header">
        <h3 className="campaign_model_body__title">Campaign brief</h3>

        <div className="campaign_model_body__header_controls">
          {campaignInfoModal?.campaign?.permissions.changeDetails && (
            <>
              <Dropdown
                overlay={
                  <Menu>
                    {CAMPAIGN_DETAILS_FIELDS.map(
                      ({ key, label, isDefault }) => {
                        if (isDefault) return null;
                        return (
                          <Menu.Item
                            key={key}
                            onClick={() => {
                              const it = availableCampaignDetailsFields.find(
                                (it) => it.key === key
                              );
                              setTimeout(() => {
                                if (!it) updateFields(key, label);
                                setScrollToNodeSelector(
                                  `[data-field="${key}"]`
                                );
                              }, 1);

                              setIsEditMode(true);
                            }}
                          >
                            <Row gutter={16}>
                              <Col span={20}>
                                <span
                                  className="main-body-text"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  {label}
                                </span>
                              </Col>
                              <Col span={4}>
                                {availableCampaignDetailsFields.find(
                                  (it) => it.key === key
                                ) && <CheckSvg />}
                              </Col>
                            </Row>
                          </Menu.Item>
                        );
                      }
                    )}
                  </Menu>
                }
                overlayClassName="asset_links_overlay"
                placement="bottomRight"
                trigger={['click']}
              >
                <Button size="small" style={{ paddingRight: 12 }} type="text">
                  <PlusSvg style={{ marginRight: 4 }} />
                  Add new field
                </Button>
              </Dropdown>

              {isEditMode ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    detailsForm.submit();
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  type="text"
                  size="small"
                  icon={<EditSvg />}
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div ref={campaignModalFieldsRef} className="campaign_model_fields">
        <CampaignDetailsField
          scrollSelector={scrollToNodeSelector}
          setScrollToNodeSelector={setScrollToNodeSelector}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          form={detailsForm}
          availableCampaignDetailsFields={availableCampaignDetailsFields}
          setAvailableCampaignDetailsFields={setAvailableCampaignDetailsFields}
          onSaveDetails={onSaveDetails}
          scrollerInstance={scrollerInstance}
          needOpenAgencyModal={needOpenAgencyModal}
          needOpenLinksModal={needOpenLinksModal}
          setNeedOpenAgencyModal={setNeedOpenAgencyModal}
          setNeedOpenLinksModal={setNeedOpenLinksModal}
        />
      </div>
      <CampaignAttachments campaignId={campaignInfoModal?.campaign?.id || ''} />
    </>
  );
}

export default CampaignDetailsTab;
