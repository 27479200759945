import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Menu, Skeleton, Table } from 'antd';
import { ReactComponent as FolderSvg } from '@assets/icons/folder.svg';
import { ReactComponent as PrivateFolderSvg } from '@assets/icons/folder-private.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import moment from 'moment';

import './Table.less';
import { AssetItemDto } from '@api/Api';

import { useTypedSelector } from '@hooks';
import { AssetsActionTypes, EntitiesStateProps } from '@redux/types/assetsType';
import { useDispatch } from 'react-redux';
import {
  toggleChangeFolderAccessModal,
  toggleEntityDeletionModal,
  toggleManageFolderAccessModal,
  toggleRenameEntityModal
} from '@redux/actions/modalAction';
import {
  restoreDeletedFolderAction,
  setSelectedFolder
} from '@redux/actions/assetsAction';
import { assetGetAssetsDownloadList } from '@api/Asset';
import UserAvatar from '@components/UserAvatar';
import { download } from '@helpers/download';
import { useOrganization } from '@components/OrganizationBoundary';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

function AssetsListSkeleton() {
  return (
    <div className="assets_card_container" style={{ height: 56 }}>
      <div className="assets_card_container__preview">
        <Skeleton.Input style={{ width: '100%', height: 56 }} />
      </div>
    </div>
  );
}

function FoldersTable({
  foldersData,
  onDrop,
  countToFetch,
  countToLoad,
  listLoading
}: {
  foldersData: EntitiesStateProps;
  onDrop?: (assetId: string, folderId: string) => void;
  countToFetch: number;
  countToLoad: any;
  listLoading: boolean | undefined;
}) {
  const dispatch = useDispatch();
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const { currentOrganization } = useOrganization();
  const navigate = useNavigate();
  const upgradePrivateFolders =
    !currentOrganization.entity?.features.privateFolders;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 480,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        const count = node.folder?.totalAssetsCount || 0;
        let copy = 'asset';
        if (count === 0 || count > 1) copy += 's';
        const assetsCountCopy = `${count} ${copy}`;

        return (
          <div className="folder_record">
            <div
              className="folder_record__type"
              onClick={() => {
                dispatch(setSelectedFolder(node));
                dispatch({
                  type: AssetsActionTypes.SET_ASSETS_LIST,
                  payload: { edges: null, count: null }
                });
                navigate(`/campaigns/single/${node.campaignId}/${node?.id}`);
              }}
            >
              {node.isPrivate ? <PrivateFolderSvg /> : <FolderSvg />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="folder_record__name">{node.folder?.name}</div>
              <div className="folder_record__assets">{assetsCountCopy}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 190,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        return (
          <div className="user_record">
            <div className="user_record__avatar">
              <UserAvatar
                isActive={true}
                size={40}
                src={node.owner?.user?.picture.url || ''}
                userEmail={node.owner?.user?.email || ''}
                userName={node.owner?.user?.name || ''}
              />
            </div>
          </div>
        );
      }
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      width: 190,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        return (
          <span className="time-state">
            {moment(node.createdAt).format('MMM DD, YYYY')}
          </span>
        );
      }
    },
    {
      title: 'Modified',
      key: 'modified',
      dataIndex: 'modified',
      width: 160,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        return (
          <span className="time-state">
            {moment(node.modifiedAt).fromNow()}
          </span>
        );
      }
    },
    {
      title: '',
      key: 'action',
      render: (text: string, { node }: { node: AssetItemDto }) => (
        <Dropdown
          overlay={
            <Menu style={{ padding: '19px 12px' }}>
              {!node.deletedAt ? (
                <>
                  <Menu.Item
                    disabled={node.folder?.assetsCount === 0}
                    onClick={async () => {
                      if (!node) return;
                      const {
                        data: { list }
                      } = await assetGetAssetsDownloadList({
                        campaignId: node.campaignId,
                        workspaceId: node.workspaceId,
                        parentId: node.id
                      });

                      for (const item of list) {
                        download(item.sourceUrl);
                      }
                    }}
                  >
                    Download files
                  </Menu.Item>
                  {node.folder?.permissions.setVisibility && (
                    <Menu.Item
                      disabled={!node.isPrivate && upgradePrivateFolders}
                      onClick={() => {
                        if (!node?.folder) return;
                        if (!node.isPrivate && upgradePrivateFolders) return;
                        dispatch(
                          toggleChangeFolderAccessModal({
                            folder: node,
                            visible: true,
                            callback: async (folder: AssetItemDto) => {
                              if (!folder.isPrivate) return;
                              dispatch(
                                toggleManageFolderAccessModal({
                                  folder,
                                  visible: true
                                })
                              );
                            }
                          })
                        );
                      }}
                    >
                      <UpgradePlanTooltip
                        text=" to a Team plan to access this feature."
                        isCanVisible={!node.isPrivate && upgradePrivateFolders}
                        placement="right"
                        innerStyle={{
                          transform: 'translate(-30px, 0)'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                          }}
                        >
                          {node?.isPrivate ? 'Make public' : 'Make private'}
                          {!node.isPrivate && upgradePrivateFolders && (
                            <UpgradePlanSvg className="upgrade-icon" />
                          )}
                        </div>
                      </UpgradePlanTooltip>
                    </Menu.Item>
                  )}
                  {node.folder?.permissions.rename && (
                    <Menu.Item
                      onClick={() => {
                        dispatch(
                          toggleRenameEntityModal({
                            visible: true,
                            entity: node,
                            page: 'folder'
                          })
                        );
                      }}
                    >
                      Rename
                    </Menu.Item>
                  )}

                  {(node?.folder?.permissions?.inviteContributors ||
                    node?.folder?.permissions?.inviteFromList) && (
                    <Menu.Item
                      onClick={() => {
                        if (!node) return;
                        dispatch(
                          toggleManageFolderAccessModal({
                            folder: node,
                            visible: true
                          })
                        );
                      }}
                    >
                      Manage Users
                    </Menu.Item>
                  )}
                  {node.folder?.permissions.delete && (
                    <Menu.Item
                      onClick={() => {
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'folder',
                            permanent: false,
                            visible: true
                          })
                        );
                      }}
                    >
                      Delete
                    </Menu.Item>
                  )}
                </>
              ) : (
                <>
                  {node.folder?.permissions.delete && (
                    <Menu.Item
                      key="delete_permanent"
                      onClick={() => {
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'folder',
                            permanent: true,
                            visible: true
                          })
                        );
                      }}
                    >
                      Delete forever
                    </Menu.Item>
                  )}
                  {node.folder?.permissions.delete && (
                    <Menu.Item
                      key="restore"
                      onClick={() => {
                        dispatch(
                          restoreDeletedFolderAction({ folderId: node.id })
                        );
                      }}
                    >
                      Restore
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="text" size="small" style={{ padding: 0 }}>
            <ActionSvg style={{ marginRight: 0 }} />
          </Button>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="table_container">
      {!listLoading && (campaign?.foldersCount || 0) > 0 ? (
        <Table
          locale={{
            emptyText: countToLoad?.map((it: any, idx: any) => (
              <React.Fragment key={idx}>
                <AssetsListSkeleton /> <br />
              </React.Fragment>
            ))
          }}
          pagination={false}
          columns={columns}
          dataSource={foldersData.edges || []}
          rowKey={({ node }) => node.id}
          onRow={({ node }) => ({
            onDragOver: (e) => e.preventDefault(),
            onDrop: (e) => {
              const type = e.dataTransfer.getData('streamwork://type');
              if (type !== 'asset') return;
              const id = e.dataTransfer.getData('streamwork://id');
              if (onDrop) onDrop(id, node.id);
            }
          })}
        />
      ) : (
        <>
          {countToLoad?.map((el: any, idx: any) => (
            <div key={idx} className="skeleton">
              <Skeleton.Input
                style={{ width: '100%', height: 56, marginBottom: 27 }}
              />{' '}
              <br />{' '}
            </div>
          ))}
        </>
      )}

      {new Array(countToFetch).fill('').map((el, idx) => (
        <div key={idx} className="skeleton">
          <Skeleton.Input
            style={{ width: '100%', height: 56, marginBottom: 27 }}
          />{' '}
          <br />{' '}
        </div>
      ))}
    </div>
  );
}

export default FoldersTable;
