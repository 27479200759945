import React from 'react';
import { useAuth } from '@hooks/useAuth';
import { Divider } from 'antd';

import Authentication from '@pages/ProfileSettings/Account/components/Authentication';
import UserPassword from '@pages/ProfileSettings/Account/components/UserPassword';
import Actions from '@pages/ProfileSettings/Account/components/Actions';

import './Account.less';

function Account(): React.ReactElement {
  const { user } = useAuth(true, 'internal');
  return (
    <div className="account_settings_container">
      <Authentication />
      {user.canChangePassword && (
        <>
          <Divider className="profile_settings_divider" />
          <UserPassword />
        </>
      )}
      <Divider className="profile_settings_divider" />
      <Actions />
    </div>
  );
}

export default Account;
