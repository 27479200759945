import { StartOnboardingSteps } from '@helpers/StartOnboardingSteps';
import { useAuth } from '@hooks/useAuth';
import OnboardingStep from '@pages/Start/components/OnboardingSteps/OnboardingStep';
import React, { useMemo, memo, useState, useCallback, useEffect } from 'react';

import './OnboardingSteps.less';
import { apiClient } from '@api/client';
import { CampaignItemDto } from '@api/Api';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '@components/OrganizationBoundary';
import CollaborateOnAnAssetModal from '@pages/Start/components/Modals/CollaborateOnAnAssetModal';
import WatchVideoModal from '@pages/Start/components/Modals/WatchVideoModal/WatchVideoModal';
import RouteForApprovalModal from '@pages/Start/components/Modals/RouteForApprovalModal';
import { OnboardingStepsType } from '@context/OnboardingProcessProvider';

export default memo(function OnboardingSteps() {
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization(true);
  const [ownCampaign, setOwnCampaign] = useState<CampaignItemDto>();
  const [demoCampaign, setDemoCampaign] = useState<CampaignItemDto>();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const [collaborateOnAnAssetModalOpen, setCollaborateOnAnAssetModalOpen] =
    useState<boolean>(false);

  const [routeForApprovalModalOpen, setRouteForApprovalModalOpen] =
    useState<boolean>(false);

  const [watchVideoModalOpen, setWatchVideoModalOpen] = useState<
    OnboardingStepsType | false
  >(false);

  const { user } = useAuth(true, 'internal');

  const items = useMemo(() => {
    const list = StartOnboardingSteps.map((it) => it);
    return list;
  }, []);

  const isContributor = useMemo(() => {
    return currentWorkspace.role === 'workspace://contributor';
  }, [currentWorkspace.role]);

  useEffect(() => {
    (async () => {
      const [demo, own] = await Promise.all([
        apiClient.campaign.campaignControllerListCampaings({
          workspaceId,
          isDemo: true
        }),
        apiClient.campaign.campaignControllerListCampaings({
          workspaceId,
          isDemo: false
        })
      ]);

      const demoCampaign = demo.data.edges.find(({ node }) => node.demo)?.node;
      const ownCampaign = own.data.edges.find(({ node }) => !node.demo)?.node;
      if (demoCampaign) setDemoCampaign(demoCampaign);
      if (ownCampaign) setOwnCampaign(ownCampaign);
    })();
  }, [workspaceId]);

  const isStepActive = useCallback(
    (type: string) => {
      if (isContributor) return true;
      return user.completedOnboardingSteps.includes('create-campaign')
        ? true
        : type === 'create-campaign';
    },
    [isContributor, user.completedOnboardingSteps]
  );

  const onCreateFirstTask = useCallback(() => {
    let campaign = ownCampaign;
    if (isContributor) {
      campaign = demoCampaign || ownCampaign;
    }
    if (!campaign || !currentOrganization.entity) return;
    navigate(
      `/campaigns/single/${campaign.id}?tab=tasks&startTour=create-task`
    );
  }, [
    currentOrganization.entity,
    navigate,
    ownCampaign,
    demoCampaign,
    isContributor
  ]);

  const onWatchVideoClick = useCallback((type: OnboardingStepsType) => {
    setWatchVideoModalOpen(type);
  }, []);

  const onCollaborateOnAnAsset = useCallback(() => {
    setCollaborateOnAnAssetModalOpen(true);
  }, []);

  const onRouteForApproval = useCallback(() => {
    setRouteForApprovalModalOpen(true);
  }, []);

  return (
    <div className="onboarding-steps">
      {items.map((it) => {
        const isDone = user.completedOnboardingSteps.includes(it.type);
        const isActive = isStepActive(it.type);
        if (
          isContributor &&
          (it.type === 'create-campaign' || it.type === 'route-for-approval')
        )
          return null;
        return (
          <OnboardingStep
            key={it.type}
            type={it.type as any}
            isDone={isDone}
            isActive={isActive}
            title={it.title}
            description={it.description}
            button={it.button}
            icon={it.icon}
            hasCampaign={!!(ownCampaign || demoCampaign)}
            onCreateFirstTask={onCreateFirstTask}
            onCollaborateOnAnAsset={onCollaborateOnAnAsset}
            onRouteForApproval={onRouteForApproval}
            onWatchVideoClick={onWatchVideoClick}
          />
        );
      })}
      <CollaborateOnAnAssetModal
        workspaceId={workspaceId}
        visible={collaborateOnAnAssetModalOpen}
        setVisible={setCollaborateOnAnAssetModalOpen}
      />
      <RouteForApprovalModal
        visible={routeForApprovalModalOpen}
        setVisible={setRouteForApprovalModalOpen}
        demoCampaign={demoCampaign}
      />
      <WatchVideoModal
        visible={watchVideoModalOpen}
        setVisible={setWatchVideoModalOpen}
      />
    </div>
  );
});
