import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Collapse, Input } from 'antd';
import { CampaignItemDto } from '@api/Api';
import { useTypedSelector } from '@hooks';
import { useCurrentWorkspace } from '@hooks/workspace';
import '@components/Tasks/TaskModal/TaskModal.less';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  campaignStateSelector,
  dueSoonTasksBoardCountSelector,
  overdueTasksBoardCountSelector,
  tasksBoardSelector
} from '@redux/selectors/tasks';
import {
  loadMoreCampaignTasks,
  openNewTaskModal,
  setTaskBoardOpenedInCampaign
} from '@redux/reducers/tasks';
import {
  fetchTaskListInCampaign,
  fetchTasksCountInCampaign,
  renameTaskBoard
} from '@redux/actions/tasks';
import { useFetch } from '@hooks/useFetch';
import TasksBoardTable from '@pages/Campaigns/CampaignItem/components/TasksTab/TasksBoardTable';
import TaskBoardItemHeader from '@pages/Campaigns/CampaignItem/components/TasksTab/TaskBoardItemHeader';
import { nonNull } from '@helpers/non-null';
import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as InlineTaskSelectSvg } from '@assets/icons/new-inline-task-create-icon.svg';
import { useCreateTask } from '@hooks/tasks';
import { useCurrentTime } from '@hooks/useCurrentTime';

export interface TaskBoardItemProps {
  campaign: CampaignItemDto;
  boardId: string;
  disableFetch: boolean;
  draggable: boolean;
  taskDraggable: boolean;
  draggingTaskId: string | null;
  overTaskId: string | null;
  dropTaskAfter: boolean;
  draggingBoardId: string | null;
  overBoardId: string | null;
  canDeleteAllTasks: boolean;
  canDeleteTaskBoards: boolean;
  canRenameTaskBoards: boolean;
  canMoveTaskBoards: boolean;
  onDragStart: (boardId: string) => void;
  onDragEnter: (boardId: string) => void;
  onDragEnd: () => void;
  onTaskDragStart: (boardId: string, taskId: string) => void;
  onTaskDragEnter: (boardId: string, taskId: string | null) => void;
  onTaskDragEnd: () => void;
  onDelete: (
    boardId: string,
    tasksCount: number,
    deleteAllTasks: boolean
  ) => void;
}

function ExpandIcon({ isActive }: any) {
  return (
    <DownArrowSvg
      style={{
        transform: `translateY(-50%) rotate(${isActive ? -180 : 0}deg)`
      }}
    />
  );
}

export default memo(function TaskBoardItem(props: TaskBoardItemProps) {
  const {
    campaign,
    boardId,
    taskDraggable,
    draggingTaskId,
    draggingBoardId,
    overBoardId,
    overTaskId,
    dropTaskAfter,
    canDeleteAllTasks,
    canDeleteTaskBoards,
    canMoveTaskBoards,
    canRenameTaskBoards,
    onDragStart,
    onDragEnter,
    onDragEnd,
    onTaskDragStart,
    onTaskDragEnter,
    onTaskDragEnd
  } = props;
  const campaignId = campaign.id;
  const dispatch = useTypedDispatch();
  const createTask = useCreateTask();
  const now = useCurrentTime();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const tasksCount = useTypedSelector(
    (state) => tasksBoardSelector(state, { campaignId, boardId }).count
  );

  const overdueTaskCount = useTypedSelector((state) =>
    overdueTasksBoardCountSelector(state, { campaignId, boardId, now })
  );

  const dueSoonTaskCount = useTypedSelector((state) =>
    dueSoonTasksBoardCountSelector(state, { campaignId, boardId, now })
  );

  const name = useTypedSelector(
    (state) => tasksBoardSelector(state, { campaignId, boardId }).name
  );
  const _open = useTypedSelector((state) =>
    campaignStateSelector(state, {
      campaignId
    }).boards.openedIds.includes(boardId)
  );
  const open = _open && !draggingBoardId;
  const [newTaskName, setNewTaskName] = useState<string | null>(null);

  const [visible, setVisible] = useState(false);
  const visibleRef = useRef(visible);
  visibleRef.current = visible;

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const newVisible = !!entries[0]?.isIntersecting;
      if (newVisible !== visibleRef.current) {
        visibleRef.current = newVisible;
        setVisible(newVisible);
      }
    },
    []
  );
  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver(handleIntersection)
  );
  const anchorRef = useRef<HTMLElement | null>();
  const setAnchorRef = useCallback((el: HTMLElement | null) => {
    if (anchorRef.current) {
      observer.current.unobserve(anchorRef.current);
    }
    anchorRef.current = el;
    if (anchorRef.current) {
      observer.current.observe(anchorRef.current);
    }
  }, []);
  useLayoutEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      const records = observer.current.takeRecords();
      if (records.length) handleIntersection(records);
    });
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  });

  const campaignIdRef = useRef(campaignId);
  campaignIdRef.current = campaignId;

  const boardIdRef = useRef(boardId);
  boardIdRef.current = boardId;

  const onOpenChange = useCallback(
    (value: boolean) => {
      dispatch(
        setTaskBoardOpenedInCampaign({
          value,
          campaignId: campaignIdRef.current,
          boardId: boardIdRef.current
        })
      );
    },
    [dispatch]
  );

  const disableFetch = useRef<boolean>(undefined as never);
  disableFetch.current = props.disableFetch || !open || !visible;

  const disableCountFetch = props.disableFetch || !visible;

  useFetch({
    key: `task-board-count-${boardId}`,
    disabled: disableCountFetch,
    selector: (state) =>
      tasksBoardSelector(state, { campaignId, boardId }).countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTasksCountInCampaign({
          workspaceId,
          campaignId,
          boardId,
          fetchType
        })
      )
  });
  useFetch({
    key: `task-board-tasks-${boardId}`,
    disabled: disableFetch.current,
    selector: (state) =>
      tasksBoardSelector(state, { campaignId, boardId }).fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchTaskListInCampaign({
          workspaceId,
          campaignId,
          boardId,
          fetchType
        })
      );
    }
  });

  const onLoadMore = useCallback(() => {
    dispatch(loadMoreCampaignTasks({ campaignId, boardId }));
  }, [campaignId, boardId, dispatch]);

  const isArchivedBoard = boardId === 'archived';
  const isNoAssignedBoard = boardId === 'no_assigned';
  const isSystemBoard = isArchivedBoard || isNoAssignedBoard;
  const showBoard = tasksCount > 0 || !isArchivedBoard;
  const hideNoAssignedBoard = isNoAssignedBoard && tasksCount === 0;
  const canDropAfter = !isArchivedBoard;
  const draggable = props.draggable && !isSystemBoard && canMoveTaskBoards;
  const getPopupContainer = useCallback(() => nonNull(anchorRef.current), []);
  const dragHandle = useRef<HTMLDivElement | null>(null);

  const createInlineTask = () => {
    if (newTaskName) {
      createTask({
        workspaceId: campaign.workspaceId,
        campaign,
        name: newTaskName,
        board: { id: boardId },
        taskPosition: 'end-of-list'
      });
    }
    setNewTaskName(null);
  };

  const onRename = useCallback(
    (newName: string) => {
      dispatch(
        renameTaskBoard({
          campaignId,
          id: boardId,
          name: newName
        })
      );
    },
    [campaignId, boardId, dispatch]
  );

  const onDeleteRef = useRef(props.onDelete);
  onDeleteRef.current = props.onDelete;
  const onDelete = useCallback(
    (deleteAllTasks: boolean) => {
      onDeleteRef.current(boardId, tasksCount, deleteAllTasks);
    },
    [boardId, tasksCount]
  );

  const onNewTaskClick = useCallback(() => {
    dispatch(
      openNewTaskModal({
        organizationId: campaign.organizationId,
        workspaceId: campaign.workspaceId,
        campaign,
        board: { id: boardId, name }
      })
    );
  }, [campaign, boardId, name, dispatch]);

  if (hideNoAssignedBoard) return null;

  return (
    <div
      className="task-group-collapse-wrapper"
      data-board-key={boardId}
      ref={setAnchorRef}
      draggable={draggable}
      onDragStart={(e) => {
        e.dataTransfer.items.clear();
        const headerEl = nonNull(
          anchorRef.current?.querySelector('.ant-collapse-header')
        );
        const rect = headerEl.getBoundingClientRect();
        const handle = document.createElement('div');
        handle.classList.add('collapse-container');
        const collapse = handle.appendChild(document.createElement('div'));
        collapse.classList.add('ant-collapse');
        collapse.classList.add('ant-collapse-icon-position-end');
        const collapseItem = collapse.appendChild(
          document.createElement('div')
        );
        collapseItem.classList.add('ant-collapse-item');
        collapseItem.appendChild(headerEl.cloneNode(true));
        handle.style.width = `${rect.width}px`;
        handle.style.position = 'fixed';
        handle.style.top = `${rect.y - e.pageY - 16}px`;
        handle.style.left = `${rect.x - e.pageX}px`;
        handle.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
        handle.style.zIndex = '1000';
        handle.style.pointerEvents = 'none';
        document.body.appendChild(handle);
        dragHandle.current = handle;
        e.dataTransfer.setDragImage(new Image(), 0, 0);
        onDragStart(boardId);
      }}
      onDrag={(e) => {
        const skip = e.pageX === 0 && e.pageY === 0;
        if (dragHandle.current && !skip) {
          dragHandle.current.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
        }
      }}
      onDragEnter={() => {
        if (draggingBoardId && canDropAfter) {
          onDragEnter(boardId);
        }
      }}
      onDragEnd={() => {
        dragHandle.current?.remove();
        dragHandle.current = null;
        onDragEnd();
      }}
    >
      {showBoard && (
        <Collapse
          expandIconPosition="end"
          expandIcon={ExpandIcon}
          activeKey={open ? ['panel'] : []}
          onChange={(openedPanels) => {
            if (Array.isArray(openedPanels))
              onOpenChange(openedPanels.includes('panel'));
            else onOpenChange(openedPanels === 'panel');
          }}
        >
          <Collapse.Panel
            key="panel"
            header={
              <TaskBoardItemHeader
                boardId={boardId}
                name={name}
                tasksCount={tasksCount}
                overdueTaskCount={overdueTaskCount}
                dueSoonTaskCount={dueSoonTaskCount}
                open={open}
                canDeleteAllTasks={canDeleteAllTasks}
                canDeleteTaskBoards={canDeleteTaskBoards}
                canRenameTaskBoards={canRenameTaskBoards}
                getPopupContainer={getPopupContainer}
                onDelete={onDelete}
                onRename={onRename}
                onNewTaskClick={onNewTaskClick}
              />
            }
          >
            <>
              <TasksBoardTable
                campaign={campaign}
                boardId={boardId}
                boardName={name}
                onLoadMore={onLoadMore}
                draggable={taskDraggable}
                draggingTaskId={draggingTaskId}
                overBoardId={overBoardId}
                overTaskId={overTaskId}
                dropTaskAfter={dropTaskAfter}
                onDragStart={onTaskDragStart}
                onDragEnter={onTaskDragEnter}
                onDragEnd={onTaskDragEnd}
              />
              {newTaskName != null && (
                <Input
                  autoFocus
                  className="new-inline-task-input"
                  prefix={<InlineTaskSelectSvg />}
                  placeholder="Write a task name"
                  value={newTaskName}
                  onChange={(event) => setNewTaskName(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') createInlineTask();
                  }}
                  onBlur={createInlineTask}
                />
              )}
              {!isArchivedBoard && tasksCount > 0 && (
                <div
                  id="START-CREATE-TASK-BTN"
                  className="add-task-table-button-container"
                  onClick={() => setNewTaskName('')}
                >
                  <PlusSvg />
                  <span>Add task</span>
                </div>
              )}
            </>
          </Collapse.Panel>
        </Collapse>
      )}
      <div
        className="drag-start-pouring-container"
        style={{ opacity: boardId === draggingBoardId ? 1 : 0 }}
      />
      {!!draggingBoardId && boardId === overBoardId && (
        <div className="drop-task-board-pouring-container" />
      )}
    </div>
  );
});
