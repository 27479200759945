import { List, Skeleton } from 'antd';
import React, { memo } from 'react';

import './InvoiceItemSkeleton.less';

type InvoiceItemSkeletonProps = {
  count: number;
};
export default memo(function InvoiceItemSkeleton({
  count
}: InvoiceItemSkeletonProps) {
  if (count <= 0) return null;
  return (
    <List
      className="invoice-list-skeleton"
      dataSource={new Array(count).fill(undefined).map((_, i) => ({ key: i }))}
      renderItem={() => (
        <List.Item>
          <Skeleton.Input style={{ height: 73 }} />
        </List.Item>
      )}
    />
  );
});
