import React from 'react';

import { ReactComponent as DueDateSvg } from '@assets/icons/due-date.svg';

import { Tag } from 'antd';

import './DueDate.less';
import moment from 'moment';

function DueDate({ date, urgent }) {
  const isUrgent = urgent ? 'date-urgent' : '';
  return (
    <span className="due_date_container">
      <Tag color={isUrgent ? '#F9F4F3' : '#E5E5E5'}>
        <span className={`date ${isUrgent}`}>
          <DueDateSvg />
          {moment(new Date(date)).format(
            `${moment(date).minutes() === 59 ? 'MMM DD' : 'MMM DD, h:mm a'}`
          )}
        </span>
      </Tag>
    </span>
  );
}

export default DueDate;
