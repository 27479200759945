import React, { useState } from 'react';

import { Table, Switch, Button, Tooltip, Avatar, Popover } from 'antd';
import { AssetItemDto, FolderMemberDto } from '@api/Api';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { OrganizationRoleCopy, WorkspaceRoleCopy } from '@helpers/userRoles';
import UserAvatar from '@components/UserAvatar';
import { useOrganization } from '@components/OrganizationBoundary';
import { ColumnsType } from 'antd/lib/table/interface';
import { hashedColor } from '@helpers/hashedColor';
import { toggleWorkspaceMemberGroupModal } from '@redux/actions/modalAction';
import classNames from 'classnames';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useDispatch } from 'react-redux';

function FolderMembersTable({
  folder,
  membersList,
  onRevokeAssess,
  onShareAccess
}: {
  folder: AssetItemDto;
  membersList: FolderMemberDto[];
  onRevokeAssess: (a: string, b: boolean) => void | Promise<void>;
  onShareAccess: (a: string) => void | Promise<void>;
}) {
  const { currentOrganization } = useOrganization();
  const [currentWorkspace] = useCurrentWorkspace();
  const dispatch = useDispatch();
  const [switchLoading, setSwitchLoading] = useState<string | false>(false);
  const [visibleGroupsPopover, setVisibleGroupsPopover] = React.useState<
    string | null
  >(null);
  const columns: ColumnsType<FolderMemberDto> = [
    {
      title: 'User name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, node) => {
        const isAdmin = node.organizationRole === 'organization://admin';
        const role =
          node.workspaceRole && !isAdmin
            ? WorkspaceRoleCopy[node.workspaceRole]
            : OrganizationRoleCopy[node.organizationRole];
        return (
          <div className="user_record">
            <div className="user_record__avatar">
              <UserAvatar
                isActive={!node.invitationEmail}
                size="large"
                src={node.user?.picture.url || ''}
                userEmail={node.user?.email || ''}
                userName={node.user?.name || ''}
              />
            </div>
            <div className="user_record__info">
              <div className="name">
                <Tooltip
                  placement="topLeft"
                  overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                  title={node.user ? node.user?.name : node.invitationEmail}
                >
                  <span>
                    {node.user ? node.user?.name : node.invitationEmail}
                  </span>
                </Tooltip>

                {node.invitationEmail && (
                  <Button
                    className="email-resend"
                    type="link"
                    size="small"
                    disabled={
                      currentOrganization.seatsCount.count >=
                      (currentOrganization.entity?.limits?.orgMembers ??
                        Number.POSITIVE_INFINITY)
                    }
                    onClick={async () => {
                      await onShareAccess(node.id);
                      toast(
                        <ActionToast
                          title="Invite sent!"
                          closeToast={undefined}
                          onUndo={undefined}
                          description={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          delay: 222,
                          style: { width: '345px' },
                          bodyClassName: 'toast_container--invitation'
                        }
                      );
                    }}
                  >
                    Resend
                  </Button>
                )}
              </div>
              <div className="email main-body-text main-body-text--tiny main-body-text--dark-gray">
                {role}
              </div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Groups',
      key: 'groups',
      dataIndex: 'groups',
      width: 90,
      render: (_: any, node: FolderMemberDto) => {
        const { groups } = node;
        return (
          <Popover
            content={
              <div>
                <div className="user_campaigns__title">
                  Groups ({groups.length})
                </div>
                {groups.map((group) => {
                  return (
                    <div key={group.id} className="user_campaigns__list">
                      <Button
                        block
                        className="user_campaigns__name"
                        type="link"
                        disabled={!currentWorkspace.permissions.listMembers}
                        onClick={() => {
                          setVisibleGroupsPopover(null);
                          dispatch(
                            toggleWorkspaceMemberGroupModal({
                              group,
                              workspace: currentWorkspace,
                              visible: true
                            })
                          );
                        }}
                      >
                        <Avatar
                          size="small"
                          shape="square"
                          style={{
                            background: hashedColor(
                              group.id,
                              'memberGroupAvatar'
                            ),
                            marginRight: 16
                          }}
                        >
                          {group.name[0].toUpperCase()}
                        </Avatar>
                        {group.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            }
            className={classNames({
              user_campaigns: true,
              'user_campaigns--empty': !groups.length
            })}
            placement="bottomLeft"
            overlayClassName="user_campaigns__popover"
            trigger={groups.length ? ['hover'] : []}
            open={visibleGroupsPopover === node.id}
            onOpenChange={(open) => {
              if (open) {
                setVisibleGroupsPopover(node.id);
              } else {
                setVisibleGroupsPopover(null);
              }
            }}
          >
            <span>{groups.length}</span>
          </Popover>
        );
      }
    },
    {
      title: () => folder.isPrivate && <>Access</>,
      dataIndex: 'access',
      key: 'access',
      render: (text: string, node) => {
        if (
          node?.accessControl === 'add_delete' &&
          node.permissions.revokeAccess
        ) {
          return (
            <Button
              type="link"
              onClick={async () => {
                setSwitchLoading(node.id);
                await onRevokeAssess(node.id, true);
                setSwitchLoading(false);
              }}
              loading={switchLoading === node.id}
              disabled={!node.permissions.revokeAccess}
              className="main-body-text main-body-text--semibold main-body-text--dark-gray"
              style={{ float: 'right', paddingRight: 0, marginBottom: -11 }}
            >
              Remove
            </Button>
          );
        }
        const isSwitcherDisabled = node.hasAccessToPrivate
          ? !node.permissions.revokeAccess
          : !node.permissions.shareAccess;
        let disabledTooltip: string | null = null;
        if (isSwitcherDisabled && node.groupAccess.length) {
          const groupNames = node.groupAccess
            .map((group) => group.name)
            .sort((a, b) => a.localeCompare(b))
            .join(', ');
          const multipleGroups = node.groupAccess.length > 1;
          disabledTooltip = `You can't change the access of this user because they are a member of the following group${
            multipleGroups ? 's' : ''
          }: ${groupNames}`;
        } else if (isSwitcherDisabled && node.user?.me) {
          disabledTooltip = "You can't change your own access";
        } else if (isSwitcherDisabled) {
          disabledTooltip =
            "You can't change the access of this user because their role allows them to access all folders";
        }
        return (
          <div style={{ float: 'right' }}>
            {folder.isPrivate && node.accessControl === 'enable_disable' && (
              <Tooltip
                title={disabledTooltip}
                overlayClassName="toggle_favorite_overlay"
              >
                <Switch
                  onChange={async () => {
                    setSwitchLoading(node.id);
                    if (!node.hasAccessToPrivate) await onShareAccess(node.id);
                    else await onRevokeAssess(node.id, false);
                    setSwitchLoading(false);
                  }}
                  disabled={isSwitcherDisabled}
                  loading={switchLoading === node.id}
                  checked={node.hasAccessToPrivate}
                />
                <span style={{ marginLeft: 4 }}>
                  {node.hasAccessToPrivate ? 'On' : 'Off'}
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      width: 180,
      align: 'right'
    }
  ];
  if (!membersList.length) return null;
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={membersList}
      rowKey={(node) => node.id}
    />
  );
}

export default FolderMembersTable;
