import React from 'react';
import { ReactComponent as Arrow } from '@assets/icons/arrow-down.svg';
import { ReactComponent as Video } from '@assets/icons/AssetPlaceholder/videofile.svg';
import { ReactComponent as Doc } from '@assets/icons/AssetPlaceholder/pdffile.svg';
import { ReactComponent as Audio } from '@assets/icons/AssetPlaceholder/audiofile.svg';

export default function ApprovalEmptyStateView() {
  return (
    <div className="approval_empty-view">
      <div className="approval_empty-view-wrapper">
        <div className="approval_empty-view-wrapper-list">
          <AwRow version="V1" icon={<Video />} open={true} />
          <StageRows />
          <AwRow version="V3" icon={<Doc />} open={false} />
          <AwRow version="V5" icon={<Audio />} open={false} />
        </div>
      </div>
    </div>
  );
}

function StageRows() {
  return (
    <div className="stages">
      <div className="stage-number">
        <Arrow /> <span>STAGE 1</span>
      </div>
      <div className="user">
        <div className="user-avatar">K</div>
        <div className="user-email">victoria@yourcompany.com</div>
        <div
          className="user-decision"
          style={{ color: '#5D9BC8', background: '#B0DAF8', marginLeft: 102 }}
        >
          No decision
        </div>
      </div>
      <div className="user">
        <div className="user-avatar">B</div>
        <div className="user-email">chase@yourclient.com</div>
        <div
          className="user-decision"
          style={{ color: '#669061', background: '#A7D2A1', marginLeft: 137 }}
        >
          Approved
        </div>
      </div>
    </div>
  );
}

function AwRow({
  version,
  icon,
  open
}: {
  version: string;
  icon: React.ReactElement;
  open: boolean;
}) {
  return (
    <div className="approval_empty-view-wrapper-list-item">
      <div className="left">
        <div
          className="arrow"
          style={open ? { transform: 'rotate(180deg)' } : undefined}
        >
          <Arrow />
        </div>
        <div className="asset">{icon}</div>
        <div className="row" style={{ width: 156, marginLeft: 4 }} />
        <div className="tag">{version}</div>
      </div>
      <div className="right">
        <div className="row" style={{ width: 59, marginRight: 24 }} />
        <div className="row" style={{ width: 59, marginRight: 48 }} />
        <div className="row" style={{ width: 28 }} />
      </div>
    </div>
  );
}
