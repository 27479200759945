import { Divider, Modal } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { getAssetList } from '@api/Asset';
import { AssetItemDto, PageDto } from '@api/Api';
import AssetsList from '@components/Approval/ApprovalEmptyStateWrapper/components/AssetsList';
import InfiniteScroll from 'react-infinite-scroll-component';
import AssetItemSkeleton from '@components/Approval/ApprovalEmptyStateWrapper/components/AssetItemSkeleton';

type AssetsListModalProps = {
  open: boolean;
  workspaceId: string;
  assetsCount: number;
  campaignId?: string;
  onCloseModal: () => void;
  getApprovalsData: () => void;
};

export default memo(function AssetsListModal({
  open,
  workspaceId,
  assetsCount,
  campaignId,
  onCloseModal,
  getApprovalsData
}: AssetsListModalProps) {
  const [assets, setAssets] = useState<
    PageDto & { edges: { node: AssetItemDto; cursor: string }[] }
  >();

  useEffect(() => {
    (async () => {
      if (!open) return;
      const { data } = await getAssetList({
        campaignId,
        workspaceId,
        flatten: true
      });
      setAssets(data);
    })();
  }, [open, workspaceId, campaignId]);

  const loadMoreAssets = useCallback(async () => {
    const { data } = await getAssetList({
      campaignId,
      workspaceId,
      flatten: true
    });
    setAssets((prev) => ({
      ...data,
      edges: [...(prev?.edges || []), ...data.edges]
    }));
  }, [workspaceId, campaignId]);

  return (
    <Modal
      open={open}
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      destroyOnClose
      width={688}
      onCancel={onCloseModal}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{
            fontSize: 22,
            marginBottom: '0.5em',
            justifyContent: 'center'
          }}
        >
          Create approval routing
        </h2>

        <p
          className="modal_container__subtitle"
          style={{
            marginBottom: '32px',
            textAlign: 'center',
            color: '#161819'
          }}
        >
          You have {assets?.edges.length} media uploaded. Select one to route
          for approval.
        </p>
        <Divider />
        {assets ? (
          <InfiniteScroll
            dataLength={assets.edges.length}
            next={loadMoreAssets}
            hasMore={assets.hasNext}
            loader={<></>}
            height="423px"
            style={{ overflowX: 'hidden' }}
          >
            <AssetsList
              list={assets.edges.map((it) => it.node)}
              onCloseModal={onCloseModal}
              getApprovalsData={getApprovalsData}
            />
          </InfiniteScroll>
        ) : (
          <AssetItemSkeleton count={assetsCount} />
        )}
      </div>
    </Modal>
  );
});
