import { useTypedSelector } from '@hooks';
import { Avatar, Button, Form, Input, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { workspaceGetWorkspaceObjectives } from '@api/Workspace';
import { AgencyModel } from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/AgencyModel';
import { ReactComponent as CheckSvg } from '@assets/icons/check.svg';
import { ReactComponent as DeleteSvg } from '@assets/icons/delete.svg';
import { ReactComponent as EditSvg } from '@assets/icons/Edit.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import LinksModal from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/LinksModal';

const DEFAULT_OBJECTIVES = [
  'Grow brand awareness',
  'Increase engagement',
  'Increase leads',
  'Drive sales'
];

function LinksTagRender({ value }: any) {
  if (!value) return <></>;
  const obj = JSON.parse(value);

  return <div style={{ padding: 12 }}>{obj.name || obj.url}</div>;
}

function AgencyTagRender({ value }: any) {
  if (!value) return <></>;
  const obj = JSON.parse(value);

  if (!obj.name) return <></>;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <strong className="agency_name">
        <span className="main-body-text main-body-text--semibold">
          {obj.name}
        </span>
      </strong>
      {!obj?.contacts?.length ? (
        <strong className="agency_no-contact">
          <span className="main-body-text main-body-text--semibold main-body-text--dark-gray">
            No agency contacts
          </span>
        </strong>
      ) : (
        <>
          {obj.contacts?.map((el: any, idx: number) => (
            <div key={idx} className="agency_tag">
              <Avatar className="agency_tag__avatar" size={24}>
                {el.name[0]}
              </Avatar>
              <span className="agency_tag__name main-body-text">
                {el.name} {obj.contacts.length - 1 !== idx && ';'}
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function ObjectivesTagRender({ value, onClose, isEdit }: any) {
  return (
    <span className="selected_item">
      <span className="selected_item__content">
        <span className="name main-body-text">{value}</span>
        {isEdit && <span className="close" onClick={onClose} />}
      </span>
    </span>
  );
}

function Edit(props: any) {
  const formRef = useRef<any>();
  const [isAgencyModalVisible, setIsAgencyModalVisible] =
    useState<boolean>(false);
  const [isLinksModalVisible, setIsLinksModalVisible] =
    useState<boolean>(false);
  const [agencyToModify, setAgencyToModify] = useState<any>(null);
  const [linkToModify, setLinkToModify] = useState<any>(null);
  const [linkToScroll, setLinkToScroll] = useState<number | false>(false);
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  useEffect(() => {
    props.form.resetFields();
  }, [campaignInfoModal?.campaign?.id]);

  const [objectiveFieldDropdownData, setObjectiveFieldDropdownData] =
    useState<string>('');

  useEffect(() => {
    if (!props.needOpenAgencyModal) return;
    if (props.needOpenAgencyModal === 'new') {
      setAgencyToModify({
        idx: 0,
        name: '',
        contacts: []
      });
    }
    if (props.needOpenAgencyModal.name) {
      setAgencyToModify(props.needOpenAgencyModal);
    }

    setIsAgencyModalVisible(true);
    props.setNeedOpenAgencyModal(false);
  }, [props.needOpenAgencyModal]);

  useEffect(() => {
    if (!props.needOpenLinksModal) return;

    const { links } = props.form.getFieldsValue();
    const tmp = links?.length ? [] : [{ name: '', url: '' }];
    setLinkToModify([
      ...(links?.map((it: string) => JSON.parse(it)) || []),
      ...tmp
    ]);

    setIsLinksModalVisible(true);
    props.setNeedOpenLinksModal(false);
  }, [props.needOpenLinksModal]);

  const [availableObjectives, setAvailableObjectives] = useState<string[]>([]);
  useEffect(() => {
    if (!campaignInfoModal?.visible) return;
    (async () => {
      const {
        data: { objectives }
      } = await workspaceGetWorkspaceObjectives({
        workspaceId: campaignInfoModal.campaign?.workspaceId || ''
      });

      setAvailableObjectives(objectives);
    })();
  }, [campaignInfoModal]);

  useEffect(() => {
    if (!props.scrollSelector) return;

    setTimeout(() => {
      const head = document.querySelector('.campaign_model_body__header');
      const elementToScroll = formRef.current.querySelector(
        props.scrollSelector
      );

      if (!elementToScroll || !head) return;
      const headRect = head.getBoundingClientRect();
      const elementRect = elementToScroll.getBoundingClientRect();
      props.scrollerInstance.scroll(
        { y: elementRect.bottom - headRect.bottom },
        400
      );
      const input =
        elementToScroll.querySelector('.ant-input') ??
        elementToScroll.querySelector('.ant-select-selection-search-input');
      input?.focus();
    }, 1);
  }, [props.scrollSelector]);

  const onSaveAgencyModal = (v: any) => {
    const newAgency = { name: v.name, contacts: v.contacts };
    if (v.isEdit) {
      const agencies = props.form
        .getFieldValue('agencies')
        .map((el: any, idx: any) => {
          if (idx === v.idx) {
            return JSON.stringify(newAgency);
          }
          return el;
        });
      const newList = props.availableCampaignDetailsFields.map((el: any) => {
        if (el.key === 'agencies')
          return {
            ...el,
            value: el.value.map((it: any, index: any) => {
              if (index === v.idx) return newAgency;
              return it;
            })
          };
        return el;
      });
      props.setAvailableCampaignDetailsFields(newList);
      props.form.setFieldsValue({ agencies });
    } else {
      const agencies = props.form.getFieldValue('agencies') || [];

      const newList = props.availableCampaignDetailsFields.map((el: any) => {
        if (el.key === 'agencies')
          return {
            ...el,
            value: [...el.value, newAgency]
          };
        return el;
      });
      props.setAvailableCampaignDetailsFields(newList);
      props.form.setFieldsValue({
        agencies: [...agencies, JSON.stringify(newAgency)]
      });
    }

    setIsAgencyModalVisible(false);
    setAgencyToModify(null);
  };

  const onSaveLinkModal = useCallback(
    (links) => {
      const newList = props.availableCampaignDetailsFields.map((el: any) => {
        if (el.key === 'links')
          return {
            ...el,
            value: links
          };
        return el;
      });

      props.setAvailableCampaignDetailsFields(newList);
      props.form.setFieldsValue({
        links: links.map((it: string) => JSON.stringify(it))
      });
    },
    [props]
  );

  const onDeleteAgency = (idx: any) => {
    let newList = props.availableCampaignDetailsFields.map((el: any) => {
      if (el.key === 'agencies')
        return {
          ...el,
          value: el.value.filter((el: any, index: any) => index !== idx)
        };
      return el;
    });

    newList = newList.filter((el: any) => {
      if (el.key === 'agencies' && el.value.length === 0) return false;
      return true;
    });

    props.setAvailableCampaignDetailsFields(newList);

    let agencies = props.form.getFieldValue('agencies');

    agencies = agencies.filter((el: any, index: any) => index !== idx);

    props.form.setFieldsValue({ agencies });
  };

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        onFinish={props.onSaveDetails}
        initialValues={props.initialValues}
      >
        <div ref={formRef}>
          <div className="form-item-container" data-field="targetAudience">
            <Form.Item
              label="Target audience"
              name="targetAudience"
              rules={[
                {
                  max: 256,
                  message:
                    'Target audience cannot be longer than 256 characters'
                }
              ]}
            >
              <Input placeholder="Add Target audience" size="large" />
            </Form.Item>
          </div>
          <div className="form-item-container" data-field="objectives">
            <Form.Item
              key="field-objectives"
              label="Campaign objectives"
              name="objectives"
            >
              <Select
                className="campaign-objectives-edit"
                placeholder="Add campaign objectives"
                size="large"
                mode="tags"
                tokenSeparators={[',']}
                onChange={(value: string[] | null, options) => {
                  if (
                    value &&
                    value[value.length - 1] &&
                    /^\s+$/i.test(value[value.length - 1])
                  ) {
                    value[value.length - 1].trim();
                    value.pop();
                  }
                }}
                dropdownRender={(menu) => {
                  const list = DEFAULT_OBJECTIVES.concat(availableObjectives);
                  const exists = list.filter((el) =>
                    el
                      .toLowerCase()
                      .includes(objectiveFieldDropdownData.toLowerCase())
                  );
                  return (
                    <>
                      {menu}
                      {objectiveFieldDropdownData && (
                        <span className="ant-select-item ant-select-item-option">
                          <span>
                            <span className="main-body-text main-body-text--dark-gray">
                              Press Enter to add a new tag
                            </span>
                            <span className="main-body-text main-body-text--semibold">
                              {` "${objectiveFieldDropdownData}"`}
                            </span>
                          </span>
                        </span>
                      )}
                    </>
                  );
                }}
                tagRender={({ value, onClose }) => {
                  return ObjectivesTagRender({
                    value,
                    onClose,
                    isEdit: true
                  });
                }}
                suffixIcon={<span>Tag</span>}
                showArrow={true}
                onSelect={() => {
                  setObjectiveFieldDropdownData('');
                }}
                dropdownClassName={`campaign-objectives-edit-dropdown${
                  objectiveFieldDropdownData.length > 0 ? ' search' : ''
                }`}
                onSearch={(value) => setObjectiveFieldDropdownData(value)}
                menuItemSelectedIcon={<CheckSvg />}
              >
                {availableObjectives.map((el) => (
                  <Select.Option key={el}>{el}</Select.Option>
                ))}
                {DEFAULT_OBJECTIVES.filter(
                  (el) => availableObjectives.indexOf(el) === -1
                ).map((el) => (
                  <Select.Option key={el}>{el}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="form-item-container" data-field="description">
            <Form.Item
              label="Campaign description"
              name="description"
              rules={[
                {
                  max: 1024,
                  message:
                    'Campaign description cannot be longer than 1024 characters'
                }
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 3 }}
                placeholder="Add campaign description"
                size="large"
              />
            </Form.Item>
          </div>
          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'finalDeliverables'
          ) && (
            <div className="form-item-container" data-field="finalDeliverables">
              <Form.Item
                label="Final deliverables"
                name="finalDeliverables"
                rules={[
                  {
                    max: 1024,
                    message:
                      'Final deliverables cannot be longer than 256 characters'
                  }
                ]}
              >
                <Input placeholder="Add final deliverables" size="large" />
              </Form.Item>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  const newList = props.availableCampaignDetailsFields.filter(
                    (el: any) => el.key !== 'finalDeliverables'
                  );
                  props.form.setFieldsValue({ finalDeliverables: null });
                  props.setAvailableCampaignDetailsFields(newList);
                }}
                className="campaign_model_fields__delete"
              >
                <DeleteSvg style={{ margin: 0 }} />
              </Button>
            </div>
          )}
          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'agencies'
          ) && (
            <Form.List name="agencies">
              {(agencies, { add, remove }) => {
                return (
                  <div data-field="agencies">
                    {agencies.map((agency, idx) => (
                      <div
                        key={`agency-${idx}`}
                        className="form-item-container"
                      >
                        <Form.Item
                          label={!idx && 'Agency contacts'}
                          name={[idx]}
                          className="campaign_details_custom_field campaign_details_custom_field--edit"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <Select
                            onInputKeyDown={(e) => e.preventDefault()}
                            onClick={() => {
                              const { agencies } = props.form.getFieldsValue();
                              if (agencies[agency.name])
                                setAgencyToModify({
                                  idx: agency.name,
                                  ...JSON.parse(agencies[agency.name])
                                });
                              else
                                setAgencyToModify({
                                  idx: agencies.length,
                                  name: '',
                                  contacts: []
                                });
                              setIsAgencyModalVisible(true);
                            }}
                            className="agency_field"
                            size="large"
                            open={false}
                            mode="tags"
                            tokenSeparators={[',']}
                            tagRender={({ value, label }) => {
                              return AgencyTagRender({
                                value,
                                label
                              });
                            }}
                            showArrow={true}
                            suffixIcon={
                              <Button
                                size="small"
                                type="text"
                                className="main-body-text main-body-text--dark-gray main-body-text--bold"
                              >
                                <EditSvg style={{ marginRight: 4 }} />
                                Edit
                              </Button>
                            }
                          />
                        </Form.Item>
                        <Button
                          type="text"
                          size="small"
                          onClick={() => {
                            let action = true;

                            const agency = props.form
                              .getFieldValue('agencies')
                              .map((el: any) => JSON.parse(el))[idx];

                            toast(
                              <ActionToast
                                title="Agency deleted"
                                description={`You have removed ${agency.name}`}
                                closeToast={() => {
                                  action = true;
                                }}
                                onUndo={() => {
                                  action = false;
                                }}
                              />,
                              {
                                onClose: () => {
                                  if (!action) return;
                                  let newList =
                                    props.availableCampaignDetailsFields.map(
                                      (el: any) => {
                                        if (el.key === 'agencies')
                                          return {
                                            ...el,
                                            value: el.value.filter(
                                              (el: any, index: any) =>
                                                index !== idx
                                            )
                                          };
                                        return el;
                                      }
                                    );

                                  newList = newList.filter((el: any) => {
                                    if (
                                      el.key === 'agencies' &&
                                      el.value.length === 0
                                    )
                                      return false;
                                    return true;
                                  });

                                  props.setAvailableCampaignDetailsFields(
                                    newList
                                  );

                                  remove(idx);
                                }
                              }
                            );
                          }}
                          style={agency.name ? { top: '8px' } : undefined}
                          className="campaign_model_fields__delete"
                        >
                          <DeleteSvg style={{ margin: 0 }} />
                        </Button>
                      </div>
                    ))}
                    <Button
                      size="small"
                      type="text"
                      icon={<PlusSvg />}
                      style={{ paddingLeft: 0 }}
                      onClick={() => {
                        setAgencyToModify({
                          idx: agencies.length,
                          name: '',
                          contacts: []
                        });
                        setIsAgencyModalVisible(true);
                      }}
                    >
                      Add new agency
                    </Button>
                    <br />
                  </div>
                );
              }}
            </Form.List>
          )}
          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'kpis'
          ) && (
            <div className="form-item-container" data-field="kpis">
              <Form.Item
                label="KPIs"
                name="kpis"
                rules={[
                  {
                    max: 256,
                    message: 'KPIs cannot be longer than 256 characters'
                  }
                ]}
              >
                <Input placeholder="Add KPIs" size="large" />
              </Form.Item>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  const newList = props.availableCampaignDetailsFields.filter(
                    (el: any) => el.key !== 'kpis'
                  );
                  props.form.setFieldsValue({ kpis: null });
                  props.setAvailableCampaignDetailsFields(newList);
                }}
                className="campaign_model_fields__delete"
              >
                <DeleteSvg style={{ margin: 0 }} />
              </Button>
            </div>
          )}
          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'budget'
          ) && (
            <div className="form-item-container" data-field="budget">
              <Form.Item
                label="Budget"
                name="budget"
                rules={[
                  {
                    max: 256,
                    message: 'Budget cannot be longer than 256 characters'
                  }
                ]}
              >
                <Input placeholder="Add budget" size="large" />
              </Form.Item>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  const newList = props.availableCampaignDetailsFields.filter(
                    (el: any) => el.key !== 'budget'
                  );
                  props.form.setFieldsValue({ budget: null });
                  props.setAvailableCampaignDetailsFields(newList);
                }}
                className="campaign_model_fields__delete"
              >
                <DeleteSvg style={{ margin: 0 }} />
              </Button>
            </div>
          )}
          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'links'
          ) && (
            <Form.List name="links">
              {(links, { add, remove }) => {
                return (
                  <div data-field="links">
                    {links.map((link, idx) => {
                      return (
                        <div
                          key={`links-${idx}`}
                          className="form-item-container"
                        >
                          <Form.Item
                            label={!idx && 'Links'}
                            name={[idx]}
                            className="campaign_details_custom_field campaign_details_custom_field--edit"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Select
                              onInputKeyDown={(e) => e.preventDefault()}
                              onClick={() => {
                                const { links } = props.form.getFieldsValue();

                                setLinkToModify(
                                  links.map((it: string) => JSON.parse(it))
                                );
                                setLinkToScroll(idx);
                                setIsLinksModalVisible(true);
                              }}
                              className="agency_field"
                              size="large"
                              open={false}
                              mode="tags"
                              tokenSeparators={[',']}
                              tagRender={({ value, label }) => {
                                return LinksTagRender({
                                  value,
                                  label
                                });
                              }}
                              showArrow={true}
                              suffixIcon={
                                <Button
                                  size="small"
                                  type="text"
                                  className="main-body-text main-body-text--dark-gray main-body-text--bold"
                                >
                                  <EditSvg style={{ marginRight: 4 }} />
                                  Edit
                                </Button>
                              }
                            />
                          </Form.Item>
                          <Button
                            type="text"
                            size="small"
                            onClick={() => {
                              let list =
                                props.availableCampaignDetailsFields.map(
                                  (el: any) => {
                                    if (el.key === 'links')
                                      return {
                                        ...el,
                                        value: el.value.filter(
                                          (it: any, index: any) => index !== idx
                                        )
                                      };

                                    return el;
                                  }
                                );

                              list = list.filter((el: any) => {
                                if (el.key === 'links' && el.value.length === 0)
                                  return false;
                                return true;
                              });
                              props.setAvailableCampaignDetailsFields(list);

                              remove(idx);
                            }}
                            style={idx > 0 ? { top: '8px' } : {}}
                            className="campaign_model_fields__delete"
                          >
                            <DeleteSvg style={{ margin: 0 }} />
                          </Button>
                        </div>
                      );
                    })}
                    <Button
                      size="small"
                      type="text"
                      icon={<PlusSvg />}
                      style={{ paddingLeft: 0 }}
                      onClick={() => {
                        const { links } = props.form.getFieldsValue();

                        setLinkToModify([
                          ...links.map((it: string) => JSON.parse(it)),
                          { name: '', url: '' }
                        ]);
                        setIsLinksModalVisible(true);
                      }}
                    >
                      Add link
                    </Button>
                    <br />
                  </div>
                );
              }}
            </Form.List>
          )}

          {props.availableCampaignDetailsFields.find(
            (el: any) => el.key === 'otherNotes'
          ) && (
            <div className="form-item-container" data-field="otherNotes">
              <Form.Item
                label="Other notes"
                name="otherNotes"
                rules={[
                  {
                    max: 256,
                    message: 'other notes cannot be longer than 256 characters'
                  }
                ]}
              >
                <Input placeholder="Add other notes" size="large" />
              </Form.Item>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  const newList = props.availableCampaignDetailsFields.filter(
                    (el: any) => el.key !== 'otherNotes'
                  );
                  props.form.setFieldsValue({ otherNotes: null });
                  props.setAvailableCampaignDetailsFields(newList);
                }}
                className="campaign_model_fields__delete"
              >
                <DeleteSvg style={{ margin: 0 }} />
              </Button>
            </div>
          )}
        </div>
      </Form>
      <LinksModal
        visible={!!isLinksModalVisible}
        links={linkToModify}
        linkToScroll={linkToScroll}
        setLinkToScroll={setLinkToScroll}
        onSave={(links) => {
          onSaveLinkModal(links);
          setIsLinksModalVisible(false);
        }}
        onClose={() => {
          setIsLinksModalVisible(false);
        }}
      />
      <AgencyModel
        visible={isAgencyModalVisible}
        agency={agencyToModify}
        onDelete={(v) => {
          onDeleteAgency(v);
          setIsAgencyModalVisible(false);
        }}
        onClose={(v, idx) => {
          if (!v) {
            onDeleteAgency(idx);
          }

          setIsAgencyModalVisible(false);
        }}
        onSave={onSaveAgencyModal}
      />
    </>
  );
}

export default Edit;
