import { Button, Form, Input, Modal, Space } from 'antd';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { ReactComponent as DeleteSvg } from '@assets/icons/delete.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { CampaignLinkDto } from '@api/Api';

type LinksModalProps = {
  visible: boolean;
  links: CampaignLinkDto[];
  linkToScroll: number | false;
  onSave: (links: CampaignLinkDto[]) => void;
  onClose: any;
  setLinkToScroll: (v: number | false) => void;
};

export default memo(function LinksModal({
  visible,
  links,
  onSave,
  onClose,
  linkToScroll,
  setLinkToScroll
}: LinksModalProps) {
  const [form] = Form.useForm();
  const linkRowsEndRef = useRef<any>();

  const onFinish = useCallback(
    ({ links }) => {
      onSave(links);
    },
    [onSave]
  );
  useEffect(() => {
    if (!visible) return;
    form.setFieldsValue({ links });
  }, [visible, form, links]);

  useEffect(() => {
    if (!visible || linkToScroll === false) return;

    const el = document.querySelector(
      `[data-field="link-row-${linkToScroll}"]`
    );
    if (!el) return;
    const input = el.getElementsByTagName('input');
    const timeoutId = setTimeout(() => {
      input[0].focus();
      el.scrollIntoView({
        behavior: 'auto',
        block: 'center'
      });
      setLinkToScroll(false);
    }, 1);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [visible, linkToScroll, setLinkToScroll]);
  return (
    <Modal
      className="campaign_links_modal"
      visible={visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={480}
      onCancel={() => onClose()}
      zIndex={1006}
    >
      <div className="campaign_links_modal--container">
        <div className="campaign_links_modal--container-title">Add links</div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ links }}
        >
          <div className="campaign_links_modal--container-content">
            <Form.List name="links">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div
                      style={{
                        maxHeight: '300px',
                        overflow: 'auto',
                        marginRight: '-8px',
                        paddingRight: '8px',
                        paddingTop: 2,
                        paddingBottom: 2
                      }}
                    >
                      {fields.map(({ key, name, ...restField }, idx) => {
                        return (
                          <Space
                            data-field={`link-row-${key}`}
                            id={`${key}`}
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="start"
                            size={[8, 0]}
                          >
                            <Form.Item
                              style={{
                                marginBottom: 0,
                                marginRight: 4,
                                marginLeft: 2,
                                width: 156
                              }}
                              {...restField}
                              name={[name, 'name']}
                              rules={[
                                {
                                  pattern:
                                    /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                                  message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                                },
                                {
                                  max: 256,
                                  message:
                                    'Title cannot be longer than 256 characters'
                                },
                                {
                                  required: true,
                                  message: 'Title required.'
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value?.length &&
                                      getFieldValue('links')[idx]?.name
                                        ?.length > 0
                                    ) {
                                      return Promise.reject(
                                        new Error('Fill url title')
                                      );
                                    }

                                    return Promise.resolve();
                                  }
                                })
                              ]}
                              validateTrigger="onSubmit"
                            >
                              <Input size="large" placeholder="Title" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'url']}
                              style={{ marginBottom: 0, marginRight: 4 }}
                              rules={[
                                {
                                  type: 'url',
                                  message: 'Not valid URL'
                                },
                                {
                                  required: true,
                                  message: 'URL required.'
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value?.length &&
                                      getFieldValue('links')[idx]?.url?.length >
                                        0
                                    ) {
                                      return Promise.reject(
                                        new Error('Fill url')
                                      );
                                    }

                                    return Promise.resolve();
                                  }
                                })
                              ]}
                              validateTrigger="onSubmit"
                            >
                              <Input size="large" placeholder="URL" />
                            </Form.Item>
                            <Button
                              type="text"
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() => {
                                remove(name);
                              }}
                              className="delete-link"
                            >
                              <DeleteSvg style={{ margin: 0 }} />
                            </Button>
                          </Space>
                        );
                      })}
                      <div ref={linkRowsEndRef} />
                    </div>
                    <Button
                      type="text"
                      size="small"
                      icon={<PlusSvg />}
                      onClick={(e) => {
                        add();
                        if (document.activeElement instanceof HTMLElement) {
                          document.activeElement.blur();
                        }
                        setTimeout(() => {
                          linkRowsEndRef.current?.scrollIntoView({
                            behavior: 'smooth'
                          });
                        }, 1);
                      }}
                      style={{ paddingLeft: 0, marginTop: 4 }}
                    >
                      Add Link
                    </Button>
                  </>
                );
              }}
            </Form.List>
          </div>
          <div className="actions">
            <Button
              type="text"
              size="large"
              style={{
                marginRight: 12,
                border: 'none',
                height: 48,
                padding: '12px 19px'
              }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="submit_form"
              htmlType="submit"
              style={{ width: 152, height: 48, padding: '8px 15px' }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
});
