import React, { useLayoutEffect, useRef, useState } from 'react';
import { nonNull } from '@helpers/non-null';
import './InlineDatePicker.less';
import './InlineRangePicker.less';
import { Moment } from 'moment';
import { DatePicker } from 'antd';

export interface InlineRangePickerProps {
  value?: [Moment, Moment] | null;
  onChange?: (value: [Moment, Moment] | null) => void;
  disabledDate?: (date: Moment) => boolean;
}

export default function InlineRangePicker(props: InlineRangePickerProps) {
  const { onChange, disabledDate } = props;
  const container = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(props.value);
  if (
    props.value !== undefined &&
    props.value?.valueOf() !== value?.valueOf()
  ) {
    setValue(props.value);
  }

  const [mounted, setMounted] = useState(false);
  useLayoutEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <div className="inline-date-picker inline-range-picker" ref={container}>
      <DatePicker.RangePicker
        open={mounted}
        onOpenChange={undefined}
        getPopupContainer={() => nonNull(container.current)}
        value={value}
        disabledDate={disabledDate}
        allowEmpty={[true, true]}
        onChange={(v) => {
          if (!v || (!v[0] && !v[1])) {
            setValue(null);
            if (onChange) onChange(null);
            return;
          }
          const from = nonNull(v[0] ?? v[1]).startOf('day');
          const to = nonNull(v[1] ?? v[0]).endOf('day');
          setValue([from, to]);
          if (onChange) onChange([from, to]);
        }}
      />
    </div>
  );
}
