import React from 'react';

function EntityAccessHasBeenChanged({
  entity
}: {
  entity: 'organization' | 'workspace' | 'campaign' | 'folder';
}) {
  return (
    <div className="toast_container">
      <div style={{ width: '100%' }}>
        <div
          className="toast_container__body"
          style={{ width: '100%', textAlign: 'center' }}
        >
          <div className="description">
            Your {entity} access has been changed.
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntityAccessHasBeenChanged;
