export const CAMPAIGN_DETAILS_FIELDS = [
  { key: 'targetAudience', label: 'Target audience', isDefault: true },
  { key: 'objectives', label: 'Campaign objectives', isDefault: true },
  {
    key: 'description',
    label: 'Campaign description',
    isDefault: true
  },
  { key: 'finalDeliverables', label: 'Final deliverables' },
  { key: 'agencies', label: 'Agency contacts' },
  { key: 'kpis', label: 'KPIs' },
  { key: 'budget', label: 'Budget' },
  { key: 'links', label: 'Links' },
  { key: 'otherNotes', label: 'Other notes' }
];
