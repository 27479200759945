import React, { useState, useEffect, useMemo } from 'react';
import { AWDefaultSettingsDto } from '@api/Api';
import { message } from 'antd';

import ApprovalSettings from '@components/Modals/ApprovalModal/components/ApprovalSettings';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@api/client';
import { getAWInitialSettings } from '@components/Modals/ApprovalModal/utils';

const awDefaultSettingsStatic = getAWInitialSettings({});
function awDefaultSettingsFactory(settings?: AWDefaultSettingsDto) {
  return {
    lockDecisions:
      settings?.lockDecisions ?? awDefaultSettingsStatic.lockDecisions,
    disableNonApprovedDownloads:
      settings?.disableNonApprovedDownloads ??
      awDefaultSettingsStatic.disableNonApprovedDownloads,
    newVersionBehavior:
      settings?.newVersionBehavior ??
      awDefaultSettingsStatic.newVersionBehavior,
    password: settings?.password ?? awDefaultSettingsStatic.password,
    customMessage:
      settings?.customMessage ?? awDefaultSettingsStatic.customMessage
  };
}

export default function AwDefaultSettings({
  campaignId,
  canChangeAWDefaultSettings
}: {
  campaignId: string;
  canChangeAWDefaultSettings: boolean;
}) {
  const awDefaultSettingsQuery = useQuery({
    queryKey: ['campaign', 'aw-default-settings', campaignId],
    queryFn: async ({ signal }) => {
      const { data } =
        await apiClient.campaign.campaignControllerGetAwDefaultSettings(
          {
            campaignId
          },
          { signal }
        );
      return data.settings;
    }
  });
  const awDefaultSettings = useMemo(
    () => awDefaultSettingsFactory(awDefaultSettingsQuery.data),
    [awDefaultSettingsQuery.data]
  );
  const [awDefaultSettingsState, setAWDefaultSettingsState] =
    useState(awDefaultSettings);
  useEffect(() => {
    if (awDefaultSettingsQuery.isLoading) return;
    setAWDefaultSettingsState(awDefaultSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awDefaultSettingsQuery.isLoading]);
  const awDefaultSettingsUpdate = useMemo(() => {
    const update: Partial<AWDefaultSettingsDto> = {};
    const keys = Object.keys(
      awDefaultSettings
    ) as (keyof AWDefaultSettingsDto)[];
    for (const key of keys) {
      if (awDefaultSettings[key] !== awDefaultSettingsState[key]) {
        update[key] =
          awDefaultSettingsState[key] === awDefaultSettingsStatic[key]
            ? null
            : (awDefaultSettingsState[key] as any);
      }
    }
    return update;
  }, [awDefaultSettings, awDefaultSettingsState]);
  const queryClient = useQueryClient();
  const awDefaultSettingsMutation = useMutation({
    mutationKey: ['set-aw-default-settings'],
    mutationFn: async (update: Partial<AWDefaultSettingsDto>) => {
      await apiClient.campaign.campaignControllerUpdateAwDefaultSettings({
        ...update,
        campaignId
      });
    },
    onError: () => {
      message.error({
        content: 'Something went wrong',
        className: 'message-dark-modal'
      });
    },
    onSettled: async () => {
      await queryClient.refetchQueries([
        'campaign',
        'aw-default-settings',
        campaignId
      ]);
      setTimeout(async () => {
        if (
          !queryClient.isMutating({ mutationKey: ['set-aw-default-settings'] })
        ) {
          const settings = queryClient.getQueryData([
            'campaign',
            'aw-default-settings',
            campaignId
          ]) as AWDefaultSettingsDto | undefined;
          setAWDefaultSettingsState(awDefaultSettingsFactory(settings));
        }
      });
    }
  });
  const [awDefaultSettingsBlurred, setAWDefaultSettingsBlurred] =
    useState(false);
  useEffect(() => {
    if (!awDefaultSettingsBlurred) return;
    if (!Object.keys(awDefaultSettingsUpdate).length) return;
    awDefaultSettingsMutation.mutate(awDefaultSettingsUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awDefaultSettingsBlurred, awDefaultSettingsUpdate]);
  return (
    <div className="campaign_settigs_tab">
      <div className="campaign_settigs_tab__head">
        <div className="title">Approval defaults</div>
        <div className="description">
          Set default settings for newly created approvals in this campaign
        </div>
        <ApprovalSettings
          disabled={
            !canChangeAWDefaultSettings || awDefaultSettingsQuery.isLoading
          }
          settings={awDefaultSettingsState}
          onUpdate={(update) => {
            setAWDefaultSettingsState((prev) => ({ ...prev, ...update }));
            setAWDefaultSettingsBlurred(false);
          }}
          onBlur={() => setAWDefaultSettingsBlurred(true)}
        />
      </div>
    </div>
  );
}
