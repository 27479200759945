import classNames from 'classnames';
import React, {
  useCallback,
  useMemo,
  useState,
  memo,
  createContext,
  ReactNode,
  useContext,
  useRef,
  useLayoutEffect
} from 'react';
import { Button, ConfigProvider, Table, Tooltip } from 'antd';
import '@components/Tasks/TaskModal/TaskModal.less';
import './Table.less';
import NameColumn from '@components/Tables/TasksTable/NameColumn';
import StatusColumn from '@components/Tables/TasksTable/StatusColumn';
import DueDateColumn from '@components/Tables/TasksTable/DueDateColumn';
import AssigneesColumn from '@components/Tables/TasksTable/AssigneesColumn';
import PriorityColumn from '@components/Tables/TasksTable/PriorityColumn';
import ActionsColumn from '@components/Tables/TasksTable/ActionsColumn';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { CampaignItemDto, TaskFieldItemDto } from '@api/Api';
import CustomTextColumn from '@components/Tables/TasksTable/CustomTextColumn';
import HyperlinkColumn from '@components/Tables/TasksTable/HyperlinkColumn';
import TagColumn from '@components/Tables/TasksTable/TagColumn';
import CustomColumnTitle from '@components/Tables/TasksTable/CustomColumnTitle';
import ActionsColumnTitle from '@components/Tables/TasksTable/ActionsColumnTitle';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { deleteTaskCustomField } from '@redux/actions/tasks';
import DeleteCustomFieldModal from '@components/Tables/TasksTable/DeleteCustomFieldModal';
import { useTypedSelector } from '@hooks';
import { campaignStateSelector, taskSelector } from '@redux/selectors/tasks';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import { openNewTaskModal } from '@redux/reducers/tasks';
import TaskListSkeleton from '@components/Tasks/TaskListSkeleton';
import { toggleCampaignFolderCreationModal } from '@redux/actions/modalAction';
import { TaskListType } from '@redux/types/tasks';
import { useCurrentWorkspace } from '@hooks/workspace';

interface DragHandleContextState {
  width: number;
  topOffset: number;
  leftOffset: number;
  top: number;
  left: number;
}

interface DragHandleContext {
  getState: () => DragHandleContextState;
  subscribe(callback: () => void): () => void;
  setState(state: Partial<DragHandleContextState>): void;
}

const DragHandleContex = createContext<DragHandleContext>({
  getState: () => ({
    width: 0,
    topOffset: 0,
    leftOffset: 0,
    top: 0,
    left: 0
  }),
  subscribe: () => {
    return () => {
      /** */
    };
  },
  setState: () => {
    /** */
  }
});

interface TaskBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  columnsCount: number;
  skeletonsCount: number;
}

function TaskBodyRow(props: TaskBodyRowProps) {
  const { columnsCount, skeletonsCount, ...otherProps } = props;
  return (
    <>
      {skeletonsCount > 0 && (
        <tr>
          <th colSpan={columnsCount} style={{ padding: 0 }}>
            <TaskListSkeleton count={skeletonsCount} />
          </th>
        </tr>
      )}
      <tr {...otherProps} />
    </>
  );
}

const components = {
  body: {
    row: TaskBodyRow
  }
};

interface TaskTableProps {
  isExistCampaign?: boolean;
  hideTableHeader?: boolean;
  organizationId: string;
  workspaceId: string;
  campaign?: CampaignItemDto | null;
  taskIds?: string[];
  emptyTableText?: string;
  hidePlaceholder?: boolean;
  isDeletedTask?: boolean;
  board?: { id: string; name: string };
  customFields?: TaskFieldItemDto[];
  topSkeletonsCount?: number;
  draggable?: boolean;
  draggingTaskId?: string | null;
  overTaskId?: string | null;
  overBoardId?: string | null;
  dropTaskAfter?: boolean;
  page?: TaskListType;
  onDragStart?: (boardId: string, taskId: string) => void;
  onDragEnter?: (boardId: string, taskId: string | null) => void;
  onDragEnd?: () => void;
}

const emptyArr = [] as never;
const emptyFunc = () => {
  /** */
};

const TasksTable = memo(function TasksTable(props: TaskTableProps) {
  const { organizationId, workspaceId } = props;
  const [currentWorkspace] = useCurrentWorkspace(true);
  const isExistCampaign = props.isExistCampaign ?? true;
  const hideTableHeader = props.hideTableHeader ?? false;
  const campaign = props.campaign ?? null;
  const taskIds = props.taskIds ?? emptyArr;
  const emptyTableText = isExistCampaign
    ? props.emptyTableText
      ? props.emptyTableText
      : 'Nothing to do? We don’t believe you.'
    : 'Start a campaign to create your first task';
  const hidePlaceholder = props.hidePlaceholder ?? false;
  const isDeletedTask = props.isDeletedTask ?? false;
  const board = props.board ?? undefined;
  const customFields = props.customFields ?? emptyArr;
  const topSkeletonsCount = props.topSkeletonsCount ?? 0;
  const draggable = props.draggable ?? false;
  const draggingTaskId = props.draggingTaskId ?? null;
  const overTaskId = props.overTaskId ?? null;
  const overBoardId = props.overBoardId ?? null;
  const dropTaskAfter = props.dropTaskAfter ?? null;
  const onDragStart = props.onDragStart ?? emptyFunc;
  const onDragEnter = props.onDragEnter ?? emptyFunc;
  const onDragEnd = props.onDragEnd ?? emptyFunc;

  const dispatch = useTypedDispatch();
  const dataSource = useMemo(() => taskIds.map((id) => ({ id })), [taskIds]);

  const handleCreateNewTask = useCallback(() => {
    dispatch(
      openNewTaskModal({
        organizationId,
        workspaceId,
        campaign,
        board
      })
    );
  }, [dispatch, organizationId, workspaceId, campaign, board]);

  const campaignId = campaign?.id ?? null;
  const canCreateCustomFields = !!campaign?.permissions.createTaskFields;
  const canDeleteCustomFields = !!campaign?.permissions.deleteTaskFields;
  const canRenameCustomFields = !!campaign?.permissions.renameTaskFields;

  const [customFieldDelete, setCustomFieldDelete] =
    useState<TaskFieldItemDto | null>(null);

  const onCustomFieldDelete = useCallback((field: TaskFieldItemDto) => {
    setCustomFieldDelete(field);
  }, []);

  const onCustomFieldDeleteCancel = useCallback(() => {
    setCustomFieldDelete(null);
  }, []);

  const onCustomFieldDeleteConfirm = useCallback(() => {
    if (!customFieldDelete) return;
    setCustomFieldDelete(null);
    dispatch(
      deleteTaskCustomField({
        campaignId: customFieldDelete.campaignId,
        taskFieldId: customFieldDelete.id
      })
    );
  }, [dispatch, customFieldDelete]);

  const columns: ColumnsType<{ id: string }> = useMemo(
    () => [
      {
        title: 'Task',
        key: 'name',
        render: (_, record) => <NameColumn taskId={record.id} />
      },
      {
        title: 'Status',
        key: 'status',
        width: 156,
        render: (_, record) => <StatusColumn taskId={record.id} />
      },
      {
        title: 'Due date',
        key: 'dueDate',
        width: 180,
        render: (_, record) => <DueDateColumn taskId={record.id} />
      },
      {
        title: 'Assignee',
        key: 'assignee',
        width: 200,
        render: (_, record) => <AssigneesColumn taskId={record.id} />
      },
      {
        title: 'Priority',
        key: 'priority',
        width: 140,
        render: (_, record) => <PriorityColumn taskId={record.id} />
      },
      ...customFields.map((field) => {
        if (field.type === 'text') {
          return {
            title: (
              <CustomColumnTitle
                field={field}
                canRename={canRenameCustomFields}
                canDelete={canDeleteCustomFields}
                onDelete={onCustomFieldDelete}
              />
            ),
            dataIndex: 'text',
            width: 142,
            render: (_, record) => (
              <CustomTextColumn taskId={record.id} field={field} />
            )
          } as ColumnType<{ id: string }>;
        }
        if (field.type === 'url') {
          return {
            title: (
              <CustomColumnTitle
                field={field}
                canRename={canRenameCustomFields}
                canDelete={canDeleteCustomFields}
                onDelete={onCustomFieldDelete}
              />
            ),
            dataIndex: 'hyperlink',
            width: 180,
            render: (_, record) => (
              <HyperlinkColumn taskId={record.id} field={field} />
            )
          } as ColumnType<{ id: string }>;
        }
        return {
          title: (
            <CustomColumnTitle
              field={field}
              canRename={canRenameCustomFields}
              canDelete={canDeleteCustomFields}
              onDelete={onCustomFieldDelete}
            />
          ),
          dataIndex: 'tag',
          width: 140,
          render: (_, record) => <TagColumn taskId={record.id} field={field} />
        } as ColumnType<{ id: string }>;
      }),
      {
        title: (
          <ActionsColumnTitle
            campaignId={campaignId}
            canCreateCustomFields={canCreateCustomFields}
            fields={customFields}
          />
        ),
        dataIndex: 'action',
        width: 24,
        render: (_, record) => <ActionsColumn taskId={record.id} />
      }
    ],
    [
      campaignId,
      customFields,
      canCreateCustomFields,
      canDeleteCustomFields,
      canRenameCustomFields,
      onCustomFieldDelete
    ]
  );

  const configProviderProps = useMemo<ConfigProviderProps>(
    () => ({
      renderEmpty: () => (
        <div
          className={classNames({
            'empty-table-content': true,
            'empty-table-content-hide': overBoardId === board?.id
          })}
          onDragEnter={() => {
            if (!board || !draggable) return;
            onDragEnter(board.id, null);
          }}
        >
          <div className="empty-table-content-inner">
            <span>{emptyTableText}</span>
            {board?.id !== 'archived' && !isDeletedTask && isExistCampaign && (
              <Button
                size="large"
                disabled={!isExistCampaign}
                type="primary"
                onClick={handleCreateNewTask}
                id="START-CREATE-TASK-BTN"
                style={{ position: 'relative', zIndex: 2 }}
              >
                Create task
              </Button>
            )}
            {!isExistCampaign && board?.id !== 'archived' && !isDeletedTask && (
              <Tooltip
                overlayClassName="long-text-hint"
                overlayInnerStyle={{ maxWidth: 285, textAlign: 'left' }}
                title={
                  !currentWorkspace.permissions.createCampaigns &&
                  'Your current role in StreamWork doesn’t allow you to create a campaign.'
                }
                trigger="hover"
                placement="bottom"
              >
                <Button
                  type="primary"
                  disabled={!currentWorkspace.permissions.createCampaigns}
                  onClick={() =>
                    dispatch(
                      toggleCampaignFolderCreationModal({
                        visible: true,
                        entity: 'campaign'
                      })
                    )
                  }
                >
                  Start campaign
                </Button>
              </Tooltip>
            )}
          </div>
          <div
            className={classNames({
              'drop-pouring-container': true,
              'empty-table-pouring': true,
              'empty-table-pouring--visible': overBoardId === board?.id
            })}
          >
            Drop task here
          </div>
        </div>
      )
    }),
    [
      currentWorkspace.permissions.createCampaigns,
      board,
      draggable,
      emptyTableText,
      handleCreateNewTask,
      isDeletedTask,
      isExistCampaign,
      onDragEnter,
      overBoardId,
      dispatch
    ]
  );

  const dragHandle = useContext(DragHandleContex);

  const columnsCount = columns.length;
  const onRow = useCallback<
    (
      data: { id: string },
      index?: number
    ) => React.HTMLAttributes<HTMLTableRowElement>
  >(
    (record, index) => ({
      columnsCount,
      skeletonsCount: index === 0 ? topSkeletonsCount : 0,
      draggable: true,
      className: classNames({
        'task-row': true,
        'task-row--dragging': draggingTaskId === record.id,
        'task-row--over-bottom':
          dropTaskAfter && draggingTaskId && overTaskId === record.id,
        'task-row--over-top':
          !dropTaskAfter && draggingTaskId && overTaskId === record.id
      }),
      onDragStart: (e) => {
        e.dataTransfer.items.clear();
        e.stopPropagation();
        if (!board || !draggable) {
          e.preventDefault();
          return;
        }
        const rect = (e.target as HTMLTableRowElement).getBoundingClientRect();
        dragHandle.setState({
          width: rect.width,
          topOffset: rect.y - e.pageY,
          leftOffset: rect.x - e.pageX,
          top: e.pageY,
          left: e.pageX
        });
        e.dataTransfer.setDragImage(new Image(), 0, 0);
        onDragStart(board.id, record.id);
      },
      onDrag: (e) => {
        const skip = e.pageX === 0 && e.pageY === 0;
        if (!skip) {
          dragHandle.setState({
            top: e.pageY,
            left: e.pageX
          });
        }
      },
      onDragEnter: () => {
        if (!board || !draggable) return;
        onDragEnter(board.id, record.id);
      },
      onDragEnd
    }),
    [
      board,
      topSkeletonsCount,
      columnsCount,
      dragHandle,
      draggingTaskId,
      overTaskId,
      draggable,
      onDragStart,
      onDragEnter,
      onDragEnd
    ]
  );

  return (
    <div
      className={classNames({
        'task-table': true,
        'deleted-task-table': isDeletedTask,
        'task-table--hide-placeholder': hidePlaceholder
      })}
    >
      <ConfigProvider {...configProviderProps}>
        <Table
          showHeader={!hideTableHeader}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          components={components}
          rowKey="id"
          onRow={onRow}
        />
      </ConfigProvider>
      <DeleteCustomFieldModal
        open={!!customFieldDelete}
        onCancel={onCustomFieldDeleteCancel}
        onConfirm={onCustomFieldDeleteConfirm}
      />
    </div>
  );
});

export const TaskDragHandleContextProvider = memo(
  function TaskDragHandleContextProvider(props: {
    children?: ReactNode | undefined;
  }) {
    const state = useRef<DragHandleContextState>({
      width: 0,
      topOffset: 0,
      leftOffset: 0,
      top: 0,
      left: 0
    });
    const subscriptions = useRef<(() => void)[]>([]);
    const ctx = useRef<DragHandleContext>({
      getState: () => state.current,
      setState: (newState) => {
        Object.assign(state.current, newState);
        subscriptions.current.forEach((fn) => fn());
      },
      subscribe: (fn: () => void) => {
        if (!subscriptions.current.includes(fn)) {
          subscriptions.current.push(fn);
        }
        return () => {
          subscriptions.current = subscriptions.current.filter((x) => x !== fn);
        };
      }
    });
    return (
      <DragHandleContex.Provider value={ctx.current}>
        {props.children}
      </DragHandleContex.Provider>
    );
  }
);

interface DragHandleProps {
  taskId: string;
  campaign: CampaignItemDto;
}

export const TaskDragHandle = memo(function TaskDragHandle(
  props: DragHandleProps
) {
  const ctx = useContext(DragHandleContex);
  const ref = useRef<HTMLDivElement>(null);
  const task = useTypedSelector((state) =>
    taskSelector(state, { taskId: props.taskId })
  );
  const customFields = useTypedSelector(
    (state) =>
      campaignStateSelector(state, { campaignId: props.campaign.id })
        .customFields.list
  );
  useLayoutEffect(() => {
    const onStateChange = () => {
      const state = ctx.getState();
      if (ref.current) {
        const handle = ref.current;
        handle.style.width = `${state.width}px`;
        handle.style.top = `${state.topOffset}px`;
        handle.style.left = `${state.leftOffset}px`;
        handle.style.transform = `translate(${state.left}px, ${state.top}px)`;
      }
    };
    const unsubscribe = ctx.subscribe(onStateChange);
    onStateChange();
    return unsubscribe;
  }, [ctx]);
  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        zIndex: 1000,
        pointerEvents: 'none'
      }}
    >
      <TasksTable
        organizationId={task.organizationId}
        workspaceId={task.workspaceId}
        hideTableHeader={true}
        draggable={false}
        isExistCampaign={true}
        campaign={props.campaign}
        taskIds={[props.taskId]}
        board={task.board}
        customFields={customFields}
      />
    </div>
  );
});

export default TasksTable;
