import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Collapse, Tag, Tooltip } from 'antd';
import './TaskGroup.less';
import '@pages/Campaigns/CampaignItem/CampaignItem.less';
import { TaskListType, TasksGroupBy } from '@redux/types/tasks';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  dueSoonTasksGroupCountSelector,
  overdueTasksGroupCountSelector,
  tasksGroupSelector,
  tasksGroupsSelector,
  workspaceStateSelector
} from '@redux/selectors/tasks';
import { useTypedSelector } from '@hooks';
import { useFetch } from '@hooks/useFetch';
import { fetchTaskList, fetchTasksCount } from '@redux/actions/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import TasksListTable from '@pages/Tasks/TasksListTable';
import { hashedColor } from '@helpers/hashedColor';
import { loadMoreTasks, setGroupOpened } from '@redux/reducers/tasks';
import { TasksTable } from '@components/Tables';
import TaskBoardSkeleton from '@components/Tasks/TaskBoardSkeleton';
import TaskCampaignGroupSkeleton from '@components/Tasks/TaskCampaignGroupSkeleton';
import { ReactComponent as WarningSvg } from '@assets/icons/warning.svg';
import { ReactComponent as DueDateSvg } from '@assets/icons/due-date.svg';

import {
  dueSoonTasksCountCopy,
  overdueTasksCountCopy
} from '@helpers/tasksDueDateCopy';
import { useCurrentTime } from '@hooks/useCurrentTime';

interface TaskGroupItemProps {
  page: TaskListType;
  groupId: string;
  groupBy: TasksGroupBy;
  disableFetch: boolean;
}

const TaskGroupItem = memo(function TaskGroupItem(props: TaskGroupItemProps) {
  const { page, groupId, groupBy } = props;
  const dispatch = useTypedDispatch();
  const now = useCurrentTime();
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;
  const tasksCount = useTypedSelector(
    (state) =>
      tasksGroupSelector(state, { workspaceId, list: page, groupBy, groupId })
        .count
  );
  const dueSoonTaskCount = useTypedSelector((state) =>
    dueSoonTasksGroupCountSelector(state, {
      workspaceId,
      list: page,
      groupBy,
      groupId,
      now
    })
  );
  const overdueTaskCount = useTypedSelector((state) =>
    overdueTasksGroupCountSelector(state, {
      workspaceId,
      list: page,
      groupBy,
      groupId,
      now
    })
  );
  const name = useTypedSelector(
    (state) =>
      tasksGroupSelector(state, { workspaceId, list: page, groupBy, groupId })
        .name
  );
  const open = useTypedSelector((state) =>
    tasksGroupsSelector(state, {
      workspaceId,
      list: page,
      groupBy
    }).openedIds.includes(groupId)
  );

  const [visible, setVisible] = useState(false);
  const visibleRef = useRef(visible);
  visibleRef.current = visible;
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const newVisible = !!entries[0]?.isIntersecting;
      if (newVisible !== visibleRef.current) {
        visibleRef.current = newVisible;
        setVisible(newVisible);
      }
    },
    []
  );
  const observer = useRef<IntersectionObserver>(
    new IntersectionObserver(handleIntersection)
  );
  const anchorRef = useRef<HTMLElement | null>();
  const setAnchorRef = useCallback((el: HTMLElement | null) => {
    if (anchorRef.current) {
      observer.current.unobserve(anchorRef.current);
    }
    anchorRef.current = el;
    if (anchorRef.current) {
      observer.current.observe(anchorRef.current);
    }
  }, []);
  useLayoutEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      const records = observer.current.takeRecords();
      if (records.length) handleIntersection(records);
    });
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  });

  const workspaceIdRef = useRef(workspaceId);
  workspaceIdRef.current = workspaceId;

  const pageRef = useRef(page);
  pageRef.current = page;

  const groupByRef = useRef(groupBy);
  groupByRef.current = groupBy;

  const groupIdRef = useRef(groupId);
  groupIdRef.current = groupId;

  const onOpenChange = useCallback(
    (value: boolean) => {
      dispatch(
        setGroupOpened({
          value,
          workspaceId: workspaceIdRef.current,
          list: pageRef.current,
          groupBy: groupByRef.current,
          groupId: groupIdRef.current
        })
      );
    },
    [dispatch]
  );

  const disableFetch = useRef<boolean>(undefined as never);
  disableFetch.current = props.disableFetch || !open || !visible;

  const disableCountFetch = props.disableFetch || !visible;

  useFetch({
    key: `task-group-count-${workspaceId}-${page}-${groupBy}-${groupId}`,
    disabled: disableCountFetch,
    selector: (state) =>
      tasksGroupSelector(state, {
        workspaceId,
        list: page,
        groupBy,
        groupId
      }).countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchTasksCount({
          workspaceId,
          group: {
            by: groupBy,
            id: groupId
          },
          list: page,
          fetchType
        })
      )
  });
  const loading = useFetch({
    key: `task-group-tasks-${workspaceId}-${page}-${groupBy}-${groupId}`,
    disabled: disableFetch.current,
    selector: (state) =>
      tasksGroupSelector(state, { workspaceId, list: page, groupBy, groupId })
        .fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchTaskList({
          workspaceId,
          group: {
            by: groupBy,
            id: groupId
          },
          list: page,
          fetchType
        })
      );
    }
  });

  const onLoadMore = useCallback(() => {
    dispatch(
      loadMoreTasks({
        workspaceId: workspaceIdRef.current,
        group: {
          by: groupByRef.current,
          id: groupIdRef.current
        },
        list: pageRef.current
      })
    );
  }, [dispatch]);

  const panelHeader =
    groupBy === 'campaign' ? (
      <div className="campaign-panel-container">
        <span>{name}</span>
        <div className="tasks-count" style={{ marginLeft: 8 }}>
          {tasksCount}
        </div>
        <GroupItemTags
          overdueTaskCount={overdueTaskCount}
          dueSoonTaskCount={dueSoonTaskCount}
        />
      </div>
    ) : (
      <div className="panel-header-container">
        <div
          className="panel-header-colored"
          style={{
            backgroundColor:
              groupId === 'no_assigned'
                ? '#FDDFA6'
                : groupId === 'archived'
                ? '#DBDBDB'
                : hashedColor(groupId, 'taskGroup')
          }}
        />
        <div className="panel-header-text">
          <span style={{ fontWeight: 700 }}>{name}</span>
          <div className="count">{tasksCount}</div>
        </div>
        <GroupItemTags
          overdueTaskCount={overdueTaskCount}
          dueSoonTaskCount={dueSoonTaskCount}
        />
      </div>
    );

  return (
    <div ref={setAnchorRef}>
      <Collapse
        ghost={groupBy === 'campaign'}
        expandIconPosition={groupBy === 'board' ? 'end' : undefined}
        activeKey={open ? ['panel'] : []}
        onChange={(openedPanels) => {
          if (Array.isArray(openedPanels))
            onOpenChange(openedPanels.includes('panel'));
          else onOpenChange(openedPanels === 'panel');
        }}
      >
        <Panel key="panel" header={panelHeader}>
          <TasksListTable
            groupId={groupId}
            loading={loading}
            page={page}
            onLoadMore={onLoadMore}
          />
        </Panel>
      </Collapse>
    </div>
  );
});

const { Panel } = Collapse;

interface TaskGroupProps {
  page: TaskListType;
  groupBy: TasksGroupBy;
}

export default memo(function TaskGroup(props: TaskGroupProps) {
  const { page, groupBy } = props;
  const [currentWorkspace] = useCurrentWorkspace(true);
  const { organizationId } = currentWorkspace;
  const workspaceId = currentWorkspace.id;
  const groupsCount = useTypedSelector(
    (state) =>
      tasksGroupsSelector(state, { workspaceId, list: page, groupBy }).count
  );
  const groupIds = useTypedSelector(
    (state) =>
      tasksGroupsSelector(state, { workspaceId, list: page, groupBy }).ids
  );
  const initiallyLoaded = useTypedSelector(
    (state) =>
      tasksGroupsSelector(state, { workspaceId, list: page, groupBy }).fetch
        .initiallyLoaded
  );
  const disableFetch = useTypedSelector(
    (state) =>
      !!tasksGroupsSelector(state, { workspaceId, list: page, groupBy }).fetch
        .newRequest
  );
  const anyCampaignExists = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, {
        workspaceId
      }).listsShared.campaigns.list.filter((it) => !it.demo).length > 0
  );
  const skeletonsCount = groupsCount - groupIds.length;
  return (
    <>
      {groupIds.map((groupId) => (
        <TaskGroupItem
          key={groupId}
          page={page}
          groupBy={groupBy}
          groupId={groupId}
          disableFetch={disableFetch}
        />
      ))}
      {initiallyLoaded && !groupIds.length && (
        <TasksTable
          organizationId={organizationId}
          workspaceId={workspaceId}
          isExistCampaign={anyCampaignExists}
          isDeletedTask={page === 'deleted'}
          taskIds={[]}
          emptyTableText={
            page === 'deleted' ? "You don't have deleted tasks yet" : undefined
          }
        />
      )}
      {groupBy === 'board' && <TaskBoardSkeleton count={skeletonsCount} />}
      {groupBy === 'campaign' && (
        <TaskCampaignGroupSkeleton count={skeletonsCount} />
      )}
    </>
  );
});

type GroupItemTagsProps = {
  overdueTaskCount: number;
  dueSoonTaskCount: number;
};

function GroupItemTags({
  overdueTaskCount,
  dueSoonTaskCount
}: GroupItemTagsProps) {
  return (
    <>
      {dueSoonTaskCount > 0 && (
        <div className="panel-header-due-soon">
          <Tooltip
            title={dueSoonTasksCountCopy(dueSoonTaskCount)}
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
            placement="bottom"
          >
            <Tag color="#F6E8DB">
              <DueDateSvg />
              <span>{dueSoonTaskCount}</span>
            </Tag>
          </Tooltip>
        </div>
      )}
      {overdueTaskCount > 0 && (
        <div className="panel-header-overdue">
          <Tooltip
            title={overdueTasksCountCopy(overdueTaskCount)}
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay"
            placement="bottom"
          >
            <Tag color="#F5C8BE">
              <WarningSvg />
              <span>{overdueTaskCount}</span>
            </Tag>
          </Tooltip>
        </div>
      )}
    </>
  );
}
