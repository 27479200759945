import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Modal, Button, Input, Tabs, Empty, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { WorkspaceMembersTable } from '@components/Tables';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as EmptyGroupsSvg } from '@assets/icons/empty-groups.svg';
import { ReactComponent as EnterpriseSvg } from '@assets/icons/billing-enterprise-icon.svg';
import {
  toggleBillingModal,
  toggleWorkspaceInvitationModal,
  toggleWorkspaceMemberGroupCreateModal,
  toggleWorkspaceMembersModal
} from '@redux/actions/modalAction';
import { useTypedSelector } from '@hooks';
import {
  changeWorkspaceMembersRole,
  deleteWorkspaceMembers,
  workspaceCountMembers,
  workspaceListMembers,
  resendInviteWorkspaceMembers,
  workspaceListMemberGroups,
  workspaceCountMemberGroups
} from '@api/Workspace';
import {
  WorkspaceMemberDto,
  ResendWorkspaceInvitationMemberDto,
  WorkspaceMemberGroupItemDto
} from '@api/Api';
import DeleteMemberModal from '@components/Modals/DeleteMemberModal';
import { WorkspaceRole } from '@helpers/userRoles';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import { useOrganization } from '@components/OrganizationBoundary';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { useDebounced } from '@helpers/useDebounced';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { nonNull } from '@helpers/non-null';
import classNames from 'classnames';
import { apiClient } from '@api/client';
import DeleteMemberGroupModal from '@components/Modals/DeleteMemberGroupModal';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import Icon from '@ant-design/icons';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import WorkspaceMemberGroupItem from './WorkspaceMemberGroupItem';

function MembersModal() {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization(true);
  const needUpgradeUserGroups =
    !currentOrganization.entity?.features.userGroups;
  const [membersToManage, setMembersToManage] = useState<WorkspaceMemberDto[]>(
    []
  );
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [groupToDelete, setGroupToDelete] =
    useState<WorkspaceMemberGroupItemDto | null>(null);
  const [activeTab, setActiveTab] = useState<string>('members');

  const [membersSearchQuery, setMembersSearchQuery] = useState<string>('');
  const debouncedMembersSearchQuery = useDebounced(membersSearchQuery, 250);
  const [membersSort, setMembersSort] = useState<
    | 'createdAt:ASC'
    | 'createdAt:DESC'
    | 'role:ASC'
    | 'role:DESC'
    | 'isActive:ASC'
    | 'isActive:DESC'
  >('createdAt:DESC');
  const [groupSearchQuery, setGroupSearchQuery] = useState<string>('');
  const debouncedGroupSearchQuery = useDebounced(groupSearchQuery, 250);

  const workspaceMembersModal = useTypedSelector(
    ({ modal }) => modal.workspaceMembersModal
  );

  useEffect(() => {
    if (!workspaceMembersModal?.visible) return;
    return () => {
      setMembersSearchQuery('');
      setGroupSearchQuery('');
      setMembersSort('createdAt:DESC');
      setMembersToManage([]);
      setShowDeleteMemberModal(false);
      setActiveTab('members');
    };
  }, [workspaceMembersModal?.visible]);

  const membersQuery = useInfiniteQuery({
    queryKey: [
      'workspace',
      'members',
      'list',
      workspaceMembersModal?.workspace?.id,
      debouncedMembersSearchQuery,
      membersSort
    ],
    keepPreviousData: true,
    enabled: !!workspaceMembersModal?.visible && activeTab === 'members',
    queryFn: async ({ pageParam, signal }) => {
      const { data } = await workspaceListMembers(
        {
          workspaceId: workspaceMembersModal?.workspace?.id || '',
          searchQuery: debouncedMembersSearchQuery,
          after: pageParam,
          orderBy: [membersSort]
        },
        { signal }
      );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersCountQuery = useQuery({
    queryKey: [
      'workspace',
      'members',
      'count',
      workspaceMembersModal?.workspace?.id,
      debouncedMembersSearchQuery
    ],
    keepPreviousData: true,
    enabled: !!workspaceMembersModal?.visible,
    queryFn: async ({ signal }) => {
      const { data } = await workspaceCountMembers(
        {
          workspaceId: nonNull(workspaceMembersModal?.workspace?.id),
          searchQuery: debouncedMembersSearchQuery
        },
        { signal }
      );
      return data.count;
    }
  });
  const memberGroupsQuery = useInfiniteQuery({
    queryKey: [
      'workspace',
      'member-groups',
      'list',
      workspaceMembersModal?.workspace?.id,
      debouncedGroupSearchQuery,
      membersSort
    ],
    keepPreviousData: true,
    enabled: !!workspaceMembersModal?.visible && activeTab === 'groups',
    queryFn: async ({ pageParam, signal }) => {
      const { data } = await workspaceListMemberGroups(
        {
          workspaceId: workspaceMembersModal?.workspace?.id || '',
          searchQuery: debouncedGroupSearchQuery,
          after: pageParam
        },
        { signal }
      );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const memberGroupsCountQuery = useQuery({
    queryKey: [
      'workspace',
      'member-groups',
      'count',
      workspaceMembersModal?.workspace?.id,
      debouncedGroupSearchQuery
    ],
    keepPreviousData: true,
    enabled: !!workspaceMembersModal?.visible,
    queryFn: async ({ signal }) => {
      const { data } = await workspaceCountMemberGroups(
        {
          workspaceId: nonNull(workspaceMembersModal?.workspace?.id),
          searchQuery: debouncedGroupSearchQuery
        },
        { signal }
      );
      return data.count;
    }
  });
  const membersList = useMemo(
    () =>
      membersQuery.data?.pages.flatMap((x) => x.edges.map((x) => x.node)) ?? [],
    [membersQuery.data]
  );
  const membersCount = membersCountQuery.data ?? 0;
  const memberGroupsList = useMemo(
    () =>
      memberGroupsQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => x.node)
      ) ?? [],
    [memberGroupsQuery.data]
  );
  const memberGroupsCount = memberGroupsCountQuery.data ?? 0;
  const memberGroupsLoading =
    memberGroupsQuery.isLoading ||
    memberGroupsQuery.isPreviousData ||
    memberGroupsQuery.isFetchingNextPage;
  const memberGroupsEmpty = !memberGroupsLoading && !memberGroupsList.length;

  const queryClient = useQueryClient();
  const deleteMemberMutation = useMutation({
    mutationKey: ['ws-member-delete'],
    mutationFn: async (memberIds: string[]) => {
      await deleteWorkspaceMembers({
        workspaceId: workspaceMembersModal?.workspace?.id || '',
        memberId: memberIds
      });
    },
    onMutate: async (memberIds) => {
      setMembersToManage([]);
      const queryKey = [
        'workspace',
        'members',
        'list',
        workspaceMembersModal?.workspace?.id
      ];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData<typeof membersQuery.data>(queryKey, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.filter(
                (edge) => !memberIds.includes(edge.node.id)
              )
            };
          })
        };
      });
    },
    onSettled: () => {
      setTimeout(() => {
        if (!queryClient.isMutating({ mutationKey: ['ws-member-delete'] })) {
          queryClient.invalidateQueries(['workspace', 'members']);
          queryClient.invalidateQueries(['workspace', 'member-groups']);
          queryClient.invalidateQueries(['campaign', 'members']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
          queryClient.invalidateQueries(['folder', 'members']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
        }
      });
    }
  });

  const changeRoleMutation = useMutation({
    mutationKey: ['ws-member-role'],
    mutationFn: async ({
      memberId,
      role
    }: {
      memberId: string;
      role: WorkspaceRole;
    }) => {
      await changeWorkspaceMembersRole({
        workspaceId: workspaceMembersModal?.workspace?.id || '',
        users: [{ memberId, role }]
      });
    },
    onMutate: async ({ memberId, role }) => {
      const queryKey = [
        'workspace',
        'members',
        'list',
        workspaceMembersModal?.workspace?.id
      ];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData<typeof membersQuery.data>(queryKey, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.map((edge) => {
                if (edge.node.id === memberId)
                  return {
                    ...edge,
                    node: {
                      ...edge.node,
                      workspaceRole: role
                    }
                  };
                return edge;
              })
            };
          })
        };
      });
    },
    onSettled: () => {
      setTimeout(() => {
        if (!queryClient.isMutating({ mutationKey: ['ws-member-role'] })) {
          queryClient.invalidateQueries(['workspace', 'members']);
        }
      });
    }
  });

  const onResendInvitationEmail = useCallback(
    async (member: ResendWorkspaceInvitationMemberDto) => {
      if (!workspaceMembersModal?.workspace?.id) return;
      await resendInviteWorkspaceMembers({
        workspaceId: workspaceMembersModal?.workspace?.id,
        users: [member]
      });
      toast(
        <ActionToast
          title="Invitation sent!"
          onUndo={undefined}
          description={undefined}
          closeToast={undefined}
        />,
        {
          hideProgressBar: true,
          style: { width: '345px', height: 80 },
          bodyClassName: 'toast_container--invitation'
        }
      );
    },
    [workspaceMembersModal?.workspace?.id]
  );

  const deleteMemberGroupMutation = useMutation({
    mutationKey: ['ws-member-group-delete'],
    mutationFn: async (id: string) => {
      await apiClient.workspace.workspaceControllerDeleteWorkspaceMemberGroup({
        workspaceId: workspaceMembersModal?.workspace?.id || '',
        groupId: id
      });
    },
    onMutate: async (id) => {
      setMembersToManage([]);
      const queryKey = [
        'workspace',
        'member-groups',
        'list',
        workspaceMembersModal?.workspace?.id
      ];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData<typeof membersQuery.data>(queryKey, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.filter((edge) => edge.node.id !== id)
            };
          })
        };
      });
    },
    onSuccess: () => {
      setShowDeleteGroupModal(false);
    },
    onSettled: () => {
      setTimeout(() => {
        if (
          !queryClient.isMutating({ mutationKey: ['ws-member-group-delete'] })
        ) {
          queryClient.invalidateQueries(['workspace', 'member-groups']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
        }
      });
    }
  });

  const handleSort = useCallback((value: string) => {
    switch (value) {
      case 'createdAt:ASC':
      case 'createdAt:DESC':
      case 'role:ASC':
      case 'role:DESC':
      case 'isActive:ASC':
      case 'isActive:DESC': {
        setMembersSort(value);
        break;
      }
      default: {
        setMembersSort('createdAt:DESC');
      }
    }
  }, []);

  const membersToRemove = useMemo(
    () => membersToManage.filter((x) => x.permissions.delete),
    [membersToManage]
  );
  const showFooter = membersToManage.length > 0 && activeTab === 'members';

  const tabBarExtraContent = useMemo(() => {
    if (activeTab === 'members') {
      return {
        right: (
          <div style={{ display: 'flex', gap: 24 }}>
            <Input
              placeholder="Search by name or email"
              prefix={<SearchSvg />}
              size="large"
              style={{ width: 296, borderRadius: 8 }}
              value={membersSearchQuery}
              onChange={(e) => setMembersSearchQuery(e.target.value)}
            />
            <Button
              type="primary"
              size="large"
              style={{
                width: 184,
                height: 48,
                padding: '8px 15px'
              }}
              disabled={
                currentOrganization.seatsCount.count >=
                (currentOrganization.entity?.limits.orgMembers ??
                  Number.POSITIVE_INFINITY)
              }
              onClick={() =>
                dispatch(
                  toggleWorkspaceInvitationModal({
                    visible: true,
                    workspace: workspaceMembersModal?.workspace
                  })
                )
              }
            >
              Invite members
            </Button>
          </div>
        )
      };
    }
    if (activeTab === 'groups') {
      return {
        right: (
          <div style={{ display: 'flex', gap: 24 }}>
            <Input
              placeholder="Search by name"
              prefix={<SearchSvg />}
              size="large"
              style={{ width: 296, borderRadius: 8 }}
              value={groupSearchQuery}
              onChange={(e) => setGroupSearchQuery(e.target.value)}
            />
            {!!workspaceMembersModal?.workspace?.permissions
              .manageMemberGroups && (
              <UpgradePlanTooltip
                text=" to a Team plan to access this feature."
                isCanVisible={needUpgradeUserGroups}
                placement="left"
              >
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: 184,
                    height: 48,
                    padding: '8px 15px'
                  }}
                  onClick={() => {
                    dispatch(
                      toggleWorkspaceMemberGroupCreateModal({
                        visible: true,
                        workspace: nonNull(workspaceMembersModal?.workspace)
                      })
                    );
                  }}
                  disabled={needUpgradeUserGroups}
                  icon={
                    needUpgradeUserGroups ? (
                      <Icon component={UpgradePlanSvg} />
                    ) : null
                  }
                >
                  Create group
                </Button>
              </UpgradePlanTooltip>
            )}
          </div>
        )
      };
    }
    return undefined;
  }, [
    activeTab,
    currentOrganization.seatsCount.count,
    currentOrganization.entity?.limits.orgMembers,
    dispatch,
    membersSearchQuery,
    setMembersSearchQuery,
    groupSearchQuery,
    setGroupSearchQuery,
    workspaceMembersModal?.workspace,
    needUpgradeUserGroups
  ]);

  return (
    <Modal
      open={workspaceMembersModal?.visible}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={1200}
      onCancel={() => dispatch(toggleWorkspaceMembersModal(null))}
      className="members-modal"
      footer={
        <div
          className={classNames({
            'members-modal__footer': true,
            'members-modal__footer--visible': showFooter
          })}
        >
          <p className="main-body-text main-body-text--small main-body-text--dark-gray">
            {membersToManage.length} member
            {membersToManage.length > 1 ? 's' : ''} selected
          </p>
          <div>
            <Button
              type="link"
              className="main-body-text main-body-text--small main-body-text--semibold main-body-text--dark-gray"
              onClick={() => setShowDeleteMemberModal(true)}
              style={{
                visibility: membersToRemove.length ? 'visible' : 'hidden'
              }}
            >
              Remove selected
            </Button>
            <UpgradePlanTooltip
              text=" to a Team plan to access this feature."
              isCanVisible={needUpgradeUserGroups}
              placement="left"
            >
              <Button
                size="small"
                onClick={() => {
                  dispatch(
                    toggleWorkspaceMemberGroupCreateModal({
                      visible: true,
                      workspace: nonNull(workspaceMembersModal?.workspace),
                      members: membersToManage.map((x) => ({
                        id: x.id,
                        email: nonNull(x.user?.email ?? x.invitationEmail),
                        name: x.user?.name ?? ''
                      }))
                    })
                  );
                  setMembersToManage([]);
                  setActiveTab('groups');
                }}
                style={{
                  visibility: workspaceMembersModal?.workspace?.permissions
                    .manageMemberGroups
                    ? 'visible'
                    : 'hidden'
                }}
                disabled={needUpgradeUserGroups}
                icon={
                  needUpgradeUserGroups ? (
                    <Icon component={UpgradePlanSvg} />
                  ) : null
                }
              >
                Create group
              </Button>
            </UpgradePlanTooltip>
          </div>
        </div>
      }
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 8 }}
        >
          Members of {workspaceMembersModal?.workspace?.name}
        </h2>
        <p
          className="modal_container__subtitle"
          style={{ color: '#161819', marginBottom: 24 }}
        >
          Add and manage workspace members here. Let the collaboration begin!
        </p>
        <div className="modal_container__workspace_members">
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            style={{ height: '100%' }}
            tabBarExtraContent={tabBarExtraContent}
            items={[
              {
                key: 'members',
                label: (
                  <>
                    Members <span className="users_count">{membersCount}</span>
                  </>
                ),
                children: (
                  <OverlayScrollbarsComponent
                    className="table_container"
                    style={{ marginRight: -20 }}
                  >
                    <InfiniteScroll
                      loading={membersQuery.isFetchingNextPage ? 'more' : false}
                      next={membersQuery.fetchNextPage}
                      hasMore={membersQuery.hasNextPage ?? false}
                    >
                      <WorkspaceMembersTable
                        setShowDeleteMemberModal={setShowDeleteMemberModal}
                        setMembersToManage={async (
                          ids,
                          members,
                          isAllSelect
                        ) => {
                          let query = membersQuery;
                          if (isAllSelect && ids.length) {
                            while (query.hasNextPage) {
                              query = await query.fetchNextPage();
                            }
                          }
                          setMembersToManage((prev) => {
                            const isDeselect =
                              isAllSelect &&
                              (!ids.length ||
                                prev.some((x) => ids.includes(x.id)));
                            if (isDeselect) return [];
                            if (isAllSelect) {
                              return (
                                query.data?.pages.flatMap((x) =>
                                  x.edges.map((x) => x.node)
                                ) ?? []
                              );
                            }
                            const newMembers = members.filter(
                              (x) => !prev.some((y) => x.id === y.id)
                            );
                            return [...prev, ...newMembers].filter((x) =>
                              ids.includes(x.id)
                            );
                          });
                        }}
                        onChangeWorkspaceMembersRole={(memberId, role) =>
                          changeRoleMutation.mutate({ memberId, role })
                        }
                        onResendInvitationEmail={onResendInvitationEmail}
                        onSort={handleSort}
                        count={membersCount}
                        edges={membersList}
                        selectedIds={membersToManage.map((x) => x.id)}
                        isLoading={
                          membersQuery.isLoading ||
                          membersQuery.isPreviousData ||
                          membersQuery.isFetchingNextPage
                        }
                      />
                    </InfiniteScroll>
                    {/* 
                      This fix infinite scroll, if we don't have some space at the bottom,
                      the infinite scroll anchor is visually hidden and won't trigger the next page fetch
                    */}
                    <div style={{ paddingTop: 10 }} />
                  </OverlayScrollbarsComponent>
                )
              },
              {
                key: 'groups',
                label: (
                  <>
                    Groups{' '}
                    <span className="users_count">{memberGroupsCount}</span>
                  </>
                ),
                children: !!workspaceMembersModal?.workspace && (
                  <Spin spinning={memberGroupsLoading}>
                    <OverlayScrollbarsComponent
                      className="table_container"
                      style={{ marginRight: -20 }}
                    >
                      {memberGroupsEmpty && (
                        <Empty
                          image={
                            needUpgradeUserGroups ? (
                              <EnterpriseSvg />
                            ) : (
                              <EmptyGroupsSvg />
                            )
                          }
                          description={
                            needUpgradeUserGroups
                              ? 'Upgrade to Team plan to access groups'
                              : 'Create your first group and manage it easily'
                          }
                          className="workspace_member_groups_empty"
                        >
                          {!needUpgradeUserGroups &&
                            !!workspaceMembersModal?.workspace?.permissions
                              .manageMemberGroups && (
                              <Button
                                type="primary"
                                size="large"
                                style={{
                                  width: 184,
                                  height: 48,
                                  padding: '8px 15px'
                                }}
                                onClick={() => {
                                  dispatch(
                                    toggleWorkspaceMemberGroupCreateModal({
                                      visible: true,
                                      workspace: nonNull(
                                        workspaceMembersModal?.workspace
                                      )
                                    })
                                  );
                                }}
                              >
                                Create group
                              </Button>
                            )}
                          {needUpgradeUserGroups &&
                            !!currentOrganization?.entity?.permissions
                              .billing && (
                              <Button
                                type="primary"
                                size="large"
                                style={{
                                  width: 184,
                                  height: 48,
                                  padding: '8px 15px'
                                }}
                                onClick={() => {
                                  dispatch(
                                    toggleBillingModal({ visible: true })
                                  );
                                }}
                              >
                                Upgrade Plan
                              </Button>
                            )}
                        </Empty>
                      )}
                      {!memberGroupsEmpty && (
                        <div className="workspace_member_groups">
                          <InfiniteScroll
                            loading={
                              memberGroupsQuery.isFetchingNextPage
                                ? 'more'
                                : false
                            }
                            next={memberGroupsQuery.fetchNextPage}
                            hasMore={memberGroupsQuery.hasNextPage ?? false}
                          >
                            {memberGroupsList.map((group) => (
                              <WorkspaceMemberGroupItem
                                key={group.id}
                                group={group}
                                workspace={nonNull(
                                  workspaceMembersModal.workspace
                                )}
                                onDelete={() => {
                                  setGroupToDelete(group);
                                  setShowDeleteGroupModal(true);
                                }}
                              />
                            ))}
                          </InfiniteScroll>
                        </div>
                      )}
                    </OverlayScrollbarsComponent>
                  </Spin>
                )
              }
            ]}
          />
        </div>
        <DeleteMemberModal
          members={membersToRemove}
          visible={showDeleteMemberModal}
          handleShowDeleteMemberModal={setShowDeleteMemberModal}
          handleDeleteMembers={deleteMemberMutation.mutate}
        />
        <DeleteMemberGroupModal
          group={groupToDelete}
          visible={showDeleteGroupModal}
          loading={deleteMemberGroupMutation.isLoading}
          onCancel={() => setShowDeleteGroupModal(false)}
          onConfirm={() =>
            deleteMemberGroupMutation.mutate(nonNull(groupToDelete?.id))
          }
        />
      </div>
    </Modal>
  );
}

export default MembersModal;
