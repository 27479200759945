import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useAuth } from '@hooks/useAuth';
import Uppy from '@uppy/core';
import { createTaskAttachmentsUppy } from '@services/taskAttachmentsUppy';

interface ITaskAttachmentsUppyContext {
  readonly uppy: Uppy;
  readonly userId: string;
}

const TaskAttachmentsUppyContext = createContext<
  ITaskAttachmentsUppyContext | undefined
>(undefined);

function createUppy(userId: string) {
  return createTaskAttachmentsUppy(userId);
}

export function TaskAttachmentsUppyProvider({
  children
}: {
  children: ReactNode;
}) {
  const { user } = useAuth(true);
  const [ctx, setCtx] = useState(() => createUppy(user.id));
  if (user.id !== ctx.userId) {
    ctx.uppy.close({ reason: 'unmount' });
    setCtx(() => createUppy(user.id));
  }
  return (
    <TaskAttachmentsUppyContext.Provider value={ctx}>
      {children}
    </TaskAttachmentsUppyContext.Provider>
  );
}

export function useTaskAttachmentsUppy() {
  const ctx = useContext(TaskAttachmentsUppyContext);
  if (!ctx)
    throw new Error(
      'No task attachments uppy found. Forgot to use TaskAttachmentsUppyProvider?'
    );
  return ctx.uppy;
}
