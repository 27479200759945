import { OnboardingStepsType } from '@context/OnboardingProcessProvider';
import { IsOnboardingCompleted } from '@helpers/StartOnboardingSteps';
import { useAuth } from '@hooks/useAuth';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  toggleCampaignFolderCreationModal,
  toggleUploadAssetModal
} from '@redux/actions/modalAction';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';

type OnboardingStepProps = {
  isDone: boolean;
  isActive: boolean;
  title: string;
  description: string;
  button: string;
  icon: React.ReactNode;
  type: OnboardingStepsType;
  hasCampaign: boolean;
  onCreateFirstTask: () => void;
  onCollaborateOnAnAsset: () => void;
  onRouteForApproval: () => void;
  onWatchVideoClick: (v: OnboardingStepsType) => void;
};
export default memo(function OnboardingStep({
  isDone,
  isActive,
  title,
  description,
  button,
  icon,
  type,
  hasCampaign,
  onCreateFirstTask,
  onCollaborateOnAnAsset,
  onRouteForApproval,
  onWatchVideoClick
}: OnboardingStepProps) {
  const { user } = useAuth(true, 'internal');
  const [currentWorkspace] = useCurrentWorkspace(true);
  const dispatch = useTypedDispatch();

  const onStartOnboardingStep = useCallback(() => {
    if (type === 'create-campaign')
      dispatch(
        toggleCampaignFolderCreationModal({
          entity: 'campaign',
          createFromOnboarding: true,
          visible: true
        })
      );
    if (type === 'create-task') {
      onCreateFirstTask();
    }
    if (type === 'upload-asset')
      dispatch(
        toggleUploadAssetModal({
          visible: true,
          postAction: 'redirect-to-campaign-tour',
          workspaceId: currentWorkspace.id
        })
      );
    if (type === 'create-task-from-comment') {
      onCollaborateOnAnAsset();
    }
    if (type === 'route-for-approval') {
      onRouteForApproval();
    }
  }, [
    type,
    dispatch,
    currentWorkspace.id,
    onCreateFirstTask,
    onCollaborateOnAnAsset,
    onRouteForApproval
  ]);
  const isOnboardingCompleted = useMemo(() => {
    return IsOnboardingCompleted(
      currentWorkspace.role === 'workspace://contributor',
      user.completedOnboardingSteps
    );
  }, [user.completedOnboardingSteps, currentWorkspace.role]);
  const canManageWithButton = useMemo(() => {
    if (currentWorkspace.role !== 'workspace://contributor') return true;
    return hasCampaign;
  }, [hasCampaign, currentWorkspace.role]);
  return (
    <div
      className={classNames({
        'onboarding-step': true,
        'onboarding-step-done': isDone,
        'onboarding-step-active': isActive
      })}
    >
      <div className="onboarding-step-info">
        <div className="onboarding-step-info__checkmark " />
        <div className="onboarding-step-info__icon">{icon}</div>
        <div className="onboarding-step-info__copies">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
      <div className="onboarding-step-actions">
        <Button
          className="watch-video-btn"
          onClick={() => onWatchVideoClick(type)}
        >
          Watch video
        </Button>
        {!isOnboardingCompleted && canManageWithButton && (
          <Button
            disabled={!isActive || isDone}
            className="start-btn"
            type="primary"
            onClick={onStartOnboardingStep}
          >
            {button}
          </Button>
        )}
      </div>
    </div>
  );
});
