import { Dropdown, Menu, Tooltip } from 'antd';
import React, { memo, useMemo } from 'react';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';

import './ItemsFilter.less';
import { TasksInDashboardListType } from '@redux/types/tasks';

type DashboardApprovalsFilterProps = {
  listType: TasksInDashboardListType;
  onFilterChange: (v: TasksInDashboardListType) => void;
};

export default memo(function DashboardApprovalsFilter({
  listType,
  onFilterChange
}: DashboardApprovalsFilterProps) {
  const menuItems = useMemo(
    () => [
      {
        key: 'all',
        label: 'All'
      },
      {
        key: 'assigned',
        label: 'Assets I need to review'
      },
      {
        key: 'own',
        label: `Assets I've routed for approval`
      }
    ],
    []
  );
  const selectedValueCopy = menuItems.find((x) => x.key === listType)?.label;
  return (
    <div className="items-filter-container">
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            selectedKeys={[listType]}
            items={menuItems}
            onClick={({ key }) =>
              onFilterChange(key as TasksInDashboardListType)
            }
          />
        }
        overlayClassName="entities-dropdown-menu-overlay"
      >
        <div className="items-filter-container--btn">
          <ArrowDownSvg className="arrow" />
          <Tooltip
            placement="topLeft"
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
            title={selectedValueCopy}
          >
            <div className="value">{selectedValueCopy}</div>
          </Tooltip>
        </div>
      </Dropdown>
    </div>
  );
});
