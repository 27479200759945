import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { TasksTable } from '@components/Tables';
import TaskListSkeleton from '@components/Tasks/TaskListSkeleton';
import { useTypedSelector } from '@hooks';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  tasksTotalCountSelector,
  workspaceStateSelector,
  tasksListOrGroupSelector,
  taskIdsSelector
} from '@redux/selectors/tasks';
import { ListFetchType, TaskListType } from '@redux/types/tasks';
import React, { memo, useEffect, useRef } from 'react';

export default memo(function TasksListTable(props: {
  page: TaskListType;
  groupId?: string;
  loading: ListFetchType | false;
  onLoadMore: () => void;
}) {
  const { page, groupId, loading, onLoadMore } = props;
  const [currentWorkspace] = useCurrentWorkspace(true);
  const { organizationId } = currentWorkspace;
  const workspaceId = currentWorkspace.id;
  const tasksCount = useTypedSelector((state) =>
    groupId
      ? tasksListOrGroupSelector(state, { workspaceId, list: page, groupId })
          .count
      : tasksTotalCountSelector(state, { workspaceId, list: page })
  );
  const hasMore = useTypedSelector(
    (state) =>
      tasksListOrGroupSelector(state, { workspaceId, list: page, groupId })
        .hasMore
  );
  const taskIds = useTypedSelector((state) =>
    taskIdsSelector(state, { workspaceId, list: page, groupId })
  );
  const anyCampaignExists = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, {
        workspaceId
      }).listsShared.campaigns.list.filter((it) => !it.demo).length > 0
  );
  const newTasks = useTypedSelector((state) => state.tasks.newTasks).filter(
    (x) => {
      if (x.location.workspaceId !== workspaceId) return false;
      const list = x.location.lists[page];
      if (!list) return false;
      if (!groupId) return list.flatList;
      return (
        list.boardIds.some((id) => groupId.includes(id)) ||
        list.campaignIds.some((id) => groupId.includes(id))
      );
    }
  );
  const topNewTasksCount = newTasks.filter(
    (x) => x.position === 'start-of-list'
  ).length;
  const bottomNewTasksCount = newTasks.filter(
    (x) => x.position === 'end-of-list'
  ).length;
  const bottomSkeletonsCount =
    tasksCount + bottomNewTasksCount - taskIds.length;
  const allSkeletonsCount = topNewTasksCount + bottomSkeletonsCount;

  const ref = useRef<HTMLDivElement>(null);
  const prevTopNewTasksCount = useRef(topNewTasksCount);
  const prevBottomNewTasksCount = useRef(bottomNewTasksCount);
  useEffect(() => {
    if (
      topNewTasksCount !== prevTopNewTasksCount.current ||
      bottomNewTasksCount !== prevBottomNewTasksCount.current
    ) {
      if (
        topNewTasksCount > prevTopNewTasksCount.current ||
        bottomNewTasksCount > prevBottomNewTasksCount.current
      ) {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block:
            topNewTasksCount > prevTopNewTasksCount.current ? 'start' : 'end'
        });
      }
      prevTopNewTasksCount.current = topNewTasksCount;
      prevBottomNewTasksCount.current = bottomNewTasksCount;
    }
  }, [topNewTasksCount, bottomNewTasksCount]);

  return (
    <div ref={ref}>
      <InfiniteScroll
        loading={loading}
        next={onLoadMore}
        hasMore={hasMore}
        loader={
          <TaskListSkeleton
            count={
              bottomSkeletonsCount + (taskIds.length ? 0 : topNewTasksCount)
            }
          />
        }
        alwaysShowLoader
      >
        <TasksTable
          organizationId={organizationId}
          workspaceId={workspaceId}
          isExistCampaign={anyCampaignExists}
          isDeletedTask={page === 'deleted'}
          page={page}
          taskIds={taskIds}
          hidePlaceholder={allSkeletonsCount > 0}
          topSkeletonsCount={topNewTasksCount}
          emptyTableText={
            page === 'deleted' ? "You don't have deleted tasks yet" : undefined
          }
        />
      </InfiniteScroll>
    </div>
  );
});
