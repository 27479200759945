import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Divider,
  Popover,
  Menu,
  Upload,
  message,
  Spin,
  Tooltip
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useUppy } from '@components/Modals/CampaignInfoModal/CampaignDetailsFields/CampaignAttachmentUpload';

import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as AttachSvg } from '@assets/icons/Attach.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import Uppy from '@uppy/core';
import LottieComponent from '@components/Lottie';
import { useWebSocketSubscription } from '@hooks/useWebSocketClient';
import { getThumbnail } from '@helpers/attachmentPreviewThumbnails';
import { useTypedSelector } from '@hooks';
import { download } from '@helpers/download';
import { bytesToSize } from '@helpers/bytesToSize';
import { useOrganization } from '@components/OrganizationBoundary';
import { useDispatch } from 'react-redux';
import { toggleBillingModal } from '@redux/actions/modalAction';
import { AttachmentDto } from '@api/Api';
import { apiClient } from '@api/client';

const { Dragger } = Upload;

function Preview({ progress, file }: any) {
  if (!file.id) {
    return (
      <LottieComponent
        view="circle"
        className="custom-picture-card__progress"
        progress={progress}
        autoPlay={false}
        isLooped={false}
      />
    );
  }

  return (
    <img
      className="custom-picture-card__preview-item"
      alt={file?.name || ''}
      src={getThumbnail(file.mimeType || file.type || '', file.url)}
    />
  );
}

function UploadItemRenderer(
  originNode: React.ReactElement,
  file: any,
  fileList: Array<UploadFile>,
  {
    remove
  }: {
    remove(): void;
  },
  uppy: Uppy,
  setFileList: any,
  changeDetails: any
) {
  const { name, extension } = useMemo(() => {
    const fileNameArr = file?.name?.split('.');
    const ext = fileNameArr?.pop();
    const name = fileNameArr?.join('.');
    return {
      name,
      extension: ext || ''
    };
  }, [file]);

  const menuItems = [
    {
      key: 'view',
      onClick: () => {
        if (!file?.url || !file?.name || !file.mimeType) return;

        if (
          file?.mimeType.includes('image') ||
          file?.mimeType.includes('audio') ||
          file?.mimeType.includes('video') ||
          file?.mimeType.includes('pdf')
        ) {
          window.open(file.url, '_blank');
        } else {
          download(file.url, file.name);
        }
      },
      label: 'View original'
    },
    {
      key: 'remove',
      onClick: () => remove(),
      label: 'Remove',
      disabled: !changeDetails
    }
  ];

  useEffect(() => {
    const handler = (file: any, progress: any) => {
      const percent = (progress.bytesUploaded * 100) / progress.bytesTotal;
      setFileList((prev: any) =>
        prev.map((el: any) => {
          if (el.uid === file.data.uid) return { ...el, prc: percent };
          return el;
        })
      );
    };
    uppy.on('upload-progress', handler);
    return () => {
      uppy.off('upload-progress', handler);
    };
  }, [file.id]);

  return (
    <div className="custom-picture-card" id={`picture-card-${file.id}`}>
      <Tooltip
        placement="topLeft"
        overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
        title={file?.name}
      >
        <div className="custom-picture-card__preview">
          <Preview file={file} progress={file.prc - 1 || 1} />
          {file.id && (
            <Popover
              getPopupContainer={() =>
                document.getElementById(`picture-card-${file.id}`)
                  ?.parentNode as HTMLElement
              }
              content={
                <Menu
                  style={{ minWidth: 200 }}
                  mode="vertical"
                  items={menuItems}
                  selectable={false}
                />
              }
              placement="rightBottom"
              overlayClassName="popover-container"
              overlayInnerStyle={{ transform: 'translate(-10px, 2px)' }}
              trigger={['click']}
            >
              <Button
                className="custom-picture-card__controll"
                type="text"
                size="small"
              >
                <ActionSvg style={{ marginRight: 0 }} />
              </Button>
            </Popover>
          )}
        </div>
      </Tooltip>
      {name && (
        <span className="custom-picture-card__name main-body-text main-body-text--tiny main-body-text--semibold">
          {name}
        </span>
      )}
      {extension && (
        <span className="custom-picture-card__extension main-body-text main-body-text--tiny main-body-text--dark-gray">
          {extension}
        </span>
      )}
    </div>
  );
}

function CampaignAttachments({ campaignId }: { campaignId: string }) {
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [fileList, setFileList] = useState<any>();
  const uppy = useUppy(campaignId);

  const modalState = useTypedSelector(({ modal }) => modal.campaignInfoModal);

  useEffect(() => {
    uppy.on('upload-error', (file, error: any) => {
      const { errorCode, remainingSpace } = error.response?.data ?? {};
      if (
        errorCode === 'assets_size_limit_will_exceeded' ||
        errorCode === 'assets_size_limit_exceeded'
      )
        message.error({
          content: (
            <div className="upload-error">
              <div className="upload-error--name">{file?.name} </div>
              <div className="upload-error--description">
                The file is too large to upload due to limited space. The
                maximum file size that can be uploaded is{' '}
                {bytesToSize({ bytes: remainingSpace || 0, to: 0 }).replace(
                  ' ',
                  ''
                )}
                . Try again or{' '}
                {currentOrganization?.entity?.permissions.billing ? (
                  <>
                    <span
                      className="upload-error--description-upgrade"
                      onClick={() => {
                        dispatch(toggleBillingModal({ visible: true }));
                      }}
                    >
                      upgrade
                    </span>
                    .
                  </>
                ) : (
                  <>
                    contact organization owner{' '}
                    <strong>{currentOrganization?.entity?.owner.email}</strong>
                  </>
                )}
              </div>
            </div>
          ),
          className: 'message-dark-modal'
        });
    });
    return () => {
      uppy.off('upload-error', () => null);
    };
  }, [uppy]);

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.target.type !== 'campaign-attachment') return;
    if (msg.payload.target.campaignId !== campaignId) return;
    if (msg.causedByMe) return;
    const {
      data: { attachment }
    } = await apiClient.attachment.attachmentControllerGetAttachment({
      id: msg.payload.attachmentId
    });
    const newFileList = [...(fileList || []), attachment];
    setFileList(newFileList);
  }, 'attachment-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.target.type !== 'campaign-attachment') return;
    if (msg.payload.target.campaignId !== campaignId) return;
    if (msg.causedByMe) return;
    const newFileList =
      fileList?.filter((el: any) => el.id !== msg.payload.attachmentId) || [];
    setFileList(newFileList);
  }, 'attachment-removed');

  useEffect(() => {
    if (campaignId) {
      (async () => {
        try {
          const {
            data: { attachments }
          }: { data: { attachments: AttachmentDto[] } } =
            await apiClient.attachment.attachmentControllerGetAttachments({
              targetId: campaignId,
              type: 'campaign-attachment'
            });
          setFileList(attachments);
        } catch (err: any) {
          message.error(err?.response?.data?.message || 'Something went wrong');
        } finally {
          setIsLoading(false);
        }
      })();
      return () => {
        setIsLoading(true);
      };
    }
  }, [campaignId]);

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    const handler = (file: any) => {
      const objectURL = URL.createObjectURL(file.data);
      const newFileList = fileList.filter(
        (el: any) => el.uid !== file.data.uid
      );
      setFileList(() => [
        ...newFileList,
        {
          uid: file.data.uid,
          id: file.meta.attachmentId,
          name: file.data.name,
          url: objectURL,
          type: file.type
        }
      ]);
    };
    uppy.on('restriction-failed', (_, error) => {
      message.error(error.message || 'Something went wrong');
    });
    uppy.on('upload-success', handler);
    return () => {
      uppy.off('restriction-failed', () => null);
      uppy.off('upload-success', handler);
    };
  }, [fileList]);

  const onRemoveFile = async (id: string) => {
    try {
      const uppyFile = uppy
        .getFiles()
        .find((item: any) => item?.meta?.attachmentId === id);
      if (uppyFile?.id) uppy.removeFile(uppyFile.id);
      await apiClient.attachment.attachmentControllerDeleteAttachment({ id });
      setFileList((oldFileList: any) =>
        oldFileList.filter((item: any) => item.id !== id)
      );
    } catch (err: any) {
      message.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const customRequest = async ({ file }: any) => {
    setFileList((oldFileList: any) => [...oldFileList, { uid: file.uid }]);
    await uppy.addFile({
      name: file.name,
      type: file.type,
      data: file,
      meta: {
        relativePath: new Date().toISOString()
      }
    });
  };

  if (isLoading) {
    return <Spin className="player_loader share_asset_modal_loader" />;
  }

  return (
    <>
      {fileList && fileList.length > 0 && (
        <Divider style={{ margin: '24px 0px 24px 0' }} />
      )}
      <div className="field_item" style={{ paddingBottom: 32 }}>
        {fileList && fileList.length > 0 && (
          <div className="field_title">Attachments</div>
        )}
        <div className="field_description">
          {fileList && fileList.length > 0 ? (
            <Upload
              listType="picture-card"
              fileList={fileList}
              multiple
              customRequest={customRequest}
              itemRender={(originNode, file, fileList, { remove }) =>
                UploadItemRenderer(
                  originNode,
                  file,
                  fileList,
                  { remove },
                  uppy,
                  setFileList,
                  modalState?.campaign?.permissions.changeDetails
                )
              }
              onRemove={(data: any) => onRemoveFile(data.id)}
            >
              {modalState?.campaign?.permissions.changeDetails && <PlusSvg />}
            </Upload>
          ) : (
            <Dragger
              fileList={fileList}
              customRequest={customRequest}
              multiple
              disabled={!modalState?.campaign?.permissions.changeDetails}
            >
              <h3
                className="main-body-text main-body-text--semibold main-body-text--primary"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 4
                }}
              >
                <AttachSvg style={{ marginRight: 4 }} />
                Attach file
              </h3>
            </Dragger>
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignAttachments;
