import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UppyFile } from '@uppy/core';
import { useMixpanel } from 'react-mixpanel-browser';
import UploadImage from '@components/UploadImage';
import {
  createNewWorkspace,
  renameWorkspace,
  setWorkspaceList
} from '@redux/actions/workspaceAction';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useOrganization } from '@components/OrganizationBoundary';
import { useTypedSelector } from '@hooks';
import {
  toggleReachedEntityLimitModal,
  toggleWorkspaceCreationModal,
  toggleWorkspaceInvitationModal
} from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { deleteWorkspacePicture, uploadWorkspacePicture } from '@api/Workspace';
import { removeExtraSpaces } from '@helpers/stringTransformers';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';

function WorkspaceCreationModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');
  const uploadImageRef = useRef<any>(null);
  const deleteImageRef = useRef<any>(null);
  const [form] = Form.useForm();
  const workspaceCreationModal = useTypedSelector(
    ({ modal }) => modal.workspaceCreationModal
  );
  const workspaceList = useTypedSelector(
    ({ workspace }) => workspace.workspaceList
  );
  const [initialWorkspaceName, setInitialWorkspaceName] = useState<
    string | null
  >(null);
  const [picture, setPicture] = useState<UppyFile | null | string>(null);
  const [isPictureError, setIsPictureError] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { currentOrganization } = useOrganization();

  useEffect(() => {
    if (!workspaceCreationModal) return;
    const workspace = workspaceList.find(
      (el) => el.id === workspaceCreationModal?.workspace?.id
    );
    if (!workspace) {
      dispatch(toggleWorkspaceCreationModal(null));
      return;
    }
    dispatch(
      toggleWorkspaceCreationModal({
        ...workspaceCreationModal,
        workspace
      })
    );
  }, [workspaceList]);
  useEffect(() => {
    if (workspaceCreationModal?.visible) {
      form.setFieldsValue({
        workspaceName: workspaceCreationModal?.workspace?.name || ''
      });
      setPicture(workspaceCreationModal?.workspace?.picture.url || '');
      setInitialWorkspaceName(workspaceCreationModal?.workspace?.name || '');
      setIsPictureError(false);
    } else {
      setPicture(null);
    }
  }, [form, workspaceCreationModal]);

  const updateWorkspaces = (ws: any) => {
    let newList = [];

    if (workspaceCreationModal?.isEditing) {
      newList = workspaceList.map((el: any) => {
        if (el.id === ws.id) return ws;
        return el;
      });
    } else {
      newList = [...workspaceList, ws];
    }
    dispatch(setWorkspaceList(newList));

    return newList;
  };

  const onUploadPicture = async (newWorkspace: any) => {
    const updatedWorkspace = { ...newWorkspace };
    if (typeof picture === 'string') return;

    if (!picture && workspaceCreationModal?.workspace?.picture.url) {
      await deleteWorkspacePicture(newWorkspace.id);
      updatedWorkspace.picture = { url: null };
    }
    if (picture) {
      await uploadImageRef.current(newWorkspace.id);
      updatedWorkspace.picture = {
        url: URL.createObjectURL(picture.data) || null
      };
    }
    updateWorkspaces(updatedWorkspace);
    return updatedWorkspace;
  };

  const creatingFlow = async ({ workspaceName }: { workspaceName: string }) => {
    if (!currentOrganization.entity) return;
    let workspace = await createNewWorkspace({
      organizationId: currentOrganization.entity?.id,
      name: removeExtraSpaces(workspaceName)
    })(dispatch);
    const workspaceWithImage = await onUploadPicture(workspace);

    if (workspaceWithImage) {
      workspace = { ...workspaceWithImage };
    }

    await MixpanelService.track(
      mixpanel,
      user.id,
      MixpanelEventType.MANAGE_WORKSPACES,
      {
        newWorkspaceName: workspaceName,
        accessLink: false,
        deleteWorkspace: false,
        updateWorkspace: false,
        oldWorkspaceName: null,
        uploadProfilePhoto: !!workspaceWithImage?.picture.url,
        planType: currentOrganization.entity?.planType ?? 'free',
        addMember: false
      }
    );

    return workspace;
  };

  const editingFlow = async ({ workspaceName }: { workspaceName: string }) => {
    if (initialWorkspaceName !== workspaceName) {
      await renameWorkspace({
        organizationId: workspaceCreationModal?.workspace?.organizationId || '',
        workspaceId: workspaceCreationModal?.workspace?.id || '',
        name: removeExtraSpaces(workspaceName)
      })(dispatch);
    }

    const workspaceWithImage = await onUploadPicture({
      ...workspaceCreationModal?.workspace,
      name: workspaceName
    });

    await MixpanelService.track(
      mixpanel,
      user.id,
      MixpanelEventType.MANAGE_WORKSPACES,
      {
        newWorkspaceName: workspaceName,
        accessLink: false,
        deleteWorkspace: false,
        updateWorkspace: true,
        oldWorkspaceName:
          initialWorkspaceName !== workspaceName ? initialWorkspaceName : null,
        uploadProfilePhoto: !!workspaceWithImage?.picture.url,
        planType: currentOrganization.entity?.planType ?? 'free',
        addMember: false
      }
    );
  };

  const onFinish = async (v: { workspaceName: string }) => {
    setIsSubmit(true);
    try {
      if (!workspaceCreationModal?.isEditing) {
        const workspace = await creatingFlow(v);
        navigate(`/dashboard/${workspace?.id}`);

        dispatch(
          toggleWorkspaceInvitationModal({
            visible: true,
            workspace
          })
        );
      } else {
        await editingFlow(v);
      }
    } catch (err) {
      dispatch(
        toggleReachedEntityLimitModal({ visible: true, entity: 'workspace' })
      );
    } finally {
      setIsSubmit(false);
      dispatch(toggleWorkspaceCreationModal(null));
    }
  };

  return (
    <Modal
      visible={workspaceCreationModal?.visible}
      footer={null}
      centered={true}
      destroyOnClose
      closeIcon={<CrossSvg />}
      width={468}
      onCancel={() => {
        dispatch(toggleWorkspaceCreationModal(null));
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="modal_container">
          <h2 className="modal_container__title" style={{ fontSize: '22px' }}>
            {workspaceCreationModal?.isEditing
              ? `Manage ${workspaceCreationModal?.workspace?.name}`
              : 'Create workspace'}
          </h2>
          <div className="modal_container__workspace_creation">
            <UploadImage
              onUploadPicture={uploadWorkspacePicture}
              picture={picture}
              setPicture={setPicture}
              isError={isPictureError}
              setIsError={setIsPictureError}
              uploadImageHandler={uploadImageRef}
              deleteImageHandler={deleteImageRef}
            />
            {isPictureError && (
              <div className="error_image_upload_copy">
                The image can&apos;t be loaded. Review the guidelines and try
                another image.
              </div>
            )}
            <Form.Item
              label="Workspace name"
              name="workspaceName"
              rules={[
                {
                  required: true,
                  message: 'Workspace name required.'
                },
                {
                  max: 255,
                  message: 'The name should be not more than 255 symbols length'
                },
                {
                  pattern: /^[a-zA-Z 0-9 (".,:'\-—/!?#+=*@&©)]*$/,
                  message: `Please use only letters, numbers or the following special symbols .,:()'-—/!?#+=*@&`
                },
                {
                  whitespace: true,
                  message: 'The name cannot contain only whitespace characters'
                }
              ]}
            >
              <Input placeholder="Workspace name" size="large" />
            </Form.Item>
          </div>
          {!workspaceCreationModal?.workspace && (
            <p className="modal_container__hint">
              Create a workspace to collaborate on campaigns with your team.{' '}
            </p>
          )}
          <div className="modal_container__actions">
            <Button
              type="text"
              size="large"
              style={{
                marginRight: 16,
                border: 'none',
                height: 48,
                padding: '8px 32px'
              }}
              disabled={isSubmit}
              onClick={() => dispatch(toggleWorkspaceCreationModal(null))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={isSubmit}
              size="large"
              htmlType="submit"
              style={{
                width: 152,
                height: 48,
                padding: '8px 15px'
              }}
            >
              {workspaceCreationModal?.isEditing ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default WorkspaceCreationModal;
