import React from 'react';

function WorkspaceNoAvailableToast() {
  return (
    <div className="toast_container">
      <div style={{ width: '100%' }}>
        <div
          className="toast_container__body"
          style={{ width: '100%', textAlign: 'center' }}
        >
          <div className="description">
            This workspace is no longer available.
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkspaceNoAvailableToast;
