import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import { ReactComponent as MenuSvg } from '@assets/icons/menu.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { Dropdown } from 'antd';
import { campaignGetSimpleCampaignsListWithFolders } from '@api/Campaign';
import { useCurrentWorkspace } from '@hooks/workspace';
import { SimpleCampaignWithFoldersItemDto } from '@api/Api';
import { useNavigate } from 'react-router-dom';

type SelectCampaignProps = {
  currentCampaignId: string;
};

export default memo(function SelectCampaign({
  currentCampaignId
}: SelectCampaignProps) {
  const navigate = useNavigate();
  const [currentWorkspace] = useCurrentWorkspace(false);
  const [list, setList] = useState<SimpleCampaignWithFoldersItemDto[]>([]);

  const workspaceId = currentWorkspace?.id;
  useEffect(() => {
    if (!workspaceId) return;
    (async () => {
      const {
        data: { list }
      } = await campaignGetSimpleCampaignsListWithFolders({ workspaceId });
      setList(list);
    })();
  }, [workspaceId]);

  const items = useMemo(() => {
    const _list = list.map((it) => ({ label: it.name, key: it.id }));
    return _list;
  }, [list]);

  const onCampaignChange = useCallback(
    ({ key }) => {
      navigate(`/campaigns/single/${key}`);
    },
    [navigate]
  );
  return (
    <div className="campaign_breadcrumbs__tag">
      <Dropdown
        overlayClassName="select-campaign-overlay"
        trigger={['click']}
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [currentCampaignId],
          onClick: onCampaignChange
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <MenuSvg />
          <ArrowDownSvg />
        </div>
      </Dropdown>
    </div>
  );
});
