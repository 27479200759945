export type WorkspaceRole =
  | 'workspace://team-lead'
  | 'workspace://member'
  | 'workspace://contributor';

export const WorkspaceRoleCopy: { [id: string]: string } = {
  'workspace://team-lead': 'Workspace lead',
  'workspace://member': 'Member',
  'workspace://contributor': 'Contributor'
};

export const OrganizationRoleCopy: { [id: string]: string } = {
  'organization://admin': 'Admin',
  'organization://workspace-member': 'Member'
};
