import React, { useCallback, useState, useEffect } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ErrorSvg } from '@assets/icons/contributors_limit_error_notification.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { useOrganization } from '@components/OrganizationBoundary';
import { useNavigate } from 'react-router-dom';
import isTrialEnd from '@helpers/isTrialEnd';

ExtraStorageSizeAlert.defaultProps = {
  fullScreen: false
};

function ExtraStorageSizeAlert({ fullScreen }: { fullScreen?: boolean }) {
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization(true);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!currentOrganization.entity?.permissions.billing) return;

    const result = isTrialEnd(currentOrganization.entity);
    if (result) return;

    if (
      !currentOrganization.entity.lockReasons.includes(
        'asset-summary-size-limit-exceeded'
      )
    )
      return;

    setIsAlertVisible(true);
  }, [currentOrganization]);
  const storageSizeLimit = useCallback(() => {
    return currentOrganization.entity?.limits.diskSpaceGB;
  }, [currentOrganization])();

  if (!isAlertVisible) return null;
  return (
    <div
      className={classNames(
        'alert-container',
        fullScreen && 'alert-container-full-screen'
      )}
    >
      <div className="info">
        <div className="icon">
          <ErrorSvg />
        </div>
        <div className="copies">
          <span className="title">
            You&apos;ve exceeded your allotted storage
          </span>
          <span className="description">
            Your plan includes {storageSizeLimit}GB storage. Upgrade your plan
            or remove creative media to free up storage.
          </span>
        </div>
      </div>
      <div className="actions">
        <Button
          type="primary"
          onClick={() => {
            setIsAlertVisible(false);
            navigate('/assets/all');
          }}
        >
          Delete storage
        </Button>
        <CrossSvg
          style={{ cursor: 'pointer' }}
          onClick={() => setIsAlertVisible(false)}
        />
      </div>
    </div>
  );
}

export default ExtraStorageSizeAlert;
