import { useAuth } from '@hooks/useAuth';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import OnboardingProgress from '@pages/Start/components/OnboardingProgress/OnboardingProgress';
import OnboardingSteps from '@pages/Start/components/OnboardingSteps/OnboardingSteps';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

function StartInWorkspace() {
  return (
    <div className="start_container__main" id="MAIN_LAYOUT">
      <div className="start_container__copies">
        <div className="start_container__copies-title">
          <h3>Let&apos;s start building</h3>
        </div>
        <div className="start_container__copies-description">
          <p>Follow these simple steps to get started</p>
        </div>
      </div>
      <OnboardingProgress />
      <OnboardingSteps />
    </div>
  );
}

export default function Start() {
  const { user } = useAuth(true, 'internal');
  const { workspaceId: workspaceIdParam } = useParams();
  const location = useLocation();

  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);

  const workspaceId =
    workspaceIdParam ?? currentWorkspace?.id ?? defaultWorkspace?.id;

  useEffect(() => {
    (async () => {
      try {
        if (workspaceId) await setCurrentWorkspace(workspaceId);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [workspaceId, setCurrentWorkspace, currentWorkspace?.id, user.id]);

  const needRedirect = !!workspaceId && workspaceId !== workspaceIdParam;

  if (needRedirect) {
    return <Navigate to={workspaceId + location.search} replace />;
  }

  return (
    <div className="start_container">
      {!!currentWorkspace && <StartInWorkspace />}
    </div>
  );
}
