import React, { useCallback, useEffect, useRef, useState } from 'react';
import '@pages/Assets/Assets.less';

import { Navigate, useParams } from 'react-router-dom';
import {
  AssetsList as InternalAssetsList,
  FoldersList
} from '@components/Assets';
import { useCurrentWorkspace, useDefaultWorkspace } from '@hooks/workspace';
import PageSortPanel from '@components/PageSortPanel';
import { useDispatch } from 'react-redux';
import {
  loadMoreAssets,
  setAssetList,
  updateAsset
} from '@redux/actions/assetsAction';
import { AssetControllerListAssetsParams } from '@api/Api';
import { useTypedSelector } from '@hooks';
import { AssetsActionTypes } from '@redux/types/assetsType';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Menu } from 'antd';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import {
  setWorkspaceCounters,
  updateAssetsWorkspaceCounters,
  updateWorkspaceCounters
} from '@redux/actions/workspaceCountersAction';
import { getAsset } from '@api/Asset';
import { useLayout } from '@layouts/PageLayout';
import { ReactComponent as DeletedSvg } from '@assets/icons/deleted-tasks-head-icon.svg';

function modifyParams(
  page: 'all' | 'my' | 'favorite' | 'deleted',
  workspaceId: string
) {
  let params: AssetControllerListAssetsParams = {
    workspaceId
  };
  if (page === 'my')
    params = {
      ...params,
      own: true
    };

  if (page === 'favorite')
    params = {
      ...params,
      isFavorite: true
    };
  if (page === 'deleted')
    params = {
      ...params,
      isDeleted: true
    };

  return params;
}

function AssetsInWorkspace({
  page
}: {
  page: 'all' | 'my' | 'favorite' | 'deleted';
}) {
  const infiniteScrollRef = useRef<any>();
  const endOfFoldersRef = useRef<any>();
  const endOfFilesRef = useRef<any>();
  const { currentView } = useLayout();
  const urlParams = useParams();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [countToFetchFiles, setCountToFetchFiles] = useState<number>(0);
  const [countToFetchFolders, setCountToFetchFolders] = useState<number>(0);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const assetsData = useTypedSelector(({ assets }) => assets.files);
  const foldersData = useTypedSelector(({ assets }) => assets.folders);
  const [currentWorkspace] = useCurrentWorkspace(false);
  const [orderBy, setOrderBy] = useState<any>();
  const workspaceCounters = useTypedSelector(
    ({ workspaceCounters }) => workspaceCounters
  );

  const setCounters = (page: string, count: number) => {
    let entity = '';

    if (page === 'all') {
      entity = 'allAssetsCount';
    }
    if (page === 'deleted') {
      entity = 'deletedAssetsCount';
    }
    if (page === 'favorite') {
      entity = 'favoriteAssetsCount';
    }
    if (page === 'my') {
      entity = 'myAssetsCount';
    }
    dispatch(
      updateWorkspaceCounters({
        entity,
        count
      })
    );
  };

  useEffect(() => {
    (async () => {
      const params = modifyParams(page, urlParams.workspaceId as string);
      if (page === 'deleted')
        dispatch(setAssetList({ ...params, flatten: true, isFolder: true }));

      const { count } = await setAssetList({
        ...params,
        flatten: true,
        isFolder: false
      })(dispatch);
      setCounters(page, count);
    })();

    return () => {
      if (infiniteScrollRef.current?.el)
        infiniteScrollRef.current.el.scrollTo(0, 0);
      dispatch({
        type: AssetsActionTypes.SET_ASSETS_LIST,
        payload: { edges: null, count: null }
      });
      dispatch({
        type: AssetsActionTypes.SET_FOLDERS_LIST,
        payload: { edges: null, count: null }
      });
    };
  }, [page, dispatch, urlParams]);

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.payload.reason === 'moved-to-folder') return;
    if (msg.causedByMe && msg.payload.reason !== 'upload') return;
    const {
      data: { asset }
    } = await getAsset({ assetId: msg.payload.assetId });
    const isRestoring = msg.payload.reason === 'restored-from-recycle-bin';
    dispatch(
      updateAssetsWorkspaceCounters(isRestoring ? 'restore' : 'create', asset)
    );

    if (page === 'deleted' && isRestoring) {
      dispatch({
        type: AssetsActionTypes.DELETE_ASSET,
        payload: { assetId: msg.payload.assetId }
      });
      return;
    }

    const me = asset.owner?.user?.me;
    const { isFavorite } = asset;
    if (
      (page === 'favorite' && isFavorite) ||
      (page === 'my' && me) ||
      page === 'all'
    ) {
      dispatch({
        type: AssetsActionTypes.ADD_NEW_ASSET,
        payload: { asset }
      });
    }
  }, 'asset-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.workspaceId !== urlParams.workspaceId) return;
    if (msg.payload.reason === 'moved-to-folder') return;
    if (msg.causedByMe) return;
    const isAssetRemovedFromDBList =
      msg.payload.reason === 'deleted-permanently' ||
      msg.payload.reason === 'added-to-stack';
    if (isAssetRemovedFromDBList) {
      const isPermanent = msg.payload.reason === 'deleted-permanently';
      const asset = assetsData.edges?.find(
        (el) => el.node.id === msg.payload.assetId
      )?.node;
      if (asset)
        dispatch(
          updateAssetsWorkspaceCounters(
            isPermanent ? 'deletePermanent' : 'addToStack',
            asset
          )
        );
      else dispatch(setWorkspaceCounters(msg.payload.workspaceId));

      dispatch({
        type: AssetsActionTypes.DELETE_ASSET,
        payload: { assetId: msg.payload.assetId }
      });
      return;
    }

    const {
      data: { asset }
    } = await getAsset({
      assetId: msg.payload.assetId,
      isDeleted: true
    });
    dispatch(updateAssetsWorkspaceCounters('delete', asset));
    if (page === 'deleted') {
      dispatch({
        type: AssetsActionTypes.ADD_NEW_ASSET,
        payload: { asset }
      });
      return;
    }
    dispatch({
      type: AssetsActionTypes.DELETE_ASSET,
      payload: { assetId: msg.payload.assetId }
    });
  }, 'asset-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    (messages) => {
      const lastMessage = messages[messages.length - 1];
      dispatch(updateAsset(lastMessage.payload.assetId));
    },
    1000,
    (msg) => {
      if (msg.payload.workspaceId !== urlParams.workspaceId) return false;
      if (msg.causedByMe && msg.payload.reason !== 'conversion-status-changed')
        return false;
      return true;
    },
    'asset-updated'
  );

  const loadMoreFiles = async () => {
    let params = modifyParams(page, urlParams.workspaceId as string);
    params = {
      ...params,
      after: assetsData.endCursor
    };
    const count = Math.min(
      (assetsData?.count || 0) - (assetsData?.edges?.length || 0),
      25
    );
    setCountToFetchFiles(count);
    await loadMoreAssets({
      ...params,
      searchQuery,
      orderBy: orderBy && [orderBy],
      flatten: true,
      isFolder: false
    })(dispatch);
    setCountToFetchFiles(0);
  };

  const loadMoreFolders = async () => {
    const params = modifyParams(page, urlParams.workspaceId as string);
    const count = Math.min(
      (foldersData?.count || 0) - (foldersData?.edges?.length || 0),
      25
    );
    setCountToFetchFolders(count);
    await loadMoreAssets({
      ...params,
      searchQuery,
      orderBy: orderBy && [orderBy],
      flatten: true,
      isFolder: true
    })(dispatch);

    setCountToFetchFolders(0);
  };

  const displayAssetsCount = useCallback(() => {
    let count = 0;
    switch (page) {
      case 'all':
        count = workspaceCounters.allAssetsCount;
        break;
      case 'deleted':
        count = workspaceCounters.deletedAssetsCount;
        break;
      case 'favorite':
        count = workspaceCounters.favoriteAssetsCount;
        break;
      case 'my':
        count = workspaceCounters.myAssetsCount;
        break;
      default:
        count = 0;
    }
    return count;
  }, [page, workspaceCounters])();
  return (
    <div>
      <div className="inner-header">
        <div className="inner-header__name">
          {page === 'deleted' && <DeletedSvg className="inner-header__icon" />}
          <span className="inner-header__title">
            {page.toUpperCase()} MEDIA
          </span>
          <div className="inner-header__count">
            {assetsData.count || displayAssetsCount}
          </div>
        </div>
        {page === 'deleted' && (
          <span className="inner-header__description main-body-text main-body-text--dark-gray">
            Creative media will be permanently deleted after{' '}
            <span className="main-body-text--main-color">30&nbsp;days</span>
          </span>
        )}
      </div>
      <PageSortPanel
        entity="media"
        actionButtonName="Upload new media"
        onAddClick={
          page === 'all'
            ? () =>
                dispatch(
                  toggleUploadAssetModal({
                    visible: true,
                    workspaceId: currentWorkspace?.id || ''
                  })
                )
            : undefined
        }
        onSearch={(v: string) => {
          const params = modifyParams(page, urlParams.workspaceId as string);

          dispatch(
            setAssetList({
              ...params,
              searchQuery: v,
              orderBy: orderBy && [orderBy],
              flatten: true,
              isFolder: false
            })
          );
          setSearchQuery(v);
        }}
        sortMenuModal={
          <Menu
            selectedKeys={[orderBy]}
            onClick={async ({ key }: any) => {
              setListLoading(true);
              const params = modifyParams(
                page,
                urlParams.workspaceId as string
              );

              await setAssetList({
                ...params,
                searchQuery,
                orderBy: orderBy && [key],
                flatten: true,
                isFolder: false
              })(dispatch);

              setListLoading(false);
              setOrderBy(key);
            }}
          >
            <span className="help_dropdown__title">Sort by</span>
            <Menu.Item key="createdAt:DESC">
              <span className="help_dropdown__dot" /> Date created
            </Menu.Item>
            <Menu.Item key="modifiedAt:DESC">
              <span className="help_dropdown__dot" /> Date modified
            </Menu.Item>
          </Menu>
        }
        onOrderBy={undefined}
        classNameSearchContainer={undefined}
        classNameActionButton={undefined}
        searchInputComponent={undefined}
        classNamePanel={undefined}
      />
      {foldersData.count ? (
        <InfiniteScroll
          dataLength={foldersData.edges?.length || 0}
          next={() => null}
          hasMore={foldersData.hasNext || false}
          loader={<></>}
          onScroll={async (e: any) => {
            const foldersRect =
              endOfFoldersRef.current?.getBoundingClientRect();
            const filesRect = endOfFilesRef.current?.getBoundingClientRect();

            const foldersElemTop = foldersRect?.top;
            const foldersElemBottom = foldersRect?.bottom;

            const filesElemTop = filesRect?.top;
            const filesElemBottom = filesRect?.bottom;

            const isEndOfFoldersVisible =
              foldersElemTop < e.target.clientHeight + 200 &&
              foldersElemBottom >= 0;

            const isEndOfFilesVisible =
              filesElemTop < e.target.clientHeight + 300 &&
              filesElemBottom >= 0;

            if (!foldersData.edges) return;
            if (!assetsData.edges) return;

            if (
              isEndOfFoldersVisible &&
              foldersData.hasNext &&
              !countToFetchFolders
            ) {
              await loadMoreFolders();
            }

            if (isEndOfFilesVisible && assetsData.hasNext) loadMoreFiles();
          }}
          height="calc(100vh - 288px)"
          style={{ padding: '0 34px', margin: '32px -34px 0' }}
        >
          <div className="campaign_items__title">DELETED FOLDERS</div>
          <FoldersList
            countToFetch={countToFetchFolders}
            isCardViewMode={currentView === 'tile'}
            listLoading={listLoading}
          />
          <div ref={endOfFoldersRef} />
          <br />
          <div className="campaign_items__title">DELETED MEDIA</div>
          <InternalAssetsList
            searchQuery={searchQuery}
            assetsData={assetsData}
            isTileView={currentView === 'tile'}
            displayAssetsCount={displayAssetsCount}
            page={page}
            countToFetch={countToFetchFiles}
            listLoading={listLoading}
          />
        </InfiniteScroll>
      ) : (
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={assetsData.edges?.length || 0}
          next={loadMoreFiles}
          hasMore={assetsData.hasNext || false}
          height="calc(100vh - 260px)"
          // style={{ overflowX: 'hidden', marginRight: -25 }}
          loader={<></>}
          scrollableTarget=""
          className="scrollable_container"
          style={{
            paddingRight: 34,
            paddingLeft: 34,
            marginRight: -34,
            marginLeft: -34
          }}
        >
          <InternalAssetsList
            searchQuery={searchQuery}
            assetsData={assetsData}
            isTileView={currentView === 'tile'}
            displayAssetsCount={displayAssetsCount}
            page={page}
            countToFetch={countToFetchFiles}
            listLoading={listLoading}
          />
        </InfiniteScroll>
      )}
    </div>
  );
}

function AssetsList(props: { page: 'all' | 'my' | 'favorite' | 'deleted' }) {
  const { workspaceId: workspaceIdParam } = useParams();
  const defaultWorkspace = useDefaultWorkspace(false);
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const workspaceId =
    workspaceIdParam ?? currentWorkspace?.id ?? defaultWorkspace?.id;
  useEffect(() => {
    if (workspaceId) setCurrentWorkspace(workspaceId);
  }, [workspaceId, setCurrentWorkspace]);
  const needRedirect = !workspaceId || workspaceId !== workspaceIdParam;
  if (needRedirect) {
    return <Navigate to={workspaceId ?? '/dashboard'} replace />;
  }
  return currentWorkspace ? <AssetsInWorkspace {...props} /> : null;
}

export default AssetsList;
