import React from 'react';
import { Skeleton } from 'antd';
import './ApprovalListSkeleton.less';

function ApprovalListSkeleton() {
  return (
    <>
      {[0, 0, 0].map((_, idx) => (
        <div key={idx} className="assset_item">
          <div className="assset_item__preview approval_list_skeleton">
            <Skeleton.Input style={{ height: 52 }} />
          </div>
          {/* <div className="assset_item__body">
            <div className="assset_item__body_title">
              <Skeleton.Input style={{ width: 252, height: 24 }} />
              <Skeleton.Input style={{ width: 40, height: 24 }} />
            </div>
            <span className="assset_item__body_info">
              <Skeleton.Input style={{ width: 68, height: 24, marginTop: 2 }} />
            </span>
          </div> */}
        </div>
      ))}
    </>
  );
}

export default ApprovalListSkeleton;
