import React, { memo, useMemo } from 'react';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as TaskCommentIconSvg } from '@assets/icons/task-from-comment-icon.svg';
import { ReactComponent as AnnotationIconSvg } from '@assets/icons/task-annotation-icon.svg';
import { ReactComponent as TaskItemNotCheckedSvg } from '@assets/icons/task-table-item-circle.svg';
import { ReactComponent as TaskItemCheckedSvg } from '@assets/icons/task-table-item-checked-circle.svg';
import { ReactComponent as TaskHoverSvg } from '@assets/icons/taskHover.svg';

import { ReactComponent as FlagSvg } from '@assets/icons/flag.svg';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import { useUpdateTask, useDeleteTask } from '@hooks/tasks';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { openTaskModal } from '@redux/reducers/tasks';
import timeLineFromNow from '@components/CampaignTimeline/utils';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';
import Status from '@components/Tasks/Status';
import DueDate from '@components/Tasks/DueDate';
import { Link, useLocation } from 'react-router-dom';
import { isDateSameOrBefore } from '@helpers/compareDueDate';
import { useCurrentTime } from '@hooks/useCurrentTime';

interface DashboardTaskListItemProps {
  taskId: string;
}

export default memo(function DashboardTaskListItem(
  props: DashboardTaskListItemProps
) {
  const { taskId } = props;
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const now = useCurrentTime();
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const isPastDueDate = useMemo(() => {
    return isDateSameOrBefore({
      dateToCompare: task.dueDate,
      baseDate: now
    });
  }, [now, task.dueDate]);
  const isTaskDone = task.status === 'done';

  const actions: MenuItemType[] = [];
  if (task.permissions.update && task.status !== 'done') {
    actions.push({
      key: 'complete',
      label: 'Mark as complete',
      onClick: () => {
        setTimeout(() => updateTask({ id: taskId, status: 'done' }), 500);
      }
    });
  }
  if (task.permissions.delete) {
    actions.push({
      key: 'delete',
      label: 'Delete',
      onClick: () => deleteTask({ id: taskId })
    });
  }

  return (
    <div className="task_item">
      <div className="task_item__body">
        <div className="task_item__body--action">
          {task.status === 'done' ? (
            <TaskItemCheckedSvg
              onClick={() => updateTask({ id: taskId, status: 'todo' })}
            />
          ) : (
            <div className="notCheckedSvg">
              <TaskHoverSvg
                onClick={() => updateTask({ id: taskId, status: 'done' })}
              />
              <TaskItemNotCheckedSvg
                onClick={() => updateTask({ id: taskId, status: 'done' })}
              />
            </div>
          )}
        </div>
        <div
          style={{
            width: 'calc(100% - 36px)',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div className="task_item__body--title">
            {task.assetVersionComment?.hasAnnotations === true && (
              <Tooltip
                overlayClassName="task-group-settings-hint"
                title="This task has an annotation"
                trigger="hover"
                placement="top"
              >
                <AnnotationIconSvg
                  style={{
                    marginRight: 8,
                    height: 20,
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            )}
            {task.assetVersionComment?.hasAnnotations === false && (
              <Tooltip
                overlayClassName="task-group-settings-hint"
                title="This task was created from a comment"
                trigger="hover"
                placement="top"
              >
                <TaskCommentIconSvg
                  style={{
                    marginRight: 8,
                    height: 20,
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            )}
            <span
              className="name"
              onClick={() => dispatch(openTaskModal({ taskId }))}
            >
              {task.name}
            </span>
            <AvatarsGroup
              membersType="task_member"
              maxCount={1}
              users={task.assignees}
              avatarSize={24}
            />
          </div>
          <div className="task_item__body--time">
            <span className="time">{timeLineFromNow(task.lastEventTime)}</span>
            {actions.length && (
              <span className="more">
                <Dropdown
                  overlay={
                    <Menu
                      selectable={false}
                      style={{ padding: '16px 24px', width: 250 }}
                      items={actions}
                    />
                  }
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <Button
                    type="text"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ActionSvg style={{ marginRight: 0 }} />
                  </Button>
                </Dropdown>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="task_item__other">
        {task.priority === 'critical' && <FlagSvg style={{ width: 17 }} />}

        <Status status={task.status} />
        {task.dueDate && (
          <DueDate urgent={!isTaskDone && isPastDueDate} date={task.dueDate} />
        )}
        <div
          className="task_item__other--breadcrumbs"
          style={task.dueDate ? { width: `54%` } : {}}
        >
          <Tooltip
            placement="top"
            overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
            title={task.campaign.name}
          >
            <Link
              to={`/campaigns/single/${task.campaign.id}?tab=tasks&boardId=${task.board.id}`}
              className="link"
            >
              {task.campaign.name}
            </Link>
          </Tooltip>
          {task.asset?.selectedVersion && (
            <Tooltip
              placement="top"
              overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
              title={task.asset.selectedVersion.name}
            >
              <Link
                to={`/media-viewer/${task.asset.id}/${task.asset.selectedVersion.id}`}
                state={{
                  state: {
                    returnTo: location.pathname + location.search
                  }
                }}
                className={`link ${task.asset.isDeleted ? 'link-delete' : ''}`}
              >
                {task.asset.selectedVersion.name}
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
});
