import Uppy, { UppyFile } from '@uppy/core';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Menu, message } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import AwsS3 from '@uppy/aws-s3';
import FileInput from '@uppy/react/src/FileInput';
import DragDrop from '@uppy/react/src/DragDrop';
import useUppy from '@uppy/react/src/useUppy';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import './UploadImage.less';
import { ReactComponent as HelpSvg } from '@assets/icons/help.svg';
import { GetWorkspacePictureUploadParamsSuccessDto } from '@api/Api';
import ImageComponent from '@components/ImageComponent';

interface IUploadImage {
  picture: any;
  onUploadPicture(data: {
    mimeType: string;
    size: number;
  }): Promise<GetWorkspacePictureUploadParamsSuccessDto | any>;
  onRemovePicture?(): void;
  options?: object;
  uploadImageHandler?: any;
  deleteImageHandler?: any;
  setPicture?(file: UppyFile | null | string): void;
  isError?: boolean;
  setIsError?(value: boolean): void;
  uploadButtonText?: string;
  removeButtonText?: string;
  tooltipPlacement?: TooltipPlacement;
}

const tooltipText = (
  <ul>
    <li>Upload a JPG or PNG</li>
    <li>10MB max size</li>
    <li>Solid background color</li>
  </ul>
);

function UploadImage({
  picture,
  onUploadPicture,
  onRemovePicture,
  options,
  uploadImageHandler,
  deleteImageHandler,
  setPicture,
  isError,
  setIsError,
  uploadButtonText,
  removeButtonText,
  tooltipPlacement
}: IUploadImage) {
  const inputRef: any = useRef(null);
  const [pictureUrl, setPictureUrl] = useState<string | null>(null);

  const getPicture = () => {
    if (picture) {
      if (typeof picture === 'string') {
        setPictureUrl(picture);
        return;
      }
      const objectURL: string = URL.createObjectURL(picture?.data);
      setPictureUrl(objectURL);
      return;
    }
    setPictureUrl(null);
  };

  useEffect(() => {
    getPicture();
    if (!picture) uppy.cancelAll();
  }, [picture]);

  const uppy = useUppy(() =>
    new Uppy({
      id: 'uppy',
      autoProceed: false,
      infoTimeout: 5000,
      restrictions: {
        maxFileSize: 10 * 1024 * 1024,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
        allowedFileTypes: ['.jpg', '.png']
      },
      ...options
    })
      .use(AwsS3, {
        getUploadParameters(file: any): Promise<any> {
          const additionalParams: { [key: string]: string | undefined } = {
            workspaceId: file.meta.workspaceId
          };
          return onUploadPicture({
            mimeType: file.type,
            size: file.size,
            ...additionalParams
          }).then(({ data }) => ({
            method: data.method,
            url: data.url,
            fields: data.fields,
            headers: data.headers
          }));
        }
      })
      .on('restriction-failed', (e, error) => {
        message.error({
          content: error?.message || 'Something went wrong'
        });
        if (setIsError) setIsError(true);
        if (setPicture) setPicture(null);
      })
      .on('file-added', (file: UppyFile) => {
        if (setIsError) setIsError(false);
        if (setPicture) setPicture(file);
      })
  );

  const uploadPicture = async (id: string) => {
    uppy.setMeta({ workspaceId: id });
    await uppy.upload();
  };

  const onDelete = () => {
    uppy.cancelAll();
    setPictureUrl(null);
    if (setPicture) setPicture(null);
  };

  const deletePicture = async () => {
    if (onRemovePicture) {
      try {
        await onRemovePicture();
        onDelete();
      } catch (error) {
        console.warn(error, '-> Delete picture');
        if (setIsError) setIsError(true);
      }
      return;
    }
    onDelete();
  };

  const onReset = () => {
    uppy.cancelAll();
    inputRef.current?.plugin.handleClick();
  };

  useEffect(() => {
    if (uploadImageHandler) uploadImageHandler.current = uploadPicture;
    if (deleteImageHandler) deleteImageHandler.current = deletePicture;
  }, []);

  const itemsActions = [
    { label: uploadButtonText, key: '0', onClick: onReset },
    { label: removeButtonText, key: '1', onClick: deletePicture }
  ];

  return (
    <div className="picture_container">
      {pictureUrl ? (
        <>
          <Tooltip
            title={<Menu items={itemsActions} />}
            overlayClassName="picture_control_dropdown"
            color="white"
            trigger="click"
            placement={tooltipPlacement}
          >
            <div className="picture_control_inner">
              <ImageComponent
                src={pictureUrl}
                width={100}
                height={100}
                alt="Uploaded image"
                types={!picture?.data ? ['png', 'webp'] : null}
                id=""
                name=""
              />
              <div className="picture_control-dots" role="button">
                <span className="picture_control-icon-dot" />
                <span className="picture_control-icon-dot" />
                <span className="picture_control-icon-dot" />
              </div>
            </div>
          </Tooltip>
          <FileInput
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={inputRef}
            uppy={uppy}
            pretty
            inputName="files[]"
          />
        </>
      ) : (
        <DragDrop
          className={`picture_control_drag-prop ${isError ? 'error' : ''}`}
          uppy={uppy}
        />
      )}
      <Tooltip
        title={tooltipText}
        placement="right"
        color="white"
        overlayClassName="picture_control_tooltip"
      >
        <HelpSvg
          className={`picture_control_icon-help ${isError ? 'error' : ''}`}
          role="button"
        />
      </Tooltip>
    </div>
  );
}

UploadImage.defaultProps = {
  options: {},
  uploadImageHandler: null,
  deleteImageHandler: null,
  uploadButtonText: 'Upload new image or logo',
  removeButtonText: 'Remove',
  onRemovePicture: null,
  isError: false,
  setIsError: null,
  setPicture: null,
  tooltipPlacement: 'bottom'
};

export default UploadImage;
