import { AssetPermissionsDto, AssetVersionItemDto } from '@api/Api';
import { toggleManageAssetVersionsModal } from '@redux/actions/modalAction';
import React from 'react';
import { useDispatch } from 'react-redux';

import './AssetVersionsList.less';

AssetVersionsList.defaultProps = {
  versionsCount: 0,
  versions: [],
  permissions: null,
  assetId: ''
};

function AssetVersionsList({
  versionsCount,
  versions,
  permissions,
  assetId
}: {
  versionsCount?: number;
  versions?: AssetVersionItemDto[];
  permissions?: AssetPermissionsDto;
  assetId?: string;
}) {
  const dispatch = useDispatch();
  return (
    <div style={{ display: 'flex', alignContent: 'center' }}>
      <span
        className="version asset-version-button"
        onClick={() =>
          dispatch(
            toggleManageAssetVersionsModal({
              versions: versions || [],
              permissions: permissions || null,
              versionsCount: versionsCount || 0,
              visible: true,
              assetId: assetId || ''
            })
          )
        }
      >
        v{versionsCount}
      </span>
    </div>
  );
}

export default AssetVersionsList;
