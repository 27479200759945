import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { useOrganization } from '@components/OrganizationBoundary';
import { toggleWorkspaceCreationModal } from '@redux/actions/modalAction';
import { Button, Input } from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

type TabBarExtraContentProps = {
  value: string;
  isManageWorkspaceTabActive: boolean;
  onChange: (value: string) => void;
};

export default memo(function TabBarExtraContent({
  value,
  isManageWorkspaceTabActive,
  onChange
}: TabBarExtraContentProps) {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization(true);

  if (!currentOrganization.entity) return null;

  return (
    <div className="operations">
      <Input
        style={{ width: 260 }}
        placeholder={`Search by ${
          isManageWorkspaceTabActive ? 'workspace name' : 'user name or email'
        }`}
        value={value}
        prefix={<SearchSvg />}
        size="large"
        onChange={(e) => onChange(e.target.value)}
      />
      <Button
        disabled={!currentOrganization.entity.permissions.createWorkspaces}
        size="middle"
        type="primary"
        onClick={() => {
          dispatch(toggleWorkspaceCreationModal({ visible: true }));
        }}
      >
        Create workspace
      </Button>
    </div>
  );
});
